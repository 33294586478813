/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, MouseEventHandler, useContext, useRef } from 'react';
import styles from './header.module.scss';
import LanguageSelect from '../languageSelect/languageSelect';
import accionaLogo from '../../assets/accionaLogo.svg';
import phiaName from '../../assets/phia-name.svg';
import avatar from '../../assets/avatar.svg';
import logoutIcon from '../../assets/logout.svg';
import { AuthContext } from '../../services/authContextProvider';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import './header.scss';
import CheckSaved from '../checkSaved/checkSaved';
import { UserModeContext } from '../../context/UserModeProvider';
import { SaveContext } from '../../context/SaveProvider';
import Notification from '../notification/Notification';
import packageJson from '../../../package.json';
import AdminPanelButton from '../adminPanelButton/adminPanelButton';
import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface headerProps {
  noAdmin?: boolean;
  permissionError?: boolean;
  mapIsSelected?: boolean;
  handleChangeSwitch?: MouseEventHandler;
  children?: JSX.Element;
}

const header: FC<headerProps> = (props) => {
  const { noAdmin, permissionError } = props;
  const authContext = useContext(AuthContext);
  const { username, logout } = authContext;
  const userModeContext = useContext(UserModeContext);
  const saveContext = useContext(SaveContext);
  const [t] = useTranslation();
  const selectRef = useRef<any>(null);
  const [goBack, setGoBack] = React.useState<boolean>(false);
  const [goBackLogo, setGoBackLogo] = React.useState<boolean>(false);
  const [goBackAdmin, setGoBackAdmin] = React.useState<boolean>(false);
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const logoutHandler = () => {
    setGoBack(false);
    logout();
  };

  const goback = (url: string) => {
    saveContext.saveDraft = false;
    saveContext.handleChangeSave(false);
    setGoBackLogo(false);
    setGoBackAdmin(false);
    navigate(url);
  };

  const discardChanges = () => {
    saveContext.saveDraft = false;
    saveContext.handleChangeSave(false);
    setGoBackLogo(false);
    setGoBackAdmin(false);
  };

  return (
    <div className={styles.header}>
      <div className={styles.mainHeader} data-testid="header-test">
        <div className={styles.mainImages} onClick={() => {
          userModeContext.userMode === 'editor' && saveContext.saveDraft
            ? setGoBackLogo(true)
            : navigate(permissionError ? '/error-permisos' : '/');
        }}>
          <img src={accionaLogo} />
          <img src={phiaName} className={styles.phiaName} />
          <div className={styles.version}>{'v' + packageJson.version}</div>
        </div>
        {
          (goBack) ?
            <CheckSaved handleFunction={() => logoutHandler()} closeFunction={() => setGoBack(true)} />
            : null
        }
        {
          (goBackLogo) ?
            <CheckSaved
              handleFunction={() => {
                goback('/');
              }}
              closeFunction={() => {
                setGoBackLogo(false);
              }}
              discardChanges={() => {
                discardChanges();
              }}
            />
            : null
        }
        {
          (goBackAdmin) ?
            <CheckSaved
              handleFunction={() => {
                goback('/admin');
              }}
              closeFunction={() => {
                setGoBackAdmin(false);
              }}
              discardChanges={() => {
                discardChanges();
              }}
            />
            : null
        }
        <div className={styles.leftActions}>
          {!noAdmin && <AdminPanelButton onClick={() => {
            saveContext.saveDraft ? setGoBackAdmin(true) : navigate('/admin');
          }} />}

          <div className={styles.notification}>
            <Notification />
          </div>
          <div id='header-userlogin' className={styles.userLogin} >
            <span>
              <Select
                ref={selectRef}
                value={username}
                variant="standard"
                disableUnderline={true}
                onChange={() => {
                  userModeContext.userMode === 'editor' && saveContext.saveDraft ? setGoBack(true) : logoutHandler();
                }}
                inputProps={{ IconComponent: () => null }}
                MenuProps={{ disablePortal: true }}
                onClose={() => {
                  selectRef.current.blur();
                }}
                renderValue={() => {
                  return <div className={styles.boxUserName}>
                    <img className={styles.avatar} src={avatar} />
                    <div id='labelname' className={styles.labelName} >
                      {username}
                    </div>
                  </div>;
                }}
              >
                <MenuItem disableRipple value={'es'} className={styles.menuItem}>
                  <div className={styles.optionMenu}>
                    <img src={logoutIcon} />
                    <div id='header-logout'><span>{t('header.logout')}</span><div />
                    </div>
                  </div>
                </MenuItem>
              </Select>
            </span>
          </div>
          <div id='header-boxlanguage' className={styles.boxLanguage} >
            <LanguageSelect></LanguageSelect>
          </div>
        </div>
      </div>
      <div className={styles.secondaryHeader}>
        {props.children}
      </div>
    </div >
  );
};

export default header;
