/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC } from 'react';
import styles from './clientBox.module.scss';


interface Field {
  title: string;
  value: string | number;
}

interface ClientBoxProps {
  picture?: string;
  items?: Field[];
  identifier?: any
}

const ClientBox: FC<ClientBoxProps> = (props) => {
  const { picture, items, identifier } = props;

  return (
    <div className={styles.box}>
      <table>
        <tr>
          {picture &&

            <td>
              <div className={styles.pictureBox}>
                <img src={picture} className={styles.picture} id={`${identifier}-picture`} />
              </div>
            </td>
          }

          {items?.map((item) => {
            return item !== undefined &&
              <td className={styles.fieldContainerItem}>
                <div className={styles.field}>
                  <div id={`${identifier}-title`} className={styles.title}>{item?.title}</div>
                  <div id={`${identifier}-value`} className={styles.value}>{item?.value}</div>
                </div>
              </td>;
          })
          }
        </tr>
      </table>
    </div>
  );
};

export default ClientBox;
