/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC } from 'react';
import styles from './InfoBoxBar.module.scss';
import parse from 'html-react-parser';
import LevelBar from '../../../components/levelBar/LevelBar';

interface Field {
  title: string;
  value: string | number;
}

interface Color {
  backgroundColor: string;
  color: string;
  fairColor: string;

}

interface InfoBoxBarProps {
  item: Field;
  identifier?: any;
  style: Color;
  level: number;

}

const InfoBoxBar: FC<InfoBoxBarProps> = (props) => {
  const { item, identifier, style, level } = props;

  return (
    <div className={styles.box} style={{ backgroundColor: style.backgroundColor }}>

      <div className={styles.field} >
        <div id={`${identifier}-title`} className={styles.title}>{parse(item?.title)}</div>
        <div id={`${identifier}-value`} className={styles.value}>{item?.value}</div>
      </div>
      <div className={styles.bar}>
        <LevelBar identifier={identifier}
          color={{ fairColor: style.fairColor, darkColor: style.color }}
          level={level}
          isPorcentualValue={true} />
      </div>


    </div >
  );
};

export default InfoBoxBar;
