import { FilterCollapsableWrapper } from '../../FiltersWrappers/FilterCollapsableWrapper/FilterCollapsableWrapper';
import CustomSimpleSelector from './customSimpleSelector/customSimpleSelector';

export const SimpleSelector = ({ filterType,  noBottomDivider }: any) => {
  return (
    <>
      <FilterCollapsableWrapper
        filterType={filterType}
        noBottomDivider={noBottomDivider}
      >
        <CustomSimpleSelector filterType={filterType} />
      </FilterCollapsableWrapper>
    </>
  );
};