import React, { useEffect, useState, type FC, useContext } from 'react';
import Header from '../../components/header/header';
import styles from './editRoles.module.scss';
import DetailsViewProvider from '../../context/DetailsViewProvider';
import TabMenuAdmin from '../../components/tabMenuAdmin/tabMenuAdmin';
import ObrasAPI from '../../services/APIObras';
import { t } from 'i18next';
import SimpleSelect from '../fullDetail/components/simpleSelect/simpleSelect';
import { Zone, Center, Supercenter } from '../../types/Option';
import { Button, Dialog, Stack, SvgIcon } from '@mui/material';
import { AdminContext, selectOptions } from '../../context/AdminProvider';
import LoadingSpinner from '../../components/loadingSpinner/loadingSpinner';
import { ReactComponent as error } from '../../assets/error.svg';
import { Usuario, UsuarioProyecto } from '../../types/Admin';
import { ADMIN, DOWNLOAD, EDITOR, VALIDATOR } from '../../userRoles/userRoles';



// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface EditRolesProps {
  hasProject: boolean,
}
export const renderRole = (role?: string) => {
  switch (role) {
  // case 'viewer':
  // case 'viewer_vip':
  //   return t('administration.table.viewer');
    case 'editor':
      return t('administration.table.editor');
    case 'validator':
      return t('administration.table.validator');
    case 'administrator':
      return t('administration.table.admin');
    case 'download':
      return t('administration.table.download');
  }
};
/* eslint-disable @typescript-eslint/no-non-null-assertion */
const EditRoles: FC<EditRolesProps> = (props) => {
  const { hasProject } = props;
  const api = ObrasAPI.getInstance();
  const [editable, setEditable] = useState(true);
  const [editableSupercenter, setEditableSupercenter] = useState(false);
  const [editableCenter, setEditableCenter] = useState(false);
  const allOptions = [t('administration.edition.allZones'), t('administration.edition.allSupercenters'), t('administration.edition.allProjects')];
  const selectAllOptions = allOptions.map((nombre, id) => { return { id, nombre }; });
  const adminContext = useContext(AdminContext);

  const optionsRoles = [
    t('administration.table.editor'),
    t('administration.table.validator'),
    t('administration.table.download'),
    t('administration.table.admin')
  ];

  const selectOptionsRoles = optionsRoles.map((nombre, id) => { return { id: id + 1, nombre }; }); // id + 1 because we start by EDITOR, which has id=1
  const rol = renderRole(adminContext.permissionToEdit?.rol);
  const defaultRole = (hasProject && rol) ? selectOptionsRoles.find((option) => option.nombre === rol) : selectOptionsRoles[0];
  const [role, setRole] = useState(defaultRole);

  const optionsEnterprise = ['Acciona Construcción'];
  const selectOptionsEnterprise = optionsEnterprise.map((nombre, id) => { return { id, nombre }; });

  const [optionsZone, setOptionsZone] = React.useState<Zone[]>([]);
  const selectOptionsZones = optionsZone?.length ? optionsZone.map((t, id) => { return { id, nombre: t.codZona + ' ' + t.nomZona }; }) : [];
  const defaultZone = hasProject ? selectOptionsZones.find((option) => option.nombre.split(' ')[0] === adminContext.projectToAdd?.codZona) : [];
  const [selectedZone, setSelectedZone] = React.useState<any>(defaultZone);


  const [optionsSupercenter, setOptionsSupercenter] = React.useState<Supercenter[]>([]);
  const selectOptionsSupercenters = optionsSupercenter?.length ? optionsSupercenter.map((t, id) => { return { id, nombre: t.codSupercentro + ' ' + t.nomSupercentro }; }) : [];
  const [selectedSupercenter, setSelectedSupercenter] = React.useState<any>([]);

  const [optionsCenter, setOptionsCenter] = React.useState<Center[]>([]);
  const selectOptionsCenters = optionsCenter?.length ? optionsCenter.map((t, id) => { return { id, nombre: t.codCentro + ' ' + t.nomCentro }; }) : [];
  const [selectedCenter, setSelectedCenter] = React.useState<any>([]);

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [openError, setOpenError] = useState(false);

  const handleChangeRol = (data: any) => {
    // if the role is admin or download, the user can't select a zone, supercenter or center
    if (data.id == 3 || data.id == 4) {
      setEditable(false);
      setEditableSupercenter(false);
      setEditableCenter(false);
      setSelectedZone([]);
      setSelectedCenter([]);
      setSelectedSupercenter([]);
    } else {
      setEditable(true);
    }
    setRole(data);
  };

  const handleChangeZone = (data: any) => {
    const supercentros = optionsZone[data].supercentros;
    if (supercentros.length) {
      setEditableSupercenter(true);
      setOptionsSupercenter(supercentros);
    } else {
      setEditableSupercenter(false);
    }
    setSelectedCenter(undefined);
    setSelectedSupercenter(undefined);
    setEditableCenter(false);
    setSelectedZone(selectOptionsZones[data]);
  };

  const handleChangeSupercenter = (data: any) => {
    const centros = optionsSupercenter[data].centros;
    if (centros.length) {
      setEditableCenter(true);
      setOptionsCenter(centros);
    } else {
      setEditableCenter(false);
    }
    setSelectedSupercenter(selectOptionsSupercenters[data]);
    setSelectedCenter(undefined);
  };
  const handleChangeCenter = (data: any) => {
    setSelectedCenter(selectOptionsCenters[data]);
  };

  useEffect(() => {
    setLoading(true);
    api.getZones().then(
      (results: Zone[]) => {
        if (hasProject) {
          // Filter zones
          const selectOptionsZones = results.map((t, id) => { return { id, nombre: t.codZona + ' ' + t.nomZona, }; });
          const defaultZone = selectOptionsZones.find(
            (option) => option.nombre.split(' ')[0] === adminContext.projectToAdd?.codZona);
          setSelectedZone(defaultZone);

          // Filter supercenters
          const zoneSupercenters = results.find(
            (zone: Zone) => zone.codZona === defaultZone?.nombre.split(' ')[0])?.supercentros;
          const selectOptionsSupercenters = zoneSupercenters?.map((supercenter: Supercenter, id: number) => ({
            id,
            nombre: supercenter.codSupercentro + ' ' + supercenter.nomSupercentro,
          }));
          const defaultSupercenter = selectOptionsSupercenters?.find(
            (option) => option.nombre.split(' ')[0] === adminContext.projectToAdd?.codSupercentro);
          setSelectedSupercenter(defaultSupercenter);
          if (zoneSupercenters && zoneSupercenters.length > 0) {
            setOptionsSupercenter(zoneSupercenters);
            setEditableSupercenter(true);
          }

          // Filter centers
          const supercenterCenters = zoneSupercenters?.find(
            (supercenter: Supercenter) => supercenter.codSupercentro === defaultSupercenter?.nombre.split(' ')[0])?.centros;
          const selectOptionsCenters = supercenterCenters?.map((center: Center, id: number) => ({
            id,
            nombre: center.codCentro + ' ' + center.nomCentro,
          }));
          
          const defaultCenter = selectOptionsCenters?.find(
            (option) => option.nombre.split(' ')[0] === adminContext.projectToAdd?.codCentro);
          setSelectedCenter(defaultCenter);
          if (supercenterCenters && supercenterCenters.length > 0) {
            setOptionsCenter(supercenterCenters);
            setEditableCenter(true);
          }
        }
        setOptionsZone(results);
        setLoading(false);
      }
    ).catch((error: any) => {
      console.log(error);
      setOptionsZone([]);
    });
  }, []);

  const saveRole = async (
    id: number | undefined,
    email: string | undefined,
    rol: string | undefined,
    codCentro: string,
    codSupercentro: string,
    codZona: string,
    codEmpresa: string
  ) => {
    setSaving(true);
    const data = id ? await api.editRole(id, email, rol, codCentro, codSupercentro, codZona, codEmpresa) :
      await api.addRole(id, email, rol, codCentro, codSupercentro, codZona, codEmpresa);
    if (data.error) {
      setOpenError(true);
      setSaving(false);
    } else {
      if (
        (adminContext.projectToAdd?.codCentro === codCentro
        || adminContext.projectToAdd?.codSupercentro === codSupercentro && !codCentro
        || adminContext.projectToAdd?.codZona === codZona && !codSupercentro && !codCentro)
      ){
        const cachedUsersPerProject = adminContext.usersPerProject;
        const filterPerProject = (user: UsuarioProyecto) => user.email === email;
        
        const currentUserPerProject = cachedUsersPerProject.find(filterPerProject);
        
        //const rol = data.rol;
        if(currentUserPerProject){ 
          const scope = codCentro ? 'project' : (codSupercentro ? 'supercenter' : (codZona && 'zone'));  
          const indexOfUserPerProject = cachedUsersPerProject.findIndex(filterPerProject);       
          const updatedUserPerProject = {
            idPermiso: currentUserPerProject.idPermiso,
            email: currentUserPerProject.email,
            rol: rol!,
            alcance: scope,
          };
          const updatedUsersPerProject = [...cachedUsersPerProject.slice(0,indexOfUserPerProject),updatedUserPerProject,...cachedUsersPerProject.slice(indexOfUserPerProject+1)];
          adminContext.handleChangeUsersPerProject(updatedUsersPerProject);
        } else{
          const scope = codCentro ? 'project' : (codSupercentro ? 'supercenter' : (codZona && 'zone'));
          const updatedUserPerProject = {
            idPermiso: id!,
            email: email!,
            rol: rol!,
            alcance: scope,
          };
          const updatedUsersPerProject = [...cachedUsersPerProject,updatedUserPerProject];
          adminContext.handleChangeUsersPerProject(updatedUsersPerProject);
        }        
      }else{
        const updatedUsersPerProject = [...adminContext.usersPerProject].filter((user) => user.email !== email);
        adminContext.handleChangeUsersPerProject(updatedUsersPerProject);
      }

      const cachedUsers = Array.from(adminContext.allUsers);
      const filter = (user: Usuario) => user.email === email;
      const currentUser = cachedUsers.find(filter);
      if(currentUser){
        const indexOfUser = cachedUsers.findIndex(filter);
        const grupos = data;
        const updatedUser = {
          id: currentUser.id,
          email: currentUser.email,
          username: currentUser.username ,
          nombre: currentUser.nombre ,
          grupos,
          permisos: currentUser.permisos
        }; 
        const updatedUsers = [...cachedUsers.slice(0,indexOfUser),updatedUser,...cachedUsers.slice(indexOfUser + 1)];
        adminContext.handleSetAllUsers(updatedUsers);
      }
      adminContext.handleChangeUpdatedPermissions({}); // Removing cached permissions from context
      setSaving(false);
      adminContext.handleSetIsEdition(false);
    }
  };

  const checkRole = (id?: number) => {
    switch (id) {
      case 1:
        return EDITOR;
      case 2:
        return VALIDATOR;
      case 3:
        return DOWNLOAD;
      case 4:
        return ADMIN;
    }
  };

  const renderErrorSaving = () => {
    return <Dialog
      classes={{
        paper: styles.dialogError
      }}
      BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.15)' } }}
      PaperProps={{ sx: { borderRadius: '20px', minWidth: '700px' } }}
      open={openError}
      onClose={() => setOpenError(false)}
    >
      <div className={styles.dialogContentError}>
        <div data-testid='error-test' className={styles.serverError}>
          <div className={styles.serverErrorContent}>
            <SvgIcon component={error} inheritViewBox />
            <div id='error-title' className={styles.title}>{t('administration.errorTitle')}</div>
            <div id='error-subtitle' className={styles.subtitle}>{t('administration.errorDescription')}</div>
          </div>
        </div>
        <Stack spacing={2} direction="row" justifyContent="center">
          <Button className={styles.buttonConfirm} onClick={() => {
            setOpenError(false);
          }} disableRipple={true} variant="contained">{t('administration.acceptButton')}</Button>
        </Stack>
      </div>
    </Dialog>;
  };
  // if the role is admin or download, the user can't select a zone, supercenter or center
  const showOptions = role?.id !== 3 && role?.id !== 4;

  return (
    <DetailsViewProvider>
      <div data-testid="edit-test" className='editRoles'>
        <div>
          <Header>
            <TabMenuAdmin editRoles={true} />
          </Header>
          <div className={styles.editPanel}>
            {(loading || saving) && <LoadingSpinner />}
            {renderErrorSaving()}
            {!loading &&
              <div className={styles.editcontainer}>
                <div className={styles.container}>
                  <div className={styles.selectSearch}>
                    <SimpleSelect
                      id='select-rol'
                      options={selectOptionsRoles}
                      value={role}
                      label={t('administration.role')}
                      handleChangeValue={(data: any) => handleChangeRol(data)}
                      editable={true}
                    />
                  </div>
                </div>
                <div className={styles.container}>
                  <div className={styles.selectSearch}>
                    <SimpleSelect
                      id='select-enterprise'
                      options={selectOptionsEnterprise}
                      value={selectOptionsEnterprise[0]}
                      label={t('administration.enterprise')}
                      handleChangeValue={() => null}
                      editable={editable && showOptions}
                    />
                  </div>
                </div>
                <ul>
                  <li className={styles.zone}>
                    <div className={styles.container}>
                      <div className={styles.selectSearchZone}>
                        <SimpleSelect
                          id='select-zone'
                          options={selectOptionsZones}
                          value={editable && showOptions ? selectedZone : selectAllOptions[0]}
                          label={t('administration.zone')}
                          handleChangeValue={(data: any) => handleChangeZone(data.id)}
                          editable={editable && showOptions}
                        />
                      </div>
                    </div>
                  </li>
                  <li className={styles.supercenter}>
                    <div className={styles.container}>
                      <div className={styles.selectSearchSupercenter}>
                        <SimpleSelect
                          id='select-supercenter'
                          options={selectOptionsSupercenters}
                          value={editable && showOptions ? selectedSupercenter : selectAllOptions[1]}
                          label={t('administration.supercenter')}
                          handleChangeValue={(data) => handleChangeSupercenter(data.id)}
                          editable={editableSupercenter && showOptions}
                        />
                      </div>
                    </div>
                  </li>
                  <li className={styles.center}>
                    <div className={styles.container}>
                      <div className={styles.selectSearchCenter}>
                        <SimpleSelect
                          id='select-center'
                          options={selectOptionsCenters}
                          value={editable && showOptions ? selectedCenter : selectAllOptions[2]}
                          label={t('administration.center')}
                          handleChangeValue={(data) => handleChangeCenter(data.id)}
                          editable={editableCenter && showOptions}
                        />
                      </div>
                    </div>
                  </li>
                </ul>
                <Stack spacing={2} direction="row" justifyContent="right" marginTop={'3.5em'}>
                  <Button className={styles.buttonSave}
                    onClick={() => {
                      saveRole(
                        adminContext.permissionToEdit?.id,
                        adminContext.userToEdit?.email,
                        checkRole(role?.id),
                        selectedCenter?.nombre?.split(' ')[0],
                        selectedSupercenter?.nombre?.split(' ')[0],
                        selectedZone?.nombre?.split(' ')[0],
                        '0008');
                    }}
                    disableRipple={true}
                    variant="contained">{t('save')}</Button>
                </Stack>
              </div>}
          </div>
        </div>
      </div>
    </DetailsViewProvider>
  );
};

export default EditRoles;

