export interface ObraProperties {
  aspectosTecnicos: ObraAspectosTecnicos;
  cliente: ObraCliente;
  contrato: ObraContrato;
  descripcion: ObraDescripcion;
  fechasPlazos: ObraFechas;
  general: ObraGeneralInfo;
  localizacion: ObraLocalizacion;
  presupuesto: ObraPresupuesto;
  socios: ObraSocios;
  clasificacion: ObraClasificacion;
  bim: ObraBim;
  comentario?: Comentario;
  esg: ObraESG;
}


export interface ObraAspectosTecnicos {
  procesosConstructivos: ProcesoConstructivo[];
  unidades: UnidadObra[];
  tiposObras: TipoObra[];
}

export interface ProcesoConstructivo {
  nombre: string;
  codigo: string;
  unidades: UnidadObra[];
}

export interface UnidadObra {
  id?: number;
  idGrupoProceso?: number;
  idProceso?: number;
  unidad?: Unidad;
  medicion?: number | undefined;
}

export interface Unidad {
  id?: number;
  codUnidad: string;
  nomUnidad: string;
  mandatory?: boolean;
}

export interface TipoObra {
  principal: boolean;
  origen: string;
  tipo?: Tipo;
  subtipo?: Subtipo;
  magnitudes?: Magnitud[]
}

export interface Tipo {
  id?: number;
  codTipo: string;
  nomTipo: string;
}
export interface Subtipo {
  id?: number;
  codSubtipo: string;
  nomSubtipo: string;
}
export interface Magnitud {
  id?: number;
  idMagnitudMaestro: number;
  nomMagnitud: string;
  medicion?: number | undefined;
}

export interface ObraCliente {
  asistenciasTecnicas: AlcanceEntidad[];
  direccionObra: AlcanceEntidad[];
  disenador: AlcanceEntidad[];
  codCliente: number;
  nomCliente: string;
}

export interface AlcanceEntidad {
  alcance: string;
  entidad: string;
  origen?: string;
  estado: Estado;
  fecInicioColaboracion?: string;
  fecFinColaboracion?: string;
  id?: string;
}
type Estado = MasterBasic;

export interface ObraContrato {
  alcance: string[],
  fuentesFinanciacion: string[],
  periodoGarantia: string,
  tipoPeriodoGarantia: string,
  modelo: string[],
  pago: string[],
  tipos: string[],
  codEstado: string,
  nomEstado: string,
}

export interface ObraDescripcion {
  descDetallada: LanguageObject;
  infoDestacable: LanguageObject;
  descGeneral: LanguageObject;
  documentacionFaseLicitacion: string;
  documentacionProyecto: string;
  fotos: Foto[];
  webs: string[];
}

export enum TipoDocumento {
  LICITACIÓN = 0,
  EJECUCION = 1,
  WEB = 2,
  EMPTY = 3,
}

export interface Foto {
  id?: number;
  tipo?: TipoFoto;
  descripcion: string;
  url: string;
  iconica: boolean
}
export interface TipoFoto {
  id: number;
  tipo: string;
}

export interface LanguageObject {
  [key: string]: string
}

export interface ObraFechas {
  fecActaRecepcion: string;
  fecActaReplanteo: string;
  fecFinEjecucion: string;
  fecFinalizacionActualizada: string;
  fecFinalizacionInicial: string;
  fecFirmaContrato: string;
  fecInicio: string;
  plazoActualizado: string;
  plazoInicial: number;
  tipoPlazoInicial: string;
}

export interface ObraGeneralInfo {
  codCentro: string;
  nomOficialCentro: string;
  nomPais: string;
  codLicitacion: number,
  actividadNegocio: string,
  codPais: string,
  codSupercentro: number,
  codUne: string,
  codZona: number,
  nomInternoCentro: string,
  nomResponsable: string,
  nomSupercentro: string,
  nomUne: string,
  nomZona: string,
  nomEstadoPhia: string,
  codEstadoPhia: number,
  porcParticipacionActualizada: number,
  dap?: boolean,
}

export interface ObraLocalizacion {
  coordenadas: Coordinates;
  espacioProteccion: EspacioProteccion[];
  especiesPeligro: EspeciesPeligro[];
  zonasProteccion: ZonaProteccion[];
  kmz: string;
  municipioPrincipal: Municipio;
  municipiosAdicionales: Municipio[];
}

export interface ZonaProteccion {
  id?: number;
  nombre: string;
  proteccion: MasterBasic;
}

export interface MasterBasic {
  id: number,
  nombre: string
}

export interface Municipio {
  nomMunicipio: string;
  codigoPostal: string;
}

export interface Coordinates {
  lat?: number;
  lon?: number;
}

export interface EspacioProteccion {
  id?: number;
  areasProtegidas: AreaProtegida[];
  nombre: string;
  superficieArea: number | null;
  superficieProyecto: number | null;
  ubicacion: Ubicacion;
}

export interface EspeciesPeligro {
  id?: number;
  nombre: string;
  nivel: Nivel;
}

type Nivel = MasterBasic;
type AreaProtegida = MasterBasic;
type Ubicacion = MasterBasic;

export interface Municipio {
  nomMunicipio: string;
  codigoPostal: string;
}

export interface ObraPresupuesto {
  fecAdjudicacion: string;
  fecAprobacionEconomica: string;
  fecAprobacionEconomicaFinal: string;
  monedaLocal: string;
  importeAdjudicacion: Importe,
  importeActualizado: Importe,
  importeActualizadoFinal: Importe;
}

export interface Importe {
  monedaLocal: number;
  monedaEur: number;
  accionaMonedaLocal: number;
  accionaMonedaEur: number;
  tipoCambio: number;
}

export interface ObraSocios {
  empresas: Entidad[];
  empresasActualizadas: Entidad[];
  fechaParticipacionActualizacion: string;
}

export interface Entidad {
  id: string;
  nombre: string;
  participacion: number;
}

export interface ObraClasificacion {
  grupos: Grupo[];
}

export interface Grupo {
  id?: number;
  grupo?: MaestroCodigoNombre;
  subGrupo?: MaestroCodigoNombre;
  origen: string;
  importeEstimado?: number;
  importeFinal?: number;
}

export interface MaestroCodigoNombre {
  id?: number;
  codigo: string;
  nombre: string;
}

export interface ObraBim {
  requisitos: number,
  notaGlobal: number,
  notaUsosBIM: number,
  notaUsosGestion: number,
  notaUsosEstandarizacion: number,
  categorizacion: Categorizacion,
  madurez: Madurez
  usosBIM: UsoBIM[],
  cde: boolean,
}

export interface ObraESG {
  eligibilidad: boolean,
  codNace1?: string,
  objetivosTaxonomicos?: ObjetivoTaxonomico[],
  salvaguardasSociales?: SalvaguardaSocial[],
  controversias?: '',
  presupuestoCarbono?: number,
  compromisosTaxonomicos?: string,
  dap?: boolean,
  exentoGIS?: boolean,
  gis?: boolean,
  categorizacionSocial?: string,
  presupuestoGIS?: number,
  porcentajeGIS?: number,
  certificadosSostenibilidad?: Certificado[],
}

interface ObjetivoTaxonomico {
  codObjetivo: string,
  nomObjetivo: string,
  actividades: Actividad[],
}

interface Actividad {
  codActividad: string,
  nomActividad: string,
}

interface SalvaguardaSocial {
  codigo: number,
  descripcion: string,
}

export interface Certificado {
  id?: number;
  niveles?: Nivel[];
  nomNivelActualizado?: string;
  codNivelActualizado?: string;
  origen?: string;
  codCertificado?: string,
  nomCertificado: string,
  codNivel?: string,
  nomNivel: string,
}


interface Categorizacion {
  codigo: number,
  descripcion: string,
}

interface Etapa {
  codigo: number,
  descripcion: string,
}

interface Madurez {
  codigo: number,
  descripcion: string,
}

export interface UsoBIM {
  codigo: number,
  descripcion: string,
  nivel: string,
  etapa: Etapa

}

export interface Comentario {
  titulo: string;
  detalles: string;
}
