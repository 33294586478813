/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import SvgIcon from '@mui/material/SvgIcon';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ReactComponent as type_obra } from '../../assets/type_obra.svg';
import { ReactComponent as date_obra } from '../../assets/date_obra.svg';
import './Infowindow.scss';
import { ObraProperties } from '../../types/ObraMap';

interface InfowindowProp {
  obra: ObraProperties,
  goDetails: any,
  t: (key: string) => string,
}

function Infowindow(props: InfowindowProp) {
  const { obra, goDetails, t } = props;

  const handleClick = (
    obra: ObraProperties,
  ) => {
    goDetails(`${obra.codCentro}/detalles`);
    // navigate(`$${obra.codCentro}/detalles`);
  };

  const dateToYear = (date: string) => {
    const splitDate = date.split('/');
    return date.split('/')[splitDate.length - 1];
  };

  return <Box data-testid='infowindow-test'>
    <List component="nav" aria-label='obra-infowindow' className='obra-infowindow' >
      <ListItem className='name' >
        <ListItemText primary={obra.nomInternoCentro} />
      </ListItem>
      <ListItem  className='code' >
        <ListItemText primary={obra.codCentro} />
      </ListItem>
      <ListItem  className='type' >
        <ListItemIcon>
          <SvgIcon component={type_obra} inheritViewBox />
        </ListItemIcon>
        <ListItemText primary={obra.tipoObra} />
      </ListItem>
      <ListItem  className='date' >
        <ListItemIcon>
          <SvgIcon component={date_obra} inheritViewBox />
        </ListItemIcon>
        <ListItemText primary={dateToYear(obra.fecAdjudicacion)} />

      </ListItem>
      <ListItem  className='status' >
        <ListItemText primary={obra.estadoContrato || t('noStatus')} />
      </ListItem>

      <ListItemButton className='link' onClick={() => handleClick(obra)}>
        <ListItemText primary={t('goDetails')} />
        <ListItemIcon>
          <KeyboardArrowRightIcon />
        </ListItemIcon>
      </ListItemButton>

    </List>
  </Box>;
}

export default Infowindow;

