import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './viewLocalizationEnvironment.module.scss';
import './viewLocalizationEnvironment.scss';

import InfoBox from '../../components/infoBox/infoBox';
import { ObraProperties } from '../../../../types/ObraDetails';

import coordinatesPic from '../../../../assets/coordinates.svg';
import locationPic from '../../../../assets/location.svg';
import emailPic from '../../../../assets/email.svg';
import InformationIcon from '../../components/informationIcon/informationIcon';
import AdditionalTown from '../components/additionalTown/additionalTown';
import TitleValue from '../../components/titleValue/titleValue';
import protectionSpace from '../../../../assets/protectionSpace.svg';
import otherTowns from '../../../../assets/otherTowns.svg';
import extinctionDanger from '../../../../assets/extinctionDanger.svg';
import culturalProtection from '../../../../assets/culturalProtection.svg';
import parse from 'html-react-parser';
import { AuthContext } from '../../../../services/authContextProvider';
import { UserModeContext } from '../../../../context/UserModeProvider';
import ProtectedSpace from '../components/protectedSpace/protectedSpace';
import { DANGER_COLORS } from '../dangerLevelColors';

interface ViewLocalizationEnvironmentProps {
  obra: ObraProperties
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const ViewLocalizationEnvironment: FC<ViewLocalizationEnvironmentProps> = (props) => {
  const { obra } = props;
  const [t] = useTranslation();
  const authContext = useContext(AuthContext);
  const userModeContext = useContext(UserModeContext);
  const [locationData, setLocationData] = useState(obra.localizacion);

  useEffect(() => {
    setLocationData(obra.localizacion);
  }, [obra.localizacion]);

  function round(n: number) {
    return Math.round(n * 10000000) / 10000000;
  }

  return (
    <div data-testid="locationEnvironment-view-test">

      <div className={styles.locationInfo}>
        <div className={styles.boxContainer}>
          <InfoBox
            identifier='locationcoordinates'
            picture={coordinatesPic}
            item={{
              title: t('details.location.coordinates'),
              value: (obra.localizacion.coordenadas && obra.localizacion.coordenadas.lat && obra.localizacion.coordenadas.lon) ?
                `${round(obra.localizacion?.coordenadas?.lat)} / ${round(obra.localizacion?.coordenadas?.lon)}` :
                t('details.notInformed')
            }}
            subItem={undefined} />
        </div>
        <div className={styles.boxContainer}>
          <InfoBox
            identifier='locationcountry'
            countryCode={obra.general.codPais || 'none'}
            item={{
              title: t('details.location.country'),
              value: obra.general?.nomPais || t('details.notInformed')
            }}
            subItem={undefined} />

        </div>
        <div className={styles.boxContainer}>
          <InfoBox
            identifier='locationtown'
            picture={locationPic}
            item={{
              title: t('details.location.town'),
              value: obra.localizacion?.municipioPrincipal?.nomMunicipio || t('details.notInformed')
            }}
            subItem={undefined} />
        </div>
        <div className={styles.boxContainer}>
          <InfoBox
            identifier='locationzipcode'
            picture={emailPic}
            item={{
              title: t('details.location.zipCode'),
              value: obra.localizacion?.municipioPrincipal?.codigoPostal || '-'
            }}
            subItem={undefined} />
        </div>
      </div>
      <div className={styles.section}>
        <div id='othertowns' className={styles.heading}>{t('details.location.otherTowns')}
          <InformationIcon message={t('help.localization.municipalities')} />
        </div>
        {locationData?.municipiosAdicionales?.length > 0 ?
          <div className={styles.otherTownsContainer}>
            {obra.localizacion.municipiosAdicionales.map(({ nomMunicipio, codigoPostal }) => {
              return (<div key={nomMunicipio}>
                <AdditionalTown name={nomMunicipio} zipCode={codigoPostal} />
              </div>);
            })}
          </div>
          :
          <div id='othertowns-nodata' className={styles.noDataPic}>
            <img src={otherTowns} />
            {authContext.hasEditorRole() ?
              <>
                {userModeContext.userMode === 'viewer' && <p>{parse(t('details.location.changeUserAddOtherTowns'))}</p>}
                {userModeContext.userMode === 'editor' && <p>{parse(t('details.location.addOtherTowns'))}</p>}
                {userModeContext.userMode === 'editorDisabled' && <p>{parse(t('details.location.noData') + '. ' + t('details.validation.noEditMode'))}</p>}
              </>
              :
              <p>{t('details.location.noData')}</p>
            }
          </div>
        }
      </div>

      <div className={styles.section}>
        <div id='protectedspace' className={styles.heading}>{t('details.location.protectedSpace')}
          <InformationIcon message={t('help.localization.protectedSpace')} />
        </div>

        {locationData?.espacioProteccion?.length > 0 ?
          <ProtectedSpace obra={obra} editable={false} />
          :
          <div id='protectionspace-nodata' className={styles.noDataPic}>
            <img src={protectionSpace} />
            {authContext.hasEditorRole() ?
              <>
                {userModeContext.userMode === 'viewer' && <p>{parse(t('details.location.changeUserAddProtectionSpace'))}</p>}
                {userModeContext.userMode === 'editor' && <p>{parse(t('details.location.addProtectionSpace'))}</p>}
                {userModeContext.userMode === 'editorDisabled' && <p>{parse(t('details.location.noData') + '. ' + t('details.validation.noEditMode'))}</p>}
              </>
              :
              <p>{t('details.location.noData')}</p>
            }
          </div>
        }
      </div>
      <div className={styles.halvedSection}>
        <div className={styles.section}>
          <div id='extinctionspecies' className={styles.heading}>{t('details.location.extinctionSpecies')}
            <InformationIcon message={t('help.localization.extinctionSpecies')} />
          </div>
          {obra.localizacion?.especiesPeligro?.length > 0 ?
            <table className={styles.extinctionSpeciesProtectionZonesContainer} cellPadding="0">
              {obra.localizacion.especiesPeligro.map((e: any) => {
                return (<tr key={e.nombre} className={styles.protectedSpaceRow}>
                  <td className={styles.speciesName}>
                    <TitleValue identifier='location-name'
                      title={t('details.location.name')}
                      value={e.nombre} />
                  </td>
                  <td className={styles.dangerLevel}>
                    <TitleValue
                      identifier={`location-dangerlevel-${e.id}`}
                      title={t('details.location.dangerLevel')}
                      value={e.nivel?.nombre}
                      valueColor={DANGER_COLORS[e.nivel?.id]}
                    />
                  </td>
                </tr>);
              })}
            </table>
            :

            <div id='extinctiondanger-nodata' className={styles.noDataPic}>
              <img src={extinctionDanger} />
              {authContext.hasEditorRole() ?
                <>
                  {userModeContext.userMode === 'viewer' && <p>{parse(t('details.location.changeUserAddExtinctionSpecies'))}</p>}
                  {userModeContext.userMode === 'editor' && <p>{parse(t('details.location.addExtinctionSecies'))}</p>}
                  {userModeContext.userMode === 'editorDisabled' && <p>{parse(t('details.location.noData') + '. ' + t('details.validation.noEditMode'))}</p>}
                </>
                :
                <p>{t('details.location.noData')}</p>
              }
            </div>
          }
        </div>
        <div className={styles.section}>
          <div id='protectionzones' className={styles.heading}>{t('details.location.protectionZones')}
            <InformationIcon message={t('help.localization.protectedZones')} />
          </div>
          {obra.localizacion?.zonasProteccion?.length > 0 ?
            <table className={styles.extinctionSpeciesProtectionZonesContainer} cellPadding="0">
              {obra.localizacion.zonasProteccion.map((z) => {
                return (<tr key={z.nombre} className={styles.protectedSpaceRow}>
                  <td className={styles.speciesName}>
                    <TitleValue identifier='location-name' title={t('details.location.name')} value={z.nombre} />
                  </td>
                  <td className={styles.dangerLevel}>
                    <TitleValue identifier='location-protection' title={t('details.location.protection')} value={z.proteccion?.nombre} />
                  </td>
                </tr>);
              })}
            </table>
            :

            <div id='culturalprotection-nodata' className={styles.noDataPic}>
              <img src={culturalProtection} />
              {authContext.hasEditorRole() ?
                <>
                  {userModeContext.userMode === 'viewer' && <p>{parse(t('details.location.changeUserAddCulturalProtection'))}</p>}
                  {userModeContext.userMode === 'editor' && <p>{parse(t('details.location.addCulturalProtection'))}</p>}
                  {userModeContext.userMode === 'editorDisabled' && <p>{parse(t('details.location.noData') + '. ' + t('details.validation.noEditMode'))}</p>}
                </>
                :
                <p>{t('details.location.noData')}</p>
              }
            </div>
          }
        </div>
      </div>
    </div >
  );
};

export default ViewLocalizationEnvironment;
