/**
 * API endpoints
 */
const ENDPOINTS = {
  v1: {
    permisos: 'v1/permisos',
    export: 'v1/export',
    obras: 'v1/obras',
    obrasMap: 'v1/obras/mapa',
    obrasList: 'v1/obras/lista',
    obrasFullDetails: 'v1/obras',
    maestros: 'v1/maestros',
    notificaciones: 'v1/notificaciones',
    dowloadPDF: 'v1/download',
  },
  admin: {
    users: 'v1/admin/usuarios',
    noPhia: 'v1/admin/no_phia',
    general: 'v1/admin/general',
    permissions: 'v1/admin/permisos',
    projects: 'v1/admin/proyectos',
    keycloakDb: 'v1/admin/keycloak_db'
  }
};

export default ENDPOINTS;
