import React from 'react';
import CustomAutocomplete from './CustomAutocomplete/CustomAutocomplete';
import { FilterCollapsableWrapper } from '../../../../components/Filters/FiltersWrappers/FilterCollapsableWrapper/FilterCollapsableWrapper';

export const Autocomplete = ({ filterType, noBottomDivider }: any) => {
  return (
    <>
      <FilterCollapsableWrapper
        filterType={filterType}
        noBottomDivider={noBottomDivider}
      >
        <CustomAutocomplete
          filterType={filterType}
        />
      </FilterCollapsableWrapper>
    </>
  );
};
