/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useContext, useEffect, useState } from 'react';
import { AlcanceEntidad, ObraProperties } from '../../../../../types/ObraDetails';
import { useTranslation } from 'react-i18next';
import { SelectOption } from '../../../../../types/Option';
import { SaveContext } from '../../../../../context/SaveProvider';
import TitleHelp from '../../../components/titleHelp/titleHelp';
import designerNoData from '../../../../../assets/designerNoData.svg';
import NoDataPicture from '../../../components/noDataPicture/noDataPicture';
import Colaborador from '../../components/colaborator/colaborator';

interface EditClientsProps {
  obra: ObraProperties;
  clientStatus: SelectOption[];
  errors?: any[]
}

const EditClients: FC<EditClientsProps> = (props) => {
  const { obra, clientStatus, errors } = props;
  const [t] = useTranslation();
  const saveContext = useContext(SaveContext);
  const [designers, setDesigners] = useState(obra.cliente?.disenador || []);

  useEffect(() => {
    const newDesigners: any = [];
    obra.cliente.disenador.sort((a, b) => a.origen && b.origen ? a.origen.localeCompare(b.origen) : 0).map((d) => newDesigners.push(d));
    setDesigners(newDesigners);
  }, [obra.cliente.disenador]);

  const handleAddDesigner = () => {
    const newDesigner = { id: undefined, origen: 'phia', entidad: '', alcance: '', estado: { id: 0, nombre: '' } };
    setDesigners([...designers, newDesigner]);
    if (obra.cliente?.disenador) {
      obra.cliente.disenador.push(newDesigner);
    } else {
      obra.cliente.disenador = [newDesigner];
    }

    saveContext.handleChangeSave(true);
  };

  const handleDelete = (i: number) => {
    obra.cliente.disenador.splice(i, 1);
    setDesigners([...obra.cliente.disenador]);
    saveContext.handleChangeSave(true);
  };

  const handleChange = (designers: AlcanceEntidad[]) => {
    obra.cliente.disenador = designers;
  };

  return (
    <div
      data-testid="designer-edit-test"
    >
      <TitleHelp
        id='designer'
        value={t('details.clients.designer')}
        help={t('help.clients.designer')}
        addItemText={t('details.clients.addNewDesigner') as string}
        handleAddItem={handleAddDesigner}
      />
      {
        designers.length > 0 ?
          <Colaborador editable={true} clientStatus={clientStatus} handleDelete={handleDelete} colaborador={designers} handleChangeData={handleChange} obra={obra} errors={errors}/>
          :
          <NoDataPicture
            id='designer-nodata'
            picture={designerNoData}
            text={t('details.clients.addDesigner')}
          />
      }

    </div >);
};

export default EditClients;
