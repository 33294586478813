/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { withTranslation } from 'react-i18next';

import TextField from '@mui/material/TextField';
import { IconButton, InputAdornment } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import styles from './autocompleteTextField.module.scss';
import { TFunction } from 'i18next';

interface AutocompleteTextFieldProps {
  filterType: string,
  params: any,
  debounceInputHandler: any,
  textFieldValue: string,
  setTextFieldValue: any,
  setOptions: any,
  children?: JSX.Element;
  t: TFunction<'translations'>;
}
const AutocompleteTextField = (props: AutocompleteTextFieldProps) => {
  const { t, filterType, params, debounceInputHandler, textFieldValue, setTextFieldValue, setOptions } = props;

  const textFieldValueLength = textFieldValue ? textFieldValue.length : 0;
  return (
    <TextField
      id='autocomplete-textfield'
      {...params}
      onChange={
        (e: React.ChangeEvent<HTMLInputElement>) => {
          debounceInputHandler(e);
          setTextFieldValue(e.target.value);
        }
      }
      InputProps={{
        ...params.InputProps,
        onKeyDown: (e) => {
          if (e.key === 'Enter') {
            e.stopPropagation();
          }
        },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => {
              setTextFieldValue('');
              setOptions([]);
            }}>
              {textFieldValueLength > 0 && <ClearIcon className={styles.icon} />}
            </IconButton>
          </InputAdornment>
        ),

      }}
      onBlur={() => {
        setTextFieldValue('');
        setOptions([]);
      }}

      InputLabelProps={{
        shrink: false,

      }}

      placeholder={t(`filters.filtersPlaceHolder.${filterType}`)}
      variant='outlined'
      autoComplete='off'
      sx={{
        '& .MuiOutlinedInput-root': {
          height: '45px',
          backgroundColor: '#FFFFFF',
          '&.Mui-focused fieldset': {
            border: '1px solid #7A7A7A',
            color: 'rgba(122, 122, 122, 1)',
          },
          padding: 0,
          paddingLeft: '10px',
          '&.Mui-focused input': {
            '&:focus::-webkit-input-placeholder': {
              color: 'transparent'
            },
          }

        },
      }}
    />
  );
};

export default withTranslation()(AutocompleteTextField);
