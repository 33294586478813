/* eslint-disable @typescript-eslint/no-explicit-any */
import React,
{
  useState,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';

import NUMBER_RANGE_TYPE from '../../numberRangeType';

import { FilterContext } from '../../../../../../../context/FilterProvider';
import { TextField } from '@mui/material';

interface CustomNumberPickerWithButtonProps {
  filterType: string,
  numberRangeType: string,
  rangeType: string,
  suffix?: string,
  value: string,
  handleChangeValue: any;
  disabled?: boolean;
  error: boolean;
}

const CustomNumberPickerWithButton = (props: CustomNumberPickerWithButtonProps) => {
  const { value, handleChangeValue, filterType, numberRangeType, rangeType, suffix, error } = props;
  const { t } = useTranslation();
  const filterContext = useContext(FilterContext);
  const [key, setKey] = useState<string | undefined>(undefined);
  const numberFilterType = `${numberRangeType}${filterType}`;
  const inputRef = useRef(null);

  const formatNumber = (num: number | string) => {
    if (!num) return '';
    if (suffix === '%') {
      const value = typeof num === 'string' ? parseFloat(num) : num;
      let res: number = value;
      res = Math.min(value, 100);
      num = res;
    }
    const numToString = num.toString();
    const removeChars = numToString.replace(/[^\d,]+/g, '');
    const removeCeros = removeChars.replace(/^0+(?=\d)/, '');
    return `${removeCeros.replace(/\B(?=(\d{3})+(?!\d))/g, '.')} ${suffix}`.trim();
  };

  useEffect(() => {
    handleChangeValue(formatNumber(value));
  }, [filterContext.filters[numberFilterType]]);

  const getLabel = () => {
    if (numberRangeType === NUMBER_RANGE_TYPE.minAmount) {
      return t('numRange.from');
    } else if (numberRangeType === NUMBER_RANGE_TYPE.maxAmount) {
      return t('numRange.to');
    } else if (['smaller', 'bigger', 'exact'].includes(rangeType)) {
      return t('numRange.number');
    }
  };

  const sanitizeDecimalPart = (magnitude: string) => {
    const splitMagnitude = magnitude.split(',');
    const intPart = splitMagnitude[0];
    if (splitMagnitude.length > 1) {
      let decimalPart = splitMagnitude[1];
      if (decimalPart.length > 2) {
        decimalPart = decimalPart[0] + decimalPart[1];
      }
      return intPart + ',' + decimalPart;
    } else
      return magnitude;
  };

  const checkDecimals = (value: string) => {
    let magnitude = value.split(' ')[0]; // removes the unit
    magnitude = sanitizeDecimalPart(magnitude);
    return magnitude;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    let caret = target.selectionStart || 0;
    const numberValue = checkDecimals(target.value);
    let europeanNumberFormat = formatNumber(numberValue);

    if (caret && ['Backspace', 'Delete'].includes(key as string) && target.defaultValue) {
      while (caret > 0 && target.defaultValue[caret].match(/[^\d,]+/g)) {
        caret -= 1;
      }
      const defaultValueSplit = target.defaultValue.split('');
      defaultValueSplit.splice(caret, 1);
      europeanNumberFormat = formatNumber(defaultValueSplit.join(''));
    } else if (caret&& key !== 'Backspace'
      && key !== 'Delete'
      && europeanNumberFormat
      && europeanNumberFormat[caret]
      && target.value[caret - 1] === europeanNumberFormat[caret]) {
      caret += 1;
    }
    handleChangeValue(europeanNumberFormat);
    window.requestAnimationFrame(() => {
      target.setSelectionRange(caret, caret);
    });
  };

  return (<>
    <TextField
      InputProps={{
        endAdornment: '',
        onKeyDown: (e) => {
          if (e.key === 'Enter') {
            e.currentTarget.blur();
            e.stopPropagation();
          }
        }
      }}
      onChange={handleChange}
      value={value}
      ref={inputRef}
      id={`CustomTextField${numberFilterType}`}
      onKeyDown={(e) => setKey(e.key)}
      InputLabelProps={{ shrink: true }}
      variant='outlined'
      autoComplete='off'
      placeholder={rangeType === NUMBER_RANGE_TYPE.exact ?
        (t(`filters.filtersPlaceHolder.${NUMBER_RANGE_TYPE.exact}${filterType}`) || '')
        : (t(`filters.filtersPlaceHolder.${numberFilterType}`) || '')}
      error={error}
      label={getLabel()}
      sx={{
        paddingRight: '12px',
        paddingLeft: '12px',
        paddingTop: '0.35rem',
        paddingBottom: '0.35rem',
        display: 'block',
        //marginBottom: '9px',
        '& .MuiInputLabel-root': {
          fontFamily: 'Roboto',
          fontWeight: '400',
          fontSize: '12px',
          color: '#696969',
          top: '23px',
          left: '12px',
          '&.Mui-focused': {
            color: '#696969',
          }
        },
        '& .MuiInputBase-root': {
          width: '100%',
          '& .MuiOutlinedInput-notchedOutline': {
            backgroundColor: '#FFFFFF',
            height: '43px',
          },
          '&.MuiOutlinedInput-root': {
            border: 'none',
            '&.Mui-focused input': {
              '&:focus::-webkit-input-placeholder': {
                color: 'transparent'
              },
            },

            '& input': {
              paddingBottom: '7px',
              paddingTop: '21px',
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '12px',
              lineHeight: '18px',
              color: '#111111',
              zIndex: 1,
            },
            '& fieldset': {
              border: '1px solid rgba(192, 192, 192, 1)',
              legend: {
                display: 'none'
              },

            },
            '&:hover': {
              '& fieldset': {
                border: '1px solid rgba(0, 0, 0, 1)'
              }
            },
            '&.Mui-focused fieldset': {
              border: '1px solid #7A7A7A',
            }
          },
        },
      }}
    />

  </>);
};

export default CustomNumberPickerWithButton;