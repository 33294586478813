/* eslint-disable indent */
import React, { type FC, useContext, useState } from 'react';
import styles from './projectTable.module.scss';
import writeToSearch from '../../../assets/writeToSearch.svg';
import { Button, Dialog, Stack, SvgIcon, Table, TableCell, TableHead, TableRow } from '@mui/material';
import { t } from 'i18next';
import { CenterParams, Permiso, UsuarioProyecto } from '../../../types/Admin';
import { ADMIN, EDITOR, VALIDATOR, VIEWER, VIEWER_VIP } from '../../../userRoles/userRoles';
import { AdminContext, EmailandName, UpdatedPermissions } from '../../../context/AdminProvider';
import { COMPANY, PROJECT, SUPERCENTER, ZONE } from '../../../userScopes/userScopes';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ObrasAPI from '../../../services/APIObras';
import LoadingSpinner from '../../../components/loadingSpinner/loadingSpinner';
import { ReactComponent as error } from '../../../assets/error.svg';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ProjectTableProps {
  selectedProject?: CenterParams;
}

interface DialogContent {
  idPermiso: number;
  usuario?: string;
  email: string;
  alcance: string;
  rol: string;
  index: number;
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const ProjectTable: FC<ProjectTableProps> = (props) => {
  const { selectedProject } = props;
  const adminContext = useContext(AdminContext);
  const users = adminContext.usersPerProject;
  const allUsers = [...adminContext.allUsers];
  const api = ObrasAPI.getInstance();
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [dialogContent, setDialogContent] = React.useState<DialogContent>();
  const [openError, setOpenError] = useState(false);

  const renderRole = (role: string) => {
    switch (role) {
      case VIEWER_VIP:
      case VIEWER:
        return t('administration.table.viewer');
      case EDITOR:
        return t('administration.table.editor');
      case VALIDATOR:
        return t('administration.table.validator');
      case ADMIN:
        return t('administration.table.admin');
    }
  };

  const renderScope = (scope: string) => {
    switch (scope) {
      case PROJECT:
        return t('administration.table.project');
      case SUPERCENTER:
        return t('administration.table.supercenter');
      case ZONE:
        return t('administration.table.zone');
      case COMPANY:
        return t('administration.table.company');
    }
  };

  const deleteRole = async (id: number | undefined, email: string, grupo: string | undefined) => {
    if (id && grupo) {
      setLoading(true);
      const response = await api.deleteRole(id, email, grupo);
      if (response.error) {
        setOpenError(true);
      } else {
        const updatedUsers = adminContext.usersPerProject.filter((user) => user.email !== email);
        adminContext.handleChangeUsersPerProject(updatedUsers);
        const updatedPermissions: UpdatedPermissions = adminContext.updatedPermissions || {};
        updatedPermissions[email] = response;
        adminContext.handleChangeUpdatedPermissions(updatedPermissions);
      }
      adminContext.handleSetIsEdition(false);
      setLoading(false);
    }
  };

  const getDialogContent = () => {
    return <>
      <div className={styles.titleDelete}>{t('administration.titleDelete')}</div>
      <div className={styles.details}>
        <div className={styles.subtitle}>{t('administration.user') + ':'}<p>{dialogContent?.usuario}</p> </div>
        <div className={styles.subtitle}>{t('administration.role') + ':'}<p>{dialogContent ? renderRole(dialogContent?.rol) : null}</p></div>
        <div className={styles.subtitle}>{t('administration.range') + ':'}<p>{dialogContent ? renderScope(dialogContent?.alcance) : null}</p></div>
      </div>
      <div className={styles.buttonSection}>
        <Stack spacing={2} direction="row" justifyContent="center">
          <Button id='buttoncancel' className={styles.buttonCancel}
            onClick={() => {
              setOpenDialog(false);
            }}
            disableRipple={true} variant="outlined">{t('cancel')}</Button>
          <Button id='buttonapprove' className={styles.buttonDelete} onClick={() => {
            if (dialogContent) {
              deleteRole(dialogContent.idPermiso, dialogContent.email, dialogContent.rol);
              setOpenDialog(false);
            }
          }} disableRipple={true} variant="contained">{t('delete')}</Button>
        </Stack>
      </div>
    </>;
  };

  const handleEditUser = (name: string, email: string, idPermiso: number, rol: string) => {
    const userToEdit: EmailandName = { name, email };
    const permission: Permiso = { id: idPermiso, nomProyecto: selectedProject!.codigoNombre.split(' - ')[1], rol: rol.replace('/phia/', '') };
    adminContext.handleChangeUserToEdit(userToEdit);
    const projectToEdit = {
      codEmpresa: '0008',
      codZona: selectedProject!.codZona,
      codCentro: selectedProject!.codCentro,
      codSupercentro: selectedProject!.codSupercentro
    };
    adminContext.handleChangePermissionToEdit(permission);
    adminContext.handleChangeProjectToAdd(projectToEdit);
    adminContext.handleChangeHasProject(true);
    adminContext.handleSetIsEdition(true);
  };

  const getName = (email: string) => {
    const user = allUsers.find((user) => user.email === email);
    return user?.nombre || email;
  };

  const renderErrorDelete = () => {
    return <Dialog
      classes={{
        paper: styles.dialogError
      }}
      BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.15)' } }}
      PaperProps={{ sx: { borderRadius: '20px', minWidth: '700px' } }}
      open={openError}
      onClose={() => setOpenError(false)}
    >
      <div className={styles.dialogContentError}>
        <div data-testid='error-test' className={styles.serverError}>
          <div className={styles.serverErrorContent}>
            <SvgIcon component={error} inheritViewBox />
            <div id='error-title' className={styles.title}>{t('administration.errorTitle')}</div>
            <div id='error-subtitle' className={styles.subtitle}>{t('administration.errorDescription')}</div>
          </div>
        </div>
        <Stack spacing={2} direction="row" justifyContent="center">
          <Button className={styles.buttonConfirm} onClick={() => {
            setOpenError(false);
          }} disableRipple={true} variant="contained">{t('administration.acceptButton')}</Button>
        </Stack>
      </div>
    </Dialog>;
  };

  return (
    <div className={styles.projectsTable}>
      <Table>
        <TableHead >
          <TableRow className={styles.tableHead}>
            <TableCell>{t('administration.table.name')}</TableCell>
            <TableCell>{t('administration.table.role')}</TableCell>
            <TableCell>{t('administration.table.scope')}</TableCell>
            <TableCell align='right'>{t('administration.table.edit')}</TableCell>
          </TableRow>
        </TableHead>
      </Table>
      {loading && <LoadingSpinner />}
      {!selectedProject ?
        <div className={styles.noData}>
          <img src={writeToSearch} />
          <div className={styles.paragraph}>{t('administration.table.selectProject')}</div>
        </div>
        :
        (users.length > 0 ?
          users.map((u, i) => {
            return <div key={i}>
              <div className={styles.tableRow}>
                <div className={styles.nameCell}>
                  <div className={styles.nameContainer}>
                    {getName(u.email)}
                  </div>
                </div>
                <div className={styles.rolesCell}>
                  <div className={styles.rolesWrapper}>
                    <div
                      key={u.rol}
                      className={`${styles[u.rol.replace('/phia/', '')]}`}
                    >
                      {renderRole(u.rol)}
                    </div>
                  </div>
                </div>
                <div className={styles.scopeCell}>
                  <div className={styles.scopeWrapper}>
                    {renderScope(u.alcance)}
                  </div>
                </div>
                <div className={styles.managementIcons}>
                  <CreateOutlinedIcon className={styles.icon} onClick={() => handleEditUser(getName(u.email) || u.email, u.email, u.idPermiso, u.rol)} />
                  <DeleteOutlineOutlinedIcon className={styles.icon} onClick={() => {
                    setDialogContent({ idPermiso: u.idPermiso, email: u.email, usuario: getName(u.email), rol: u.rol, alcance: u.alcance, index: i });
                    setOpenDialog(true);
                  }} />
                </div>
              </div>
            </div>;
          })
          :
          <div className={styles.tableBody} style={{
            height: '82px',
            display: 'flex',
            flexFlow: 'row',
            marginTop: '20px',
            width: '100%',
            marginLeft: '15px',
          }}>
            <p>{t('administration.table.notFound')}</p>
          </div>
        )
      }
      <Dialog
        classes={{
          paper: styles.dialog
        }}
        BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.15)' } }}
        PaperProps={{ sx: { borderRadius: '20px', minWidth: '90vW' } }}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <div className={styles.dialogContent}>
          {getDialogContent()}
        </div>
      </Dialog>
      {renderErrorDelete()}
    </div>
  );
};

export default ProjectTable;
