/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './notification.module.scss';
import { Divider, IconButton, Menu, MenuItem, MenuList, SvgIcon } from '@mui/material';
import ObrasAPI from '../../services/APIObras';

import { ReactComponent as notificationReadIcon } from '../../assets/notification_read.svg';
import { ReactComponent as notificationPendingIcon } from '../../assets/notification_pending.svg';
import { AuthContext } from '../../services/authContextProvider';
import { ReactComponent as tab_error } from '../../assets/tab_error.svg';
import { useNavigate } from 'react-router-dom';
import { Motivo, NotificationType } from '../../types/Notification';
import parse, { Element } from 'html-react-parser';
import { NotificationContext } from '../../context/NotificationProvider';
import CheckSaved from '../checkSaved/checkSaved';
import { SaveContext } from '../../context/SaveProvider';
import { UserModeContext } from '../../context/UserModeProvider';

const DAYS_AGE = 30; // to return only notifications newer than this number of days

function Notification() {
  const { t } = useTranslation();

  const api = ObrasAPI.getInstance();
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const newNotification = useContext(NotificationContext);
  const saveContext = useContext(SaveContext);
  const userModeContext = useContext(UserModeContext);
  const isAuthorized = authContext.hasEditorRole(true);
  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const [unreadNotifications, setUnreadNotifications] = useState<boolean>(false);
  const [hasChangedForm, setHasChangedform] = useState<boolean>(false);
  const [route, setRoute] = useState<string>('/notificaciones?filter=ALL');

  const continueEdit = () => {
    saveContext.saveDraft = false;
    saveContext.handleChangeSave(false);
    setHasChangedform(false);
    navigate(route);
  };

  const discardChanges = () => {
    saveContext.saveDraft = false;
    saveContext.handleChangeSave(false);
    setHasChangedform(false);
  };

  const isUnreadNotifications = (updatedNotifications: NotificationType[]) => {
    const unread = updatedNotifications && updatedNotifications.find((n) => !n.leido);
    setUnreadNotifications(!!unread);
  };

  const getNotificationsFromAPI = () => {
    // eslint-disable-next-line no-async-promise-executor
    api.getNotificationsAPI(DAYS_AGE).then((notifications: NotificationType[]) => {
      setNotifications(notifications);
      isUnreadNotifications(notifications);
    }).catch((e: any) => {
      console.log(e);
    });
  };


  useEffect(() => {
    if (authContext.hasEditorRole(true)) {
      getNotificationsFromAPI();
    }
  }, [authContext.hasGroupsLoaded, newNotification]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    getNotificationsFromAPI();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (routeToNavigate: string) => {
    // Check if the user is in editor mode and draft is being saved
    if (userModeContext.userMode === 'editor' && saveContext.saveDraft) {
      // If true, set the flag to show the dialog to continue or discard changes
      setHasChangedform(true);
    } else {
      // If false, navigate to the specified route
      navigate(routeToNavigate);
      // Reset navigation history to avoid going back to previous route
      navigate(0);
    }
  };

  const handleGoNotification = async (n: NotificationType) => {
    handleClose();
    api.setNotificationRead(n).then(() => {
      if (
        n.motivo === Motivo.NEW_SUPERCENTER_EDITOR ||
        n.motivo === Motivo.NEW_SUPERCENTER_VALIDATOR ||
        n.motivo === Motivo.NEW_ZONE_EDITOR ||
        n.motivo === Motivo.NEW_ZONE_VALIDATOR
      ) {
        setRoute('/notificaciones');
        handleNavigation('/notificaciones');
      } else if (n.motivo === Motivo.REJECT) {
        setRoute('/notificaciones?filter=REJECT');
        handleNavigation('/notificaciones?filter=COMMENTED');
      } else {
        setRoute(`/${n.codCentro}/detalles`);
        handleNavigation(`/${n.codCentro}/detalles`);
      }
    });
  };

  const handleAll = () => {
    handleNavigation(route);
    handleClose();
  };

  const parseDate = (date: string) => {
    const d = new Date(date);
    const options: any = { year: 'numeric', month: 'short', day: 'numeric' };
    return `${d.toLocaleDateString('es-es', options)} - ${d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
  };

  const getNotificationComponent = (not: NotificationType) => {
    return <div className={styles.notificationItem}>
      <div className={styles.notificationPoint}>
        {!not.leido && <SvgIcon component={tab_error} inheritViewBox />}
      </div>
      <div className={styles.notificationData}>
        <div className={styles.notificationName}>
          {`${not.nomCentro} - ${not.codCentro}`}
        </div>
        <div className={styles.notificationMotive}>
          {parse(t('notifications.' + not.motivo),
            {
              replace: (domNode) => {
                if (domNode instanceof Element && domNode.attribs) {
                  if (domNode.attribs && domNode.attribs.id === 'replace') {
                    return <span>{not.nomCentro}</span>;
                  }
                }
              }
            })
          }
        </div>
        <div className={styles.notificationDate}>
          {parseDate(not.fecCreacion)}
        </div>
      </div>
    </div >;
  };

  return isAuthorized ? (
    <>
      <div data-testid='notification-test' className={styles.notificationContainer}>
        <IconButton
          className={styles.notificationButton}
          onClick={handleClick}>
          <SvgIcon component={unreadNotifications ? notificationPendingIcon : notificationReadIcon} inheritViewBox />
        </IconButton>

        <Menu
          id='notification-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          sx={{
            '& .MuiPaper-root': {
              borderRadius: '3px !important'
            },
            '& .MuiMenu-list': {
              padding: 0
            },
          }}
        >
          <MenuList dense
            className={styles.menuList}
            sx={{
              padding: 0
            }}
          >
            <MenuItem className={styles.menuHeader} onClick={handleAll}>
              <div className={styles.headerTitle}>
                {t('notifications.title')}
              </div>
              <div>
                {<IconButton
                  id='notifications-all'
                  className={styles.all}
                  disableRipple={true}
                >
                  {t('notifications.all')}
                </IconButton>}
              </div>
            </MenuItem>
            <Divider sx={{
              margin: '0 !important'
            }} />
            {notifications?.length ?
              <div className={styles.notificationList}>
                {notifications.map((n, i) => {
                  return <>
                    <div
                      key={n.id}
                      onClick={() => handleGoNotification(n)}>
                      {getNotificationComponent(n)}
                    </div>
                    {i < notifications.length - 1 && <Divider sx={{
                      margin: '0 !important'
                    }} />}
                  </>;
                })
                }
              </div>
              : <div className={styles.noData}>
                {t('notifications.noData')}
              </div>
            }
          </MenuList>

        </Menu>
      </div>
      {
        (hasChangedForm) ?
          <CheckSaved
            handleFunction={() => {
              continueEdit();
              navigate(route);
              navigate(0);
            }}
            closeFunction={() => {
              setHasChangedform(false);
              handleClose();
            }}
            discardChanges={() => {
              discardChanges();
            }}
          />
          : null
      }
    </>
  ) : <></>;
}

export default Notification;
