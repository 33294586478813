/* eslint-disable @typescript-eslint/no-explicit-any */
import { ClusterStats, MarkerClusterer, MarkerClustererEvents } from '@googlemaps/markerclusterer';


export class MarkerClustererCustom extends MarkerClusterer {
  constructor(props: any) {
    super(props);
  }
  onAdd(): void {
    /* this.idleListener = this.getMap().addListener(
      "idle",
      this.render.bind(this)
    ); */
    //this.render();
  }

  getClusters() {
    return this.clusters;
  }

  preCalculateClusters(markers: google.maps.Marker[], mapInstance: google.maps.Map) {
    const map: google.maps.Map | google.maps.StreetViewPanorama | null = mapInstance;
    if (map.getProjection()) {
      const { clusters, changed } = this.algorithm.calculate({
        markers,
        map,
        mapCanvasProjection: this.getProjection(),
      });
      return { clusters, changed };
    }
    return {};
  }

  removeClusters(clusters: any) {
    clusters.forEach((cluster: any) => {
      cluster.delete();
    });

    this.clusters = this.clusters.filter((el) => {
      return !clusters.includes(el);
    });
  }

  addClusters(clusters: any) {
    this.clusters = this.clusters.concat(clusters);
    const stats = new ClusterStats(this.markers, this.clusters);
    const map = this.getMap() as google.maps.Map;
    clusters.forEach((cluster: any) => {
      if (cluster.markers.length === 1) {
        cluster.marker = cluster.markers[0];
      } else {
        cluster.marker = this.renderer.render(cluster, stats, map);
        if (this.onClusterClick) {
          cluster.marker.addListener(
            'click',
            /* istanbul ignore next */
            (event: google.maps.MapMouseEvent) => {
              google.maps.event.trigger(
                this,
                MarkerClustererEvents.CLUSTER_CLICK,
                cluster
              );
              this.onClusterClick(event, cluster, map);
            }
          );
        }
      }
      cluster.marker.setMap(map);
      google.maps.event.trigger(
        this,
        MarkerClustererEvents.CLUSTERING_END,
        this
      );
    });
  }
}