/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useState,
  useEffect,
} from 'react';

import { withTranslation } from 'react-i18next';

import { TFunction } from 'i18next';
import { MenuItem, Select } from '@mui/material';

import { FilterCollapsableWrapper } from '../../FiltersWrappers/FilterCollapsableWrapper/FilterCollapsableWrapper';
import ContainKeywordTextField from '../keywordInput/containKeyWordTextField/containKeywordTextField';
import SelectGroupAndType from './selectGroupAndType/selectGroupAndType';
import styles from './containSelect.module.scss';
import './containSelect.scss';

interface ContainSelectProps {
  filterType: any;
  t: TFunction<'translations'>;
  noBottomDivider?: boolean;
}

const ContainSelect = (props: ContainSelectProps) => {
  const { filterType, t, noBottomDivider } = props;

  const initialTags: any[] = [];
  const [tags, setTags] = useState<Array<any>>([]);
  const [searchType, setSearchType] = useState('keyword');

  useEffect(() => {
    if (
      tags.length === 0
      && Array.isArray(initialTags[filterType])
      && initialTags[filterType].length > 0
    ) {
      setTags(initialTags[filterType]);
    }
  }, [initialTags]);

  const renderSelectSearchMode = () => {
    return (
      <Select
        className={styles.selectorSearchMode}
        value={searchType}
        onChange={(e) => setSearchType(e.target.value)}
        sx={{
          backgroundColor: '#FFFFFF',
          height: '45px',
          '&.MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              border: '1px solid #7A7A7A',
              color: 'rgba(122, 122, 122, 1)'
            },
          },
        }}

      >
        {
          options.map((op) => (
            <MenuItem key={op} value={op}

            >
              <div id='worktypeselector-item' className={'menu-item'}>{t(`filters.filtersSelector.${op}`)}</div>
            </MenuItem>
          ))
        }
      </Select >
    );
  };

  const options = ['keyword', 'selection'];
  const searchByKeyword = searchType === 'keyword';
  const useFilterType = searchByKeyword ? `${filterType}Keyword` : `${filterType}GroupType`;

  return (
    <>
      <FilterCollapsableWrapper
        filterType={filterType}
        noBottomDivider={noBottomDivider}
      >
        <div className={styles.filterWrapper}>
          {renderSelectSearchMode()}
          {searchByKeyword
            ? <ContainKeywordTextField filterType={useFilterType} />
            : <SelectGroupAndType filterType={useFilterType} />
          }
        </div>
      </FilterCollapsableWrapper>
    </>
  );
};

export default withTranslation()(ContainSelect);