import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';import CollapsableFilter, { CollapsableFilterChildrenProps } from '../components/collapsableFilter/collapsableFilter';
import NumberRange from '../../../components/Filters/Components/numberRangeFilters/numberRange/numberRange';
import styles from './budgetFilters.module.scss';
import FILTER_TYPES, { FILTER_CONFIG } from '../../../components/Filters/filterTypes';
import { DatePickerRange } from '../../../components/Filters/Components/DatePickerRange/DatePickerRange';
import { Autocomplete } from '../components/Autocomplete/Autocomplete';
import { FilterContext } from '../../../context/FilterProvider';
import { AuthContext } from '../../../services/authContextProvider';

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const BudgetFilters: FC<CollapsableFilterChildrenProps> = (props) => {
  const { open, handleClickOpen } = props;
  const [t] = useTranslation();
  const filterContext = useContext(FilterContext);
  const authContext = useContext(AuthContext);

  const [unit, setUnit] = useState<string | undefined>(undefined);

  const isAuthorized = !authContext.isViewer();

  useEffect(()=>{
    const hasLocalCurrency = filterContext.getTags(FILTER_TYPES.localCurrency);
    setUnit(hasLocalCurrency[0] ? hasLocalCurrency[0].code : FILTER_CONFIG.amountCurrency) ;
  });

  return (
    <CollapsableFilter
      id='budget'
      value={t('advancedFilters.budget')}
      help={t('advancedFilters.budget')}
      handleClickOpen={handleClickOpen}
      open={open}
      advancedFilter={'budget'}
    >
      <div className={styles.budgetFiltersContainer}>
        <div className={styles.collapsableComponent}>
          <Autocomplete filterType={FILTER_TYPES.localCurrency} disableScroll={true} noBottomDivider={true} />
        </div>
        <div className={styles.collapsableComponent}>
          <NumberRange filterType={FILTER_TYPES.accionaBudget} defaultUnit={unit} noBottomDivider={true} />
        </div>
        {isAuthorized &&
        <>
          <div className={styles.collapsableComponent}>
            <DatePickerRange filterType={FILTER_TYPES.updatedBudgetDate} noBottomDivider={true} />
          </div>
          <div className={styles.collapsableComponent}>
            <NumberRange filterType={FILTER_TYPES.updatedBudget} defaultUnit={unit} noBottomDivider={true} />
          </div>
          <div className={styles.collapsableComponent}>
            <NumberRange filterType={FILTER_TYPES.updatedAccionaBudget} defaultUnit={unit} noBottomDivider={true} />
          </div>
          <div className={styles.collapsableComponent}>
            <DatePickerRange filterType={FILTER_TYPES.finalBudgetDate} noBottomDivider={true} />
          </div>
          <div className={styles.collapsableComponent}>
            <NumberRange filterType={FILTER_TYPES.finalBudget} defaultUnit={unit} noBottomDivider={true} />
          </div>
          <div className={styles.collapsableComponent}>
            <NumberRange filterType={FILTER_TYPES.finalAccionaBudget} defaultUnit={unit} noBottomDivider={true} />
          </div>
        </>
        }
      </div>
    </CollapsableFilter>
  );
};

export default BudgetFilters;
