import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutocompleteMultipleChoice } from '../../../components/Filters/Components/AutocompleteMultichoice/AutocompleteMultichoice';
import SingleSelectorNumberRange from '../../../components/Filters/Components/numberRangeFilters/singleSelectorNumberRange/singleSelectorNumberRange';
import FILTER_TYPES from '../../../components/Filters/filterTypes';
import CollapsableFilter, { CollapsableFilterChildrenProps } from '../components/collapsableFilter/collapsableFilter';
import styles from './technicalAspectsFilters.module.scss';
import DoubleSelectorNumberRange from '../../../components/Filters/Components/numberRangeFilters/doubleSelectorNumberRange/doubleSelectorNumberRange';

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const TechnicalAspectsFilters: FC<CollapsableFilterChildrenProps> = (props) => {
  const { open, handleClickOpen } = props;
  const [t] = useTranslation();
  const [, setScrollDisabled] = useState(false);

  const disableScroll = (disable: boolean) => {
    setScrollDisabled(disable);
  };
  return (
    <CollapsableFilter
      id='technicalAspects'
      value={t('advancedFilters.technicalAspects')}
      help={t('advancedFilters.technicalAspects')}
      handleClickOpen={handleClickOpen}
      open={open}
      advancedFilter={'technical'}
    >
      <div className={styles.technicalAspectsContainer}>
        <div className={styles.collapsableComponent}>
          <DoubleSelectorNumberRange filterType={FILTER_TYPES.workTypeTechnicalAspects} unitType={FILTER_TYPES.magnitude} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <AutocompleteMultipleChoice filterType={FILTER_TYPES.buildingProcess} disableScroll={disableScroll} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <SingleSelectorNumberRange filterType={FILTER_TYPES.measurement} unitType={FILTER_TYPES.workUnit} noBottomDivider />
        </div>
      </div>
    </CollapsableFilter>
  );
};

export default TechnicalAspectsFilters;
