/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import styles from './editSustainability.module.scss';
import { Certificado, ObraProperties } from '../../../../../types/ObraDetails';
import { useTranslation } from 'react-i18next';
import TitleHelp from '../../../components/titleHelp/titleHelp';
import { SaveContext } from '../../../../../context/SaveProvider';
import ObrasAPI from '../../../../../services/APIObras';
import NoDataPicture from '../../../components/noDataPicture/noDataPicture';
import sustainabilityNoData from '../../../../../assets/sustainabilityNoData.svg';
import EditSustainabilityRow from './editSuistainabilityRow/editSustainabilityRow';

interface EditSustainabilityProps {
  obra: ObraProperties;
  errors?: any[];
}

// We use this method to avoid the useEffect execution in the first render
const useDidMountEffect = (func: any, deps: any) => {
  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

const editSustainability: FC<EditSustainabilityProps> = (props) => {
  const { obra, errors } = props;
  const [t] = useTranslation();
  const [certificates, setCertificates] = useState<any>([]);
  const [levelMaster, setLevelMaster] = useState<any>([]);
  const [projectCertificates, setProjectCertificates] = useState(obra.esg.certificadosSostenibilidad || []);
  const saveContext = useContext(SaveContext);
  const api = ObrasAPI.getInstance();

  useDidMountEffect(() => {
    obra.esg.certificadosSostenibilidad = projectCertificates;
    saveContext.handleChangeSave(true);
  }, [projectCertificates]);

  const getCertificates = async () => {
    const results = await api.getCertificates();
    setCertificates(results.data.results.map((c: any) => {
      return { id: parseInt(c.codigo), nombre: c.nombre, niveles: c.niveles.map((l: any) => ({ id: parseInt(l.codigo), nombre: l.nombre })) };
    }));

    const resultsLevel = await api.getCertificatesLevel();
    setLevelMaster(resultsLevel.data.results);
  };

  if (!certificates || certificates.length === 0) {
    getCertificates();
  }

  const handleAddDocument = () => {
    const newCertificate: Certificado = { nomCertificado: '', nomNivel: '' };
    setProjectCertificates([...projectCertificates, newCertificate]);
    if (obra.esg.certificadosSostenibilidad) {
      obra.esg.certificadosSostenibilidad.push(newCertificate);
    } else {
      obra.esg.certificadosSostenibilidad = [newCertificate];
    }
    saveContext.handleChangeSave(true);
  };

  const getLevelCECOC = (codCertificate: string) => {
    let resultsLevel = [];
    if (levelMaster && levelMaster.length > 0) {
      resultsLevel = levelMaster.filter((lm: any) => codCertificate.includes(lm.codigo)).map((l: any) => ({
        id: l.id,
        nombre: l.nombre
      }));
      if (resultsLevel.length === 0) {
        resultsLevel = levelMaster;
      }
    }
    // remove repeated levels
    const levelRepeated: any = {};
    return resultsLevel.filter((l: any) => {
      // eslint-disable-next-line no-prototype-builtins
      if (!levelRepeated.hasOwnProperty(l.nombre)) {
        levelRepeated[l.nombre] = true;
        return true;
      }
      return false;
    });
  };

  const isFieldError = (field: string) => {
    return !!(errors && errors.find((e) => e.field === field || e.fieldField === field));
  };

  return (
    <div data-testid='edit-sustainability-test' className={styles.containerSustainability}>
      <div data-testid="sustainability-edit-test" >
        <TitleHelp
          id='sustainability'
          value={t('details.esg.sustainability.helper')}
          help={t('help.esg.sustainabilityCertificates')}
          addItemText={t('details.esg.sustainability.add') as string}
          handleAddItem={handleAddDocument}
          error={isFieldError('documentacionProyecto') || isFieldError('codNivel') || isFieldError('codNivelActualizado') ? ' ' : null}
        />
        {
          projectCertificates?.map((cert, index) => {
            const handleChangeCertificate = (data: any) => {
              cert.niveles = certificates.filter((c: any) => c.id === data.id)[0].niveles;
              cert.nomCertificado = data.nombre;
              cert.codCertificado = data.id;
              cert.nomNivel = '';
              cert.codNivel = '';
              setProjectCertificates([...projectCertificates]);
            };

            const handleChangeLevel = (data: any) => {
              if (cert.origen === 'cecoc') {
                cert.nomNivelActualizado = data.nombre;
                cert.codNivelActualizado = data.id;
              } else {
                cert.nomNivel = data.nombre;
                cert.codNivel = data.id;
              }
              setProjectCertificates([...projectCertificates]);
            };

            const handleDelete = () => {
              projectCertificates.splice(index, 1);
              setProjectCertificates([...projectCertificates]);
            };

            if (!cert.niveles && certificates && certificates.length > 0) {
              const certMaster = cert.codCertificado ? certificates.filter((c: any) => c.id === parseInt(cert.codCertificado!)) : certificates;
              cert.niveles = certMaster && certMaster.length > 0 ? certMaster[0].niveles : [];
            }
            const isFieldError = (field: string) => {
              return !!(errors && errors.find((e) => (e.field === field || e.fieldField === field) && e.index === index.toString()));
            };

            return <EditSustainabilityRow
              key={index}
              index={index}
              cert={cert}
              certificateOptions={certificates.filter((c: any) => (cert.nomCertificado !== c.nombre)) || []}
              getLevelCECOC={getLevelCECOC}
              handleChangeCertificate={handleChangeCertificate}
              handleChangeLevel={handleChangeLevel}
              handleDelete={cert.origen === 'cecoc' ? undefined : handleDelete}
              isFieldError={isFieldError}
            />;
          })
        }
        {
          !obra.esg.certificadosSostenibilidad || obra.esg.certificadosSostenibilidad.length === 0 ?
            <div>
              < NoDataPicture
                id='designer-nodata'
                picture={sustainabilityNoData}
                text={t('details.esg.sustainability.noDataAdd')}
              />
            </div>
            : null
        }
      </div >
    </div>);
};

export default editSustainability;