import React, { useContext, useEffect, useState, type FC } from 'react';
import styles from './searchUserDialog.module.scss';
import { t } from 'i18next';
import { Dialog, Stack } from '@mui/material';
import searchIcon from '../../../../assets/search.svg';
import { AdminContext, EmailandName } from '../../../../context/AdminProvider';
import ObrasAPI from '../../../../services/APIObras';
import { CenterParams, Usuario, UsuarioProyecto } from '../../../../types/Admin';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SearchUserDialogProps {
  open: boolean;
  handleOpen: (b: boolean) => void;
  users: UsuarioProyecto[];
  selectedProject: CenterParams;
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const SearchUserDialog: FC<SearchUserDialogProps> = (props) => {
  const { open, handleOpen, users, selectedProject } = props;
  const [value, setValue] = useState('');
  const api = ObrasAPI.getInstance();
  const adminContext = useContext(AdminContext);

  const [filteredUsers, setFilteredUsers] = useState<Usuario[]>([]);

  useEffect(() => {
    if (adminContext.allUsersNoPhia.length === 0) {
      api.getAllNoPhiaUsers()
        .then(res => {
          adminContext.handleCacheUsersNoPhia(res);
        }).catch(err => {
          console.log(err);
        });
    }
  }, []);

  const handleChangeInput = (newValue: string) => {
    setValue(newValue);
    if (newValue.length <= 3) {
      setFilteredUsers([]);
    } else {
      const newFilteredUsers = adminContext.allUsersNoPhia
        .filter(user => user.nombre.toLowerCase()
          .includes(newValue.toLowerCase()));
      setFilteredUsers(newFilteredUsers);
    }
  };

  const handleEditUser = (name: string, email: string) => {
    const userToEdit: EmailandName = { name, email};
    //const permission: Permiso = { id: idPermiso, nomProyecto: selectedProject.codigoNombre.split(' - ')[1], rol: rol.replace('/phia/','') };
    adminContext.handleChangeUserToEdit(userToEdit);
    const projectToEdit = {
      codEmpresa: '0008', 
      codZona: selectedProject.codZona, 
      codCentro: selectedProject.codCentro, 
      codSupercentro: selectedProject.codSupercentro
    };
    //adminContext.handleChangePermissionToEdit(permission);
    adminContext.handleChangeProjectToAdd(projectToEdit);
    adminContext.handleChangeHasProject(true);
    adminContext.handleSetIsEdition(true);
  };
  return (
    <div data-testid='searchUserDialog-test' className={styles.searchUserDialog}>
      <Dialog
        BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.15)' } }}
        PaperProps={{ sx: { borderRadius: '20px' } }}
        open={open}
      >
        <div className={styles.dialogContent}>
          <p className={styles.header}>{t('administration.searchDialog.searchUser')}</p>
          <p className={styles.text}>{t('administration.searchDialog.searchUserTip')}</p>
          <div className={styles.userInput}>
            <div className={styles.userInputTitle}>
              {t('administration.user')}
            </div>
            <input
              className={styles.inputField}
              value={value}
              onChange={(e) => handleChangeInput(e.target.value)}
            />
            <img className={styles.searchIcon} src={searchIcon} />
          </div>
          <div className={styles.filteredUsers}>
            <table>
              {filteredUsers.map((filtered, i) => {
                const isIncluded = users.map(u => u.email).includes(filtered.email);
                return <>
                  <tr key={i}>
                    <td>{filtered.nombre}</td>
                    <td>
                      <div className={styles.addProjectContainer}>
                        <div
                          className={isIncluded ? styles.isIncluded : styles.notIncluded}
                          onClick={() => { 
                            if(!isIncluded){
                              handleEditUser(filtered.nombre, filtered.email); 
                              handleOpen(false);
                            }}
                          }
                        >
                          + {
                            isIncluded
                              ? t('administration.searchDialog.addedToProject')
                              : t('administration.searchDialog.addToProject')
                          }
                        </div>  
                      </div>
                    </td>
                  </tr>
                </>;
              })}
              {value.length > 3 && filteredUsers.length === 0 && <p>{t('administration.table.notFound')}</p>}
            </table>
          </div>
          <Stack spacing={2} direction="row" justifyContent="center">
            <div className={styles.buttonClose} onClick={() => handleOpen(false)}>{t('administration.searchDialog.close')}</div>
          </Stack>
        </div>
      </Dialog>
    </div>
  );
};

export default SearchUserDialog;

