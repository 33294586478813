/* eslint-disable linebreak-style */
import { CSSProperties, memo} from 'react';
import { Box, Paper, Typography } from '@mui/material';
import parse from 'html-react-parser';

interface Props {
  id: string;
  text: string;
  isBold?: boolean;
  customCss?: CSSProperties;
  maxRows?: number;
}

const Description = ({ id, text, isBold=false, customCss={}, maxRows}: Props) => {
  return (
    <Box
      id={id}
      component={Paper}
      elevation={0}
      style={customCss}
      sx={{
        p: 2,
        borderRadius: 2,
        bgcolor: 'background.default',
        height: '100%',
        flex: 1,
      }}
    >
      <Typography
        variant="body2"
        sx={{
          ...(maxRows && { overflowY: 'auto', maxHeight: `${maxRows + 2}em`, minHeight: '2em' }),
          ...(isBold && {fontWeight: 700, fontSize: '16px'}),
        }}
      >
        {parse(text)}
      </Typography>
    </Box>
  );
};

export default memo(Description);
