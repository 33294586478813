import React, { FC, useRef } from 'react';
import styles from './editSpeciesAndZones.module.scss';
import { ObraProperties } from '../../../../../types/ObraDetails';
import SpeciesAndZonesComponent from './speciesAndZonesComponent/speciesAndZonesComponent';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface EditSpeciesAndZonesProps {
  obra: ObraProperties;
  errors?: any[];
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const EditSpeciesAndZones: FC<EditSpeciesAndZonesProps> = (props) => {
  const { obra, errors } = props;
  const ref = useRef<HTMLDivElement>(null);

  const scrollDown = ()=>{
    setTimeout(()=>{
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }, 100);
  };

  return (
    <div className={styles.editSpeciesAndZones}>
      <div className={styles.section}>
        <SpeciesAndZonesComponent
          obra={obra}
          data={obra.localizacion?.especiesPeligro || []}
          type='species'
          errors={errors}
          scrollDown={()=>scrollDown()}
        />
      </div>
      <div className={styles.section}>
        <SpeciesAndZonesComponent
          obra={obra}
          data={obra.localizacion?.zonasProteccion || []}
          type='zones'
          errors={errors}
          scrollDown={()=>scrollDown()}
        />
      </div>
      <div ref={ref}></div>
    </div>
  );
};

export default EditSpeciesAndZones;