/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC } from 'react';
import styles from './addItemButton.module.scss';

interface AddItemButtonProps {
  id?: string;
  addItemText: string;
  handleAddItem: () => any;
}

const AddItemButton: FC<AddItemButtonProps> = (props) => {
  const { id, addItemText, handleAddItem } = props;

  return (
    <div className={styles.addButton}
      id={`${id}-addBtn`}
      onClick={handleAddItem}>
        + {addItemText}
    </div>
  );
};

export default AddItemButton;
