/* eslint-disable indent */
import React, { createContext, useState, useEffect, useContext } from 'react';
import { ACCION, FilterValue, Mode } from '../types/FiltersTypes';
import { ACCUMULATIVE_NUMBER_FILTERS, FILTERS_COMBINED, FILTERS_UNIQUE_VALUE } from '../config/APIConfig';
import ObrasAPI from '../services/APIObras';
import FILTER_TYPES, {
  BASIC_FILTERS, GENERAL_DATA_FILTERS, DESCRIPTION_FILTERS, LOCATION_FILTERS, BUDGET_FILTERS, CONTRACT_FILTERS,
  PARTNERS_FILTERS, CLIENTS_FILTERS, DATES_FILTERS, BIM_FILTERS, ESG_FILTERS, FILTER_TYPES_ANALYTICS
} from '../components/Filters/filterTypes';
import ReactGA4 from 'react-ga4';
import { Option } from '../types/Option';
import { AuthContext } from '../services/authContextProvider';

export type AdvancedFilter = 'basic' | 'general' | 'description' | 'location' | 'budget' | 'technical'
  | 'contract' | 'partners' | 'clients' | 'dates' |  'bim' | 'esg' | 'classification' | ''; // Include the remaining filters

const defaultNumberFilterType = {
  [FILTER_TYPES.adjudicationAmount]: 'range',
  [FILTER_TYPES.accionaParticipation]: 'range',
  [FILTER_TYPES.protectedArea]: 'range',
  [FILTER_TYPES.protectedProjectArea]: 'range',
  [FILTER_TYPES.accionaBudget]: 'range',
  [FILTER_TYPES.updatedAccionaBudget]: 'range',
  [FILTER_TYPES.finalAccionaBudget]: 'range',
  [FILTER_TYPES.updatedBudget]: 'range',
  [FILTER_TYPES.finalBudget]: 'range',
  [FILTER_TYPES.measurement]: 'range',
  [FILTER_TYPES.magnitude]: 'range',
  [FILTER_TYPES.estimatedAmount]: 'range',
  [FILTER_TYPES.finalAmount]: 'range',
  [FILTER_TYPES.warrantyPeriod]: 'range',
  [FILTER_TYPES.initialDeadline]: 'range',
  [FILTER_TYPES.updatedDeadline]: 'range',
  [FILTER_TYPES.numberPartner]: 'range',
  [FILTER_TYPES.percentPartnerParticipation]: 'range',
  [FILTER_TYPES.complexityNote]: 'range',
  [FILTER_TYPES.noteComplexityBimApplications]: 'range',
  [FILTER_TYPES.complexityNoteUsageManagement]: 'range',
  [FILTER_TYPES.complexityNoteStandardUses]: 'range',
  [FILTER_TYPES.gisBudget]: 'range',
  [FILTER_TYPES.carbonBudget]: 'range',
};

const ONLY_VALUES_FILTERS = ['exactAdjudicationAmount', 'exactWarrantyPeriod', 'exactPercentPartnerParticipation', 'exactUpdatedDeadline', 'exactInitialDeadline'];

const UNITS = {
  [FILTER_TYPES.workTypeTechnicalAspects]: FILTER_TYPES.magnitude,
  [FILTER_TYPES.classificationEstimatedAmount]: FILTER_TYPES.estimatedAmount,
  [FILTER_TYPES.classificationFinalAmount]: FILTER_TYPES.finalAmount,
  [FILTER_TYPES.classificationFinalAmount]: FILTER_TYPES.taxonomicActivity
};

interface FilterContextValues {
  appliedFilters: { [key: string]: FilterValue[] },
  subtypesPerGroup: { [key: string]: FilterValue[] },
  filters: { [key: string]: FilterValue[] },
  openFilter: string,
  numberFilterType: { [key: string]: string },
  filteredProjectsNumber: number | null,
  masterFilters: Map<string, string[]>, // This map is made for caching those filters that come from an elastic master (e.g. workType)
  selectedWorkUnit: Option | null,
  selectedWorkType: Option | null,
  handleChange: (key: string | string[], value: FilterValue | FilterValue[], mode?: Mode, groupCode?: string, rangeType?: string) => void,
  handleBulkChange: (key: string, values: FilterValue[], mode?: Mode) => void,
  getTags: (key: string) => FilterValue[],
  getAppliedTags: (key: string) => FilterValue[],
  handleRemoveAppliedFilterTag: (tagToRemove: FilterValue, key: string) => void,
  finish: (apply: ACCION) => void,
  clearFiltersByType: (filter: AdvancedFilter) => void,
  deleteNumberFilter: (key: string) => void,
  deleteBulkNumberFilter: (keys: string[]) => void,
  getAppliedFiltersCount: () => number,
  getDefaultOpenSection: () => number[],
  getAppliedAdvancedFiltersCount: () => number,
  setOpenFilter: (filterType: string) => void,
  getFieldsCountByFilter: (filter: AdvancedFilter) => number,
  handleChangeNumberFilterType: (filterType: string, newType: string) => void,
  getFieldsCountDescription: (filter: AdvancedFilter) => number,
  handleCacheMasterFilter: (key: string, newFilter: string) => void,
  handleChangeSelectedWorkUnit: (workUnit: Option) => void,
  handleChangeSelectedWorkType: (workType: Option | null) => void,
  transformMasterFilters: (filters: any, cachedFilters?: any) => any,
  getFieldsCountContract: (filter: AdvancedFilter) => number,
  getFieldsCountPartners: (filter: AdvancedFilter) => number,
  getFieldsCountClients: (filter: AdvancedFilter) => number,
  getFieldsDatesClients: (filter: AdvancedFilter) => number,
  getFieldsCountBim: (filter: AdvancedFilter) => number,
  getFieldsCountClassification: (filter: AdvancedFilter) => number,
}

const defaultFilterContextValues: FilterContextValues = {
  appliedFilters: {},
  subtypesPerGroup: {},
  filters: {},
  openFilter: '',
  numberFilterType: defaultNumberFilterType,
  filteredProjectsNumber: 0,
  masterFilters: new Map<string, string[]>(),
  selectedWorkUnit: null,
  selectedWorkType: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleChange: () => { },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleBulkChange: () => { },
  getTags: () => [],
  getAppliedTags: () => [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleRemoveAppliedFilterTag: () => { },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  finish: () => { },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  clearFiltersByType: () => { },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  deleteNumberFilter: () => { },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  deleteBulkNumberFilter: () => { },
  getAppliedFiltersCount: () => 0,
  getDefaultOpenSection: () => [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  getAppliedAdvancedFiltersCount: () => 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setOpenFilter: () => { },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  getFieldsCountByFilter: () => 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleChangeNumberFilterType: () => { },
  getFieldsCountDescription: () => 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleCacheMasterFilter: () => { },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleChangeSelectedWorkUnit: () => { },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleChangeSelectedWorkType: () => { },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  transformMasterFilters: () => { },
  getFieldsCountContract: () => 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  getFieldsCountPartners: () => 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  getFieldsCountClients: () => 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  getFieldsDatesClients: () => 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  getFieldsCountBim: () => 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  getFieldsCountClassification: () => 0,
};

export const FilterContext = createContext<FilterContextValues>(
  defaultFilterContextValues
);

const firstLetterToLowerCase = (text: string) => {
  return text.charAt(0).toLowerCase() + text.slice(1);
};

interface FilterContextProviderProps {
  /**
   * The elements wrapped by the auth context.
   */
  children: JSX.Element;
}

const FilterProvider = (props: FilterContextProviderProps) => {
  const [filters, setFilters]: any = useState({});
  const [appliedFilters, setAppliedFilters]: any = useState({});
  const [subtypesPerGroup, setSubtypesPerGroup]: any = useState({});
  const [openFilter, setOpenFilter]: any = useState('');
  const [numberFilterType, setNumberFilterType]: any = useState(defaultNumberFilterType);
  const [filteredProjectsNumber, setFilteredProjectsNumber] = useState<number | null>(0);
  const [masterFilters, setMasterFilters] = useState(new Map<string, string[]>()); // key: filterType, value: array of master options
  const [selectedWorkUnit, setSelectedWorkUnit] = useState<Option | null>(null);
  const [selectedWorkType, setSelectedWorkType] = useState<Option | null>(null);
  const authContext = useContext(AuthContext);
  const api = ObrasAPI.getInstance();

  const transformMasterFilters = (filters: any, cachedFilters?: any) => {
    const transformedFilters = { ...filters };
    ACCUMULATIVE_NUMBER_FILTERS.forEach((ac) => {
      transformedFilters[ac] = [];
      const filtersToLoop = cachedFilters?.get(firstLetterToLowerCase(ac)) || masterFilters.get(firstLetterToLowerCase(ac));
      filtersToLoop?.forEach((f: any) => {
        if (filters[f]) transformedFilters[ac].push(...filters[f]);
        delete transformedFilters[f];
      });
    });
    delete transformedFilters[FILTER_TYPES.workTypeTechnicalAspects];
    delete transformedFilters[FILTER_TYPES.measurement];
    delete transformedFilters[FILTER_TYPES.classificationEstimatedAmount];
    delete transformedFilters[FILTER_TYPES.classificationFinalAmount];
    return transformedFilters;
  };

  useEffect(() => {
    api.getNumberOfFilteredProjects(transformMasterFilters(filters)).then((numberOfProjects) => {
      setFilteredProjectsNumber(numberOfProjects);
    }).catch((error) => {
      setFilteredProjectsNumber(null);
      console.log(error);
    });
  }, []);

  useEffect(() => {
    deleteBulkNumberFilter([
      FILTER_TYPES.accionaBudget,
      FILTER_TYPES.updatedAccionaBudget,
      FILTER_TYPES.finalAccionaBudget,
      FILTER_TYPES.finalBudget,
      FILTER_TYPES.updatedBudget
    ]);
  }, [filters[FILTER_TYPES.localCurrency]]);

  const getFilterKeys = (key: string) => {
    let keys = [key];
    if (!filters[key]) {
      const specialTags = (FILTERS_COMBINED as any)[key];
      if (specialTags) {
        keys = specialTags.map((o: any) => o.key);
      }
    }
    return keys;
  };

  const handleChange = async (keys: string | string[], values: FilterValue | FilterValue[], mode?: Mode, groupCode?: string) => {
    const newFilters = { ...filters };

    const keysToIterate = Array.isArray(keys) ? keys : [keys];
    const valuesToIterate = Array.isArray(values) ? values : [values];

    keysToIterate.forEach((key, i) => {
      ReactGA4.event('filter', {
        event_category: FILTER_TYPES_ANALYTICS[key],
        event_label: mode as string,
        role: authContext.mainRole(true),
        type: BASIC_FILTERS.includes(key) ? 'basic' : 'advanced',
      });
      const filterKeys = getFilterKeys(key);
      const value = valuesToIterate[i];

      if ([
        FILTER_TYPES.measurement,
        FILTER_TYPES.workTypeTechnicalAspects,
        FILTER_TYPES.classificationEstimatedAmount,
        FILTER_TYPES.classificationFinalAmount,
        FILTER_TYPES.taxonomicActivity
      ].includes(key) && mode === Mode.DELETE) {
        const newKey = value.code;
        newFilters[newKey] = [];
      } else {
        filterKeys.forEach((k: any) => {
          let filterValue: FilterValue[] = [];
          switch (mode) {
            case Mode.REPLACE:
              newFilters[k] = [value];
              break;
            case Mode.DELETE:
              filterValue = filters[k]?.filter((v: FilterValue) => {
                if (Array.isArray(value.code)) return !value.code.includes(v.code);
                return v.code !== value.code;
              }) || [];
              newFilters[k] = filterValue;
              break;
            default:
              newFilters[k] = filters[k] || [];
              if ([
                FILTER_TYPES.measurement
              ].includes(k)) {
                newFilters[k] = newFilters[k].filter((f: FilterValue) => f.unitCode !== value.unitCode);
              }
              if (FILTERS_UNIQUE_VALUE.includes(k))
                newFilters[k] = [value];
              else
                newFilters[k] = newFilters[k].concat(value);
              break;
          }

          if (groupCode) {
            const opts = mode === Mode.DELETE ?
              subtypesPerGroup[groupCode]?.filter((op: FilterValue) => op.code !== value.code) :
              (subtypesPerGroup[groupCode] || []).concat({ code: value.code, name: value.name });

            setSubtypesPerGroup({ ...subtypesPerGroup, [groupCode]: opts });
          } else if (mode === Mode.DELETE) {
            const updatedSubtypesPerGroup: any = {};
            for (const i of Object.keys(subtypesPerGroup)) {
              updatedSubtypesPerGroup[i] = subtypesPerGroup[i].filter((el: FilterValue) => el.code !== value.code);
            }
            setSubtypesPerGroup(updatedSubtypesPerGroup);
          }
        });
      }
    });
    setFilters(newFilters);
    const numberOfProjects = await api.getNumberOfFilteredProjects(transformMasterFilters(newFilters));
    setFilteredProjectsNumber(numberOfProjects);
  };

  /**
   * Helper function that processes common tags for getTags and getAppliedTags
   * @param filterObj 
   * @param key 
   * @returns 
   */
  const processTags = (filterObj: any, key: string) => {
    // If filter type is measurement or magnitude, we need to search the tags within the master filters map
    if ([
      FILTER_TYPES.measurement,
      FILTER_TYPES.taxonomicActivity,
    ].includes(key)) {
      const filtersArray = masterFilters.get(key) || [];
      const res = [];
      for (const f of filtersArray) {
        const filter = filters[f];
        if (filter && filter[0]) {
          res.push({ code: f, name: filter[0].tag });
        }
      }
      return res;
    }
    if (FILTER_TYPES.workTypeTechnicalAspects === key) {
      const filtersArray = masterFilters.get(FILTER_TYPES.magnitude) || [];
      const res = [];
      for (const f of filtersArray) {
        const filter = filters[f];
        if (filter && filter[0]) {
          res.push({ code: f, name: filter[0].tag });
        }
      }
      return res;
    }
    if (FILTER_TYPES.classificationEstimatedAmount === key) {
      const filtersArray = masterFilters.get(FILTER_TYPES.estimatedAmount) || [];
      const res = [];
      for (const f of filtersArray) {
        const filter = filters[f];
        if (filter && filter[0]) {
          res.push({ code: f, name: filter[0].tag });
        }
      }
      return res;
    }

    if (FILTER_TYPES.classificationFinalAmount === key) {
      const filtersArray = masterFilters.get(FILTER_TYPES.finalAmount) || [];
      const res = [];
      for (const f of filtersArray) {
        const filter = filters[f];
        if (filter && filter[0]) {
          res.push({ code: f, name: filter[0].tag });
        }
      }
      return res;
    }

    if (FILTER_TYPES.taxonomicActivity === key) {
      const filtersArray = masterFilters.get(FILTER_TYPES.taxonomicActivity) || [];
      const res = [];
      for (const f of filtersArray) {
        const filter = filters[f];
        if (filter && filter[0]) {
          res.push({ code: f, name: filter[0].tag });
        }
      }
      return res;
    }

    // Initialize tagsByType with tags corresponding to the key, or an empty array if none exist
    let tagsByType = filterObj[key] || [];
    // Check if there are no direct tags for the key
    if (!filterObj[key]) {
      // Get combined tags from the FILTERS_COMBINED object
      const specialTags = (FILTERS_COMBINED as any)[key];

      // Check if combined tags exist
      if (specialTags) {
        // Initialize an array to store combined tags
        const parsedTags: any[] = [];

        // Iterate over combined tags
        specialTags.forEach((o: any) => {
          // Check if there are tags for the combined key
          filterObj[o.key] && filterObj[o.key].forEach((value: FilterValue, i: number) => {
            // Create structure for combined tags
            parsedTags[i] = { ...parsedTags[i], [o.key]: { code: value.code, name: getOnlyValues(o.key) ? value.name : value.tag } };

            // Remove duplicates from fromAdjudicationAmount and toAdjudicationAmount
            if (parsedTags[0]?.fromAdjudicationAmount?.code === parsedTags[0]?.toAdjudicationAmount?.code) {
              delete parsedTags[0].toAdjudicationAmount;
              delete parsedTags[0].fromAdjudicationAmount;
            }
          });
        });

        // Map combined tags to the desired format
        tagsByType = parsedTags.map((t: any) => {
          const labels = Object.keys(t).map((key: string) => t[key]?.name || '');
          const label = labels.join(' - ');
          const codes = Object.keys(t).map((key: string) => t[key]?.code || '');
          return { code: codes, name: label };
        });
      }
    }
    // Return processed tags
    return tagsByType;
  };

  const getOnlyValues = (name: string): boolean => ONLY_VALUES_FILTERS.includes(name);

  /**
   *  Method to get tags based on a key
   * @param key 
   * @returns 
   */
  const getTags = (key: string) => processTags(filters, key);

  /**
   * Method to get applied tags based on a key
   * @param key 
   * @returns 
   */
  const getAppliedTags = (key: string) => processTags(appliedFilters, key);

  const handleRemoveAppliedFilterTag = async (tagToRemove: FilterValue, key: string) => {
    const appliedFiltersByKey = appliedFilters[key]?.filter((f: FilterValue) => f !== tagToRemove);
    const newAppliedFilters = { ...appliedFilters };

    if (appliedFiltersByKey) newAppliedFilters[key] = appliedFiltersByKey;

    function checkCachedMasterFilters(filter: string) {
      const cachedFilter = masterFilters.get(UNITS[filter] || filter);
      if (cachedFilter?.includes(tagToRemove.code)) {
        newAppliedFilters[tagToRemove.code] = [];
      }
    }

    function checkCombinedFilter(filter: string | string[]) {
      if (!Array.isArray(filter)) filter = [filter];
      const newFilters = { ...filters };
      for (const f of filter) {
        delete newFilters[`from${f}`];
        delete newFilters[`to${f}`];
        delete newFilters[`exact${f}`];
        newAppliedFilters[`from${f}`] = [];
        newAppliedFilters[`to${f}`] = [];
        newAppliedFilters[`exact${f}`] = [];
        if (f === FILTER_TYPES.localCurrency) {
          delete newFilters[f];
          newAppliedFilters[f] = [];
        }
      }
      setFilters(newFilters);
    }

    function checkAllCombinedFilters() {
      const combinedFilters = Object.keys(FILTERS_COMBINED);
      if (combinedFilters.includes(key)) {
        checkCombinedFilter(key);
        return true;
      }
      return false;
    }
    if (!checkAllCombinedFilters()) {
      if (key === FILTER_TYPES.localCurrency) {
        checkCombinedFilter([
          FILTER_TYPES.localCurrency,
          FILTER_TYPES.accionaBudget,
          FILTER_TYPES.updatedAccionaBudget,
          FILTER_TYPES.finalAccionaBudget,
          FILTER_TYPES.finalBudget,
          FILTER_TYPES.updatedBudget,
          FILTER_TYPES.warrantyPeriod
        ]);
      } else {
        checkCachedMasterFilters(key);
        handleChange(key, tagToRemove, Mode.DELETE);
      }
    }
    setAppliedFilters(newAppliedFilters);
    const numberOfProjects = await api.getNumberOfFilteredProjects(transformMasterFilters(newAppliedFilters));
    setFilteredProjectsNumber(numberOfProjects);
  };

  const handleBulkChange = async (key: string, values: FilterValue[], mode?: Mode, groupCode?: string) => {
    let newFilters;
    const filterValues = mode === Mode.DELETE
      ? deleteFiltersByGroup(key, groupCode)
      : values;
    if (groupCode) {
      setSubtypesPerGroup({ ...subtypesPerGroup, [groupCode]: mode === Mode.ADD ? values : [] });
      newFilters = {
        ...filters,
        [key]: mode === Mode.ADD && filters[key]
          ? [...new Set([...filters[key], ...filterValues])]
          : filterValues,
      };
      setFilters(newFilters);
    } else {
      newFilters = {
        ...filters,
        [key]: filterValues,
      };
      setFilters(newFilters);
    }
    const numberOfProjects = await api.getNumberOfFilteredProjects(transformMasterFilters(newFilters));
    setFilteredProjectsNumber(numberOfProjects);
  };

  const deleteFiltersByGroup = (key: string, groupCode?: string) => {
    let res: string[] = [];
    if (groupCode) {
      const subtypesCodes = subtypesPerGroup[groupCode].map((st: FilterValue) => st.code);
      res = filters[key]?.filter((f: FilterValue) => !subtypesCodes.includes(f.code));
    }
    return res;
  };

  const clearFiltersByType = async (filter: AdvancedFilter) => {
    const newFilters: any = {};
    let filtersToMap: any[];
    switch (filter) {
      case 'basic':
        filtersToMap = BASIC_FILTERS;
        setSubtypesPerGroup({});
        break;
      case 'description':
        filtersToMap = DESCRIPTION_FILTERS;
        break;
      case 'budget':
        filtersToMap = BUDGET_FILTERS;
        break;
      case 'location':
        filtersToMap = LOCATION_FILTERS;
        break;
      case 'contract':
        filtersToMap = CONTRACT_FILTERS;
        break;
      case 'partners':
        filtersToMap = PARTNERS_FILTERS;
        break;
      case 'clients':
        filtersToMap = CLIENTS_FILTERS;
        break;
      case 'dates':
        filtersToMap = DATES_FILTERS;
        break;
      case 'bim':
        filtersToMap = BIM_FILTERS;
        break;
      case 'general':
        filtersToMap = GENERAL_DATA_FILTERS;
        setSubtypesPerGroup({});
        break;
      case 'technical':
        filtersToMap = getTechnicalAspectsFilters();
        setSubtypesPerGroup({});
        break;
      case 'esg':
        filtersToMap = getESGFilters();
        break;
      case 'classification':
        filtersToMap = getClassificationsFilters();
        break;
      default:
        filtersToMap = [];
    }
    Object.keys(filters).filter((f) => !filtersToMap.includes(f)).map((f) => {
      newFilters[f] = filters[f];
    });
    setFilters(newFilters);
    const numberOfProjects = await api.getNumberOfFilteredProjects(transformMasterFilters(newFilters));
    setFilteredProjectsNumber(numberOfProjects);
  };

  const handleChangeNumberFilterType = (filterType: string, newType: string) => {
    if (!ACCUMULATIVE_NUMBER_FILTERS.includes(filterType)) {
      deleteNumberFilter(filterType);
    }
    const newNumberFilterType = { ...numberFilterType };
    newNumberFilterType[filterType] = newType;
    setNumberFilterType(newNumberFilterType);
  };

  const deleteNumberFilter = async (key: string) => {
    let newFilters = filters;
    const keys = getFilterKeys(key);
    keys.forEach((k: any) => {
      newFilters = {
        ...newFilters,
        [k]: [],
      };
    });
    setFilters(newFilters);
    const numberOfProjects = await api.getNumberOfFilteredProjects(transformMasterFilters(newFilters));
    setFilteredProjectsNumber(numberOfProjects);
  };

  const deleteBulkNumberFilter = async (keys: string[]) => {
    let newFilters = filters;
    keys.forEach((key: any) => {
      const filterKeys = getFilterKeys(key);
      filterKeys.forEach((k: any) => {
        newFilters = {
          ...newFilters,
          [k]: [],
        };
      });
    });
    setFilters(newFilters);
    const numberOfProjects = await api.getNumberOfFilteredProjects(transformMasterFilters(newFilters));
    setFilteredProjectsNumber(numberOfProjects);
  };

  const finish = async (accion: ACCION) => {
    let numberOfProjects;
    switch (accion) {
      case ACCION.APPLY:
        setAppliedFilters(filters);
        break;
      case ACCION.CLOSE:
        setFilters(appliedFilters);
        break;
      case ACCION.CLEAR:
        setFilters({});
        setSubtypesPerGroup({});
        numberOfProjects = await api.getNumberOfFilteredProjects({});
        setFilteredProjectsNumber(numberOfProjects);
        break;
      case ACCION.DELETE:
        setFilters({});
        setSubtypesPerGroup({});
        setAppliedFilters({});
        numberOfProjects = await api.getNumberOfFilteredProjects({});
        setFilteredProjectsNumber(numberOfProjects);
        break;
      default:
    }
  };

  const handleCacheMasterFilter = (key: string, newFilter: string) => {
    const cachedFilters = masterFilters.get(key);
    if (!cachedFilters) {
      const value = [newFilter];
      masterFilters.set(key, value);
    } else {
      const updatedFilters = !cachedFilters.includes(newFilter) ? [...cachedFilters, newFilter] : cachedFilters;
      masterFilters.set(key, updatedFilters);
    }
    setMasterFilters(new Map(masterFilters));
  };

  const handleChangeSelectedWorkUnit = (workUnit: Option) => {
    setSelectedWorkUnit(workUnit);
  };

  const handleChangeSelectedWorkType = (workType: Option | null) => {
    setSelectedWorkType(workType);
  };

  const getAppliedFiltersCount = () => {
    const filtersCount: any = {};
    const basicFiltersApplied = Object.keys(appliedFilters).filter((f) => BASIC_FILTERS.includes(f));
    basicFiltersApplied.forEach((filterType) => {
      let isFilterCombined = false;
      Object.keys(FILTERS_COMBINED).map((filterKey: string) => {
        const filterCombined = (FILTERS_COMBINED as any)[filterKey];
        filterCombined.forEach((f: any) => {
          if (f.key === filterType) {
            filtersCount[filterKey] = appliedFilters[filterType]?.length ? 1 : filtersCount[filterKey] || 0;
            isFilterCombined = true;
          }
        });
      });
      if (!isFilterCombined) {
        filtersCount[filterType] = appliedFilters[filterType]?.length ? 1 : 0;
      }
    });
    return Object.values(filtersCount).filter((value: any) => value).length;
  };

  const getDefaultOpenSection = () => {
    const defaultRes = [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    function check(array: string[], index: number) {
      for (const f of array) {
        if (filters[f]?.length > 0) {
          return defaultRes.map((_val, i) => {
            return i === index ? 1 : 0;
          });
        }
      }
      return null;
    }

    return check(BASIC_FILTERS, 0)
      || check(GENERAL_DATA_FILTERS, 1)
      || check(DESCRIPTION_FILTERS, 2)
      || check(LOCATION_FILTERS, 3)
      || check(BUDGET_FILTERS, 4)
      || check(CONTRACT_FILTERS, 5)
      || check(CLIENTS_FILTERS, 6)
      || check(PARTNERS_FILTERS, 7)
      || check(getTechnicalAspectsFilters(), 8)
      || check(DATES_FILTERS, 9)
      || check(getESGFilters(), 10)
      || check(BIM_FILTERS, 11)
      || check(getClassificationsFilters(), 12)
      || defaultRes;
    // Include remaining filters with check(FILTER, INDEX)
  };

  const getTechnicalAspectsFilters = () => {
    const magnitudes = masterFilters.get(FILTER_TYPES.magnitude) || [];
    const measurement = masterFilters.get(FILTER_TYPES.measurement) || [];
    const technicalAspectsFilters = [magnitudes, measurement].flat();
    technicalAspectsFilters.push(FILTER_TYPES.buildingProcess);
    return technicalAspectsFilters;
  };

  const getClassificationsFilters = () => {
    const classificationsFilters = ['estimatedAmount', 'finalAmount'];
    const result = classificationsFilters.map((f) => {
      const cachedFilters = masterFilters.get(f);
      return cachedFilters ? cachedFilters : [];
    }).flat();
    return result;
  };

  const getESGFilters = () => {
    const masters = masterFilters.get(FILTER_TYPES.taxonomicActivity);
    const esgFilters = masters ? [...masters] : [];
    esgFilters.push(...ESG_FILTERS);
    return esgFilters;
  };
 
  const getAppliedAdvancedFiltersCount = () => {
    const counts = [
      getFieldsCountGeneral(),
      getFieldsCountDescription(),
      getFieldsCountBudget(),
      getFieldsCountLocation(),
      getFieldsCountTechnicalAspects(),
      getFieldsCountClassification(),
      getFieldsCountContract(),
      getFieldsCountPartners(),
      getFieldsCountClients(),
      getFieldsDatesClients(),
      getFieldsCountBim(),
      getFieldsCountESG(),
    ];
    return counts.reduce((a, b) => a + b, 0);
  };

  const getFieldsCountByFilter = (filter: AdvancedFilter) => {
    switch (filter) {
      case 'basic':
        return getFieldsCountBasic();
      case 'general':
        return getFieldsCountGeneral();
      case 'location':
        return getFieldsCountLocation();
      case 'budget':
        return getFieldsCountBudget();
      case 'contract':
        return getFieldsCountContract();
      case 'partners':
        return getFieldsCountPartners();
      case 'description':
        return getFieldsCountDescription();
      case 'technical':
        return getFieldsCountTechnicalAspects();
      case 'classification':
        return getFieldsCountClassification();
      case 'clients':
        return getFieldsCountClients();
      case 'dates':
        return getFieldsDatesClients();
      case 'bim':
        return getFieldsCountBim();
      case 'esg':
        return getFieldsCountESG();
      default:
        return 0;
      // Include remaining cases
    }
  };

  const simplifyFilter = (res: any, iteratedFilter: string, filterType: string) => {
    if (res[filterType]) {
      if (filters[iteratedFilter]) res[filterType].push(...filters[iteratedFilter]);
    } else {
      res[filterType] = filters[iteratedFilter] ? [...filters[iteratedFilter]] : [];
    }
  };

  const getCombinedFilters = (filterType: string) => {
    const combinedFilters: string[] = FILTERS_COMBINED[filterType].map((f: any) => f.key);
    combinedFilters.push(filterType);
    return combinedFilters;
  };

  const loopCombinedFilters = (res: any, filtersType: string[], combinedFilters: string[]) => {
    for (const f of filtersType) {
      let combined = false;
      for (const filterType of combinedFilters) {
        if (getCombinedFilters(filterType).includes(f)) {
          simplifyFilter(res, f, filterType);
          combined = true;
          break;
        }
      }
      if (!combined) res[f] = filters[f];
    }
  };

  const processFieldsCount = (filterType: string[], combinedFilters: string[]) => {
    const res: any = {};
    loopCombinedFilters(res, filterType, combinedFilters);
    return Object.keys(res).filter((f) => res[f]?.length > 0).length;
  };

  const getFieldsCountBasic = () => {
    return processFieldsCount(BASIC_FILTERS, [
      FILTER_TYPES.adjudicationAmount,
      FILTER_TYPES.adjudicationDate,
    ]);
  };

  const getFieldsCountBudget = () => {
    return processFieldsCount(BUDGET_FILTERS, [
      FILTER_TYPES.accionaBudget,
      FILTER_TYPES.finalAccionaBudget,
      FILTER_TYPES.finalBudget,
      FILTER_TYPES.updatedAccionaBudget,
      FILTER_TYPES.updatedBudget,
      FILTER_TYPES.finalBudgetDate,
      FILTER_TYPES.updatedBudgetDate,
    ]);
  };

  const getFieldsCountClients = () => {
    return processFieldsCount(CLIENTS_FILTERS, [
      FILTER_TYPES.clientCollaborationEndDate,
      FILTER_TYPES.endDateCollaborationSiteManagement,
      FILTER_TYPES.startDateTechnicalAssistance,
      FILTER_TYPES.endDateCollaborationTechnicalAssistance,
      FILTER_TYPES.startDateWorkManagement,
    ]);
  };
  const getFieldsDatesClients = () => {
    return processFieldsCount(DATES_FILTERS, [
      FILTER_TYPES.signatureContract,
      FILTER_TYPES.stakeoutReport,
      FILTER_TYPES.beginningWork,
      FILTER_TYPES.initialDeadline,
      FILTER_TYPES.updatedDeadline,
      FILTER_TYPES.plannedEnd,
      FILTER_TYPES.plannedEndUpdatedApproved,
      FILTER_TYPES.receptionReport,
      FILTER_TYPES.endExecution,
    ]);
  };

  const getFieldsCountBim = () => {
    return processFieldsCount(BIM_FILTERS, [
      FILTER_TYPES.complexityNote,
      FILTER_TYPES.noteComplexityBimApplications,
      FILTER_TYPES.complexityNoteUsageManagement,
      FILTER_TYPES.complexityNoteStandardUses,
    ]);
  };

  const getFieldsCountGeneral = () => {
    return processFieldsCount(GENERAL_DATA_FILTERS, [
      FILTER_TYPES.accionaParticipation,
    ]);
  };

  const getFieldsCountLocation = () => {
    return processFieldsCount(LOCATION_FILTERS, [
      FILTER_TYPES.protectedArea,
      FILTER_TYPES.protectedProjectArea,
    ]);
  };

  const getFieldsCountContract = () => {
    const res: any = {};
    CONTRACT_FILTERS.map((f) => {
      if ([
        FILTER_TYPES.exactWarrantyPeriod,
        FILTER_TYPES.fromWarrantyPeriod,
        FILTER_TYPES.toWarrantyPeriod,
        FILTER_TYPES.warrantyPeriod,
      ].includes(f)) {
        if (res[FILTER_TYPES.warrantyPeriod]) {
          if (filters[f]) res[FILTER_TYPES.warrantyPeriod].push(...filters[f]);
        } else {
          res[FILTER_TYPES.warrantyPeriod] = filters[f] ? [...filters[f]] : [];
        }
      } else
        res[f] = filters[f];
    });
    return Object.keys(res).filter((f) => res[f]?.length > 0).length;
  };

  const getFieldsCountPartners = () => {
    const res: any = {};
    PARTNERS_FILTERS.map((f) => {
      if ([
        FILTER_TYPES.exactNumberPartner,
        FILTER_TYPES.fromNumberPartner,
        FILTER_TYPES.toNumberPartner,
        FILTER_TYPES.numberPartner,
      ].includes(f)) {
        if (res[FILTER_TYPES.numberPartner]) {
          if (filters[f]) res[FILTER_TYPES.numberPartner].push(...filters[f]);
        } else {
          res[FILTER_TYPES.numberPartner] = filters[f] ? [...filters[f]] : [];
        }
      } else if ([
        FILTER_TYPES.exactPercentPartnerParticipation,
        FILTER_TYPES.fromPercentPartnerParticipation,
        FILTER_TYPES.toPercentPartnerParticipation,
        FILTER_TYPES.percentPartnerParticipation,
      ].includes(f)) {
        if (res[FILTER_TYPES.percentPartnerParticipation]) {
          if (filters[f]) res[FILTER_TYPES.percentPartnerParticipation].push(...filters[f]);
        } else {
          res[FILTER_TYPES.percentPartnerParticipation] = filters[f] ? [...filters[f]] : [];
        }
      } else
        res[f] = filters[f];
    });

    return Object.keys(res).filter((f) => res[f]?.length > 0).length;
  };

  const getFieldsCountDescription = () => {
    return processFieldsCount(DESCRIPTION_FILTERS, []);
  };

  const getFieldsCountTechnicalAspects = () => {
    const res = [];
    const keys = ['workTypeTechnicalAspects', 'workUnit'];
    const nonEmptyFilters = Object.keys(filters).filter((f: any) => filters[f]?.length > 0);
    for (const k of keys) {
      const found = nonEmptyFilters.find((f) => f !== 'workTypeTechnicalAspects' && f.includes(k));
      if (found) res.push(found);
    }
    return res.length + processFieldsCount([FILTER_TYPES.buildingProcess], []);
  };

  const getFieldsCountESG = () => {
    const res = [];
    const keys = [FILTER_TYPES.taxonomicActivity];
    const nonEmptyFilters = Object.keys(filters).filter((f: any) => filters[f]?.length > 0);
    for (const k of keys) {
      const found = nonEmptyFilters.find((f) => f !== k && f.includes(k));
      if (found) res.push(found);
    }
    return res.length + processFieldsCount(ESG_FILTERS, [
      FILTER_TYPES.carbonBudget,
      FILTER_TYPES.gisBudget,
    ]);
  };

  const getFieldsCountClassification = () => {
    const res = [];
    const keys = [FILTER_TYPES.classificationEstimatedAmount, FILTER_TYPES.classificationFinalAmount];
    const classificationFilters = Object.keys(filters).filter((f: any) => filters[f]?.length > 0);
    for (const k of keys) {
      const found = classificationFilters.find((f) => f !== FILTER_TYPES.classificationEstimatedAmount && f!== FILTER_TYPES.classificationFinalAmount && f.includes(k));
      if (found) res.push(found);
    }
    return res.length;
  };

  // Setup the context provider
  return (
    <FilterContext.Provider
      value={
        {
          appliedFilters,
          subtypesPerGroup,
          filters,
          selectedWorkUnit,
          selectedWorkType,
          handleChange,
          handleBulkChange,
          getTags,
          getAppliedTags,
          handleRemoveAppliedFilterTag,
          finish,
          getAppliedFiltersCount,
          getDefaultOpenSection,
          getAppliedAdvancedFiltersCount,
          openFilter,
          numberFilterType,
          filteredProjectsNumber,
          masterFilters,
          setOpenFilter,
          getFieldsCountByFilter,
          clearFiltersByType,
          deleteNumberFilter,
          deleteBulkNumberFilter,
          handleChangeNumberFilterType,
          getFieldsCountDescription,
          handleCacheMasterFilter,
          handleChangeSelectedWorkUnit,
          handleChangeSelectedWorkType,
          transformMasterFilters,
          getFieldsCountContract,
          getFieldsCountPartners,
          getFieldsCountClients,
          getFieldsDatesClients,
          getFieldsCountBim,
          getFieldsCountClassification,
        }}
    >
      {props.children}
    </FilterContext.Provider>
  );
};

export default FilterProvider;
