import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import React, { FC, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TitleValue from '../titleValue/titleValue';
import styles from './autocompleteFields.module.scss';
import { ReactComponent as SearchIcon } from './../../../../assets/search.svg';
import { t } from 'i18next';
import searchIcon from '../../../../assets/search.svg';
import arrowDown from '../../../../assets/arrowDown.svg';
import { isUnionTypeNode } from 'typescript';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AutocompleteFieldsProps {
  id: string;
  editable?: boolean;
  codeValue?: string;
  nameValue?: string;
  options: any[];
  inputChangeHandler: any;
  handleChange: any;
  errors?: any;
  isUnits?: boolean;
  isTable?: boolean;
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const AutocompleteFields: FC<AutocompleteFieldsProps> = (props) => {
  const { id, editable, codeValue, nameValue, options, inputChangeHandler, handleChange, errors, isUnits, isTable } = props;
  const [codeInputValue, setCodeInputValue] = useState(codeValue || ''); // if undefined, uses an empty string in order to be automatically set when nameInput changes. Otherwise, it will not change.
  const [nameInputValue, setNameInputValue] = useState(nameValue || ''); // if undefined, uses an empty string in order to be automatically set when codeInput changes. Otherwise, it will not change.
  const [freeSoloCode, setFreeSoloCode] = useState(true); // if true, shows no message when ther are no options to select
  const [freeSoloName, setFreeSoloName] = useState(true); // if true, shows no message when ther are no options to select

  const renderAutocomplete = (
    id: string,
    title: string,
    value: string,
    options: any[],
    placeholder: string,
    onInputChange: (e: SyntheticEvent<Element, Event>, newValue: string) => void,
    onChange: (e: SyntheticEvent<Element, Event>, newValue: string) => void,
    freeSolo: boolean,
    editable: boolean,
    isDropdown?: boolean,
    error?: boolean,
    groupBy?: any,
  ) => {
    const [dropDown, setDropDown] = useState(true);
    const [isFocused, setIsFocused] = useState(false);

    {/* Stylised title value for the autocomplete input*/ }
    return <TitleValue
      identifier={`${id}InputAutocomplete`}
      title={title}
      value={value}
      editable={editable}
      onlyDisable={!editable}
      contrast={!isFocused}
      error={error}
      type='autocomplete'
      isTable={isTable}
    >
      {/*Creates an autocomplete input for the worktype-code which, when changes its value, also changes the worktype-name*/}
      {/*OR creates an autocomplete input for the worktype-name which, when changes its value, also changes the worktype-code*/}
      <Autocomplete
        id={`${id}Input`}
        options={options}
        freeSolo={freeSolo !== undefined ? freeSolo : true}
        noOptionsText={t('input.noResults')}
        onOpen={() => setDropDown(false)}
        onClose={() => setDropDown(true)}
        groupBy={groupBy}
        renderInput={(params) =>
          <TextField
            variant='standard'
            onFocus={() => setIsFocused(true)} // Cuando el input obtiene el foco
            onBlur={() => setIsFocused(false)} // Cuando el input pierde el foco
            {...params}
            InputProps={{
              ...params.InputProps, disableUnderline: true, endAdornment: (
                <InputAdornment position="end">
                  {isDropdown
                    ? <img className={dropDown ? styles.arrowDownIcon : styles.arrowUpIcon} src={arrowDown} />
                    : <img className={styles.searchIcon} src={searchIcon} />
                  }
                </InputAdornment>
              )
            }}
            placeholder={placeholder}

          />}
        disableClearable
        forcePopupIcon={false}
        onInputChange={onInputChange}
        value={value}
        onChange={onChange}
      />
    </TitleValue>;
  };

  return (
    <div className={styles.autocompleteContainer}>
      <div className={styles.titleValue1}>
        {renderAutocomplete(
          `${id}-code`,
          t('details.technical.code'),
          codeValue || codeInputValue,
          options ? options.map((op) => op.code).sort() : [],
          codeValue!,
          (e, newValue) => {
            inputChangeHandler(newValue, 'code', 0); // queries elasticsearch when input length >= 3
            setFreeSoloCode(newValue.length < 2); // if input length < 2, shows no message when ther are no options to select
          },
          (e, newValue) => {
            if (newValue?.length && newValue?.length > 0) {
              // Parameters received by handleChange: 1. id of the item to be modified, 2. code of the item, 3. name of the item
              // It gets the code from options array in function of the new value of the name.
              handleChange(
                options?.filter((op) => op.code === newValue)[0]?.id,
                newValue,
                options?.filter((op) => op.code === newValue)[0]?.name
              );
            }
          },
          freeSoloCode,
          editable && !isUnits ? true : false,
          true,
          errors && errors?.filter((u: any) => u.fieldField === 'codUnidad' || u.fieldField === 'codSubtipo' ).length > 0)
        }
      </div>
      <div className={styles.titleValue2}>
        {renderAutocomplete(
          `${id}-name`,
          isUnits ? t('details.technical.nameUnit') : t('details.technical.name'),
          nameValue || nameInputValue,
          options ? options.map((op) => op.name).sort() : [],
          nameValue!,
          (e, newValue) => {
            inputChangeHandler(newValue, 'name', 0); // queries elasticsearch when input length >= 3
            setFreeSoloName(newValue.length < 4); // if input length < 4, shows no message when ther are no options to select
          },
          (e, newValue) => {
            if (newValue?.length && newValue?.length > 0) {
              // Parameters received by handleChange: 1. id of the item to be modified, 2. code of the item, 3. name of the item
              // It gets the code from options array in function of the new value of the name.
              handleChange(
                options?.filter((op) => op.name === newValue)[0]?.id,
                options?.filter((op) => op.name === newValue)[0]?.code,
                newValue
              );
            }
          },
          freeSoloName,
          editable ? true : false,
          true,
          errors && errors?.filter((u: any) => u.fieldField === 'nomUnidad' || u.fieldField === 'nomSubtipo' ).length > 0,
          (option: any) => option[0])
        }
      </div>
    </div>
  );
};

export default AutocompleteFields;
