import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CollapsableFilter, { CollapsableFilterChildrenProps } from '../components/collapsableFilter/collapsableFilter';
import styles from './classificationFilters.module.scss';
import DoubleSelectorNumberRange from '../../../components/Filters/Components/numberRangeFilters/doubleSelectorNumberRange/doubleSelectorNumberRange';
import FILTER_TYPES from '../../../components/Filters/filterTypes';

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const ClassificationFilters: FC<CollapsableFilterChildrenProps> = (props) => {
  const { open, handleClickOpen } = props;
  const [t] = useTranslation();

  return (
    <CollapsableFilter
      id='classification'
      value={t('advancedFilters.classification')}
      help={t('advancedFilters.classification')}
      handleClickOpen={handleClickOpen}
      open={open}
      advancedFilter={'classification'}
    >
      <div className={styles.classificationFiltersContainer}>
        <div className={styles.collapsableComponent}>
          <DoubleSelectorNumberRange filterType={FILTER_TYPES.classificationEstimatedAmount} unitType={FILTER_TYPES.estimatedAmount} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <DoubleSelectorNumberRange filterType={FILTER_TYPES.classificationFinalAmount} unitType={FILTER_TYPES.finalAmount} noBottomDivider />
        </div>
      </div>
    </CollapsableFilter>
  );
};

export default ClassificationFilters;
