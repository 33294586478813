import { TFunction } from 'i18next';
import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { ViewContext } from '../../context/ViewProvider';
import './MapListSwitch.scss';

interface MapSwitchProps {
  t: TFunction<'translations'>;
}
function MapListSwitch(props: MapSwitchProps) {
  const { t } = props;
  const viewContext = useContext(ViewContext);
  return (
    <div className='container-mapSwitchList'>
      <div id='maplistswitch' className='switch-buttons'>
        <button className='switch-button-map' onClick={viewContext.handleChangeSwitch} disabled={viewContext.mapIsSelected}> {t('switch.map')}</button>
        <button className='switch-button-list' onClick={viewContext.handleChangeSwitch} disabled={!viewContext.mapIsSelected}>{t('switch.list')}</button>
      </div>
    </div>
  );
}

export default withTranslation()(MapListSwitch);