import React, { createContext, useContext, useState } from 'react';
import { AuthContext } from '../services/authContextProvider';
import { useLocation } from 'react-router-dom';

export type UserMode = 'viewer' | 'editor' | 'editorDisabled';

interface UserModeContextValues {
  userMode?: UserMode;
  handleChangeSwitch: (value: string) => void,
}

const defaultUserModeContextValues: UserModeContextValues = {
  userMode: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  handleChangeSwitch: () => { },
};

export const UserModeContext = createContext<UserModeContextValues>(
  defaultUserModeContextValues
);


interface UserModeProviderProps {
  /**
   * The elements wrapped by the auth context.
   */
  children: JSX.Element;
}
const UserModeProvider = (props: UserModeProviderProps) => {
  const authContext = useContext(AuthContext);
  const location = useLocation();
  const reloadUserMode = location?.state?.userMode;
  const mode = reloadUserMode || authContext.defaultMode();
  const [userMode, setUserMode] = useState<UserMode>(mode);

  const handleChangeSwitch = (value: string) => {
    setUserMode(value as UserMode);
  };

  // Setup the context provider
  return (
    <UserModeContext.Provider
      value={
        {
          userMode,
          handleChangeSwitch,
        }}
    >
      {props.children}
    </UserModeContext.Provider>
  );
};

export default UserModeProvider;
