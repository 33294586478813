import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CollapsableFilter, { CollapsableFilterChildrenProps } from '../components/collapsableFilter/collapsableFilter';
import styles from './contractFilters.module.scss';
import FILTER_TYPES, { FILTER_CONFIG } from '../../../components/Filters/filterTypes';
import { CheckSelector } from '../../../components/Filters/Components/checkSelector/checkSelector';
import NumberRange from '../../../components/Filters/Components/numberRangeFilters/numberRange/numberRange';
import { AuthContext } from '../../../services/authContextProvider';

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const ContractFilters: FC<CollapsableFilterChildrenProps> = (props) => {
  const { open, handleClickOpen } = props;
  const [t] = useTranslation();
  const authContext = useContext(AuthContext);

  const isAuthorized = !authContext.isViewer();

  return (
    <CollapsableFilter
      id='contract'
      value={t('advancedFilters.contract')}
      help={t('advancedFilters.contract')}
      handleClickOpen={handleClickOpen}
      open={open}
      advancedFilter={'contract'}
    >
      <div className={styles.contractFiltersContainer}>
        {isAuthorized &&
          <div className={styles.collapsableComponent}>
            <CheckSelector filterType={FILTER_TYPES.typeContract} noBottomDivider />
          </div>
        }
        <div className={styles.collapsableComponent}>
          <CheckSelector filterType={FILTER_TYPES.modelContract} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <CheckSelector filterType={FILTER_TYPES.scopeContract} noBottomDivider />
        </div>
        {isAuthorized &&
          <>
            <div className={styles.collapsableComponent}>
              <CheckSelector filterType={FILTER_TYPES.formPayment} noBottomDivider />
            </div>
            <div className={styles.collapsableComponent}>
              <CheckSelector filterType={FILTER_TYPES.sourceFinancing} noBottomDivider />
            </div>
            <div className={styles.collapsableComponent}>
              <NumberRange filterType={FILTER_TYPES.warrantyPeriod} defaultUnit={FILTER_CONFIG.period} noBottomDivider />
            </div>
          </>
        }
      </div>
    </CollapsableFilter>
  );
};

export default ContractFilters;
