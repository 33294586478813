import React, { createContext, useState } from 'react';
import { ObraProperties } from '../types/ObraDetails';

interface DetailsViewContextValues {
  obra?: ObraProperties,
  mapIsSelected: boolean,
  handleVisualization: (visible: boolean) => void,
  setObra: (obra: ObraProperties | undefined) => void
}

const defaultViewContextValues: DetailsViewContextValues = {
  mapIsSelected: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleVisualization: () => { },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setObra: () => {},
};

export const DetailsViewContext = createContext<DetailsViewContextValues>(
  defaultViewContextValues
);


interface DetailsViewContextProviderProps {
  /**
   * The elements wrapped by the auth context.
   */
  children: JSX.Element;
}
const DetailsViewProvider = (props: DetailsViewContextProviderProps) => {
  const [mapIsSelected, setMapIsSelected] = useState<boolean>(true);
  const [obra, setObra] = useState<ObraProperties>();

  const handleVisualization = (visible: boolean) => {
    setMapIsSelected(visible);
  };
  // Setup the context provider
  return (
    <DetailsViewContext.Provider
      value={
        {
          obra,
          setObra,
          mapIsSelected,
          handleVisualization,
        }}
    >
      {props.children}
    </DetailsViewContext.Provider>
  );
};

export default DetailsViewProvider;
