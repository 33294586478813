import React, {useContext, type FC } from 'react';
import { Button, Collapse, Dialog, Stack } from '@mui/material';
import styles from './collapsableTable.module.scss';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Permiso, Usuario } from '../../../../../types/Admin';
import { renderRole } from '../usersTableRow';
import LoadingSpinner from '../../../../../components/loadingSpinner/loadingSpinner';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { AdminContext, EmailandName } from '../../../../../context/AdminProvider';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CollapsableTableProps {
  open: boolean;
  permissions: Permiso[];
  loading: boolean;
  user: Usuario;
  deleteRole: (id: number | undefined, email: string, grupo: string | undefined, index: number) => void;
}

interface DialogContent {
  permission: Permiso;
  index: number;
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const CollapsableTable: FC<CollapsableTableProps> = (props) => {
  const { user, open, permissions, loading, deleteRole} = props;
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [dialogContent, setDialogContent] = React.useState<DialogContent>();
 
  const adminContext = useContext(AdminContext);
  const renderRole = (role?: string) => {
    switch(role) {
      case 'viewer':
      case 'viewer_vip':
        return t('administration.table.viewer');
      case 'editor':
        return t('administration.table.editor');
      case 'validator':
        return t('administration.table.validator');
      case 'administrator':
        return t('administration.table.admin');
      case 'download':
        return t('administration.table.download');
    }
  };

  const getDialogContent = () => {
    return <>
      <div className={styles.titleDelete}>{t('administration.titleDelete')}</div>
      <div className={styles.details}>
        <div className={styles.subtitle}>{t('administration.user') + ':'}<p>{user.nombre}</p> </div>
        <div className={styles.subtitle}>{t('administration.role') + ':'}<p>{renderRole(dialogContent?.permission.rol)}</p></div>
        <div className={styles.subtitle}>{t('administration.range') + ':'}<p>{dialogContent?.permission.nomProyecto}</p></div>
      </div>
      <div className={styles.buttonSection}>
        <Stack spacing={2} direction="row" justifyContent="center">
          <Button id='buttoncancel' className={styles.buttonCancel}
            onClick={() => {
              setOpenDialog(false);
            }}
            disableRipple={true} variant="outlined">{t('cancel')}</Button>
          <Button id='buttonapprove' className={styles.buttonDelete} onClick={() => {
            if (dialogContent) {
              deleteRole(dialogContent.permission.id, user.email, dialogContent.permission.rol, dialogContent.index);
              setOpenDialog(false);
            }
          }} disableRipple={true} variant="contained">{t('delete')}</Button>
        </Stack>
      </div>
    </>;
  };

  const handleEditUser = (permission: Permiso) => {
    const userName = [...adminContext.allUsers].find((u) => u.email === user.email)?.nombre;
    const userToEdit: EmailandName = {email: user?.email, name:userName};
    if (userName) {
      adminContext.handleChangeUserToEdit(userToEdit);
      adminContext.handleChangeProjectToAdd({
        codEmpresa: '0008',
        codZona: permission.nomProyecto.split(', ')[0].split(' ')[0],
        codSupercentro: permission.nomProyecto.split(', ')[1]?.split(' ')[0],
        codCentro: permission.nomProyecto.split(', ')[2]?.split(' ')[0],
      });
      adminContext.handleChangePermissionToEdit(permission);
      adminContext.handleChangeHasProject(true);
      adminContext.handleSetIsEdition(true);
    }
  };

  return (
    <Collapse in={open} timeout='auto' unmountOnExit>
      {
        (loading) ?
          <LoadingSpinner /> : false
      }
      {!loading && <ul className={styles.collapsableList}>
        { permissions.map((p, i) => {
          return (
            <li key={i}>
              <table className={styles.collapsableListRow}>
                <tr>
                  <td>{ p.nomProyecto }</td>
                  <td>
                    <div className={styles.rolesWrapper}>
                      <div
                        key={`role-${i}`}
                        className={`${styles[p.rol]}`}
                      >
                        {renderRole(p.rol)}
                      </div>
                    </div>
                  </td>
                  <td align='right'>
                    <div className={styles.managementIcons}>
                      <CreateOutlinedIcon className={styles.icon} onClick={() => handleEditUser(p)}/>
                      <DeleteOutlineOutlinedIcon
                        className={styles.icon}
                        onClick={() => {
                          setDialogContent({permission: p, index: i});
                          setOpenDialog(true);
                        }
                        }/>
                    </div>
                  </td>
                </tr>
              </table>
            </li>
          );
        })}
      </ul>}
      <Dialog
        classes={{
          paper: styles.dialog
        }}
        BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.15)' } }}
        PaperProps={{ sx: { borderRadius: '20px', minWidth: '90vW' } }}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <div className={styles.dialogContent}>
          {getDialogContent()}
        </div>
      </Dialog>
    </Collapse>
  );
};

export default CollapsableTable;
