/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { withTranslation } from 'react-i18next';

import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Tooltip from '@mui/material/Tooltip';
import { TFunction } from 'i18next';
import { Box, ListItem, Typography } from '@mui/material';
import styles from './autocompleteList.module.scss';
import { Height } from '@mui/icons-material';


const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

interface AutocompleteListProps {
  props: any,
  option: any,
  selected?: boolean,
  clickOption: () => any,
  t: TFunction<'translations'>;
}
const AutocompleteList = ({ props, option, selected, clickOption, t }: AutocompleteListProps) => {
  const noResult = `${t('input.noResults')}`;
  return (
    <ListItem
      {...props}
      id={option.code}
      key={uuidv4()}
      className={styles.item}
      style={{ cursor: option.name !== noResult ? 'pointer' : 'not-allowed' }}
      sx={{
        padding: '0',
        paddingRight: '1rem',
        backgroundColor: 'rgba(255, 0, 0, 0)',
        '& .MuiCheckbox-root': {
          color: '#7A7A7A;',
          borderRadius: '5px'
        },
        '&.Mui-focused': {
          backgroundColor: 'rgba(255, 0, 0, 0.1)',
          '& .MuiCheckbox-root': {
            color: 'red',
          }
        }
      }}
      onClick={clickOption}
    >
      {
        <Box style={{ width: '0.75rem', height: '40px' }}></Box>
      }
      <Tooltip
        key={uuidv4()}
        enterDelay={2000}
        title={
          <Typography
            style={{ padding: '0.625rem' }}
          >
            {option.name}
          </Typography>
        }
        followCursor>
        <div style={{ overflowX: 'hidden' }}>
          <Typography
            style={{ paddingTop: '0.1rem', pointerEvents: 'none' }}
            noWrap

          >
            {option.name}
          </Typography>
        </div>
      </Tooltip>
    </ListItem>);
};

export default withTranslation()(AutocompleteList);
