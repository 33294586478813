/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useContext, useEffect, useState } from 'react';
import { AlcanceEntidad, ObraProperties } from '../../../../../types/ObraDetails';
import { useTranslation } from 'react-i18next';
import { SelectOption } from '../../../../../types/Option';
import { SaveContext } from '../../../../../context/SaveProvider';
import TitleHelp from '../../../components/titleHelp/titleHelp';
import technicalNoData from '../../../../../assets/technicalNoData.svg';
import NoDataPicture from '../../../components/noDataPicture/noDataPicture';
import Colaborador from '../../components/colaborator/colaborator';

interface EditClientsProps {
  obra: ObraProperties;
  clientStatus: SelectOption[];
  errors?: any[]
}

const EditDirectors: FC<EditClientsProps> = (props) => {
  const { obra, clientStatus, errors } = props;
  const [t] = useTranslation();
  const saveContext = useContext(SaveContext);
  const [directors, setDirectors] = useState(obra.cliente?.direccionObra || []);

  useEffect(() => {
    const newDirectors: any = [];
    obra.cliente.direccionObra.map((d) => newDirectors.push(d));
    setDirectors(newDirectors);
  }, [obra.cliente.direccionObra.length]);

  const handleAddDirector = () => {
    const newDirector = { id: undefined, origen: 'phia', entidad: '', alcance: '', estado: { id: 0, nombre: '' } };
    setDirectors([...directors, newDirector]);
    if (obra.cliente?.direccionObra) {
      obra.cliente.direccionObra.push(newDirector);
    } else {
      obra.cliente.direccionObra = [newDirector];
    }

    saveContext.handleChangeSave(true);
  };

  const handleDelete = (i: number) => {
    obra.cliente.direccionObra.splice(i, 1);
    setDirectors([...obra.cliente.direccionObra]);
    saveContext.handleChangeSave(true);
  };

  const handleChange = (directors: AlcanceEntidad[]) => {
    obra.cliente.direccionObra = directors;
  };

  return (
    <div
      data-testid="director-edit-test"
    >
      <TitleHelp
        id='direccionObra'
        value={t('details.clients.director')}
        help={t('help.clients.director')}
        addItemText={t('details.clients.addNewDirector') as string}
        handleAddItem={handleAddDirector}
      />
      {
        obra.cliente.direccionObra.length > 0 ?
          <Colaborador editable={true} clientStatus={clientStatus} handleDelete={handleDelete} colaborador={directors} handleChangeData={handleChange} errors={errors}/>
          :
          <NoDataPicture
            id='director-nodata'
            picture={technicalNoData}
            text={t('details.clients.addDirector')}
          />
      }

    </div >);
};

export default EditDirectors;
