import React, { type FC } from 'react';
import Header from '../../components/header/header';
import Map from '../../components/Map/Map';
import './home.scss';
import MapListSwitch from '../../components/mapListSwitch/MapListSwitch';
import ClusterLayer from '../../components/Map/MapLayers/ClusterLayer/ClusterLayer';
import Grid from './grid/grid';
import Filters from '../../components/Filters/Filters';
import ExportExcel from '../../components/exportXlsx/exportXlsx';
import AdvancedFilters from '../../components/advancedFilters/advancedFilters';


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface HomeProps {
  hidden: boolean;
}

const Home: FC<HomeProps> = (props: HomeProps) => {
  const { hidden } = props;
  const [enableExport, setEnableExport] = React.useState(false);
  const [noProjects, setNoProjects] = React.useState(false);
  return (

    <div data-testid="home-test" className='home' style={
      hidden ? { display: 'none' } : { display: 'flex  ' }
    }>
      <Header>
        <div id='home-header' className='header'>
          <Filters />
          <div>
            <AdvancedFilters />
            <ExportExcel enableExport={enableExport} />
          </div>
          <MapListSwitch />
        </div>
      </Header>

      <div id='home-content' className='content'>
        <Map noProjects={noProjects} showTags>
          {(mapInstance: google.maps.Map) => (
            <>
              <ClusterLayer enableExport={(value: boolean) =>setEnableExport(value)} mapInstance={mapInstance} setNoProjects={(value: boolean) =>setNoProjects(value)}/>
            </>
          )}

        </Map>
        <Grid enableExport={(value: boolean) =>setEnableExport(value)}/>
      </div>
    </div>

  );
};

export default Home;
