/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react';
import { FilterCollapsableWrapper } from '../../../FiltersWrappers/FilterCollapsableWrapper/FilterCollapsableWrapper';
import { FormGroup } from '@mui/material';
import SelectRangeMode from '../components/selectRangeMode/selectRangeMode';
import SelectField from '../components/selectField/selectField';
import { t } from 'i18next';
import ObrasAPI from '../../../../../services/APIObras';
import FILTER_TYPES from '../../../filterTypes';
import { Option } from '../../../../../types/Option';
import RangeModeFieldsWithButton from '../components/rangeModeFieldsWithButton/rangeModeFieldsWithButton';
import { FilterContext } from '../../../../../context/FilterProvider';
import CustomAutocomplete from '../../../../../pages/advancedSearch/components/Autocomplete/CustomAutocomplete/CustomAutocomplete';

interface SingleSelectorNumberRangeProps {
  filterType: string;
  unitType: string;
  noBottomDivider?: boolean;
}
export const SingleSelectorNumberRange = (props: SingleSelectorNumberRangeProps) => {
  const { filterType, unitType, noBottomDivider } = props;
  const filterContext = useContext(FilterContext);
  const [selectedUnit, setSelectedUnit] = useState<any>(filterContext.selectedWorkUnit || null);

  useEffect(() => {
    setSelectedUnit(filterContext.selectedWorkUnit || null);
  }, [filterContext.selectedWorkUnit]);

  return (
    <>
      <FilterCollapsableWrapper
        filterType={filterType}
        noBottomDivider={noBottomDivider}
      >
        <FormGroup>
          <CustomAutocomplete
            filterType={filterType}
            handleSelect={(newValue) => {
              setSelectedUnit(newValue);
            }}
          />
          <SelectRangeMode filterType={filterType} />
          <RangeModeFieldsWithButton filterType={filterType} unitType={unitType} selectedUnit={selectedUnit} />
        </FormGroup>
      </FilterCollapsableWrapper>
    </>
  );
};

export default SingleSelectorNumberRange;