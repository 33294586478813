import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import React, { FC, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TitleValue from '../titleValue/titleValue';
import styles from './searchAutocomplete.module.scss';
import searchIcon from '../../../../assets/search.svg';
import arrowDown from '../../../../assets/arrowDown.svg';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SearchAutocompleteProps {
  id: string;
  title?: string;
  editable?: boolean;
  searchValue?: string;
  options: any[];
  inputChangeHandler: any;
  contrast: boolean;
  handleChange: any;
  isDropdown?: boolean;
  groupBy?: any;
  onFocus?: () => any;
  onBlur?: () => any;
  error?: boolean;
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const SearchAutocomplete: FC<SearchAutocompleteProps> = (props) => {
  const { id, editable, searchValue, options, inputChangeHandler, handleChange, title, contrast = false, isDropdown, groupBy, onFocus, onBlur, error } = props;
  const [codeInputValue, setSearchInputValue] = useState(searchValue || ''); // if undefined, uses an empty string in order to be automatically set when nameInput changes. Otherwise, it will not change.
  const [dropDown, setDropDown] = useState(true);
  const [isFocused, setIsFocused] = useState(false);
  const [t] = useTranslation();

  const [freeSolo, setFreeSolo] = useState(true);


  const onInputChange = (e: any, newValue: string) => {
    inputChangeHandler(newValue); // queries elasticsearch when input length >= 1
    setSearchInputValue(newValue);

    setFreeSolo(newValue.length < 2);

  };

  const onChange = (e: any, newValue: string) => {
    if (newValue?.length && newValue?.length > 0) {
      // Parameters received by handleChange: 1. id of the item to be modified, 2. code of the item, 3. name of the item
      // It gets the name from options array in function of the new value of the code.
      handleChange(newValue);
    }
  };

  return (
    <div className={styles.autocompleteContainer}>
      <div className={styles.titleValueContainer}>
        <div>
          <TitleValue
            identifier={'searchAutocomplete'}
            title={title || ''}
            value={searchValue ? searchValue : null}
            editable={editable}
            contrast={contrast && !isFocused}
            error={error}
            type='autocomplete'
          >
            <Autocomplete
              id={`${id}Input`}
              options={options}
              freeSolo={freeSolo !== undefined ? freeSolo : true}
              noOptionsText={t('input.noResults')}
              groupBy={groupBy}
              onOpen={() => setDropDown(false)}
              onClose={() => setDropDown(true)}
              onFocus={onFocus}
              onBlur={onBlur}
              renderInput={(params) =>
                <TextField
                  variant='standard'
                  onFocus={() => setIsFocused(true)} // Cuando el input obtiene el foco
                  onBlur={() => setIsFocused(false)} // Cuando el input pierde el foco
                  {...params}
                  InputProps={
                    {
                      ...params.InputProps,
                      endAdornment: (
                        <InputAdornment position="end">
                          {isDropdown
                            ? <img className={dropDown ? styles.arrowDownIcon : styles.arrowUpIcon} src={arrowDown} />
                            : <img className={styles.searchIcon} src={searchIcon} />
                          }
                        </InputAdornment>
                      ),
                      disableUnderline: true
                    }}
                  placeholder={searchValue!}
                />}
              onInputChange={onInputChange}
              value={searchValue!}
              onChange={onChange}
              disableClearable={isDropdown}
            />
          </TitleValue>
        </div>
      </div>
    </div>
  );
};

export default SearchAutocomplete;
