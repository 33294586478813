/* eslint-disable linebreak-style */
import { Box, Paper, Typography } from '@mui/material';
import { SelectOption } from '../../../../../../types/Option';
import './index.scss';

interface Props {
  id: string;
  label: string;
  values: SelectOption[];
}

const MultipleSelectView = ({ id, label, values }: Props) => {
  return (
    <Box
      id={id}
      component={Paper}
      elevation={0}
      sx={{
        p: 2,
        borderRadius: 2,
        bgcolor: 'background.default',
        minWidth: '200px',
      }}
    >
      <Typography variant="subtitle2" className='title'>
        {label}
      </Typography>
      {
        values.map(option => <div key={option.id} className='description'>
          {option.nombre}
        </div>)
      }
    </Box>
  );
};

export default MultipleSelectView;
