import React, { MouseEventHandler, useState, type FC, useContext, useEffect, useCallback, useRef } from 'react';
import Header from '../../components/header/header';
import styles from './pendingActions.module.scss';
import { useTranslation } from 'react-i18next';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, Divider, FormControlLabel, List, ListItem, ListItemText, SvgIcon, Switch } from '@mui/material';
import { ReactComponent as notificationReadIcon } from '../../assets/notification_read.svg';
import ObrasAPI from '../../services/APIObras';

import { AuthContext } from '../../services/authContextProvider';
import { ReactComponent as tab_error } from '../../assets/tab_error.svg';

import { PendingActionType, ActionObra, ACTION_FILTER, ACTION_FILTER_SORT } from '../../types/PendingActionType';
import { ObraGeneralInfo } from '../../types/ObraDetails';
import { useNavigate } from 'react-router-dom';
import Comment from './components/comment/Comment';
import Error from '../../components/Error/Error';
import { VALIDATOR } from '../../userRoles/userRoles';
import { UserModeContext } from '../../context/UserModeProvider';
import LoadingSpinner from '../../components/loadingSpinner/loadingSpinner';



// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PendingActionsProps {
  defaultFilter?: string,
}

const PendingActions: FC<PendingActionsProps> = (props: PendingActionsProps) => {
  const { defaultFilter } = props;
  const { t } = useTranslation();
  const api = ObrasAPI.getInstance();
  const authContext = useContext(AuthContext);
  const userModeContext = useContext(UserModeContext);
  const isAuthorized = authContext.hasEditorRole(true);
  const isValidator = authContext.hasRole(VALIDATOR);
  const navigate = useNavigate();

  const [pendingActions, setPendingActions] = useState<(ActionObra & ObraGeneralInfo)[]>([]);
  const validatorFilters = isValidator ?
    {
      [ACTION_FILTER.VALIDATING]: 0,
      [ACTION_FILTER.FINAL_VALIDATING]: 0,
    } :
    {};
  const [countFilter, setCountFilter] = useState<any>({
    [ACTION_FILTER.ALL]: 0,
    [ACTION_FILTER.UPDATING]: 0,
    [ACTION_FILTER.VALIDATED]: 0,
    [ACTION_FILTER.COMMENTED]: 0,
    ...validatorFilters,
  });

  const [filter, setFilter] = useState(defaultFilter || 'ALL');
  const [onlyPending, setOnlyPending] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);



  useEffect(() => {

    getNotificationsFromAPI();


  }, [filter, onlyPending]);


  const getNotificationsFromAPI = () => {
    setError(false);
    if (isAuthorized) {
      setLoading(true);
      // eslint-disable-next-line no-async-promise-executor
      api.getPendingActionsAPI(pageNumber, filter, onlyPending).then((actions: PendingActionType) => {
        const count = (({ result, ...o }) => o)(actions);
        setCountFilter(count);
        const prevActions = pageNumber === 1 ? [] : pendingActions;
        setPendingActions(prevActions.concat(actions.result.obras || []));
        setLoading(false);
        setHasMore(actions.result.hasMore);
      }).catch((e: any) => {
        console.log(e);
        setError(true);
      });
    }
  };

  const close = () => {
    navigate(-1);
  };

  const handleFilter = (item: string) => {
    setFilter(item);
  };

  const handleChangePending = () => {
    setOnlyPending(!onlyPending);
  };

  const handleGoButton = (not: ActionObra & ObraGeneralInfo) => {
    api.setNotificationRead(not.notificacion).then(() => {

      navigate(`../${not.codCentro}/detalles`, {
        state: {
          userMode: 'editor',
        }
      });
      navigate(0);
    });
  };

  const parseDate = (date: string) => {
    if (!date) return '';
    const d = new Date(date);
    const options: any = { year: 'numeric', month: 'short', day: 'numeric' };
    return `${d.toLocaleDateString('es-es', options)} - ${d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
  };

  const getActionComponent = (not: any, i: number) => {
    // validated projects always have button active
    const enabled = not.pending || not.accion === 'project_validated';
    return <>
      <div className={styles.notificationItem}>
        <div className={styles.notificationHeader}>
          <div className={styles.notificationPoint}>
            {not.notificacion && !not.notificacion?.leido && <SvgIcon component={tab_error} inheritViewBox />}
          </div>
          <div className={styles.notificationDataCTI}>
            <div className={styles.notificationData}>
              <div className={styles.notificationName}>
                {`${not.nomCentro} - ${not.codCentro}`}
              </div>
              <div className={styles.notificationMotive}>
                {t(`actions.${not.accion}`)}
              </div>
              {/*  <div>{not.estadoPhia}</div>
              <div>{Object.keys(not.rolInProject).filter((r) => !!not.rolInProject[r]).join(',')}</div> */}
              <div className={styles.notificationDate}>
                {parseDate(not.fecModificacion)}
              </div>
            </div>
            <div className={styles.notificationCTI}>
              <Button variant='contained' className={styles.actionButton} disabled={!enabled}
                onClick={() => handleGoButton(not)}>
                {t(`actions.button.${not.accion}${!enabled ? '_disabled' : ''}`)}
              </Button>
            </div>
          </div>
        </div>
        {not.comentario &&
          <div className={styles.notificationComment}>
            <Comment comentario={not.comentario} />
          </div>}
      </div >

    </>;
  };

  return (
    <div data-testid="pendingActions-test" className='home' >
      <Header>
        <div id='pendingActions-header' className={styles.header}>
          <span onClick={close} className={styles.goBack}><ArrowBackIosIcon /></span>
          <div>{t('actions.title')}</div>
        </div>
      </Header>

      {!error ?
        <div id='pendingActions-content' className={styles.pendingActionsContent}>
          <div className={styles.filtersContainer}>
            <div className={styles.filtersTitle}>{t('actions.filters.title')}</div>
            <List className={styles.filtersList} >
              {Object.keys(countFilter)
                .sort((a, b) => (ACTION_FILTER_SORT[a] - ACTION_FILTER_SORT[b]))
                .map((item: string, index) => {
                  const title = t(`actions.filters.${item.toLowerCase()}`);
                  return <ListItem button key={index + item} className={`${styles.filtersItem} ${filter === item && styles.filterSelected}`}
                    onClick={() => handleFilter(item)}>
                    <SvgIcon component={notificationReadIcon} inheritViewBox />
                    <ListItemText primary={`${title} (${countFilter[item]})`} />
                  </ListItem>;
                })}
            </List>
          </div>
          <div className={styles.resultsContainer}>
            <div className={styles.resultsHeader}>
              <FormControlLabel
                value='start'
                control={<Switch checked={onlyPending} onChange={handleChangePending}
                  sx={{
                    '& .MuiSwitch-switchBase': {

                      '&.Mui-checked': {
                        color: '#fff',
                        '& + .MuiSwitch-track': {
                          opacity: 1,
                          backgroundColor: 'rgba(255, 0, 0, 1)',
                        },
                      }
                    }
                  }} />}
                label={t('actions.pending')}
                labelPlacement='start'
              />
            </div>
            <div className={styles.resultsList} id='element'>
              {!loading && pendingActions?.length ?
                pendingActions.map((action: any, i: number) => {
                  return (<>
                    {getActionComponent(action, i)}
                    {i < pendingActions.length - 1 && <Divider sx={{
                      margin: '0 !important'
                    }} />}
                  </>);
                })
                :
                loading
                  ? (<LoadingSpinner />)
                  :
                  <div className={styles.noResults}>{!loading && t('actions.noResults')}</div>

              }
            </div>
          </div>
        </div>
        : <div className={styles.error}>
          <Error />
        </div>
      }
    </div >

  );
};

export default PendingActions;
