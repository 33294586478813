/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useMemo, useState, useRef } from 'react';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Fab, Fade, IconButton } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import styles from './Filters.module.scss';
import FilterListWrapper from './FiltersWrappers/FilterListWrapper/FilterListWrapper';
import { AutocompleteMultipleChoice } from './Components/AutocompleteMultichoice/AutocompleteMultichoice';
import ContainSelect from './Components/containSelect/containSelect';
import FILTER_TYPES, { FILTER_CONFIG } from './filterTypes';
import { FilterContext } from '../../context/FilterProvider';
import { ACCION } from '../../types/FiltersTypes';
import { CheckSelector } from './Components/checkSelector/checkSelector';
import { DatePickerRange } from './Components/DatePickerRange/DatePickerRange';
import  NumberRange  from './Components/numberRangeFilters/numberRange/numberRange';

interface FiltersProps {
  t: TFunction<'translations'>;
}

const Filters = (props: FiltersProps) => {
  const { t } = props;
  const filterContext = useContext(FilterContext);
  const [open, setOpen] = useState(false);
  const [scrollDisabled, setScrollDisabled] = useState(false);
  const isFilters = Object.values(filterContext.filters) && Object.values(filterContext.filters).flat().length > 0;
  const filterReference: any = useRef(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (open && filterReference.current && !filterReference.current.contains(event.target) && event.x > 350) {
        setOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [filterReference, open]);

  const getFiltersNumber = () => {
    return filterContext.getAppliedFiltersCount();
  };

  const appliedFiltersNumber = useMemo(() => getFiltersNumber(), [filterContext.appliedFilters]);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleCloseFilter = () => {
    filterContext.finish(ACCION.CLOSE);
    setOpen(false);
  };

  const handleApplyFilter = () => {
    filterContext.finish(ACCION.APPLY);
    setOpen(false);
  };

  const handleClearFilter = () => {
    filterContext.clearFiltersByType('basic');
  };

  const disableScroll = (disable: boolean) => {
    setScrollDisabled(disable);
  };

  const renderFilters = () => {
    return <FilterListWrapper>
      <AutocompleteMultipleChoice filterType={FILTER_TYPES.nameCode} disableScroll={disableScroll} />
      <AutocompleteMultipleChoice filterType={FILTER_TYPES.country} disableScroll={disableScroll} />
      <AutocompleteMultipleChoice filterType={FILTER_TYPES.supercenter} disableScroll={disableScroll} />
      <DatePickerRange filterType={FILTER_TYPES.adjudicationDate} />
      <ContainSelect filterType={FILTER_TYPES.workType} />
      <NumberRange filterType={FILTER_TYPES.adjudicationAmount} defaultUnit={FILTER_CONFIG.amountCurrency} />
      <CheckSelector filterType={FILTER_TYPES.projectStatus} />
    </FilterListWrapper>;
  };
  const cardFilters = (
    <React.Fragment>
      <CardHeader
        action={
          <IconButton id='filters-clearbutton' aria-label='clear-filters' className={styles.clearFilters}
            disableRipple={true}
            style={isFilters ? { opacity: '1' } : { opacity: '0.25', cursor: 'default' }}
            onClick={handleClearFilter}>
            {t('filters.clearFilters')}
          </IconButton>
        }
      />
      <CardContent className={styles.cardContent}
        sx={{
          pointerEvents: scrollDisabled ? 'none' : 'auto'
        }}>
        {renderFilters()}

        <CardActions className={styles.buttons}>
          <Button className={styles.close} disableRipple={true} onClick={handleCloseFilter}>{t('filters.close')}</Button>
          <Button className={styles.apply} disableRipple={true} onClick={handleApplyFilter}>
            {filterContext.filteredProjectsNumber === 0
              ? t('advancedFilters.noResults')
              : `${t('advancedFilters.show')} ${filterContext.filteredProjectsNumber || ''} ${t('advancedFilters.projects')}`}
          </Button>
        </CardActions>
      </CardContent>
    </React.Fragment>
  );
  return (

    <div ref={filterReference} data-testid='Filters' className={styles.filters}>
      <div id='filter-button' className={styles.button}
        onClick={handleOpen}>
        <Fab
          variant='extended'
          disableRipple={true}>
          <div className={styles.title}>{t('filters.title')}</div>
          {appliedFiltersNumber ?
            <div className={styles.count}>{appliedFiltersNumber}</div>
            : <FilterListIcon />}
        </Fab>
      </div>
      <Fade in={open}>
        <Box className={styles.cardFilters}>
          <Card variant='outlined'>{cardFilters}</Card>
        </Box>
      </Fade>
    </div>
  );
};

export default withTranslation()(Filters);
