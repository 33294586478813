import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TipoObra } from '../../../../../types/ObraDetails';
import TitleValue from '../../../components/titleValue/titleValue';
import styles from './viewWorkType.module.scss';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ViewWorkTypeProps {
  key: number;
  workType: TipoObra;
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const ViewWorkType: FC<ViewWorkTypeProps> = (props) => {
  const { key, workType } = props;
  const [t] = useTranslation();

  const ORIGIN_CECOC = workType.origen === 'cecoc';

  const getFormattedMedicion = (value?: any) => {
    if(value){  
      let medicion;   
      const parts = value.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      if(parts.length == 2){      
        medicion = parts[0] + ',' + parts[1];
      }
      else{
        medicion = parts[0];
      }
      return medicion;
    }
    return null;
  };

  return (
    <tr key={key} className={styles.worktypesTableRow}>
      <td>
        <div key={key} className={styles.workTypes}>
          <div className={styles.titleValue1}>
            <TitleValue
              identifier={`subtype${key}-code`}
              title={t('details.technical.code')}
              value={ORIGIN_CECOC && !workType.subtipo ? workType.tipo?.codTipo : workType.subtipo?.codSubtipo}
              onlyDisable
            />
          </div>
          <div className={styles.titleValue2}>
            <TitleValue
              identifier={`subtype${key}-name`}
              title={t('details.technical.name')}
              value={ORIGIN_CECOC && !workType.subtipo ?  workType.tipo?.nomTipo : workType.subtipo?.nomSubtipo}
              onlyDisable
            />
          </div>
        </div>
      </td>
      <td>
        <div>
          { // loops all magnitudes of a worksubtype
            workType.magnitudes?.map((m, j) => {
              return (
                <div key={j} className={styles.magnitudes}>
                  <div className={styles.titleValue3}>
                    <TitleValue
                      identifier={`magnitude${key}${j}-name`}
                      title={t('details.technical.magnitude.name')}
                      value={m.nomMagnitud}
                      onlyDisable
                    />
                  </div>
                  <div className={styles.titleValue4}>
                    <TitleValue
                      identifier={`magnitude${key}${j}-measuring`}
                      title={`${t('details.technical.magnitude.measuring')} ${j+1}`}
                      value={getFormattedMedicion(m.medicion?.toString()) || '-'}
                      onlyDisable
                    />
                  </div>
                </div>
              );
            }) }
        </div>
      </td>
    </tr>
  );
};

export default ViewWorkType;
