import React from 'react';
import { useTranslation } from 'react-i18next';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as error } from '../../assets/error.svg';
import './Error.scss';



function Error() {
  const { t } = useTranslation();
  return <div data-testid='error-test' className='server-error'>
    <div className='server-error-content'>
      <SvgIcon component={error} inheritViewBox />
      <div id='error-title' className='title'>{t('error.main')}</div>
      <div id='error-subtitle' className='subtitle'>{t('error.second')}</div>
    </div>
  </div>;
}

export default Error;

