import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutocompleteMultipleChoice } from '../../../components/Filters/Components/AutocompleteMultichoice/AutocompleteMultichoice';
import styles from './generalDataFilters.module.scss';
import FILTER_TYPES, { FILTER_CONFIG } from '../../../components/Filters/filterTypes';
import CollapsableFilter, { CollapsableFilterChildrenProps } from '../components/collapsableFilter/collapsableFilter';
import { NumberRange } from '../../../components/Filters/Components/numberRangeFilters/numberRange/numberRange';
import { CheckSelector } from '../../../components/Filters/Components/checkSelector/checkSelector';
import KeywordInput from '../../../components/Filters/Components/keywordInput/keywordInput';

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const GeneralDataFilters: FC<CollapsableFilterChildrenProps> = (props) => {
  const { open, handleClickOpen } = props;
  const [t] = useTranslation();
  const [, setScrollDisabled] = useState(false);

  const disableScroll = (disable: boolean) => {
    setScrollDisabled(disable);
  };

  return (
    <CollapsableFilter
      id='generalDataFilters'
      value={t('advancedFilters.generalData')}
      help={t('advancedFilters.generalData')}
      handleClickOpen={handleClickOpen}
      open={open}
      advancedFilter={'general'}
    >
      <div className={styles.generalDataFiltersContainer}>
        <div className={styles.collapsableComponent}>
          <KeywordInput filterType={FILTER_TYPES.projectName} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <KeywordInput filterType={FILTER_TYPES.manager} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <KeywordInput
            filterType={FILTER_TYPES.tenderCode}
            noBottomDivider
            isNumber
            isExactWord
            placeholder={t('filters.filtersPlaceHolder.code') as string}
          />
        </div>
        <div className={styles.collapsableComponent}>
          <NumberRange filterType={FILTER_TYPES.accionaParticipation} defaultUnit={FILTER_CONFIG.percentage} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <CheckSelector filterType={FILTER_TYPES.businessActivity} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <AutocompleteMultipleChoice filterType={FILTER_TYPES.zone} disableScroll={disableScroll} noBottomDivider />
        </div>
      </div>
    </CollapsableFilter>
  );
};

export default GeneralDataFilters;
