import { FC, KeyboardEvent, FocusEvent, useContext, useState } from 'react';
import { t } from 'i18next';
import { Grid } from '@mui/material';
import {  ValidationError } from 'yup';
import styles from './editMainTown.module.scss';
import { ObraProperties } from '../../../../../types/ObraDetails';
import TitleValue from '../../../components/titleValue/titleValue';
import { SaveContext } from '../../../../../context/SaveProvider';
import { DetailsViewContext } from '../../../../../context/DetailsViewProvider';
import validationSchema from '../../../../../validations/localizationEnvironment';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface EditMainTownProps {
  obra: ObraProperties;
  errors?: any;
}

// Interfaz para los errores de validación
interface ValidationErrors {
    lat?: string;
    lon?: string;
}

interface ErrorCustom {
  text: string;
  order: number;
  helpText: string
}

type ValidationErrorCustom = ValidationError & {
  errors: ErrorCustom[];
  inner: ValidationErrorCustom[];
  message: ErrorCustom;
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const EditMainTown: FC<EditMainTownProps> = (props) => {
  const { obra, errors } = props;
  const [coordenadas, setCoordenadas] = useState(obra.localizacion.coordenadas);
  const [errorsCoordenadas, setErrorsCoordenadas] = useState<ValidationErrors>({});
  const saveContext = useContext(SaveContext);
  const {setObra} = useContext(DetailsViewContext);

  const handleChangeCoordenates = (value: string, key: 'lat' | 'lon') => {
    const newValue = value && value !== '' ? (value === '-' ? value : Number(value)) : undefined;
    setCoordenadas((prev) => ({...prev, [key]: newValue}));
    obra.localizacion.coordenadas = {...obra.localizacion.coordenadas, [key]: newValue !== '-' ? newValue : undefined};
    saveContext.handleChangeSave(true);
  };

  const onBlurCoordenadas = (event: FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const newValue = value && value !== '' ? (value === '-' ? null : Number(value)) : null;
    try {
      validationSchema(t).validateSyncAt('coordenadas', { ...obra.localizacion, coordenadas: {...obra.localizacion.coordenadas, [name]: newValue }}, { abortEarly: false });
      setErrorsCoordenadas({lat: undefined, lon: undefined});
      if(!errorsCoordenadas.lat && !errorsCoordenadas.lon){
        setObra && setObra({...obra, localizacion: {...obra.localizacion, coordenadas}});
      }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (errors: any) {
      let typedErrors: ValidationErrors = {};
      // Manage errors
      errors.inner.forEach((error: ValidationErrorCustom) => {
        if (error.path) {
          const fieldPath = error.path.split('.');
          if (fieldPath.length === 2) {
            typedErrors = {...typedErrors, [fieldPath[1]]: error.message.helpText};
          }
        }
      });

      setErrorsCoordenadas(typedErrors);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    // Allow only numbers, the point, and the backspace key
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '-'];
    if (!allowedKeys.includes(event.key) && event.key !== 'Backspace') {
      event.preventDefault();
    }
  };

  const handleChangeMainZip = (s: string) => {
    obra.localizacion.municipioPrincipal.codigoPostal = s;
    saveContext.handleChangeSave(true);
  };

  return (
    <Grid container rowSpacing={0.5} columnSpacing={2} className={styles.locationInfo}>
      <Grid container item spacing={1} xs={6}>
        <Grid item xs={6}>
          <TitleValue
            identifier='lat'
            title={t('details.location.coordinatesLatitude')}
            editable={true}
            handleChange={(s: string) => handleChangeCoordenates(s, 'lat')}
            onBlur={onBlurCoordenadas}
            value={obra.localizacion?.coordenadas?.lat}
            error={Boolean(errorsCoordenadas.lat)}
            helperText={errorsCoordenadas.lat}
            onKeyDown={handleKeyDown}
          />
        </Grid>
        <Grid item xs={6}>
          <TitleValue
            identifier='lon'
            title={t('details.location.coordinatesLongitude')}
            editable={true}
            handleChange={(s: string) => handleChangeCoordenates(s, 'lon')}
            onBlur={onBlurCoordenadas}
            value={obra.localizacion?.coordenadas?.lon}
            error={Boolean(errorsCoordenadas.lon)}
            helperText={errorsCoordenadas.lon}
            onKeyDown={handleKeyDown}
          />
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <TitleValue
          identifier='country'
          title={t('details.location.country')}
          value={obra.general?.nomPais || t('details.notInformed')}
          editable={false}
        />
      </Grid>
      <Grid item xs={6}>
        <TitleValue
          identifier='town'
          title={t('details.location.town')}
          value={obra.localizacion?.municipioPrincipal?.nomMunicipio || t('details.notInformed')}
          editable={false}
        />
      </Grid>
      <Grid item xs={6}>
        <TitleValue
          identifier='zipCode'
          title={t('details.location.zipCode')}
          value={obra.localizacion?.municipioPrincipal?.codigoPostal}
          editable={true}
          handleChange={handleChangeMainZip}
          valueMaxLength={25}
          parseText='[^a-zA-Z0-9-]'
          error={errors && errors.length > 0 && errors[0].field === 'municipioPrincipal'}
        />
      </Grid>
    </Grid>
  );
};

export default EditMainTown;