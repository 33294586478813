/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import { MenuItem, Select } from '@mui/material';
import { Option } from '../../../../../../types/Option';
import { t } from 'i18next';
import FILTER_TYPES from '../../../../filterTypes';

const PLACEHOLDER_TEXT = {
  [FILTER_TYPES.workTypeTechnicalAspects]: 'filters.filtersPlaceHolder.everyMagnitude',
  [FILTER_TYPES.classificationEstimatedAmount]: 'filters.filtersPlaceHolder.subGroup',
  [FILTER_TYPES.classificationFinalAmount]: 'filters.filtersPlaceHolder.subGroup',
};

interface SelectFieldProps {
  options: Option[];
  selectedValue: Option;
  unitType?: string;
  disabled?: boolean;
  handleSelect: (newValue: Option) => any;
}

export const SelectField = (props: SelectFieldProps) => {
  const { options, selectedValue, unitType, disabled, handleSelect } = props;
  const placeholder = (unitType && PLACEHOLDER_TEXT[unitType]) ? t(PLACEHOLDER_TEXT[unitType])
    : t('filters.filtersPlaceHolder.selectUnit');

  return (
    <Select
      style={{
        margin: '10px 12px 0px 12px',
        textAlign: 'left',
      }}
      value={selectedValue || ''}
      disabled={disabled || options.length === 0}
      displayEmpty
      renderValue={(value: any) => value === '' ? t(placeholder) : (value.name.length > 50 ? `${value.name.slice(0, 50)}...` : value.name)}
      sx={{
        backgroundColor: '#FFFFFF',
        height: '45px',
        '&.MuiOutlinedInput-root': {
          marginBottom: '11px',
          '&.Mui-focused fieldset': {
            border: '1px solid #7A7A7A',
            color: 'rgba(122, 122, 122, 1)'
          },
        },
      }}
    >
      {
        options.map((op) => {
          const opt = {
            code: op.code,
            name: op.name
          };
          return (
            <MenuItem key={op.code} value={op.code}>
              <div
                id='worktypeselector-item'
                className={'menu-item'}
                onClick={() => {
                  handleSelect(opt);
                }}
              >
                {op.name.length > 50 ? `${op.name.slice(0, 50)}...` : op.name}
              </div>
            </MenuItem>
          );
        })
      }
    </Select >
  );
};

export default SelectField;
