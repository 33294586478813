
import api from './APIConfig';
import { ResponseObrasMap, ResponseError, ObraProperties } from '../types/ObraMap';
import { ObraProperties as ObraPropertiesDetails } from '../types/ObraDetails';
import { SelectOption, OptionBoolean } from '../types/Option';
import ENDPOINTS from '../config/APIRoutes';
import { MASTERS_PATHS, FILTERS_TYPES, MASTERS_RESPONSE_SCHEMA, ACCUMULATIVE_NUMBER_FILTERS } from '../config/APIConfig';
import { FilterValue } from '../types/FiltersTypes';
import { CenterParams } from '../types/Admin';
import FILTER_TYPES from '../components/Filters/filterTypes';
import { TFunction } from 'i18next';

class ObrasAPI {
  private static instance: ObrasAPI;

  private getLanguage(): string {
    return localStorage.getItem('language') && localStorage.getItem('language') !== '' ? localStorage.getItem('language') || 'es' : 'es';
  }

  public static getInstance(): ObrasAPI {
    if (!ObrasAPI.instance) {
      ObrasAPI.instance = new ObrasAPI();
    }
    return ObrasAPI.instance;
  }

  private deleteCombinedFilters(filtersToApply: any, filters: any, filter: string):void {
    const types = FILTER_TYPES as any;
    if (Object.keys(filters).includes(types[`exact${filter}`]) && filters[types[`exact${filter}`]].length > 0) {
      delete filtersToApply[types[`exact${filter}`]];
      filtersToApply[types[`from${filter}`]] = filters[types[`exact${filter}`]];
      filtersToApply[types[`to${filter}`]] = filters[types[`exact${filter}`]];
    }
  }

  private bimRequirementsFilters(filtersToApply: any, filters: any):void {
    const types = FILTER_TYPES as any;
    if(filters.requirements && filters.requirements.length > 0){
      const newFilter = filters.requirements.flatMap((obj: any) => obj.codigos.map((codigo: number) => ({
        code: codigo,
        name: obj.name
      })));
      filtersToApply[types.requirements] = newFilter;
    }
  }

  private generateFiltersPath(filters: any): string {
    const filtersToApply = {...filters};
    if (!filters) return `idioma=${this.getLanguage()}`;
    this.deleteCombinedFilters(filtersToApply, filters, FILTER_TYPES.adjudicationAmount);
    this.deleteCombinedFilters(filtersToApply, filters, FILTER_TYPES.accionaParticipation);
    this.deleteCombinedFilters(filtersToApply, filters, FILTER_TYPES.protectedArea);
    this.deleteCombinedFilters(filtersToApply, filters, FILTER_TYPES.protectedProjectArea);
    this.deleteCombinedFilters(filtersToApply, filters, FILTER_TYPES.accionaBudget);
    this.deleteCombinedFilters(filtersToApply, filters, FILTER_TYPES.updatedAccionaBudget);
    this.deleteCombinedFilters(filtersToApply, filters, FILTER_TYPES.updatedBudget);
    this.deleteCombinedFilters(filtersToApply, filters, FILTER_TYPES.finalAccionaBudget);
    this.deleteCombinedFilters(filtersToApply, filters, FILTER_TYPES.finalBudget);
    this.deleteCombinedFilters(filtersToApply, filters, FILTER_TYPES.warrantyPeriod);
    this.deleteCombinedFilters(filtersToApply, filters, FILTER_TYPES.percentPartnerParticipation);
    this.deleteCombinedFilters(filtersToApply, filters, FILTER_TYPES.numberPartner);
    this.deleteCombinedFilters(filtersToApply, filters, FILTER_TYPES.initialDeadline);
    this.deleteCombinedFilters(filtersToApply, filters, FILTER_TYPES.updatedDeadline);
    this.deleteCombinedFilters(filtersToApply, filters, FILTER_TYPES.complexityNote);
    this.deleteCombinedFilters(filtersToApply, filters, FILTER_TYPES.noteComplexityBimApplications);
    this.deleteCombinedFilters(filtersToApply, filters, FILTER_TYPES.complexityNoteUsageManagement);
    this.deleteCombinedFilters(filtersToApply, filters, FILTER_TYPES.complexityNoteStandardUses);
    this.deleteCombinedFilters(filtersToApply, filters, FILTER_TYPES.gisBudget);
    this.deleteCombinedFilters(filtersToApply, filters, FILTER_TYPES.carbonBudget);

    const generatePath = (serverField: string, filterType: string, code: string, workTypeCode?: string, unitCode?: string) => {
      return `${encodeURIComponent(serverField)}=${ACCUMULATIVE_NUMBER_FILTERS.includes(filterType)
        ? `${workTypeCode ? encodeURIComponent(workTypeCode) + '|' : ''}${unitCode ? encodeURIComponent(unitCode) : ''}`
        : ''}${encodeURIComponent(code)}`;
    };
    this.bimRequirementsFilters(filtersToApply, filters);
    const filtersPath = Object.keys(filtersToApply).map((filterType) => {
      const serverField = (FILTERS_TYPES as any)[filterType];
      const filterTypePath = filtersToApply[filterType].map((filter: FilterValue) => {
        return filterType === FILTER_TYPES.taxonomicActivity
          ? generatePath(serverField, filterType, filter.code)
          : generatePath(serverField, filterType, filter.code, filter.workTypeCode, filter.unitCode);
      });
      return filterTypePath.join('&');
    }).filter((f) => f.length);
    return filtersPath?.length ? `idioma=${this.getLanguage()}&${filtersPath.join('&')}` : `idioma=${this.getLanguage()}`;
  }

  async getObrasMapAPI(filters?: any): Promise<ResponseObrasMap | ResponseError> {
    const pathFilters = this.generateFiltersPath(filters);
    const url = `/${ENDPOINTS.v1.obrasMap}?${pathFilters}`;
    return await api.get(url).then((response) => {
      return response.data;
    }).catch((error) => {
      return error.data || { messageError: error.message };
    });
  }

  getObraPropertiesAPI = async (code: string): Promise<ObraProperties> => {
    return await api.get(`/${ENDPOINTS.v1.obrasMap}/${code}?idioma=${this.getLanguage()}`)
      .then((response) => {
        return response.data;
      });
  };

  downloadPDF = async (code: string): Promise<void> => {
    await api.get(`/${ENDPOINTS.v1.obras}/${code}/one-page?idioma=${this.getLanguage()}`)
      .then((response) => {
        // Create blob link to download
        const binaryString = window.atob(response.data);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
          const ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }
        const blob = new Blob([bytes], {type: 'application/pdf'});
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${code} - one page.pdf`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        if (link.parentNode) {
          link.parentNode.removeChild(link);
        }
      });
  };


  getRoles = async (code: string): Promise<string[]> => {
    return await api.get(`/${ENDPOINTS.v1.permisos}/${code}`)
      .then((response) => {
        return response.data;
      });
  };

  getAutocompleteUrl = async (filter: string, value: string, notLanguage?: boolean) => {
    const maestro = (MASTERS_PATHS as any)[filter];
    const schema = (MASTERS_RESPONSE_SCHEMA as any)[filter];
    return await api.get(`/${ENDPOINTS.v1.maestros}/${maestro}?texto=${value}${!notLanguage ? `&idioma=${this.getLanguage()}` : ''}`)
      .then((response) => {
        const results = response.data?.results || [];
        return results.map((r: any) => ({ code: r[schema.code], name: r[schema.name] }));
      });
  };

  getAutocompleteWorkSubtypesByCode = async (value: string) => {
    return this.getAutocompleteWorkSubtypes(value, 'codigo');
  };

  getAutocompleteWorkSubtypesByName = async (value: string) => {
    return this.getAutocompleteWorkSubtypes(value, 'texto');
  };

  private getAutocompleteWorkSubtypes = async (value: string, param: string) => {
    const maestro = MASTERS_PATHS.workSubtypeAutocomplete;
    const schema = MASTERS_RESPONSE_SCHEMA.workSubtype;
    return await api.get(`/${ENDPOINTS.v1.maestros}/${maestro}?${param}=${value}&idioma=${this.getLanguage()}`)
      .then((response) => {
        const results = response.data?.results || [];
        return results.map((r: any) => ({
          id: r[schema.id],
          code: r[schema.code],
          name: r[schema.name],
          elements: r[schema.elements],
        }));
      });
  };

  getAutocompleteGroupsClassificationByName = async (value: string) => {
    return this.getAutocompleteGroupsClassification(value, 'texto');
  };

  //Get groups by classification using custom mapper. include
  private getAutocompleteGroupsClassification = async (value: string, param: string) => {
    const maestro = MASTERS_PATHS.classificationGroups;
    const schema = MASTERS_RESPONSE_SCHEMA.classificationGroup;
    return await api.get(`/${ENDPOINTS.v1.maestros}/${maestro}?${param}=${value}&idioma=${this.getLanguage()}`)
      .then((response) => {
        const results = response.data?.results || [];
        return results.map((r: any) => ({
          id: r[schema.id],
          code: r[schema.code],
          name: `${r[schema.code]} ${r[schema.name]}`,
          elements: r[schema.elements]?.map((sub: any) => ({...sub, [schema.name]: `${sub[schema.code]} ${sub[schema.name]}`})),
        }));
      });
  };

  getAutocompleteClassificationGroups = async (value: string) => {
    const maestro = MASTERS_PATHS.classificationGroups;
    const schema = MASTERS_RESPONSE_SCHEMA.classificationGroup;
    return await api.get(`/${ENDPOINTS.v1.maestros}/${maestro}?texto=${value}&idioma=${this.getLanguage()}`)
      .then((response) => {
        const results = response.data?.results || [];
        return results.map((r: any) => ({ id: r[schema.id], code: r[schema.code], name: r[schema.name] }));
      });
  };

  getClassificationSubgroupsByGroupCode = async (code: string) => {
    const maestro = MASTERS_PATHS.classificationGroups;
    const schema = MASTERS_RESPONSE_SCHEMA.classificationSubgroup;
    return await api.get(`/${ENDPOINTS.v1.maestros}/${maestro}/${code}/subgrupos?idioma=${this.getLanguage()}`)
      .then((response) => {
        const results = response.data?.results || [];
        return results.map((r: any) => ({ id: r[schema.id], groupCode: r[schema.groupCode], code: r[schema.code], name: r[schema.name] }));
      });
  };

  getClassificationGroupBySubgroupCode: any = async (code: string) => {
    const maestro = MASTERS_PATHS.classificationGroups;
    const schema = MASTERS_RESPONSE_SCHEMA.classificationGroup;
    return await api.get(`/${ENDPOINTS.v1.maestros}/${maestro}/${code}?idioma=${this.getLanguage()}`)
      .then((response) => {
        const r = response.data || {};
        return { id: r[schema.id], code: r[schema.code], name: r[schema.name] };
      });
  };

  getUnitsByBuildingProcessGroup = async (codigo: any) => {
    return this.getUnitsByBuildingProcessGeneric(codigo, 'processGroup');
  };

  getUnitsByBuildingProcess = async (codigo: any) => {
    return this.getUnitsByBuildingProcessGeneric(codigo, 'process');
  };

  private getUnitsByBuildingProcessGeneric = async (codigo: any, type: string) => {
    const maestro = type === 'processGroup' ? MASTERS_PATHS.processGroup : MASTERS_PATHS.process;
    const schema = MASTERS_RESPONSE_SCHEMA.workUnit;
    return await api.get(`/${ENDPOINTS.v1.maestros}/${maestro}/${codigo}/unidades?idioma=${this.getLanguage()}`)
      .then((response) => {
        const results = response.data?.results || [];
        return results.map((r: any) => ({ id: r[schema.id], code: r[schema.code], name: r[schema.name] }));
      });
  };

  getWorkTypes = async () => {
    const maestro = MASTERS_PATHS.workSubtypeAutocomplete;
    const schema = MASTERS_RESPONSE_SCHEMA.workSubtype;
    return await api.get(`/${ENDPOINTS.v1.maestros}/${maestro}?idioma=${this.getLanguage()}`)
      .then((response) => {
        const results = response.data?.results || [];
        return results.map((r: any) => ({ id: r[schema.id], code: r[schema.code], name: r[schema.name] }));
      });
  };

  getWorkUnits = async () => {
    const maestro = MASTERS_PATHS.workUnitAutocomplete;
    const schema = MASTERS_RESPONSE_SCHEMA.workUnit;
    return await api.get(`/${ENDPOINTS.v1.maestros}/${maestro}?idioma=${this.getLanguage()}`)
      .then((response) => {
        const results = response.data?.results || [];
        return results.map((r: any) => ({ id: r[schema.id], code: r[schema.code], name: r[schema.name] }));
      });
  };

  getClassificationSubgroups = async () => {
    const maestro = MASTERS_PATHS.classificationSubgroups;
    const schema = MASTERS_RESPONSE_SCHEMA.classificationSubgroup;
    return await api.get(`/${ENDPOINTS.v1.maestros}/${maestro}?idioma=${this.getLanguage()}`)
      .then((response) => {
        const results = response.data?.results || [];
        return results.map((r: any) => ({ id: r[schema.id], groupCode: r[schema.groupCode], code: r[schema.code], name: r[schema.name] }));
      });
  };

  getClassificationGroups = async () => {
    const maestro = MASTERS_PATHS.classificationGroups;
    const schema = MASTERS_RESPONSE_SCHEMA.classificationGroup;
    return await api.get(`/${ENDPOINTS.v1.maestros}/${maestro}?idioma=${this.getLanguage()}`)
      .then((response) => {
        const results = response.data?.results || [];
        return results.map((r: any) => ({ id: r[schema.id], code: r[schema.code], name: r[schema.name] }));
      });
  };

  getAutocompleteWorkUnitsByCode = async (value: string) => {
    return this.getAutocompleteWorkUnits(value, 'codigo');
  };

  getAutocompleteWorkUnitsByName = async (value: string) => {
    return this.getAutocompleteWorkUnits(value, 'texto');
  };

  private getAutocompleteWorkUnits = async (value: string, param: string) => {
    const maestro = MASTERS_PATHS.workUnitAutocomplete;
    const schema = MASTERS_RESPONSE_SCHEMA.workUnit;
    return await api.get(`/${ENDPOINTS.v1.maestros}/${maestro}?${param}=${value}&idioma=${this.getLanguage()}`)
      .then((response) => {
        const results = response.data?.results || [];
        return results.map((r: any) => ({ id: r[schema.id], code: r[schema.code], name: r[schema.name] }));
      });
  };

  getAllChapter = async () => {
    const maestro = MASTERS_PATHS.chapter;
    return await api.get(`/${ENDPOINTS.v1.maestros}/${maestro}?idioma=${this.getLanguage()}`)
      .then((response) => {
        const results = response.data?.results || [];
        return results;
      });
  };

  getChapter = async (value: string) => {
    const maestro = MASTERS_PATHS.chapter;
    return await api.get(`/${ENDPOINTS.v1.maestros}/${maestro}/${value}/capitulos?idioma=${this.getLanguage()}`)
      .then((response) => {
        const results = response.data?.results || [];
        return results;
      });
  };

  getAutocompleteProcess = async (value: string) => {
    const maestro = MASTERS_PATHS.processGroup;
    return await api.get(`/${ENDPOINTS.v1.maestros}/${maestro}/${value}/procesos_constructivos?idioma=${this.getLanguage()}`)
      .then((response) => {
        const results = response.data?.results || [];
        return results;
      });
  };

  getGroupChapter = async (value: string) => {
    const maestro = MASTERS_PATHS.chapter;
    return await api.get(`/${ENDPOINTS.v1.maestros}/${maestro}/${value}/grupos_procesos_constructivos?idioma=${this.getLanguage()}`)
      .then((response) => {
        const results = response.data?.results || [];
        return results;
      });
  };

  getObrasListAPI = async (query?: string, filters?: any): Promise<ResponseObrasMap | ResponseError> => {
    const pathFilters = this.generateFiltersPath(filters);
    const url = query ? `/${ENDPOINTS.v1.obrasList}?${query}&${pathFilters}` : `/${ENDPOINTS.v1.obrasList}?${pathFilters}`;
    return await api.get(url)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error.data || { messageError: error.message };
      });
  };

  getNumberOfFilteredProjects = async (filters?: any): Promise<number> => {
    const pathFilters = this.generateFiltersPath(filters);
    const url = `/${ENDPOINTS.v1.obrasList}?total=0&${pathFilters}`; // With total=0, the server returns the number of projects but not the projects
    return await api.get(url)
      .then((response) => {
        return response.data.total;
      }).catch((error) => {
        return error.data || { messageError: error.message };
      });
  };

  getObrasXLSXAPI = async (filters?: any): Promise<void> => {
    const pathFilters = this.generateFiltersPath(filters);
    const url = `/${ENDPOINTS.v1.export}/xlsx?${pathFilters}`;
    await api.get(url, { responseType: 'blob' })
      .then((response: any) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([response.data]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `phia-${new Date().toLocaleDateString()}.zip`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        if (link.parentNode) {
          link.parentNode.removeChild(link);
        }
      });
  };

  getObraFullDetailsAPI = async (code: string, version: string): Promise<ObraPropertiesDetails> => {
    return await api.get(`/${ENDPOINTS.v1.obrasFullDetails}/${code}?idioma=${this.getLanguage()}&version=${version}`)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        throw new Error(error);
      });
  };

  getGroups = async () => {
    const maestro = MASTERS_PATHS.workType;
    const schema = MASTERS_RESPONSE_SCHEMA.workType;
    return await api.get(`/${ENDPOINTS.v1.maestros}/${maestro}?idioma=${this.getLanguage()}`)
      .then((response) => {
        const results = response.data?.results || [];
        return results.map((r: any) => ({ id: r[schema.id], code: r[schema.code], name: r[schema.name] }));
      });
  };

  getSubtypes = async (groupCode: string) => {
    const maestro = MASTERS_PATHS.workType;
    const subMaestro = MASTERS_PATHS.workSubtype;
    const schema = MASTERS_RESPONSE_SCHEMA.workSubtype;
    return await api.get(`/${ENDPOINTS.v1.maestros}/${maestro}/${groupCode}/${subMaestro}?idioma=${this.getLanguage()}`)
      .then((response) => {
        const results = response.data?.results || [];
        return results.map((r: any) => ({ code: r[schema.code], name: r[schema.name] }));
      });
  };

  getMagnitudes = async (subTypeCode: string) => {
    const maestro = MASTERS_PATHS.workSubtype;
    const subMaestro = MASTERS_PATHS.magnitudes;
    const schema = MASTERS_RESPONSE_SCHEMA.workSubtype;
    return await api.get(`/${ENDPOINTS.v1.maestros}/${maestro}/${subTypeCode}/${subMaestro}?idioma=${this.getLanguage()}`)
      .then((response) => {
        const results = response.data?.results || [];
        return results.map((r: any) => ({ id: r[schema.id], name: r[schema.name] }));
      });
  };

  private getMasters = async (maestro: string, schema: any) => {
    return await api.get(`/${ENDPOINTS.v1.maestros}/${maestro}?idioma=${this.getLanguage()}`)
      .then((response) => {
        const results = response.data?.results || [];
        return results.map((r: any) => ({ code: r[schema.code], name: r[schema.name] }));
      });
  };

  getMasterData = async (masterPath: string, masterSchema: string) => {
    const maestro = (MASTERS_PATHS as any)[masterPath];
    const schema = (MASTERS_RESPONSE_SCHEMA as any)[masterSchema];
    return await this.getMasters(maestro, schema);
  };

  getContractStatus = async () => {
    const maestro = MASTERS_PATHS.contractStatus;
    const schema = MASTERS_RESPONSE_SCHEMA.contractStatus;
    return await this.getMasters(maestro, schema);
  };
  getContractTypes = async () => {
    const maestro = MASTERS_PATHS.contractTypes;
    const schema = MASTERS_RESPONSE_SCHEMA.contractTypes;
    return await this.getMasters(maestro, schema);
  };

  getContractModels = async () => {
    const maestro = MASTERS_PATHS.contractModels;
    const schema = MASTERS_RESPONSE_SCHEMA.contractModels;
    return await this.getMasters(maestro, schema);
  };

  getContractScope = async () => {
    const maestro = MASTERS_PATHS.contractScope;
    const schema = MASTERS_RESPONSE_SCHEMA.contractScope;
    return await this.getMasters(maestro, schema);
  };

  getFormPayment = async () => {
    const maestro = MASTERS_PATHS.contractFormPayment;
    const schema = MASTERS_RESPONSE_SCHEMA.contractFormPayment;
    return await this.getMasters(maestro, schema);
  };

  getSourceFinance = async () => {
    const maestro = MASTERS_PATHS.contractSourceFinance;
    const schema = MASTERS_RESPONSE_SCHEMA.contractSourceFinance;
    return await this.getMasters(maestro, schema);
  };

  getBusinessActivities = async () => {
    const maestro = MASTERS_PATHS.businessActivities;
    const schema = MASTERS_RESPONSE_SCHEMA.businessActivities;
    return await this.getMasters(maestro, schema);
  };

  getMaturityLevel = async () => {
    const maestro = MASTERS_PATHS.maturityLevel;
    const schema = MASTERS_RESPONSE_SCHEMA.maturityLevel;
    return await this.getMasters(maestro, schema);
  };

  getCategorization = async () => {
    const maestro = MASTERS_PATHS.categorization;
    const schema = MASTERS_RESPONSE_SCHEMA.categorization;
    return await this.getMasters(maestro, schema);
  };

  getRequirements = async () => {
    const maestro = MASTERS_PATHS.requirements;
    const schema = MASTERS_RESPONSE_SCHEMA.requirements;
    return await api.get(`/${ENDPOINTS.v1.maestros}/${maestro}?idioma=${this.getLanguage()}`)
      .then((response) => {
        const results = response.data?.results || [];
        return results.map((r: any) => ({ codigos: r[schema.codigos], code: r[schema.code], name: r[schema.name] }));
      });
  };

  getTaxonomicObjectives = async () => {
    const maestro = MASTERS_PATHS.taxonomicObjective;
    const schema = MASTERS_RESPONSE_SCHEMA.taxonomicObjective;
    return await this.getMasters(maestro, schema);
  };

  getSocialCategories = async () => {
    const maestro = MASTERS_PATHS.socialCategories;
    const schema = MASTERS_RESPONSE_SCHEMA.socialCategories;
    return await this.getMasters(maestro, schema);
  };

  getGISPercentage = async () => {
    const maestro = MASTERS_PATHS.gisPercentage;
    const schema = MASTERS_RESPONSE_SCHEMA.gisPercentage;
    return await this.getMasters(maestro, schema);
  };

  getPhotoTypes = async () => {
    const maestro = MASTERS_PATHS.photoTypes;
    const schema = MASTERS_RESPONSE_SCHEMA.photoType;
    const response = await api.get(`/${ENDPOINTS.v1.maestros}/${maestro}?idioma=${this.getLanguage()}`);
    const results = response.data?.results || [];
    return results.map((r: any) => ({ id: r[schema.id], tipo: r[schema.name] }));
  };

  getProtectedSpaces = async (): Promise<SelectOption[]> => {
    const maestro = MASTERS_PATHS.protectedSpace;
    const schema = MASTERS_RESPONSE_SCHEMA.protectedSpace;
    return await api.get(`/${ENDPOINTS.v1.maestros}/${maestro}?idioma=${this.getLanguage()}`)
      .then((response) => {
        const results = response.data?.results || [];
        return results.map((r: any) => ({ id: parseInt(r[schema.code]), nombre: r[schema.name], code: parseInt(r[schema.code]), name: r[schema.name] }));
      }).catch(() => {
        return [];
      });
  };

  getProtectedSpacesLocations = async (): Promise<SelectOption[]> => {
    const maestro = MASTERS_PATHS.protectedSpaceLocations;
    const schema = MASTERS_RESPONSE_SCHEMA.protectedSpace;
    return await api.get(`/${ENDPOINTS.v1.maestros}/${maestro}?idioma=${this.getLanguage()}`)
      .then((response) => {
        const results = response.data?.results || [];
        return results.map((r: any) => ({ id: parseInt(r[schema.code]), nombre: r[schema.name], code: parseInt(r[schema.code]), name: r[schema.name] }));
      }).catch((error) => {
        return [];
      });
  };

  getDangerLevels = async (): Promise<SelectOption[]> => {
    const maestro = MASTERS_PATHS.dangerLevels;
    const schema = MASTERS_RESPONSE_SCHEMA.dangerLevels;
    const response = await api.get(`/${ENDPOINTS.v1.maestros}/${maestro}?idioma=${this.getLanguage()}`);
    const results = response.data?.results || [];
    return results.map((r: any) => ({ id: parseInt(r[schema.code]), nombre: r[schema.name], code: parseInt(r[schema.code]), name: r[schema.name] }));
  };

  getProtectionZones = async (): Promise<SelectOption[]> => {
    const maestro = MASTERS_PATHS.protectionZones;
    const schema = MASTERS_RESPONSE_SCHEMA.protectionZones;
    const response = await api.get(`/${ENDPOINTS.v1.maestros}/${maestro}?idioma=${this.getLanguage()}`);
    const results = response.data?.results || [];
    return results.map((r: any) => ({ id: parseInt(r[schema.code]), nombre: r[schema.name], code: parseInt(r[schema.code]), name: r[schema.name] }));
  };

  getClientStatusMasters = async (): Promise<SelectOption[]> => {
    const maestro = MASTERS_PATHS.clientStatus;
    const schema = MASTERS_RESPONSE_SCHEMA.clientStatus;
    return await api.get(`/${ENDPOINTS.v1.maestros}/${maestro}?idioma=${this.getLanguage()}`)
      .then((response) => {
        const results = response.data?.results || [];
        return results.map((r: any) => ({ id: parseInt(r[schema.code]), nombre: r[schema.name], code: parseInt(r[schema.code]), name: r[schema.name] }));
      }).catch(() => {
        return [];
      });
  };

  getCDE = async (t: TFunction<'translations'>): Promise<OptionBoolean[]> => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const content: OptionBoolean[] = [
          { code: true, name: `${t('optionBoolean.yes')}`},
          { code: false, name: `${t('optionBoolean.no')}`},
        ];
        resolve(content);
      }, 100);
    });
  };

  getTypeBimApplicationsMasters = async (): Promise<SelectOption[]> => {
    const maestro = MASTERS_PATHS.typesBimApplications;
    const schema = MASTERS_RESPONSE_SCHEMA.typesBimApplications;
    return await api.get(`/${ENDPOINTS.v1.maestros}/${maestro}?idioma=${this.getLanguage()}`)
      .then((response) => {
        const results = response.data?.results || [];
        return results.map((r: any) => ({ code: parseInt(r[schema.code]), name: r[schema.name] }));
      }).catch(() => {
        return [];
      });
  };

  save = async (data: any, draft = false) => {
    const url = `/${ENDPOINTS.v1.obras}`;
    return await api.post(`${draft ? `${url}/draft` : url}`, data)
      .then((response) => {
        const results = response.data?.results || [];
        return results;
      }).catch(err => {
        console.error(err);
      });
  };

  approve = async (data: any) => {
    const url = `/${ENDPOINTS.v1.obras}`;
    return await api.post(`${url}/validate`, data)
      .then((response) => {
        return response.data;
      }).catch(err => {
        console.error(err);
      });
  };

  requestValidation = async (data: any, draft = false) => {
    const url = `/${ENDPOINTS.v1.obras}`;
    return await api.post(`${draft ? `${url}/requestValidation` : url}`, data)
      .then((response) => {
        return response.data;
      }).catch(err => {
        console.error(err);
      });
  };

  reject = async (data: any) => {
    const url = `/${ENDPOINTS.v1.obras}`;
    return await api.post(`${url}/reject`, data)
      .then((response) => {
        return response.data;
      }).catch(err => {
        console.error(err);
      });
  };

  uploadFile = async (file: any, cod_centro: string, type: string) => {
    const data = new FormData();
    data.append('file', file);
    const url = `/${ENDPOINTS.v1.obras}`;
    return await api.post(`${url}/${cod_centro}/ficheros?tipo=${type === 'image' ? 'imagen' : 'kmz'}`, data)
      .then((response) => {
        const responseUrl = response.data?.url || '';
        return { statusCode: 200, url: responseUrl };
      }).catch((error) => {
        return error.response.data;
      });
  };

  getNotificationsAPI = async (diasAntiguedad?: number) => {
    const url = `/${ENDPOINTS.v1.notificaciones}`;
    const params = diasAntiguedad ? `&diasAntiguedad=${diasAntiguedad}` : '';
    return await api.get(`${url}?idioma=${this.getLanguage()}${params}`)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error.response.data;
      });
  };

  setNotificationRead = async (data: any) => {
    const url = `/${ENDPOINTS.v1.notificaciones}`;
    return await api.post(`${url}/read`, data)
      .then((response) => {
        return { statusCode: 200 };
      }).catch(() => {
        return { statusCode: 500 };
      });
  };

  getUsers = async (pageNumber: number) => {
    const url = `/${ENDPOINTS.admin.general}?idioma=${this.getLanguage()}&page=${pageNumber}`;
    return await api.get(url)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error.response.data;
      });
  };

  getPermissionsByUserEmail = async (userEmail: string) => {
    const url = `/${ENDPOINTS.admin.permissions}?idioma=${this.getLanguage()}&email=${userEmail}`;
    return await api.get(url)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error.response.data;
      });
  };

  getAdminKeycloakAndDB = async (email: string): Promise<boolean> => {
    return await api.get(`/${ENDPOINTS.admin.keycloakDb}?email=${email}&idioma=${this.getLanguage()}`)
      .then((response) => {
        return response.data;
      });
  };

  getAutocompleteMastersUrl = async (filter: string, value: string) => {
    const maestro = (MASTERS_PATHS as any)[filter];
    const schema = (MASTERS_RESPONSE_SCHEMA as any)[filter];
    return await api.get(`/${ENDPOINTS.v1.maestros}/${maestro}?texto=${value}&idioma=${this.getLanguage()}`)
      .then((response) => {
        const results = response.data?.results || [];
        return results.map((r: any) => ({ code: r[schema.code], name: r[schema.codname] }));
      });
  };

  getAllNoPhiaUsers = async () => {
    const url = `/${ENDPOINTS.admin.noPhia}`;
    return await api.get(url)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error.response.data;
      });
  };

  getProjectByCodeName = async (newValue: string) => {
    const url = `/${ENDPOINTS.admin.projects}?idioma=${this.getLanguage()}&texto=${newValue}`;
    return await api.get(url)
      .then((response) => {
        return response.data?.results || [];
      }).catch((error) => {
        return error.response.data;
      });
  };

  getUsersByProject = async (project: CenterParams) => {
    let params = `&centro=${project.codCentro}`;
    params = `${params}&supercentro=${project.codSupercentro}`;
    params = `${params}&zona=${project.codZona}`;
    params = `${params}&empresa=${project.codActividadNegocio}`;
    const url = `/${ENDPOINTS.admin.users}?idioma=${this.getLanguage()}${params}`;
    return await api.get(url)
      .then((response) => {
        return response.data || [];
      }).catch((error) => {
        return error.response.data;
      });
  };

  getPendingActionsAPI = async (pageNumber: number, filter: string, onlyPending: boolean) => {
    const url = `/${ENDPOINTS.v1.notificaciones}/acciones`;
    return await api.get(`${url}?filter=${filter}&onlyPending=${onlyPending}&idioma=${this.getLanguage()}&page=${pageNumber}`)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error.response.data;
      });
  };

  editRole = async (id: number | undefined, email: string | undefined, rol: string | undefined, codCentro: string, codSupercentro: string, codZona: string, codEmpresa: string) => {
    const url = `/${ENDPOINTS.admin.permissions}?idioma=${this.getLanguage()}`;
    const data = { id, email, rol, codCentro, codSupercentro, codZona, codEmpresa };
    return await api.patch(url, data)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return { error };
      });
  };

  addRole = async (id: number | undefined, email: string | undefined, rol: string | undefined, codCentro: string, codSupercentro: string, codZona: string, codEmpresa: string) => {
    const url = `/${ENDPOINTS.admin.permissions}?idioma=${this.getLanguage()}`;
    const data = { id, email, rol, codCentro, codSupercentro, codZona, codEmpresa };
    return await api.post(url, data)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return { error };
      });
  };


  deleteRole = async (id: number, userEmail: string, grupo: string) => {
    const url = `/${ENDPOINTS.admin.permissions}/${id}?email=${userEmail}&grupo=${grupo}`;
    return await api.delete(url)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return { error };
      });
  };

  getZones = async () => {
    const maestro = MASTERS_PATHS.allZones;
    return await api.get(`/${ENDPOINTS.v1.maestros}/${maestro}?idioma=${this.getLanguage()}`)
      .then((response) => {
        const results = response.data?.results || [];
        return results;
      });
  };

  getSupercenterByZone = async (value: string) => {
    const maestro = MASTERS_PATHS.supercenter;
    const schema = MASTERS_RESPONSE_SCHEMA.supercenter;
    return await api.get(`/${ENDPOINTS.v1.maestros}/${maestro}?idioma=${this.getLanguage()}&zona=${value}`)
      .then((response) => {
        const results = response.data?.results || [];
        return results.map((r: any) => ({ id: parseInt(r[schema.code]), nombre: r[schema.name] }));
      });
  };


  getCertificates = async () => {
    const maestro = MASTERS_PATHS.certificates;
    return await api.get(`/${ENDPOINTS.v1.maestros}/${maestro}?idioma=${this.getLanguage()}`)
      .then((response) => {
        return response;
      }).catch((error) => {
        return error.response.data;
      });
  };


  getCertificatesLevel = async () => {
    const maestro = MASTERS_PATHS.certificatesLevel;
    return await api.get(`/${ENDPOINTS.v1.maestros}/${maestro}?idioma=${this.getLanguage()}`)
      .then((response) => {
        return response;
      }).catch((error) => {
        return error.response.data;
      });
  };

}

export default ObrasAPI;
