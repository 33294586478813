/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC } from 'react';
import styles from './workType.module.scss';

interface WorkTypeProps {
  typeCode: string;
  code: string;
  name: string;
}


const WorkType: FC<WorkTypeProps> = (props) => {
  const { typeCode, code, name } = props;
  const colorsByCode = (): string => {
    if (['A', 'B', 'C', 'F', 'H', 'J', 'M', 'S', 'U'].includes(typeCode)) return '#469AD3';
    if (['D', 'E', 'K', 'N', 'O', 'P', 'R', 'V', 'T'].includes(typeCode)) return '#FFC8CA';
    if (['Q', 'G', 'I'].includes(typeCode)) return '#FFD770';
    if (['L', 'Y'].includes(typeCode)) return '#92CF59';
    if (typeCode === 'W') return '#C29CDF';
    return '#D1CECE';
  };
  const style = { '--label-color': `${colorsByCode()}` } as React.CSSProperties;
  return (
    <div className={styles.workTypeContainer}>
      <div id='worktype-typecode' className={styles.typeCode} style={style}></div>
      <div id='worktype-code' className={styles.code}>{code}</div>
      <div id='worktype-name' className={styles.name}>{name}</div>
    </div>
  );
};

export default WorkType;
