/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useTranslation } from 'react-i18next';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as badge } from '../../../../../assets/badge.svg';
import styles from './titleStyle.module.scss';
import { FormGroup, Grid, Typography } from '@mui/material';

export const Title = (
  {
    filterType,
    tags,
    isOpenFilter,
    toggleIsOpenFilter,
    hoverable
  }: any,
) => {
  const { t } = useTranslation();
  const tagsLength = Array.isArray(tags) ? tags.length : 0;

  return (
    <FormGroup
      row
      className={styles.titleContainer}
    >
      <Grid
        container
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        onClick={() => toggleIsOpenFilter()}
        className={hoverable ? styles.hoverableTitle : styles.title}
      >
        <Grid item>
          {isOpenFilter ? <ExpandLess /> : <ExpandMore />}
        </Grid>
        <Grid
          container
          alignItems='center'
          className={styles.titleBadgeTypographyContainer}
        >

          <Typography
            id='filters-titles'
            variant='body1'
            className={styles.titleTypography}
          >
            {t(`filters.${filterType}`)}
          </Typography>

        </Grid>
        <Grid>
          <SvgIcon component={badge} inheritViewBox
            style={tagsLength === 0 ? { opacity: '0' } : { opacity: '1' }} />

        </Grid>

      </Grid >
    </FormGroup>
  );
};
