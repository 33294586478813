import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CollapsableFilter, { CollapsableFilterChildrenProps } from '../components/collapsableFilter/collapsableFilter';

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const EfficiencyFilters: FC<CollapsableFilterChildrenProps> = (props) => {
  const { open, handleClickOpen } = props;
  const [t] = useTranslation();

  return (
    <CollapsableFilter
      id='efficiency'
      value={t('advancedFilters.efficiency')}
      help={t('advancedFilters.efficiency')}
      handleClickOpen={handleClickOpen}
      open={open}
      advancedFilter={''}
    >
      <div>
      </div>
    </CollapsableFilter>
  );
};

export default EfficiencyFilters;
