import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './esg.module.scss';
import { ObraProperties } from '../../../types/ObraDetails';
import Taxonomy from './taxonomy/taxonomy';
import TaxonomyObjetive from './taxonomyObjetive/taxonomyObjetive';
import SocialSafeguards from './socialSafeguards/socialSafeguards';
import Impact from './impact/impact';
import Controversies from './controversies/controversies';
import GreenFinancing from './greenFinancing/greenFinancing';
import LifeCycle from './lifeCycle/lifeCycle';
import Sustainability from './sustainability/sustainability';

interface ESGProps {
  obra: ObraProperties,
  errors?: any
}

const ESG: FC<ESGProps> = (props) => {
  const { obra, errors } = props;

  return (
    <div data-testid='esg-test' id='esg-nodata' className={styles.container}>
      <Taxonomy obra={obra} />
      <TaxonomyObjetive obra={obra} errors={errors} />
      <SocialSafeguards obra={obra} />
      <Impact obra={obra} />
      <Controversies obra={obra} />
      <GreenFinancing obra={obra} />
      <LifeCycle obra={obra} />
      <Sustainability obra={obra} errors={errors} />
      <div className='footer' style={{marginBottom: '75px'}}></div>
    </div>
  );
};


export default ESG;
