import { TFunction } from 'i18next';
import React, { useContext } from 'react';
import ReactGA from 'react-ga4';
import { withTranslation } from 'react-i18next';
import styles from './saveDraft.module.scss';
import { Button, Dialog, Stack } from '@mui/material';
import ObrasAPI from '../../services/APIObras';
import { ObraProperties } from '../../types/ObraDetails';
import { SaveContext } from '../../context/SaveProvider';
import saveIcon from '../../assets/save.svg';
import LoadingSpinner from '../loadingSpinner/loadingSpinner';
import { AuthContext } from '../../services/authContextProvider';

interface SaveDraftProps {
  t: TFunction<'translations'>;
  obra: ObraProperties;
}
function SaveDraft(props: SaveDraftProps) {
  const { t, obra } = props;
  const saveContext = useContext(SaveContext);
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = React.useState(false);

  const [saveKO, setSaveKO] = React.useState<boolean>(false);
  const [saveOK, setSaveOK] = React.useState<boolean>(false);

  const saveProject = async () => {
    saveContext.handleValidationClicked(false);
    setLoading(true);
    const api = ObrasAPI.getInstance();
    const saved = await api.save(obra, true);
    ReactGA.event('save_draft', {
      role: authContext.mainRole(false),
      code: obra.general.codCentro
    });
    setLoading(false);
    if (!saved) {
      setSaveKO(true);
    } else {
      saveContext.handleChangeSave(false);
      // 
      setSaveOK(true);
    }
  };


  const handleClose = (reloadData: boolean) => {
    setSaveOK(false);
    setSaveKO(false);
    if (reloadData) {
      saveContext.handleReloadData();
    }
  };


  return (
    <div data-testid='saveDraft-test' className={styles.saveDraftContainer}>
      {
        loading ?
          <LoadingSpinner />
          : null
      }
      <button
        id='tabmenu-save'
        onClick={() => {
          saveProject();
        }}
        disabled={!saveContext.saveDraft}
      >
        <img src={saveIcon} /> {t('details.save')}
      </button>
      <Dialog
        classes={{
          paper: styles.dialog
        }}
        BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.15)' } }}
        PaperProps={{ sx: { borderRadius: '20px', minWidth: '90vW' } }}
        open={saveOK || saveKO}
        onClose={() => handleClose(false)}
      >
        {
          (saveOK) ?
            <div className={styles.dialogContent}>
              <p>{t('details.saved')}</p>
              <Stack spacing={2} direction="row" justifyContent="center">
                <Button id='buttonaccept' className={styles.buttonAccept} onClick={() => handleClose(true)} disableRipple={true} variant="contained">{t('details.savedButtonAccept')}</Button>
              </Stack>
            </div>
            :
            (saveKO) ?
              <div className={styles.dialogContent}>
                <p>{t('details.savedError')}</p>
                <Stack spacing={2} direction="row" justifyContent="center">
                  <Button id='buttoncancel' className={styles.buttonCancel} onClick={() => handleClose(false)} disableRipple={true} variant="outlined">{t('details.savedErrorButtonCancel')}</Button>
                  <Button id='buttonretry' className={styles.buttonRetry} onClick={() => {
                    setSaveKO(false);
                    saveProject();
                  }} disableRipple={true} variant="contained">{t('details.savedErrorButtonRetry')}</Button>
                </Stack>
              </div>
              : null
        }
      </Dialog>
    </div >
  );
}

export default withTranslation()(SaveDraft);