import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './lifeCycle.module.scss';
import { ObraProperties } from '../../../../types/ObraDetails';
import TitleHelp from '../../components/titleHelp/titleHelp';
import InfoBoxBoolean from '../../components/InfoBoxBoolean/InfoBoxBoolean';
import InfoBox from '../../components/infoBox/infoBox';
import { ReactComponent as checkFalse } from '../../../assets/check_false_disable.svg';
import TitleValue from '../../components/titleValue/titleValue';
import SimpleSelect from '../../components/simpleSelect/simpleSelect';
import { UserModeContext } from '../../../../context/UserModeProvider';
import { SaveContext } from '../../../../context/SaveProvider';

interface LifeCycleProps {
  obra: ObraProperties,
}

const LifeCycle: FC<LifeCycleProps> = (props) => {
  const { obra } = props;
  const { t, i18n } = useTranslation();
  const userModeContext = useContext(UserModeContext);
  const isEditorMode = userModeContext.userMode === 'editor';
  const saveContext = useContext(SaveContext);

  const [valueAcciona, setValueAcciona] = useState({ id: 0, nombre: '-' });

  let dap;
  if (obra.esg.dap) {
    dap = t('details.esg.lifeCycle.yes');
  } else if (obra.esg.dap === false) {
    dap = t('details.esg.lifeCycle.no');
  } else {
    dap = t('details.notInformed');
  }

  if (typeof obra.general.dap !== 'undefined' && valueAcciona.id === 0) {
    obra.general.dap ? setValueAcciona({ id: 1, nombre: t('details.esg.lifeCycle.yes') }) : setValueAcciona({ id: 2, nombre: t('details.esg.lifeCycle.no') });
  }

  const handleChangeDAP = (data: any) => {
    if (data.id === 1) {
      obra.general.dap = true;
    } else if (data.id === 2) {
      obra.general.dap = false;
    } else if (data.id === 0) {
      obra.general.dap = undefined;
    }
    setValueAcciona(data);
    saveContext.handleChangeSave(true);
  };

  return (
    <div data-testid='esg-test' id='esg-nodata' className={styles.container}>
      <TitleHelp
        id='lifeCycle'
        value={t('details.esg.lifeCycle.helper')}
        help={t('help.esg.lifecycle')}
      />
      <div className={styles.sectionContainer}>
        <div className={styles.dapClient}>
          <TitleValue type='input' editable={false} identifier={dap === t('details.esg.lifeCycle.no') ? 'dapNo' : dap === t('details.esg.lifeCycle.yes') ? 'dap' : 'dapEmpty'} title={t('details.esg.lifeCycle.dap')} value={dap} />
        </div>
        <div className={valueAcciona && valueAcciona.id === 1 ? styles.dapAcciona : valueAcciona.id === 0 ? styles.dapAccionaEmpty : styles.dapAccionaNo}>
          <SimpleSelect
            id='select-dapAcciona'
            options={[{ id: 0, nombre: '-' }, { id: 1, nombre: t('details.esg.lifeCycle.yes') }, { id: 2, nombre: t('details.esg.lifeCycle.no') }]}
            value={valueAcciona}
            label={t('details.esg.lifeCycle.dapAcciona')}
            handleChangeValue={(data: any) => handleChangeDAP(data)}
            editable={isEditorMode}
          />
        </div>
      </div>
    </div>
  );
};


export default LifeCycle;
