import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './datesFilters.module.scss';
import CollapsableFilter, { CollapsableFilterChildrenProps } from '../components/collapsableFilter/collapsableFilter';
import FILTER_TYPES, { FILTER_CONFIG } from '../../../components/Filters/filterTypes';
import NumberRange from '../../../components/Filters/Components/numberRangeFilters/numberRange/numberRange';
import { DatePickerRange } from '../../../components/Filters/Components/DatePickerRange/DatePickerRange';

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const DatesFilters: FC<CollapsableFilterChildrenProps> = (props) => {
  const { open, handleClickOpen } = props;
  const [t] = useTranslation();

  return (
    <CollapsableFilter
      id='dates'
      value={t('advancedFilters.dates')}
      help={t('advancedFilters.dates')}
      handleClickOpen={handleClickOpen}
      open={open}
      advancedFilter={'dates'}
    >
      <div className={styles.datesFiltersContainer}>
        <div className={styles.collapsableComponent}>
          <DatePickerRange filterType={FILTER_TYPES.signatureContract}  noBottomDivider={true} />
        </div>
        <div className={styles.collapsableComponent}>
          <DatePickerRange filterType={FILTER_TYPES.stakeoutReport}  noBottomDivider={true} />
        </div>
        <div className={styles.collapsableComponent}>
          <DatePickerRange filterType={FILTER_TYPES.beginningWork}  noBottomDivider={true} />
        </div>
        <div className={styles.collapsableComponent}>
          <NumberRange filterType={FILTER_TYPES.initialDeadline} defaultUnit={FILTER_CONFIG.period} noBottomDivider />
        </div>
        {/* <div className={styles.collapsableComponent}>
          <NumberRange filterType={FILTER_TYPES.updatedDeadline} defaultUnit={t(FILTER_CONFIG.period)} noBottomDivider />
        </div> */}
        <div className={styles.collapsableComponent}>
          <DatePickerRange filterType={FILTER_TYPES.plannedEnd}  noBottomDivider={true} />
        </div>
        <div className={styles.collapsableComponent}>
          <DatePickerRange filterType={FILTER_TYPES.plannedEndUpdatedApproved}  noBottomDivider={true} />
        </div>
        <div className={styles.collapsableComponent}>
          <DatePickerRange filterType={FILTER_TYPES.receptionReport}  noBottomDivider={true} />
        </div>
        <div className={styles.collapsableComponent}>
          <DatePickerRange filterType={FILTER_TYPES.endExecution}  noBottomDivider={true} />
        </div>
      </div>
    </CollapsableFilter>
  );
};

export default DatesFilters;
