import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './descriptionFilters.module.scss';
import FILTER_TYPES from '../../../components/Filters/filterTypes';
import CollapsableFilter, { CollapsableFilterChildrenProps } from '../components/collapsableFilter/collapsableFilter';
import KeywordInput from '../../../components/Filters/Components/keywordInput/keywordInput';

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const DescriptionFilters: FC<CollapsableFilterChildrenProps> = (props) => {
  const { open, handleClickOpen } = props;
  const [t] = useTranslation();

  return (
    <CollapsableFilter
      id='description'
      value={t('advancedFilters.description')}
      help={t('advancedFilters.description')}
      handleClickOpen={handleClickOpen}
      open={open}
      advancedFilter={'description'}
    >
      <div className={styles.descriptionFiltersContainer}>
        <div className={styles.collapsableComponent}>
          <KeywordInput filterType={FILTER_TYPES.descGeneral} noBottomDivider={true} />
        </div>
        <div className={styles.collapsableComponent}>
          <KeywordInput filterType={FILTER_TYPES.infoDestacable} noBottomDivider={true} />
        </div>
        <div className={styles.collapsableComponent}>
          <KeywordInput filterType={FILTER_TYPES.descDetallada} noBottomDivider={true} />
        </div>
      </div>
    </CollapsableFilter>
  );
};

export default DescriptionFilters;
