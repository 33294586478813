import React, { FC, useContext, useState, useEffect, useRef, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import RichTextEditor, { EditorValue, ToolbarConfig } from 'react-rte';
import LanguageDetect from 'languagedetect';
import styles from './DescriptionMultiLan.module.scss';
import { DESCRIPTION_LAN, FLAG_COUNTRY_CODE, DESCRIPTION_LAN_NAME } from './DescriptionMutilLanConfig';
import { getTextAlignClassName } from './blockStyleFunctions';
import { UserModeContext } from '../../context/UserModeProvider';
import ErrorIcon from '@mui/icons-material/Error';
import { SaveContext } from '../../context/SaveProvider';
import InformationIcon from '../../pages/fullDetail/components/informationIcon/informationIcon';
import Description from '../Description';
import { INFO_DESTACABLE_MAX_CHARS_PER_LINE } from '../../pages/fullDetail/descriptionPhoto/description/description';

interface ErrorLen {
  [language: string]: boolean;
}

interface DescriptionLanguage {
  [language: string]: string
}

interface DescriptionData {
  title: string,
  data: DescriptionLanguage
}

interface DescriptionMultiLanProps {
  description: DescriptionData;
  rows?: number;
  editable?: boolean;
  modeView?: boolean;
  error?: string | null;
  titleMessage: string;
  maxLen?: number;
}
const DescriptionMultiLan: FC<DescriptionMultiLanProps> = (props) => {
  const editorRef = useRef<any>(null);
  const saveContext = useContext(SaveContext);
  const format = 'html';
  const { description, rows, editable, error, titleMessage, modeView = false, maxLen } = props;
  const { t, i18n } = useTranslation();
  const [descriptionData, setDescriptionData] = useState(description);
  const [descriptionLan, setDescriptionLan] = useState<string>(i18n.language);
  const [maxLenError, setMaxLenError] = useState<ErrorLen>({es: false, en: false, pt: false, pl: false});
  const lngDetector = new LanguageDetect();

  const getEditorValue = () => {
    const descriptionContent = description.data[descriptionLan] === undefined ? '' : description.data[descriptionLan];
    return RichTextEditor.createValueFromString(descriptionContent, format);
  };

  const descriptionContent = description.data[descriptionLan] || t('details.noData');
  const [editorStateValue, setEditorStateValue] = useState<EditorValue>(RichTextEditor.createEmptyValue());
  const [lanWarning, setLanWarning] = useState<boolean>(false);

  const userModeContext = useContext(UserModeContext);

  const isEditorMode = userModeContext.userMode === 'editor' && editable;

  useEffect(() => {
    if(isEditorMode){
      setEditorStateValue(getEditorValue());
    }
  }, [isEditorMode]);

  useEffect(() => {
    const editor = editorRef.current;
    if(editor && isEditorMode){
      // Manejar el evento de copiado
      const handleCopy = (event: ClipboardEvent) => {
        event.preventDefault();
        event.clipboardData?.setData('text/html', editorStateValue.toString(format));
      };

      // Agregar los manejadores de eventos al documento
      editor.addEventListener('copy', handleCopy);

      // Limpiar los manejadores de eventos cuando el componente se desmonte
      return () => {
        editor.removeEventListener('copy', handleCopy);
      };
    }
  }, [editorStateValue]);

  const handleDescriptionLanguage = (
    event: React.MouseEvent<HTMLElement>,
    newDescriptionLan: string,
  ) => {
    if (!newDescriptionLan) {
      newDescriptionLan = i18n.language;
    }
    setDescriptionLan(newDescriptionLan);
    let descriptionContent = description.data[newDescriptionLan] === undefined ? '' : description.data[newDescriptionLan];
    if(/\r\n/.test(descriptionContent)){
      descriptionContent = descriptionContent.replace(/\r\n/g, '<br>');
    }
    setEditorStateValue(RichTextEditor.createValueFromString(descriptionContent, format));
    setLanWarning(false);
  };

  const correctLanguage = (text: string) => {
    const numWords = text ? text.split(' ').length : 0;
    if (editable && numWords > 10) {
      const lanResponse = lngDetector.detect(text);
      const language = lanResponse && lanResponse[0];
      if (language && !language.includes((DESCRIPTION_LAN_NAME as any)[descriptionLan])) {
        setLanWarning(true);
      } else {
        setLanWarning(false);
      }
    } else {
      setLanWarning(false);
    }
  };

  const saveData = () => {
    const text = editorStateValue.toString(format);
    if (text) {
      const textInput = text === '\n' ? '' : text;
      const change = { [descriptionLan]: textInput };
      setDescriptionData({ ...description, ...change });
      props.description.data[descriptionLan] = textInput;
    }
  };

  const countCharacters = (text: string) => {
    const lines = text.split('\n');
    let countCharacters = 0;
    lines.forEach((line) => {
      if (line.length === 0) {
        countCharacters += INFO_DESTACABLE_MAX_CHARS_PER_LINE;
      } else {
        countCharacters += Math.ceil(line.length / INFO_DESTACABLE_MAX_CHARS_PER_LINE) * INFO_DESTACABLE_MAX_CHARS_PER_LINE;
      }
    });
    return countCharacters;
  };

  const editDescription = (value: EditorValue) => {
    const currentText = value.getEditorState().getCurrentContent().getPlainText();
    if (maxLen && countCharacters(currentText) > maxLen) {
      setMaxLenError(prevState => ({...prevState , [descriptionLan]: true}));
    }else{
      setMaxLenError(prevState => ({...prevState , [descriptionLan]: false}));
    }
    setEditorStateValue(value);
    if (saveContext.saveDraft !== true) {
      saveContext.handleChangeSave(true);
    }
    correctLanguage(value.toString(format));
    if (error) {
      saveContext.forceUpdate();
    }
  };

  const toolbarConfig: ToolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['HISTORY_BUTTONS', 'BLOCK_ALIGNMENT_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'INLINE_STYLE_BUTTONS', 'LINK_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
      { label: 'Italic', style: 'ITALIC' },
      { label: 'Underline', style: 'UNDERLINE' }
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: 'Normal', style: 'unstyled' },
      { label: 'Heading Large', style: 'header-one' },
      { label: 'Heading Medium', style: 'header-two' },
      { label: 'Heading Small', style: 'header-three' }
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: 'UL', style: 'unordered-list-item' },
      { label: 'OL', style: 'ordered-list-item' }
    ],
    BLOCK_ALIGNMENT_BUTTONS: [
      { label: 'Align Left', style: 'ALIGN_LEFT' },
      { label: 'Align Center', style: 'ALIGN_CENTER' },
      { label: 'Align Right', style: 'ALIGN_RIGHT' },
      { label: 'Align Justify', style: 'ALIGN_JUSTIFY' },
    ]
  };

  const renderEditableDescription = () => {
    return <div ref={editorRef} className={`${styles.editor} ${!isEditorMode && styles.noEditable} ${lanWarning && styles.editorWarning}`}>
      <RichTextEditor
        key={description.title}
        placeholder={'-'}
        value={editorStateValue}
        readOnly={!isEditorMode}
        toolbarConfig={toolbarConfig}
        onBlur={saveData}
        onChange={editDescription}
        blockStyleFn={getTextAlignClassName}
        className={styles.descriptionEditorContainer}
        toolbarClassName={styles.descriptionEditorToobar}
        editorClassName={styles.descriptionEditorArea}
        editorStyle={{
          height: 180,
          fontSize: '14px',
          fontFamily: 'Roboto',
          color: isEditorMode ? '#111111' : '#7A7A7A',
          paddingTop: '15px',
          paddingLeft: '5px'
        }}
        rootStyle={(maxLenError[descriptionLan] || lanWarning) ? {borderBottom: '2px solid red'} : {}}
      />
      <div className={styles.warning} style={lanWarning ? { display: 'flex' } : { display: 'none' }}>
        <ErrorIcon />
        <span>{t('details.description.languageWarning')}</span>
      </div>
      <div className={styles.warning} style={maxLenError[descriptionLan] ? { display: 'flex' } : { display: 'none' }}>
        <ErrorIcon />
        <span>{t('details.description.lenWarning', {maxLen})}</span>
      </div>
    </div>;
  };

  return (
    <div className={styles.descriptionMultiLanContainer}>
      <div id='descriptionmultilan-header' className={styles.heading}>{t(`details.description.${description.title}`)}
        <InformationIcon message={titleMessage} />
        {
          error ? <div className={styles.errorContainer} ><ErrorIcon className={styles.errorIcon} /><div className={styles.errorText} >{error}</div></div> : null
        }
      </div>
      <div id='descriptionmultilan-content' className={styles.descriptionMultiLanContent}>
        <ToggleButtonGroup
          id='languajecontrol'
          className={styles.languageControl}
          value={descriptionLan}
          exclusive
          onChange={handleDescriptionLanguage}
        >
          {Object.values(DESCRIPTION_LAN).map((lan: string) => {
            return (
              <ToggleButton
                key={lan}
                value={lan}
                sx={{
                  '&.MuiToggleButton-root': {
                    background: '#FFF',
                    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                    '&.Mui-selected': {
                      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset'
                    }
                  }
                }}
              >
                {lan &&
                  <img src={(FLAG_COUNTRY_CODE as any)[lan.substring(0, 2)]} />
                }
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>

        <div className={styles.description}>
          {editable ?
            renderEditableDescription()
            : (modeView ?
              <Description
                id='descriptionmultilan-description'
                text={descriptionContent}
                maxRows={rows}
              /> :
              <TextField
                id='descriptionmultilan-description'
                variant='filled'
                multiline={true}
                value={descriptionContent}
                InputProps={{
                  readOnly: true,
                }}
                minRows={rows}
                maxRows={rows}
                sx={{
                  borderRadius: '8px 8px 0px 0px',
                  borderBottom: '2px solid #C0C0C0',
                  width: '100%',
                  overflowY: 'auto',
                  '& .MuiFilledInput-root': {
                    background: '#F4F4F4',
                    color: 'var(--gris-4, #7A7A7A)',
                    fontSize: '14px',
                    fontFamily: 'Roboto',
                    lineHeight: '15px',
                    '&:before': {
                      content: 'none'
                    },
                    '&:after': {
                      content: 'none'
                    },
                    '&.Mui-focused': {
                      background: '#F4F4F4',
                      color: 'var(--gris-4, #7A7A7A)',
                    }
                  }
                }}
              />
            )
          }

        </div>
      </div>
    </div >
  );
};

export default memo(DescriptionMultiLan);
