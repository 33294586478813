/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useContext, useEffect } from 'react';
import styles from './clients.module.scss';
import { ObraProperties } from '../../../types/ObraDetails';
import clientPicture from '../../../assets/client.svg';
import { useTranslation } from 'react-i18next';
import InformationIcon from '../components/informationIcon/informationIcon';
import ClientBox from './components/clientBox/clientBox';
import EditClients from './editClients/editClients';
import { UserModeContext } from '../../../context/UserModeProvider';
import ViewClients from './viewClients/viewClients';


interface ClientsProps {
  obra: ObraProperties
  errors?: any[]
}

const Clients: FC<ClientsProps> = (props) => {
  const { obra, errors } = props;
  const [t] = useTranslation();
  const userModeContext = useContext(UserModeContext);
  const isEditorMode = userModeContext.userMode === 'editor';


  // data for section client
  const clientInfo = [
    { title: t('details.clients.clientName'), value: (obra.cliente.nomCliente?.length > 0) ? obra.cliente.nomCliente : t('details.notInformed') },
    { title: t('details.clients.clientCode'), value: obra.cliente.codCliente ? obra.cliente.codCliente : t('details.notInformed') },
  ];

  return (
    <div
      data-testid="clients-test"
      className={styles.clientContainer}>

      <div id='client' className={styles.heading}>{t('details.clients.client')}
        <InformationIcon message={t('help.clients.client')} />
      </div>
      <div className={styles.box}>
        <div className={styles.boxContainerChild}>
          <ClientBox identifier='client' picture={clientPicture} items={clientInfo} />
        </div>
      </div>
      {isEditorMode ? <EditClients obra={obra} errors={errors}/> : <ViewClients obra={obra} />}
      <div className='footer' style={{marginBottom: '75px'}}></div>
    </div>);
};

export default Clients;
