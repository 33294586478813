import React, { useCallback, useState, type FC, useContext } from 'react';
import Header from '../../components/header/header';
import styles from './administrationPanel.module.scss';
import './administrationPanel.scss';
import DetailsViewProvider from '../../context/DetailsViewProvider';
import TabMenuAdmin from '../../components/tabMenuAdmin/tabMenuAdmin';
import UsersTable from './usersTable/usersTable';
import SimpleSelect from '../fullDetail/components/simpleSelect/simpleSelect';
import TitleValue from '../fullDetail/components/titleValue/titleValue';
import searchIcon from '../../assets/search.svg';
import debounce from 'lodash.debounce';
import ObrasAPI from '../../services/APIObras';
import ProjectAutocomplete from './components/projectAutocomplete/projectAutocomplete';
import { CenterParams, UsuarioProyecto } from '../../types/Admin';
import ProjectTable from './projectTable/projectTable';
import SearchUserDialog from './components/searchUserDialog/searchUserDialog';
import EditRoles from '../editRoles/editRoles';
import {AdminContext} from '../../context/AdminProvider';
import LoadingSpinner from '../../components/loadingSpinner/loadingSpinner';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AdministrationPanelProps { }

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const AdministrationPanel: FC<AdministrationPanelProps> = () => {
  const api = ObrasAPI.getInstance();
  const [t] = useTranslation();
  const [userInput, setUserInput] = useState('');
  const [projectInput, setProjectInput] = useState('');

  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [selectedProject, setSelectedProject] = useState<CenterParams | undefined>(undefined);
  const [usersPerProject, setUsersPerProject] = useState<UsuarioProyecto[]>([]);
  const adminContext = useContext(AdminContext);
  const searchMethod = adminContext.searchMethod;
  const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);
  const [loading, setLoading] = useState(false);

  const selectOptions = [
    { id: 0, nombre: t('administration.user') },
    { id: 1, nombre: t('administration.project') },
  ];

  // queries elasticsearch looking for all the work subtypes by its code or name
  const codeInputRequest = async (newValue: string) => {
    setProjectInput(newValue);
    if (newValue.length >= 3) {
      setLoadingAutocomplete(true);
      try {
        let results = await api.getProjectByCodeName(newValue);
        results = results.length ? results : [];
        setLoadingAutocomplete(false);
        return setAutocompleteOptions(results);
      } catch (error) {
        setLoadingAutocomplete(false);
        console.log(error);
      }
    }
    return setAutocompleteOptions([]);
  };
  // Calls the query to elasticsearch each time that the autoselect input changes
  const inputAutocompleteChangeHandler = useCallback(
    debounce(codeInputRequest, 0),
    [],
  );

  const handleChangeAutocomplete = async (project: CenterParams | undefined) => {
    setLoading(true);
    try {
      setSelectedProject(project);
      if (project) {
        let results = await api.getUsersByProject(project);
        results = results.length ? results : [];
        adminContext.handleChangeUsersPerProject(results);
        setLoading(false);
        return setUsersPerProject(results);
      } else {
        adminContext.handleChangeUsersPerProject([]);
        setLoading(false);
        return setUsersPerProject([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <DetailsViewProvider>
      {adminContext.isEdition ? 
        <EditRoles hasProject={adminContext.hasProject} />
        :
        <div data-testid="administration-test" className='administration'>
          <div>
            <Header>
              <TabMenuAdmin />
            </Header>
            <div className='adminPanel'>
              <div className='container'>
                <div className={styles.searchMethodContainer}>
                  <div className={styles.selectSearchMethod}>
                    <SimpleSelect
                      id='select-searchMethod'
                      options={selectOptions}
                      value={selectOptions[searchMethod]}
                      label={t('administration.searchBy')}
                      handleChangeValue={({id}) => {
                        adminContext.handleChangeSearchMethod(id);
                        setProjectInput('');
                        setSelectedProject(undefined);
                      }}
                      editable
                    />
                  </div>
                  {searchMethod === 0 &&
                    <div className={styles.inputSearchMethod}>
                      <TitleValue
                        identifier='input-searchMethod'
                        title={t('administration.user')}
                        value={undefined}
                        handleChange={(value) => setUserInput(value)}
                        editable
                      />
                      <div className={styles.searchIcon}>
                        <img src={searchIcon} />
                      </div>
                    </div>
                  }
                  {/* search by project*/}
                  {searchMethod === 1 &&
                    <>
                      <ProjectAutocomplete
                        options={autocompleteOptions}
                        onInputChange={inputAutocompleteChangeHandler}
                        onChange={handleChangeAutocomplete}
                        onClear={() => {
                          setProjectInput('');
                        }}
                        value={projectInput}
                        freeSolo={!projectInput || projectInput.length < 3 || loadingAutocomplete}
                      />
                      <div
                        className={selectedProject ? styles.addUser : styles.addUserDisabled}
                        onClick={() => {
                          if (selectedProject) {
                            setOpenDialog(true);
                            const projectToEdit = {
                              codEmpresa: '0008', 
                              codZona: selectedProject.codZona, 
                              codCentro: selectedProject.codCentro, 
                              codSupercentro: selectedProject.codSupercentro
                            };
                            adminContext.handleChangeProjectToAdd(projectToEdit);
                          }}}>
                        + {t('administration.addUser')}
                      </div>
                    </>
                  }
                </div>
                {searchMethod === 0 && <UsersTable userInput={userInput} />}
                {searchMethod === 1 &&
                <>
                  <ProjectTable /*users={usersPerProject}*/ selectedProject={selectedProject} />
                  <SearchUserDialog
                    open={openDialog}
                    handleOpen={(b: boolean) => setOpenDialog(b)}
                    users={usersPerProject}
                    selectedProject={selectedProject!}
                  />
                </>}
                {loading && <LoadingSpinner />}
              </div>
            </div>
          </div>
        </div>
      }
    </DetailsViewProvider>
  );
};

export default AdministrationPanel;


