import { TFunction } from 'i18next';
import * as yup from 'yup';

const DatesSchema = (t: TFunction<'translations'>) => yup.object({
  fecActaReplanteo: yup.string().required({ text: `${t('details.validation.tab')} ${t('details.dates.tab')}: ${t('details.validation.noData')} "${t('details.dates.reconsideration')}"`, orden: 3 }),
  fecInicio: yup.string().required({ text: `${t('details.validation.tab')} ${t('details.dates.tab')}: ${t('details.validation.noData')} "${t('details.dates.start')}"`, orden: 3 }),
  fecActaRecepcion: yup.string().test(
    'fechasPlazos.fecActaRecepcion',
    { text: `${t('details.validation.tab')} ${t('details.dates.tab')}: ${t('details.validation.noData')} "${t('details.dates.reception')}"`, optional: true },
    (value, context) => {
      return (context.parent.origen === 'cecoc' || !context.parent.alcance || context.parent.alcance.length === 0) || Boolean(context.parent.alcance.length > 0 && typeof value === 'string' && value.length);
    }
  ).nullable(),
}).required();

export const DatesSchemaFinal = (t: TFunction<'translations'>) => yup.object({
  fecActaRecepcion: yup.string().required({ text: `${t('details.validation.tab')} ${t('details.dates.tab')}: ${t('details.validation.noData')} "${t('details.dates.reception')}"`, tab: 'fechasPlazos', field: 'fecActaRecepcion', orden: 3 }),
  fecFinEjecucion: yup.string().required({ text: `${t('details.validation.tab')} ${t('details.dates.tab')}: ${t('details.validation.noData')} "${t('details.dates.execFinish')}"`, tab: 'fechasPlazos', field: 'fecFinEjecucion', orden: 3 }),
}).required();

export default DatesSchema;
