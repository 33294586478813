/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC } from 'react';
import styles from './titleHelp.module.scss';
import InformationIcon from '../informationIcon/informationIcon';
import AddItemButton from '../addItemButton/addItemButton';
import ErrorIcon from '@mui/icons-material/Error';

interface TitleHelpProps {
  id?: string;
  value: string;
  help: string;
  addItemText?: string;
  error?: string | null;
  handleAddItem?: () => any;
}

const TitleHelp: FC<TitleHelpProps> = (props) => {
  const { id, value, help, addItemText, handleAddItem, error } = props;

  return (
    <div className={styles.editionHeading}>
      <div id={id} className={styles.heading}>
        {value}
        <InformationIcon message={help} />
        {
          error ? <div className={styles.errorContainer} ><ErrorIcon className={styles.errorIcon} /><div className={styles.errorText} >{error}</div></div> : null
        }
      </div>
      {addItemText && handleAddItem &&
        <AddItemButton id={`${id}-addBtn`} handleAddItem={handleAddItem} addItemText={addItemText} />
      }
    </div>
  );
};

export default TitleHelp;
