/* eslint-disable linebreak-style */
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import './typeObra.scss';
import { Grid, List, ListItem, Typography } from '@mui/material';
import parse from 'html-react-parser';
import InformationIcon from '../../../../components/informationIcon/informationIcon';
import WorkType from '../../workType/workType';
import { AuthContext } from '../../../../../../services/authContextProvider';
import { UserModeContext } from '../../../../../../context/UserModeProvider';
import coffeeBreak from '../../../../../../assets/coffeeBreak.svg';

const sxTitle = {
  fontFamily: 'Bitter',
  fontSize: '20px',
  fontWeight: 700,
  mb: 2,
  ml: 1.5,
};

interface Props {
  originCecoc: Record<string, any>[];
  originPhia: Record<string, any>[];
}

const TypeObraSection = ({ originCecoc, originPhia }: Props) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const userModeContext = useContext(UserModeContext);

  return (
    <Grid item container spacing={2} style={{ marginTop: '-24px' }}>
      <List
        sx={{ width: '100%' }}
        subheader={
          <Typography id="worktype" sx={sxTitle} mt={1}>
            {t('details.generalData.workTypes')}
            <InformationIcon message={t('help.general.workTypes')} />
          </Typography>
        }
      >
        {originCecoc.map(({ tipo, subtipo }) => {
          return (
            <ListItem key={`item-${tipo!.codTipo}`} >
              <WorkType
                key={tipo!.codTipo}
                typeCode={tipo!.codTipo}
                code={subtipo ? subtipo.codSubtipo : tipo!.codTipo}
                name={subtipo ? subtipo.nomSubtipo : tipo!.nomTipo}
              />
            </ListItem>
          );
        })}
        {originPhia.length > 0
          ? originPhia.map(({ tipo, subtipo }) => {
            return subtipo ? (
              <ListItem key={`item-${subtipo!.codSubtipo}`} >
                <WorkType
                  key={subtipo!.codSubtipo}
                  typeCode={tipo?.codTipo || ''}
                  code={subtipo!.codSubtipo || ''}
                  name={subtipo!.nomSubtipo || ''}
                />
              </ListItem>
            ) : null;
          })
          : authContext.hasEditorRole() && (
            <div className="coffeeBreak">
              {userModeContext.userMode !== 'editorDisabled' && (
                <img src={coffeeBreak} />
              )}
              {userModeContext.userMode === 'viewer' && (
                <p>{parse(t('details.generalData.changeTabAndUser'))}</p>
              )}
              {userModeContext.userMode === 'editor' && (
                <p>{parse(t('details.generalData.changeTab'))}</p>
              )}
            </div>
          )}
      </List>
    </Grid>
  );
};

export default TypeObraSection;
