import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import styles from './speciesAndZonesComponent.module.scss';
import { ObraProperties } from '../../../../../../types/ObraDetails';
import { SelectOption } from '../../../../../../types/Option';
import extinctionDanger from '../../../../../../assets/extinctionDanger.svg';
import culturalProtection from '../../../../../../assets/culturalProtection.svg';
import parse from 'html-react-parser';
import { EDITOR } from '../../../../../../userRoles/userRoles';
import { SaveContext } from '../../../../../../context/SaveProvider';
import { UserModeContext } from '../../../../../../context/UserModeProvider';
import { t } from 'i18next';
import { AuthContext } from '../../../../../../services/authContextProvider';
import TitleHelp from '../../../../components/titleHelp/titleHelp';
import ObrasAPI from '../../../../../../services/APIObras';
import TitleValue from '../../../../components/titleValue/titleValue';
import DeleteButton from '../../../../components/deleteButton/deleteButton';
import SimpleSelect from '../../../../components/simpleSelect/simpleSelect';
import { DANGER_COLORS } from '../../../dangerLevelColors';


interface SpeciesAndZonesComponentProps {
  obra: ObraProperties;
  data: any;
  scrollDown: any;
  type: 'species' | 'zones';
  errors?: any[];
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const SpeciesAndZonesComponent: FC<SpeciesAndZonesComponentProps> = (props) => {
  const { obra, data, type, scrollDown, errors } = props;
  const authContext = useContext(AuthContext);
  const saveContext = useContext(SaveContext);
  const userModeContext = useContext(UserModeContext);
  const [dataState, setDataState] = useState(data);
  const [options, setOptions] = useState<any>([]);

  const NO_RESULTS = [{ nombre: `${t('input.noResults')}`, id: 0 }];
  const TYPE_SPECIES = type === 'species';

  useEffect(() => {
    const api = ObrasAPI.getInstance();
    const apiCall = TYPE_SPECIES ? api.getDangerLevels() : api.getProtectionZones();
    apiCall.then(
      (results: SelectOption[]) => {
        results = results.length ? results : NO_RESULTS;
        const sortedResults = results.sort((a, b) => (a.id < b.id ? 1 : a.id > b.id ? -1 : 0));
        setOptions(sortedResults);
      }
    ).catch((error) => {
      console.log(error);
    });
  }, []);

  useEffect(() => {
    setDataState(data);
  }, [data]);


  const handleAddItem = () => {
    if (TYPE_SPECIES) {
      const newSpecies = { id: undefined, nivel: { id: 0, nombre: '' }, nombre: '' };
      setDataState([...dataState, newSpecies]);
      if (obra.localizacion?.especiesPeligro)
        obra.localizacion.especiesPeligro.push(newSpecies);
      else
        obra.localizacion.especiesPeligro = [newSpecies];
    } else {
      const newZone = { id: undefined, proteccion: { id: 0, nombre: '' }, nombre: '' };
      setDataState([...dataState, newZone]);
      if (obra.localizacion?.zonasProteccion)
        obra.localizacion.zonasProteccion.push(newZone);
      else
        obra.localizacion.zonasProteccion = [newZone];
    }
    saveContext.handleChangeSave(true);
    scrollDown();
  };

  return (
    <div className={styles.editExtinctionSpecies}>
      <TitleHelp
        id={`${TYPE_SPECIES ? 'extinctionspecies' : 'protectionzones'}`}
        value={t(`details.location.${TYPE_SPECIES ? 'extinctionSpecies' : 'protectionZones'}`)}
        help={t(`help.localization.${TYPE_SPECIES ? 'extinctionSpecies' : 'protectedZones'}`)}
        addItemText={t(`details.location.${TYPE_SPECIES ? 'addSpecies' : 'addZone'}`) as string}
        handleAddItem={handleAddItem}
      />
      <div className={styles.extinctionSpeciesProtectionZonesContainer}>
        {dataState.length > 0 ?
          <div>
            {dataState.map((e: any, i: number) => {
              const handleChangeName = (s: string) => {
                if (TYPE_SPECIES) obra.localizacion.especiesPeligro[i].nombre = s;
                else obra.localizacion.zonasProteccion[i].nombre = s;
                saveContext.handleChangeSave(true);
              };

              const handleChangeValue = (o: SelectOption) => {
                //const { id, nombre } = options.filter((opt: any) => opt.id === s)[0];
                if (TYPE_SPECIES) obra.localizacion.especiesPeligro[i].nivel = o;
                else obra.localizacion.zonasProteccion[i].proteccion = o;
                saveContext.handleChangeSave(true);
              };

              const handleDeleteItem = () => {
                if (TYPE_SPECIES) {
                  obra.localizacion.especiesPeligro.splice(i, 1);
                  setDataState([...obra.localizacion.especiesPeligro]);
                } else {
                  obra.localizacion.zonasProteccion.splice(i, 1);
                  setDataState([...obra.localizacion.zonasProteccion]);
                }
                saveContext.handleChangeSave(true);
              };

              return (
                <div key={i.toString()} className={styles.row}>
                  <div className={styles.column1}>
                    <TitleValue
                      identifier={`${TYPE_SPECIES ? 'extinctionSpeciesTable' : 'protectionZoneTable'}-title${i}`}
                      title={t('details.location.name')}
                      error={  errors && errors.length > 0 ? TYPE_SPECIES ? !obra.localizacion.especiesPeligro[i].nombre : !obra.localizacion.zonasProteccion[i]?.nombre : false}
                      value={
                        TYPE_SPECIES ?
                          obra.localizacion.especiesPeligro[i]?.nombre :
                          obra.localizacion.zonasProteccion[i]?.nombre
                      }
                      editable={true}
                      handleChange={handleChangeName}
                    />
                  </div>
                  <div className={styles.column2}>
                    <SimpleSelect
                      id={`${TYPE_SPECIES ? 'extinctionSpeciesTable' : 'protectionZoneTable'}-${i}`}
                      options={options}
                      error={  errors && errors.length > 0 ? TYPE_SPECIES ? !obra.localizacion.especiesPeligro[i].nivel?.nombre : !obra.localizacion.zonasProteccion[i]?.proteccion?.nombre : false}
                      value={
                        TYPE_SPECIES ?
                          obra.localizacion.especiesPeligro[i]?.nivel :
                          obra.localizacion.zonasProteccion[i]?.proteccion
                      }
                      label={
                        TYPE_SPECIES ? t('details.location.dangerLevel') : t('details.location.protection')
                      }
                      handleChangeValue={handleChangeValue}
                      colorSchema={TYPE_SPECIES && DANGER_COLORS}
                      editable={true} />
                  </div>
                  <DeleteButton id={`deleteBtn${i}`} onClick={handleDeleteItem} />
                </div>
              );
            })}
          </div>
          :
          <div id={`${TYPE_SPECIES ? 'extinctionDanger' : 'protection'}-nodata`} className={styles.noDataPic}>
            <img src={`${TYPE_SPECIES ? extinctionDanger : culturalProtection}`} />
            {authContext.hasEditorRole() ?
              <>
                {userModeContext.userMode === 'viewer' &&
                  <p>{parse(t(`details.location.${TYPE_SPECIES
                    ? 'changeUserAddExtinctionSpecies'
                    : 'changeUserAddCulturalProtection'}`))}</p>}
                {userModeContext.userMode === 'editor' &&
                  <p>{parse(t(`details.location.${TYPE_SPECIES
                    ? 'addExtinctionSpecies'
                    : 'addCulturalProtection'}`))}</p>}
              </>
              :
              <p>{t('details.location.noData')}</p>
            }
          </div>
        }
      </div>
    </div>
  );
};

export default SpeciesAndZonesComponent;