import axios from 'axios';
import { refreshToken } from './authContextProvider';

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_HOST,
});

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('tokenPhia');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
api.interceptors.response.use(
  (response) => response,
  async (err) => {
    const originalRequest = err.config;
    let error = err;
    // If the error response is a Blob, we need to parse it to JSON. Case export to excel
    if (err.response.data instanceof Blob) {
      error = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const result = JSON.parse(reader.result as string);
            resolve({response: {status: result.statusCode, data: result}});
          } catch (e) {
            resolve(reader.result);
          }
        };
        reader.onerror = reject;
        reader.readAsText(err.response.data);
      });
    }
    // If the error status is 401 and there is no originalRequest._retry flag,
    // it means the token has expired and we need to refresh it
    if (error.response?.status === 401 && error.response?.data?.message === 'Token Expired' && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        await refreshToken();
        // Retry the original request with the new token
        originalRequest.headers.Authorization = `Bearer ${localStorage.getItem('tokenPhia')}`;
        return axios(originalRequest);
      } catch (err) {
        // Handle refresh token error or redirect to login
        console.log(err);
        handleSession(error);
      }
    } else if (!originalRequest.url.startsWith('/v1/admin')) {
      console.log(error);
      handleSession(error);
    }

    return Promise.reject(error);
  }
);

/**
 * Go to login if the token has expired or go to error permisos if the user has not permission
 * @param error 
 */
function handleSession(error: any): void {
  const errorsToReset = ['Token Expired', 'jwt malformed', 'invalid signature', 'jwt must be provided'];
  if (error?.response?.data?.statusCode === 401 && errorsToReset.includes(error?.response?.data?.message)) {
    localStorage.removeItem('tokenPhia');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('username');
    localStorage.removeItem('general_groups');
    localStorage.removeItem('particular_groups');
    window.location.href = '/';
  } else if (error?.response?.data?.statusCode === 403) {
    window.location.href = '/error-permisos';
  }
}

export default api;
