/* eslint-disable linebreak-style */
import { Grid, Typography } from '@mui/material';
import styles from '../budget.module.scss';
import InformationIcon from '../../components/informationIcon/informationIcon';
import InfoBox from '../../components/infoBox/infoBox';
import SwitchInfoBox from '../../components/switchInfoBox/switchInfoBox';

type infoSwitch = {
    id: string;
    item: {
        title: string;
        value: string;
    };
    switchItem: string;
    switchData: boolean;
}

type infoBox = {
  id: string;
  item: {
      title: string;
      value: string;
  };
}

interface Props {
    id: string;
    idTitle: string;
    title: string;
    infoMsg: string;
    date: infoBox;
    amount: infoSwitch;
    accionaAmount: infoSwitch;
    currencyExchange: infoBox;
}

const SectionComponent = ({id, idTitle, title, infoMsg, date, amount, accionaAmount, currencyExchange}: Props) =>
  (<Grid id={id} item container spacing={3} direction="column" xs mt={3}>
    <Grid item xs>
      <Typography id={idTitle} className={styles.heading}>
        {title}
        <InformationIcon message={infoMsg} />
      </Typography>
    </Grid>
    <Grid item container justifyContent="space-between" xs>
      <Grid item xs={2.8} display="flex" flexDirection="column">
        <InfoBox
          identifier={date.id}
          item={date.item}
          overwriteStyle={{ marginBottom: 'auto', height: 'auto', flex: 1 }}
        />
      </Grid>
      <Grid item xs={2.8} display="flex" flexDirection="column">
        <SwitchInfoBox
          id={amount.id}
          item={amount.item}
          switchItem={amount.switchItem}
          switchData={amount.switchData}
        />
      </Grid>
      <Grid item xs={2.8} display="flex" flexDirection="column">
        <SwitchInfoBox
          id={accionaAmount.id}
          item={accionaAmount.item}
          switchItem={accionaAmount.switchItem}
          switchData={accionaAmount.switchData}
        />
      </Grid>
      <Grid item xs={2.8} display="flex" flexDirection="column">
        <InfoBox
          identifier={currencyExchange.id}
          item={currencyExchange.item}
          overwriteStyle={{ marginBottom: 'auto', height: 'auto', flex: 1 }}
        />
      </Grid>
    </Grid>
  </Grid>
  );

export default SectionComponent;
