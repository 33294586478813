import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './socialSafeguards.module.scss';
import { ObraProperties } from '../../../../types/ObraDetails';
import TitleHelp from '../../components/titleHelp/titleHelp';
import InfoBoxBoolean from '../../components/InfoBoxBoolean/InfoBoxBoolean';
import InfoBox from '../../components/infoBox/infoBox';
import { ReactComponent as checkFalse } from '../../../assets/check_false_disable.svg';

interface SocialSafeguardsProps {
  obra: ObraProperties,
}

const SocialSafeguards: FC<SocialSafeguardsProps> = (props) => {
  const { obra } = props;
  const { t, i18n } = useTranslation();

  const colorsByCode = (): string => {
    return '#FFBFBF';
  };

  const saveGuards = obra.esg.salvaguardasSociales || [];

  const style = { '--label-color': `${colorsByCode()}` } as React.CSSProperties;

  return (
    <div data-testid='esg-test' id='esg-nodata' className={styles.container}>
      <TitleHelp
        id='socialSafeguards'
        value={t('details.esg.socialSafeguards.helper')}
        help={t('help.esg.socialSafeguards')}
      />
      {
        saveGuards.length === 0 || !Array.isArray(saveGuards) ?
          <div className={styles.socialContainer}>
            <div id='socialSafeguards-typecode' className={styles.typeCode} style={style}></div>
            <div id='socialSafeguards-code' className={styles.code}>1</div>
            <div id='socialSafeguards-name' className={styles.name}>{t('details.notInformed')}</div>
          </div>
          :
          saveGuards.map((ss, i) => {
            return (
              <div key={i} className={styles.socialContainer}>
                <div id='socialSafeguards-typecode' className={styles.typeCode} style={style}></div>
                <div id='socialSafeguards-code' className={styles.code}>ss.codigo</div>
                <div id='socialSafeguards-name' className={styles.name}>ss.nombre</div>
              </div>
            );
          })
      }
    </div>
  );
};


export default SocialSafeguards;
