import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ObraProperties } from '../../../types/ObraDetails';
import TitleValue from '../components/titleValue/titleValue';
import styles from './dates.module.scss';
import { UserModeContext } from '../../../context/UserModeProvider';
import { SaveContext } from '../../../context/SaveProvider';
import moment from 'moment';
import InformationIcon from '../components/informationIcon/informationIcon';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DatesProps {
  obra: ObraProperties;
  errors?: any[];
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const Dates: FC<DatesProps> = (props) => {
  const { obra, errors } = props;
  const [t] = useTranslation();
  const userModeContext = useContext(UserModeContext);
  const isEditorMode = userModeContext.userMode === 'editor';
  const saveContext = useContext(SaveContext);

  const finishTime = obra.fechasPlazos.fecFinalizacionActualizada ? moment(obra.fechasPlazos.fecFinalizacionActualizada, 'DD/MM/YYYY') : moment(obra.fechasPlazos.fecFinalizacionInicial, 'DD/MM/YYYY');
  const isOnTime = moment().isAfter(finishTime.subtract(30, 'days'));
  const plazoInicial = obra.fechasPlazos.plazoInicial ? `${obra.fechasPlazos.plazoInicial} ${obra.fechasPlazos.tipoPlazoInicial}` : t('details.notInformed');

  const handleChange = (field: string, value: string) => {
    saveContext.handleChangeSave(true);
    if (field === 'fecActaReplanteo') {
      obra.fechasPlazos.fecActaReplanteo = value;
    }
    if (field === 'fecInicio') {
      obra.fechasPlazos.fecInicio = value;
    }
    if (field === 'fecActaRecepcion') {
      obra.fechasPlazos.fecActaRecepcion = value;
    }
    if (field === 'fecFinEjecucion') {
      obra.fechasPlazos.fecFinEjecucion = value;
    }
  };

  const isFieldError = (field: string) => {
    return !!(errors && errors.find((e) => e.field === field));
  };

  return (

    <div data-testid="dates-test">
      {
        <div className={styles.datesContainer}>
          <div id='dates-start' className={styles.heading}>{t('details.dates.start')}
            <InformationIcon message={t('help.dates.start')} />
          </div>
          <div className={styles.datesStart}>
            <TitleValue identifier='dates-award' title={t('details.dates.award')} value={obra.presupuesto.fecAdjudicacion || t('details.notInformed')} viewStyle={{padding: '0 16px'}}/>
            <TitleValue identifier='dates-contractSignature' title={t('details.dates.contractSignature')} value={obra.fechasPlazos.fecFirmaContrato || t('details.notInformed')} viewStyle={{padding: '0 16px'}}/>
            <TitleValue type='date' handleChange={(data) => handleChange('fecActaReplanteo', data)} error={isFieldError('fecActaReplanteo')} minDate={null} maxDate={null} editable={isEditorMode} identifier='dates-reconsideration' title={t('details.dates.reconsideration')} value={obra.fechasPlazos.fecActaReplanteo || '--'} viewStyle={{padding: '0 16px'}}/>
            <TitleValue type='date' handleChange={(data) => handleChange('fecInicio', data)} error={isFieldError('fecInicio')} minDate={null} maxDate={null} editable={isEditorMode} identifier='dates-start' title={t('details.dates.start')} value={obra.fechasPlazos.fecInicio || '--'} viewStyle={{padding: '0 16px'}}/>
          </div>
          <div id='dates-deadlines' className={styles.heading}>{t('details.dates.deadlines')}
            <InformationIcon message={t('help.dates.deadlines')} />
          </div>
          <div className={styles.datesDeadlines}>
            <TitleValue identifier='dates-initialDeadline' title={t('details.dates.initialDeadline')} value={plazoInicial} viewStyle={{padding: '0 16px'}}/>
            <TitleValue identifier='dates-updatedDeadline' title={t('details.dates.updatedDeadline')} value={obra.fechasPlazos.plazoActualizado || t('details.notInformed')} viewStyle={{padding: '0 16px'}}/>
            <div className={styles.space} />
            <div className={styles.space} />
          </div>
          <div id='dates-finish' className={styles.heading}>{t('details.dates.finish')}
            <InformationIcon message={t('help.dates.finish')} />
          </div>
          <div className={styles.datesFinish}>
            <TitleValue identifier='dates-initialFinish' title={t('details.dates.initialFinish')} value={obra.fechasPlazos.fecFinalizacionInicial || t('details.notInformed')} viewStyle={{padding: '0 16px'}}/>
            <TitleValue identifier='dates-updatedFinish' title={t('details.dates.updatedFinish')} value={obra.fechasPlazos.fecFinalizacionActualizada || t('details.notInformed')} viewStyle={{padding: '0 16px'}}/>
            <TitleValue type='date' handleChange={(data) => handleChange('fecActaRecepcion', data)} minDate={null} maxDate={null} editable={isEditorMode && isOnTime} identifier='dates-reception' title={t('details.dates.reception')} value={obra.fechasPlazos.fecActaRecepcion || '--'} error={isFieldError('fecActaRecepcion')} viewStyle={{padding: '0 16px'}}/>
            <TitleValue type='date' handleChange={(data) => handleChange('fecFinEjecucion', data)} minDate={null} maxDate={null} editable={isEditorMode && isOnTime} identifier='dates-execFinish' title={t('details.dates.execFinish')} value={obra.fechasPlazos.fecFinEjecucion || '--'} error={isFieldError('fecFinEjecucion')} viewStyle={{padding: '0 16px'}}/>
          </div>
        </div>
      }
      <div className='footer' style={{marginBottom: '75px'}}></div>
    </div>
  );
};

export default Dates;
