import { TFunction } from 'i18next';
import * as yup from 'yup';

const tecnicalAspectSchema = (t: TFunction<'translations'>, hasProcessWithUnits: boolean) => {
  const genericText = `${t('details.validation.tab')} ${t('details.technical.tab')}: ${t('details.validation.noData')}`;
  return yup.object({
  
    tiposObras: yup.array().min(1, { text: `${genericText} "${t('details.technical.workTypesMagnitudes')}"`, orden: 5 }).of(
      yup.object({
        subtipo: yup.object({
          codSubtipo: yup.string().required({ text: `${genericText} "${t('details.technical.workTypesMagnitudes')} - ${t('details.technical.code')}"`, orden: 5 }),
          nomSubtipo: yup.string().required({ text: `${genericText} "${t('details.technical.workTypesMagnitudes')} - ${t('details.technical.name')}"`, orden: 5 }),
        }),
        magnitudes: yup.array().of(
          yup.object({
            medicion: yup.number().required({ text: `${genericText} "${t('details.technical.workTypesMagnitudes')} - ${t('details.technical.measuring')}"`, orden: 5 }),
          }).required({ text: `${genericText} "${t('details.technical.workTypesMagnitudes')} - ${t('details.technical.measuring')}"`, orden: 5 }),
        ).required({ text: `${genericText} "${t('details.technical.workTypesMagnitudes')} - ${t('details.technical.measuring')}"`, orden: 5 }),
      })
    ).required(),
    procesosConstructivos: yup.array().min(1, { text: `${genericText} "${t('details.technical.buildingProcesses')}"`, orden: 5 }).of(
      yup.object({
        codigo: yup.string().required({ text: `${genericText} "${t('details.technical.buildingProcesses')} - ${t('details.technical.buildingProcess.code')}"`, orden: 5 }),
        capitulo: yup.object().required({ text: `${genericText} "${t('details.technical.buildingProcesses')} - ${t('details.technical.buildingProcess.chapter')}"`, orden: 5 }),
        nombre: yup.string().required({ text: `${genericText} "${t('details.technical.buildingProcesses')} - ${t('details.technical.buildingProcess.group')}"`, orden: 5 }),
        unidades: yup.array().min(0).of(
          yup.object({
            unidad: yup.object({
              codUnidad: yup.string().required({ text: `${genericText} "${t('details.technical.workUnits')} - ${t('details.technical.code')}"`, orden: 5 }),
              nomUnidad: yup.string().required({ text: `${genericText} "${t('details.technical.workUnits')} - ${t('details.technical.name')}"`, orden: 5 }),
            }),
            medicion: yup.string().required({ text: `${genericText} "${t('details.technical.workUnits')} - ${t('details.technical.magnitude.measuring')}"`, orden: 5 }),
          })
        ).required(),
      })
    ).required(),
    unidades: yup.array().min(0, { text: `${genericText} "${t('details.technical.workUnits')}"`, optional: hasProcessWithUnits }).of(
      yup.object({
        unidad: yup.object({
          codUnidad: yup.string().required({ text: `${genericText} "${t('details.technical.workUnits')} - ${t('details.technical.code')}"`, orden: 5 }),
          nomUnidad: yup.string().required({ text: `${genericText} "${t('details.technical.workUnits')} - ${t('details.technical.name')}"`, orden: 5 }),
        }),
        medicion: yup.string().required({ text: `${genericText} "${t('details.technical.workUnits')} - ${t('details.technical.magnitude.measuring')}"`, orden: 5 }),
      })
    ).required(),
  }).required();
};

export default tecnicalAspectSchema;
