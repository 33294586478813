import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Magnitud, TipoObra } from '../../../../../types/ObraDetails';
import TitleValue from '../../../components/titleValue/titleValue';
import AutocompleteFields from '../../../components/autocompleteFields/autocompleteFields';
import styles from './editWorkType.module.scss';
import ObrasAPI from '../../../../../services/APIObras';
import { debounce } from '@mui/material';
import { SaveContext } from '../../../../../context/SaveProvider';
import DeleteButton from '../../../components/deleteButton/deleteButton';
import SearchAutocomplete from '../../../components/searchAutocomplete/searchAutocomplete';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface EditWorkTypeProps {
  key: number;
  workType: TipoObra;
  handleChange?: (id: number, code: string, name: string) => void;
  handleDeleteItem?: () => void;
  error?: any;
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const EditWorkType: FC<EditWorkTypeProps> = (props) => {
  const { key, workType, handleChange, handleDeleteItem, error } = props;
  const [t] = useTranslation();
  const [optionsWorkTypes, setOptionsWorkTypes] = useState<any>([]);
  const [magnitudes, setMagnitudes] = useState<any>(workType.magnitudes || []);
  const saveContext = useContext(SaveContext);
  const api = ObrasAPI.getInstance();

  const getWorkTypes = async () => {
    return await api.getWorkTypes();
  };

  useEffect(() => {
    const promise = getWorkTypes();
    promise.then(results => setOptionsWorkTypes(results)).catch((error) => console.log(error));
  }, []);

  // Gets all the magnitudes from a subtype given its code.
  const setMagnitudesBySubtypeCode = async (code: string) => {
    try {
      const api = ObrasAPI.getInstance();
      let results = await api.getMagnitudes(code);
      results = results.length ? results : [];
      const res: Magnitud[] = results.map((r: any) => {
        return {
          idMagnitudMaestro: r.id,
          nomMagnitud: r.name,
          medicion: undefined,
        };
      });
      workType.magnitudes = res;
      return setMagnitudes(res);

    } catch (error) {
      console.log(error);
    }
  };

  // queries elasticsearch looking for all the work subtypes by its code or name
  const codeInputRequest = async (newValue: string, type: string, length: number) => {
    if (newValue.length >= length) {
      try {
        const api = ObrasAPI.getInstance();
        let results = type === 'code'
          ? await api.getAutocompleteWorkSubtypesByCode(newValue)
          : await api.getAutocompleteWorkSubtypesByName(newValue);
        results = results.length ? results : [];
        return setOptionsWorkTypes(results);
      } catch (error) {
        console.log(error);
      }
    }
    return setOptionsWorkTypes([]);
  };

  // Calls the query to elasticsearch each time that the autoselect input changes
  const inputChangeHandler = useCallback(
    debounce(codeInputRequest, 0),
    [],
  );

  // When autocomplete inputs change, sets new magnitudes and calls external handleChange to modify subtype data
  const handleChangeAutocomplete = (id: number, code: string, name: string) => {
    if (handleChange) {
      setMagnitudesBySubtypeCode(code);
      handleChange(id, code, name);
    }
  };

  const handleDelete = () => {
    if (handleDeleteItem) {
      handleDeleteItem();
      setMagnitudes(workType.magnitudes);
    }
  };

  const getFormattedMedicion = (value?: any) => {
    if(value){
      let medicion;
      const parts = value.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      if(parts.length == 2){
        medicion = parts[0] + ',' + parts[1];
      }
      else{
        medicion = parts[0];
      }
      return medicion;
    } else if (value === 0) {
      return 0;
    }
    return null;
  };

  const ORIGIN_CECOC = workType.origen === 'cecoc';
  return (
    <tr key={key} className={styles.worktypesTableRow}>
      <td>
        <div className={styles.workTypes}>
          <AutocompleteFields
            id={`workSubtypeAutoselect${key}`}
            codeValue={ORIGIN_CECOC && !workType.subtipo ? workType.tipo?.codTipo : workType.subtipo?.codSubtipo}
            nameValue={ORIGIN_CECOC && !workType.subtipo ? workType.tipo?.nomTipo : workType.subtipo?.nomSubtipo}
            options={optionsWorkTypes}
            errors={error}
            inputChangeHandler={inputChangeHandler}
            handleChange={handleChangeAutocomplete}
            editable={!ORIGIN_CECOC}
          />
        </div>
      </td>
      <td>
        <div>
          { // loops all magnitudes of a worksubtype
            workType.magnitudes?.map((m: Magnitud, j: number) => {

              const handleChangeMeasuring = (newValue: string ) => {
                if (workType.magnitudes) {
                  const formattedNewValue: number | undefined = newValue.trim() === '' ? undefined : parseFloat(newValue.replace(/\./g, '').replace(',', '.'));
                  workType.magnitudes[j].medicion = formattedNewValue;
                  saveContext.handleChangeSave(true);
                }
              };

              return (
                <div key={j} className={styles.magnitudes}>
                  <div className={styles.titleValue3}>
                    <TitleValue
                      identifier={`magnitude${key}${j}-name`}
                      title={t('details.technical.magnitude.name')}
                      value={m.nomMagnitud}
                      contrast
                    />
                  </div>
                  <div className={styles.titleValue4}>
                    <TitleValue
                      identifier={`magnitude${key}${j}-measuring`}
                      title={`${t('details.technical.magnitude.measuring')} ${j+1}`}
                      value={m.medicion || m.medicion === 0 ? getFormattedMedicion(m.medicion.toString()) : ''}
                      editable
                      handleChange={handleChangeMeasuring}
                      isDecimalNumber
                      error={error && error.length > 0 && !m.medicion }
                      contrast
                    />
                  </div>
                </div>
              );
            }) }
        </div>
      </td>
      {!ORIGIN_CECOC && <td className={styles.trashBtn}>
        <div className={styles.trashBtn}>
          <DeleteButton id={`deleteBtn-${key}`} onClick={() => handleDelete()}/>
        </div>
      </td>}
    </tr>
  );
};

export default EditWorkType;
