/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import { withTranslation } from 'react-i18next';
import { Mode } from '../../types/FiltersTypes';

import { TFunction } from 'i18next';
import { MenuItem, Select } from '@mui/material';

import styles from './checkboxSelector.module.scss';

import { v4 as uuidv4 } from 'uuid';

import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';
import CustomCheckbox from './customCheckbox/customCheckbox';
import { Option } from '../../types/Option';

interface CheckboxSelectorProps {
  filterType: string;
  filterContext: any;
  options: Option[];
  disabled?: boolean;
  selectAllOption?: boolean;
  placeholder?: string;
  groupCode?: string;
  t: TFunction<'translations'>;
}

const CheckboxSelector = (props: CheckboxSelectorProps) => {
  const { t, filterType, filterContext, options, disabled, selectAllOption, placeholder, groupCode = '0' } = props;

  const handleChangeFilters = (option: Option): void => {
    const { code, name, codigos } = option;
    const dblSelection = filterContext.filters[filterType] && filterContext.filters[filterType].find((filter: Option) => filter.code === code);
    const modeFilter = dblSelection ? Mode.DELETE : Mode.ADD;
    filterContext.handleChange(filterType, { code, name, ...(codigos && {codigos}) }, modeFilter, groupCode);
  };


  const filters = filterContext?.filters;
  const filtersLength = filters[filterType]?.length;

  const subtypesPerGroup = filterContext?.subtypesPerGroup;
  const subtypesPerGroupLength = subtypesPerGroup[groupCode]?.length;

  const allOptionsSelected = groupCode
    ? subtypesPerGroupLength === options.length
    : filtersLength === options.length;

  return (
    <div data-testid="checkboxSelector-test">
      <div className={styles.selectorContainer}>
        <Select
          className={styles.selectorGroupAndType}
          value={[-1]}
          disabled={disabled}
          multiple
          MenuProps={
            {
              PaperProps:
              {
                sx:
                {
                  maxHeight: '50vh',
                  width: 241,
                  '.MuiMenuItem-root:hover': {
                    '& .MuiSvgIcon-root': { color: '#FF0000' },
                  },
                },
              }
            }
          }
          sx={{
            backgroundColor: disabled ? '#f7f8fa' : '#FFFFFF',
            height: '45px',
            '&.MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                border: '1px solid #7A7A7A',
                color: 'rgba(122, 122, 122, 1)'
              },
            },
          }}
        >
          <option value='-1' disabled hidden>{placeholder}</option>
          {selectAllOption && <MenuItem className={styles.item} value='all'
            onClick={() => {
              filterContext.handleBulkChange(filterType, options, !allOptionsSelected ? Mode.ADD : Mode.DELETE, groupCode);

            }}
            sx={{
              paddingLeft: '0.5rem',
              paddingRight: '1rem',
            }}
          >
            <div id='checkbox-item' className={'menu-item'}>
              <CustomCheckbox
                checked={
                  allOptionsSelected
                }
                indeterminate={
                  subtypesPerGroupLength > 0 && !allOptionsSelected
                }
              />
              {t('filters.filtersSelector.selectAll')} -
              <div id='item-selected' className={styles.selectedFilters}>{`${subtypesPerGroup[groupCode]?.length || 0} ${filtersLength === 1
                ? t('filters.filtersSelector.oneSelected')
                : t('filters.filtersSelector.manySelected')}`}</div>
            </div>
          </MenuItem>}
          {options.map(({ code, name, codigos }) => {
            return (<MenuItem className={styles.item} key={code} value={code}
              onClick={() => {
                handleChangeFilters({ code, name, ...(codigos && {codigos}) });
              }}
              sx={{
                paddingLeft: '0.5rem',
                paddingRight: '1rem',
                '& .MuiSvgIcon-root:focus': { color: '#FF0000' },
              }}
            >
              <div className={'menu-item'}>
                <CustomCheckbox checked={
                  (filterContext.filters[filterType]
                    && filterContext.filters[filterType]
                      .filter((f: Option) => f.code === code).length > 0) || false
                } />
                <Tooltip
                  key={uuidv4()}
                  enterDelay={2000}
                  title={
                    <Typography
                      style={{ padding: '0.625rem' }}
                    >
                      {name}
                    </Typography>
                  }
                  followCursor>
                  <Typography
                    style={{ paddingTop: '0.1rem', width: '100%' }}
                    noWrap
                  >
                    {name}
                  </Typography>
                </Tooltip>
              </div>
            </MenuItem>);
          })}
        </Select>
      </div>
    </div >
  );
};

export default withTranslation()(CheckboxSelector);
