import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserModeContext } from '../../../../context/UserModeProvider';
import { ObraProperties } from '../../../../types/ObraDetails';
import CollapsableField from '../../../../components/collapsableField/collapsableField';
import TitleHelp from '../../components/titleHelp/titleHelp';
import styles from './workTypesMagnitudes.module.scss';
import AddItemButton from '../../components/addItemButton/addItemButton';
import { SaveContext } from '../../../../context/SaveProvider';
import EditWorkType from './editWorkType/editWorkType';
import ViewWorkType from './viewWorkType/viewWorkType';
import NoDataPicture from '../../components/noDataPicture/noDataPicture';
import { EDITOR } from '../../../../userRoles/userRoles';
import technicalNoData from '../../../../assets/technicalNoData.svg';
import { AuthContext } from '../../../../services/authContextProvider';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface WorkTypesMagnitudesProps {
  obra: ObraProperties;
  errors?: any[];
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const WorkTypesMagnitudes: FC<WorkTypesMagnitudesProps> = (props) => {
  const { obra, errors } = props;
  const [t] = useTranslation();
  const userModeContext = useContext(UserModeContext);
  const isEditorMode = userModeContext.userMode === 'editor';
  const saveContext = useContext(SaveContext);

  obra.aspectosTecnicos?.tiposObras.sort((a: any, b: any) => a.id - b.id);

  const [workTypes, setWorkTypes] = useState(obra.aspectosTecnicos?.tiposObras || []);
  const [changeWorktypes, setChangeWorktypes] = useState(false);
  const authContext = useContext(AuthContext);

  // adds a new worktype registry with empty data
  const handleAddWorkType = () => {
    const newWorktype = {
      principal: false,
      origen: 'phia',
    };
    setWorkTypes([...workTypes, newWorktype]);
    if (obra.aspectosTecnicos?.tiposObras) {
      obra.aspectosTecnicos.tiposObras.push(newWorktype);
    } else {
      obra.aspectosTecnicos.tiposObras = [newWorktype];
    }
    saveContext.handleChangeSave(true);
  };

  return (
    <div className={styles.workTypesMagnitudesContainer}>
      <CollapsableField
        id='worktypesCollapsable'
        title={
          <TitleHelp
            id='worktypesCollapsable-title'
            value={t('details.technical.workTypesMagnitudes')}
            help={t('help.technicalAspects.workTypesMagnitudes')}
            error={errors && errors.length > 0 ? ' ' : null}
          />
        }>
        {obra.aspectosTecnicos.tiposObras?.length > 0 ?
          <>
            {isEditorMode ?
              <>
                <div className={styles.addBtnContainer}>
                  <div className={styles.addBtn}>
                    <AddItemButton
                      addItemText={t('details.technical.addType') as string}
                      handleAddItem={handleAddWorkType} />
                  </div>
                </div>
                <table className={styles.worktypesTable}>
                  {// loops all worksubtypes
                    workTypes.map((tp, i) => {
                      // for each subtype, a handle is created to control every subtype code or subtype name modification
                      const handleChange = (id: number, code: string, name: string) => {
                        obra.aspectosTecnicos.tiposObras[i].subtipo = {
                          id, codSubtipo: code, nomSubtipo: name,
                        };
                        setChangeWorktypes(changeWorktypes);
                        saveContext.handleChangeSave(true);
                      };

                      const handleDeleteItem = () => {
                        obra.aspectosTecnicos.tiposObras.splice(i, 1);
                        setWorkTypes([...obra.aspectosTecnicos.tiposObras]);
                        saveContext.handleChangeSave(true);
                      };
                      const error = errors?.filter(e => parseInt(e.index) === i);
                      return <EditWorkType key={i + 1}
                        handleChange={handleChange}
                        workType={obra.aspectosTecnicos.tiposObras[i]}
                        error={error}
                        handleDeleteItem={handleDeleteItem} />;
                    })}
                </table>
              </>
              :
              <table className={styles.worktypesTable}>
                { /* loops all worksubtypes*/
                  obra.aspectosTecnicos.tiposObras.map((tp, i) => {
                    return <ViewWorkType workType={tp} key={i + 1} />;
                  })
                }
              </table>
            }
            {workTypes.filter(wt => wt.origen !== 'cecoc').length === 0 && authContext.hasEditorRole() && userModeContext.userMode === 'viewer' ?
              <NoDataPicture
                id='buildingProcesses-nodata'
                picture={technicalNoData}
                text={t('details.technical.changeUserAddWorktypeMagnitude')}
              />
              : null
            }
          </>
          :
          <p>{t('details.noData')}</p>
        }
      </CollapsableField>
    </div>
  );
};

export default WorkTypesMagnitudes;
