import React, { useContext } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import styles from './advancedFilters.module.scss';
import search from '../../assets/search_advanced.svg';
import round from '../../assets/acciona_cluster.svg';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FilterContext } from '../../context/FilterProvider';

function AdvancedFilters() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const filterContext = useContext(FilterContext);

  return (
    <div data-testid='advancedFilters-test' className={styles.advancedFilters}>
      <Button
        variant="text"
        className={styles.title}
        sx={{ textTransform: 'none' }}
        startIcon={<img src={search} />}
        onClick={() => navigate('/busqueda-avanzada')}
      >
        {t('advancedFilters.title')}
      </Button>
      { filterContext.getAppliedAdvancedFiltersCount() > 0 ? 
        <div className={styles.appliedFiltersWrapper}>
          {filterContext.getAppliedAdvancedFiltersCount()}
        </div> : null}
    </div >
  );
}

export default withTranslation()(AdvancedFilters);