/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC } from 'react';
import styles from './infoBox.module.scss';
import ReactCountryFlag from 'react-country-flag';

interface Field {
  title: string;
  value: string;
}

interface InfoBoxProps {
  picture?: string;
  countryCode?: string;
  item?: Field | null;
  subItem?: Field | null;
  identifier?: any;
  customStyle?: any;
  overwriteStyle?: Record<string, any>;
}

const InfoBox: FC<InfoBoxProps> = (props) => {
  const { picture, countryCode, item, subItem, identifier, customStyle, overwriteStyle = {} } = props;

  return (
    <div className={styles.box + ' ' + customStyle?.box} style={overwriteStyle}>
      <table>
        {countryCode &&
          <tr>
            <td className={styles.tdFlag}>
              {countryCode !== 'none' && <div className={styles.flagContainer}>
                <ReactCountryFlag
                  style={{fontSize: '1.5em'}}
                  countryCode={countryCode.substring(0, 2)}
                  title={countryCode}
                  svg
                />
              </div>}
            </td>
          </tr>}
        {picture &&
          <tr>
            <td>
              <img src={picture} className={styles.picture} id={`${identifier}-picture`} />
            </td>
          </tr>}
        {item !== undefined &&
          <tr className={styles.fieldContainerItem}>
            <td>
              <div className={styles.field}>
                <div id={`${identifier}-title`} className={styles.title + ' ' + customStyle?.title}>{item?.title}</div>
                <div id={`${identifier}-value`} className={styles.value} style={customStyle?.value}>{item?.value}</div>
              </div>
            </td>
          </tr>}
        {subItem !== undefined &&
          <tr className={styles.fieldContainerSubitem}>
            <td>
              <div className={styles.field}>
                <div id={`${identifier}-title`} className={styles.title + ' ' + customStyle?.title}>{subItem?.title}</div>
                <div id={`${identifier}-value`} className={styles.value}>{subItem?.value}</div>
              </div>
            </td>
          </tr>}
      </table>
    </div>
  );
};

export default InfoBox;
