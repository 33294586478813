/* eslint-disable @typescript-eslint/no-explicit-any */
import React,
{
  useState,
  KeyboardEvent,
  useContext,
  useMemo,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import NUMBER_RANGE_TYPE from '../../numberRangeType';
import { Mode } from '../../../../../../../types/FiltersTypes';
import { FilterContext } from '../../../../../../../context/FilterProvider';
import { FILTER_CONFIG } from '../../../../../../../components/Filters/filterTypes';

const PARSER_VALUE_BY_SUFFIX = {
  [FILTER_CONFIG.percentage]: (num: number | string): string => {
    const valueString = typeof num === 'string' ? num.replace(/\s|%|/g, '') : num.toString();
    const value = Number(valueString.replace(',', '.'));
    if(!isNaN(value) && value > 100){
      return `100 ${FILTER_CONFIG.percentage}`;
    }else{
      return `${valueString} ${FILTER_CONFIG.percentage}`;
    }
  },
  [FILTER_CONFIG.number]: (num: number | string) => num,
  [FILTER_CONFIG.integer]: (num: number | string): string => {
    const valueString = typeof num === 'string' ? num.replace(/\s|%|/g, '') : num.toString();
    const value = parseInt(valueString.replace(/[.,]/g, ''));
    if(!isNaN(value)){
      return Math.round(value).toString();
    }
    return valueString.replace(',', '.');
  },
  [FILTER_CONFIG.amountCurrency]: (num: number | string) => num,
};

const PARSER_BY_SUFFIX = {
  [FILTER_CONFIG.amountCurrency]: () => ` ${FILTER_CONFIG.amountCurrency}`,
  [FILTER_CONFIG.percentage]: () => ` ${FILTER_CONFIG.percentage}`,
  [FILTER_CONFIG.hectare]: () => ` ${FILTER_CONFIG.hectare}`,
  [FILTER_CONFIG.period]:  (num: number | string, t: any): string => {
    const dates = [1, '1', `1 ${t('dateMeasures.month')}`, `1 ${t('dateMeasures.months')}`];
    return ` ${dates.includes(num.toString()) ? t('dateMeasures.month') : t('dateMeasures.months')}`;
  },
  [FILTER_CONFIG.number]: () => '',
  [FILTER_CONFIG.integer]: () => '',
};


export const CustomNumberPicker = (
  {
    filterType,
    numberRangeType,
    rangeType,
    suffix,
  }: any,
) => {
  const { t } = useTranslation();
  const filterContext = useContext(FilterContext);
  const [error, setError] = useState<boolean>(false);
  const [key, setKey] = useState<string | undefined>(undefined);
  const numberFilterType = `${numberRangeType}${filterType}`;
  const value = useMemo(() => filterContext.filters[numberFilterType]
  && filterContext.filters[numberFilterType][0]?.name,
  [filterContext.filters[numberFilterType]]);

  const inputRef = useRef(null);

  const formatNumber = useCallback((num: number | string) => {
    if (!num) return '';
    let auxSuffix = ` ${suffix}`;
    if (PARSER_VALUE_BY_SUFFIX[suffix]) {
      num = PARSER_VALUE_BY_SUFFIX[suffix](num);
    }
    const numToString = num.toString().trim();
    const removeChars = numToString.replace(/[^\d,]+/g, '');
    const removeCeros = removeChars.replace(/^0+(?=\d)/, '');
    if(PARSER_BY_SUFFIX[suffix]){
      auxSuffix = PARSER_BY_SUFFIX[suffix](num, t);
    }

    return `${removeCeros.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}${auxSuffix}`.trim();
  }, [filterContext.filters[numberFilterType]]);

  const getSanitizedNumber = (num: string) => {
    return num.replace(/[^\d,]/g, '').replace(',', '.');
  };
  const [textFieldValue, setTextFieldValue] = useState(formatNumber(value));

  useEffect(()=>{
    setTextFieldValue(formatNumber(value));
  }, [filterContext.filters[numberFilterType]]);


  const handlePressKey = (
    e: KeyboardEvent<HTMLDivElement>,
    newValue: any,
  ) => {
    if (e.key === 'Enter') textSelectedHandler(newValue);
  };

  const textSelectedHandler = (newValue: any) => {
    if (newValue.length === 0) return;
    const textFieldValue = getSanitizedNumber(newValue);
    if(textFieldValue !== ''){
      try {
        if(numberRangeType === NUMBER_RANGE_TYPE.exact){
          filterContext.handleChange(numberFilterType,
            {
              code: textFieldValue,
              name: newValue,
              tag: `${getLabel()}: ${newValue}`
            },
            Mode.ADD,
            undefined,
            'exact');
        }else if ((!minNumber || parseFloat(textFieldValue) > minNumber)
          && (!maxNumber || parseFloat(textFieldValue) < maxNumber)) {
          filterContext.handleChange(numberFilterType,
            {
              code: textFieldValue,
              name: newValue,
              tag: `${getLabel()}: ${newValue}`
            },
            Mode.ADD);
        } else {
          setError(true);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const getMaxNumber = (): any => {
    let maxNumber = null;
    if (
      (numberRangeType === NUMBER_RANGE_TYPE.minAmount)
      && filterContext.filters[`${NUMBER_RANGE_TYPE.maxAmount}${filterType}`]
      && filterContext.filters[`${NUMBER_RANGE_TYPE.maxAmount}${filterType}`][0]
    ) {
      maxNumber = filterContext.filters[`${NUMBER_RANGE_TYPE.maxAmount}${filterType}`][0].code;
    }
    return maxNumber;
  };
  const maxNumber = getMaxNumber();


  const getMinNumber = (): any => {
    let minNumber = null;
    if (
      numberRangeType === NUMBER_RANGE_TYPE.maxAmount
      && filterContext.filters[`${NUMBER_RANGE_TYPE.minAmount}${filterType}`]
      && filterContext.filters[`${NUMBER_RANGE_TYPE.minAmount}${filterType}`][0]
    ) {
      minNumber = filterContext.filters[`${NUMBER_RANGE_TYPE.minAmount}${filterType}`][0].code;
    }
    return minNumber;
  };
  const minNumber = getMinNumber();

  const getLabel = () => {
    if (numberRangeType === NUMBER_RANGE_TYPE.minAmount) return t('numRange.from');
    if (numberRangeType === NUMBER_RANGE_TYPE.maxAmount) return t('numRange.to');
    if (['smaller', 'bigger', 'exact'].includes(rangeType)) return t('numRange.number');
  };

  const sanitizeDecimalPart = (magnitude: string) => {
    const splitMagnitude = magnitude.split(',');
    const intPart = splitMagnitude[0];
    if (splitMagnitude.length > 1) {
      let decimalPart = splitMagnitude[1];
      if (decimalPart.length > 2) {
        decimalPart = decimalPart[0] + decimalPart[1];
      }
      return intPart + ',' + decimalPart;
    } else
      return magnitude;
  };

  const checkDecimals = (value: string) => {
    let magnitude = value.split(' ')[0]; // removes the currency
    magnitude = sanitizeDecimalPart(magnitude);
    return magnitude;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(false);
    const element = e.target;
    const targetSplitted = e.target.value.split('');
    let caret = element.selectionStart || 0;
    const numberValue = checkDecimals(e.target.value);
    let europeanNumberFormat = formatNumber(numberValue);
    if (
      (caret && key !== 'Backspace' && key !== 'Delete')
      && (
        europeanNumberFormat && europeanNumberFormat[caret]
        && (
          targetSplitted[caret - 1] === europeanNumberFormat[caret]
        )
      )
    ) {
      caret += 1;
    }
    if (
      (caret && (key === 'Backspace' || key === 'Delete'))
      && (
        e.target.defaultValue && e.target.defaultValue[caret]

      )
    ) {
      while (caret > 0 && e.target.defaultValue[caret].match(/[^\d,]+/g)) {
        caret -= 1;
      }
      const defaultValueSplit = e.target.defaultValue.split('');
      defaultValueSplit.splice(caret, 1);
      europeanNumberFormat = formatNumber(defaultValueSplit.join(''));
    }
    setTextFieldValue(europeanNumberFormat);
    window.requestAnimationFrame(() => {
      element.setSelectionRange(caret, caret);
    });
  };

  return (<>
    <TextField
      InputProps={{
        endAdornment: '',
        onKeyDown: (e) => {
          if (e.key === 'Enter') {
            e.currentTarget.blur();
            e.stopPropagation();
          }
        }
      }}
      onChange={handleChange}
      value={textFieldValue}
      ref={inputRef}
      id={`CustomTextField${numberFilterType}`}
      onKeyDown={(e) => setKey(e.key)}
      onKeyPress={(e) => {
        handlePressKey(e, textFieldValue);
      }}
      onBlur={() => textSelectedHandler(textFieldValue)}
      InputLabelProps={{ shrink: true }}
      variant='outlined'
      autoComplete='off'
      placeholder={rangeType === NUMBER_RANGE_TYPE.exact ?
        (t(`filters.filtersPlaceHolder.${NUMBER_RANGE_TYPE.exact}${filterType}`) || '')
        : (t(`filters.filtersPlaceHolder.${numberFilterType}`) || '')}
      error={error}
      label={getLabel()}
      sx={{
        paddingRight: '12px',
        paddingLeft: '12px',
        paddingTop: '0.35rem',
        paddingBottom: '0.35rem',
        display: 'block',
        //marginBottom: '9px',
        '& .MuiInputLabel-root': {
          fontFamily: 'Roboto',
          fontWeight: '400',
          fontSize: '12px',
          color: '#696969',
          top: '23px',
          left: '12px',
          '&.Mui-focused': {
            color: '#696969',
          }
        },
        '& .MuiInputBase-root': {
          width: '100%',
          '& .MuiOutlinedInput-notchedOutline': {
            backgroundColor: '#FFFFFF',
            height: '43px',
          },
          '&.MuiOutlinedInput-root': {
            border: 'none',
            '&.Mui-focused input': {
              '&:focus::-webkit-input-placeholder': {
                color: 'transparent'
              },
            },

            '& input': {
              paddingBottom: '7px',
              paddingTop: '21px',
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '12px',
              lineHeight: '18px',
              color: '#111111',
              zIndex: 1,
            },
            '& fieldset': {
              border: '1px solid rgba(192, 192, 192, 1)',
              legend: {
                display: 'none'
              },

            },
            '&:hover': {
              '& fieldset': {
                border: '1px solid rgba(0, 0, 0, 1)'
              }
            },
            '&.Mui-focused fieldset': {
              border: '1px solid #7A7A7A',
            }
          },
        },
      }}
    />

  </>);
};
