import { TFunction } from 'i18next';
import * as yup from 'yup';


const partnerSchema = (t: TFunction<'translations'>, partner: string) => yup.array().of(
  yup.object({
    alcance: yup.string().required({ text: `${t('details.validation.tab')} ${t('details.clients.tab')}: ${t('details.validation.noData')} "${partner}" - "${t('details.clients.range')}"`, optional: true, orden: 4 }),
    entidad: yup.string().test(
      'cliente.disenador.entidad',
      { text: `${t('details.validation.tab')} ${t('details.clients.tab')}: ${t('details.validation.noData')} "${partner}" - "${t('details.clients.entity')}"`, orden: 4 },
      (value, context) => {
        return (context.parent.origen === 'cecoc' || !context.parent.alcance || context.parent.alcance.length === 0) || Boolean(context.parent.alcance.length > 0 && typeof value === 'string' && value.length);
      }
    ).nullable(),
    fecInicioColaboracion: yup.string().test(
      'cliente.disenador.fecInicioColaboracion',
      { text: `${t('details.validation.tab')} ${t('details.clients.tab')}: ${t('details.validation.noData')} "${partner}" - "${t('details.clients.initialDate')}"`, orden: 4 },
      (value, context) => {
        return (context.parent.origen === 'cecoc' || !context.parent.alcance || context.parent.alcance.length === 0) || Boolean(context.parent.alcance.length > 0 && typeof value === 'string' && value.length);
      }
    ).nullable(),
    estado: yup.object({
      nombre: yup.string().required({ text: `${t('details.validation.tab')} ${t('details.clients.tab')}: ${t('details.validation.noData')} "${partner}" - "${t('details.clients.status')}"`, orden: 4 }),
    }).required({ text: `${t('details.validation.tab')} ${t('details.clients.tab')}: ${t('details.validation.noData')} "${partner}" - "${t('details.clients.status')}"`, orden: 4 }),
    fecFinColaboracion: yup.string().test(
      'cliente.disenador.fecFinColaboracion',
      { text: `${t('details.validation.tab')} ${t('details.clients.tab')}: ${t('details.validation.noData')} "${partner}" - "${t('details.clients.finalDate')}"`, orden: 4 },
      (value, context) => {
        return (context.parent.origen === 'cecoc' || context.parent.estado && context.parent.estado.id !== 2 || !context.parent.alcance || context.parent.alcance.length === 0) || Boolean(context.parent.alcance.length > 0 && typeof value === 'string' && value.length);
      }
    ).nullable(),
  })
);

const ClientSchema = (t: TFunction<'translations'>) => yup.object({
  disenador: partnerSchema(t, t('details.clients.designer')),
  direccionObra: partnerSchema(t, t('details.clients.director')),
  asistenciasTecnicas: partnerSchema(t, t('details.clients.assistance')),
}).required();

export default ClientSchema;