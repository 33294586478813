import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './additionalTown.module.scss';
import locationPic from '../../../../../assets/location.svg';
import emailPic from '../../../../../assets/email.svg';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AdditionalTownProps {
  name: string;
  zipCode: string;
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const AdditionalTown: FC<AdditionalTownProps> = (props) => {
  const { name, zipCode } = props;
  const [t] = useTranslation();

  return (
    <div className={styles.box}>
      <table>
        <tr>
          <td>
            <img src={locationPic} className={styles.picture} />
          </td>
          <td>
            <div className={styles.field}>
              <div id='additionaltown-title' className={styles.title}>{t('details.location.town')}</div>
              <div id='additionaltown-name' className={styles.value}>{name}</div>
            </div>
          </td>
        </tr>
      </table>
      <table>
        <tr>
          <td>
            <img src={emailPic} className={styles.picture} />
          </td>
          <td>
            <div className={styles.field}>
              <div id='additionaltown-zipcode-title' className={styles.title}>{t('details.location.zipCode')}</div>
              <div id='additionaltown-zipcode' className={styles.value}>{zipCode}</div>
            </div>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default AdditionalTown;
