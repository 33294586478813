/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useState,
  useEffect,
} from 'react';
import { withTranslation } from 'react-i18next';
import { FilterCollapsableWrapper } from '../../FiltersWrappers/FilterCollapsableWrapper/FilterCollapsableWrapper';
import ContainKeywordTextField from './containKeyWordTextField/containKeywordTextField';
import styles from './keywordInput.module.scss';
import './keywordInput.scss';

interface KeywordInputProps {
  filterType: any;
  isNumber?: boolean;
  placeholder?: string;
  isExactWord?: boolean;
  noBottomDivider?: boolean;
}

const KeywordInput = (props: KeywordInputProps) => {
  const { filterType, noBottomDivider, isNumber, isExactWord, placeholder } = props;

  const initialTags: any[] = [];
  const [tags, setTags] = useState<Array<any>>([]);

  useEffect(() => {
    if (
      tags.length === 0
      && Array.isArray(initialTags[filterType])
      && initialTags[filterType].length > 0
    ) {
      setTags(initialTags[filterType]);
    }
  }, [initialTags]);

  return (
    <>
      <FilterCollapsableWrapper
        filterType={filterType}
        noBottomDivider={noBottomDivider}
      >
        <div className={styles.filterWrapper}>
          <ContainKeywordTextField filterType={filterType} isNumber={isNumber} placeholder={placeholder} isExactWord={isExactWord}/>
        </div>
      </FilterCollapsableWrapper>
    </>
  );
};

export default withTranslation()(KeywordInput);