/* eslint-disable linebreak-style */
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InfoBox from '../../../../components/infoBox/infoBox';
import InformationIcon from '../../../../components/informationIcon/informationIcon';
import locationPicture from '../../../../../../assets/location.svg';
import supercenterPicture from '../../../../../../assets/supercenter.svg';

const sxTitle = {
  fontFamily: 'Bitter',
  fontSize: '20px',
  fontWeight: 700,
  mb: 2,
  ml: 1.5,
};

interface Props {
  obra: Record<string, any>;
}

const OrganizationSection = ({ obra }: Props) => {
  const { t } = useTranslation();

  return (
    <Grid item container spacing={2} style={{ marginTop: '-12px'}}>
      <Typography id="organization" sx={sxTitle}>
        {t('details.generalData.organization')}
        <InformationIcon message={t('help.general.organization')} />
      </Typography>

      <Grid item container spacing={2} sx={{marginTop: '-32px'}}>
        <Grid item xs={4} lg={3}>
          <InfoBox
            identifier="country"
            countryCode={obra.general.codPais || 'none'}
            item={{
              title: t('details.generalData.country'),
              value: obra.general.nomPais || t('details.notInformed'),
            }}
            subItem={null}
            overwriteStyle={{ marginBottom: 'auto', height: 'auto' }}
          />
        </Grid>
        <Grid item xs={4} lg={3}>
          <InfoBox
            identifier="zone"
            picture={locationPicture}
            item={{
              title: t('details.generalData.zone'),
              value: obra.general.nomZona || t('details.notInformed'),
            }}
            subItem={{
              title: t('details.generalData.zoneCode'),
              value:
                obra.general.codZona?.toString() || t('details.notInformed'),
            }}
            overwriteStyle={{ marginBottom: 'auto', height: 'auto' }}
          />
        </Grid>
        <Grid item xs={4} lg={3}>
          <InfoBox
            identifier="supercenter"
            picture={supercenterPicture}
            item={{
              title: t('details.generalData.supercenter'),
              value: obra.general.nomSupercentro || t('details.notInformed'),
            }}
            subItem={{
              title: t('details.generalData.supercenterCode'),
              value:
                obra.general.codSupercentro?.toString() ||
                t('details.notInformed'),
            }}
            overwriteStyle={{ marginBottom: 'auto', height: 'auto' }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrganizationSection;
