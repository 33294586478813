export const CATEGORY_COLORS: any = {
  'A': { backgroundColor: 'rgba(255, 0, 0, 1)', color: '#fff' },
  'B': { backgroundColor: 'rgb(255, 190, 45)', color: '#fff' },
  'C': { backgroundColor: 'rgb(255, 247, 59)', color: '#000' },
  'D': { backgroundColor: 'rgb(147, 207, 90)', color: '#fff' },

};

export const LEVEL_COLORS: any = {
  0: { backgroundColor: 'rgb(243, 156, 18)', color: '#fff' },
  1: { backgroundColor: 'rgb(97, 188, 33)', color: '#fff' },
  2: { backgroundColor: 'rgb(27, 188, 156)', color: '#fff' },
  3: { backgroundColor: 'rgb(13, 147, 65)', color: '#fff' },
};

export const LEVEL_BAR_COLORS: any = {
  0: { backgroundColor: 'rgba(245, 182, 182, 1)', color: 'rgba(255, 0, 0, 1)', fairColor: 'rgba(255, 0, 0, 0.25)' },
  1: { backgroundColor: 'rgba(243, 156, 18, 0.25)', color: 'rgb(243, 156, 18)', fairColor: 'rgba(243, 156, 18, 0.5)' },
  2: { backgroundColor: 'rgba(97, 188, 33, 0.25)', color: 'rgb(97, 188, 33)', fairColor: 'rgba(97, 188, 33, 0.5)' },
  3: { backgroundColor: 'rgba(27, 188, 156, 0.25)', color: 'rgb(27, 188, 156)', fairColor: 'rgba(27, 188, 156, 0.5)' },
  4: { backgroundColor: 'rgba(13, 147, 65, 0.25)', color: 'rgb(13, 147, 65)', fairColor: 'rgba(13, 147, 65, 0.5)' },
};

