import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ObraProperties } from '../../../../types/ObraDetails';
import styles from './dataGroup.module.scss';
import { SaveContext } from '../../../../context/SaveProvider';
import SearchAutocomplete from '../../components/searchAutocomplete/searchAutocomplete';
import DeleteButton from '../../components/deleteButton/deleteButton';
import ObrasAPI from '../../../../services/APIObras';
import TitleValue from '../../components/titleValue/titleValue';
import { UserModeContext } from '../../../../context/UserModeProvider';
import moment from 'moment';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DataGroupProps {
  obra: ObraProperties;
  group: any;
  index: number;
  handleDelete?: any;
  errors?: any[];
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const DataGroup: FC<DataGroupProps> = (props) => {
  const { obra, group, index, handleDelete, errors } = props;
  const [t] = useTranslation();
  const saveContext = useContext(SaveContext);
  const [groupValue, setGroupValue] = useState(group.grupo ? `${group.grupo.codigo} ${group.grupo.nombre}` : '');
  const [subgroupValue, setSubgroupValue] = useState(group.subGrupo ? `${group.subGrupo.codigo} ${group.subGrupo.nombre}` : '');
  const [groups, setGroups] = useState<any>([]);
  const [subGroups, setSubGroups] = useState<any>([]);
  const api = ObrasAPI.getInstance();
  const originCecoc = group.origen === 'cecoc';
  const userModeContext = useContext(UserModeContext);
  const isEditorMode = userModeContext.userMode === 'editor';
  // Controlling the focus of the group autocomplete field we can either delete or not the value of the subgroup field. We only delete it when the input is focused.
  const [focusAutocomplete, setFocusAutocomplete] = useState(false);
  // Using this variable, we can control the data consumption when we change the value of the autocomplete field.
  const [loadDataFromElastic, setLoadDataFromElastic] = useState(true);

  const finishTime = obra.fechasPlazos.fecFinalizacionActualizada ? moment(obra.fechasPlazos.fecFinalizacionActualizada, 'DD/MM/YYYY') : moment(obra.fechasPlazos.fecFinalizacionInicial, 'DD/MM/YYYY');
  const isOnTime = moment().isAfter(finishTime.subtract(30, 'days'));
  const getClassificationSubgroups = async () => {
    return await api.getClassificationSubgroups();
  };
  const getClassificationGroups = async () => {
    return await api.getClassificationGroups();
  };

  /*
    Loads all the existing subgroups when no group is selected.
    Otherwise, it loads only the subgroups that belong to the selected group.
  */
  useEffect(() => {
    const promise = groupValue === ''
      ? getClassificationSubgroups()
      : api.getClassificationSubgroupsByGroupCode(group.grupo.codigo);
    promise.then(results => setSubGroups(results || [])).catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    const promise = getClassificationGroups();
    promise.then(results => setGroups(results)).catch((error) => console.log(error));
  }, []);



  const handleChangeGroup = async (value: string | any) => {
    setFocusAutocomplete(false);
    const optionSelected = groups.filter((opt: any) => opt.name === value)[0];
    const { code: codigo, name: nombre } = optionSelected;
    await setSubgroupsByGroup(codigo);
    obra.clasificacion.grupos[index].grupo = { codigo, nombre };
    obra.clasificacion.grupos[index].subGrupo = undefined;

    setGroupValue(`${codigo} ${nombre}`);
    setSubgroupValue('');

    saveContext.forceUpdate();
    saveContext.handleChangeSave(true);
  };

  // If a group is selected, sets the state of subGroups with the subgroups that belong to the selected group.
  const setSubgroupsByGroup = async (code: string) => {
    try {
      const results = await api.getClassificationSubgroupsByGroupCode(code) || [];
      setLoadDataFromElastic(true);
      return setSubGroups(results);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeSubgroup = async (value: string | any) => {
    setFocusAutocomplete(false);
    const optionSelected = subGroups.filter((opt: any) => opt.name === value)[0];
    const { groupCode: codGrupo, code: codigo, name: nombre } = optionSelected;

    await setGroupBySubgroup(codGrupo);
    obra.clasificacion.grupos[index].subGrupo = { codigo, nombre };
    setSubgroupValue(`${codigo} ${nombre}`);
    //setGroups([]);

    saveContext.handleChangeSave(true);
  };

  // If a subGroup is selected, the group field is set with the group the selected subGroup belongs to.
  // Then, the state of subGroups is set with the subgroups that belong to the current group.
  const setGroupBySubgroup = async (code: string) => {
    try {
      const result = await api.getClassificationGroupBySubgroupCode(code);
      const { id, code: codigo, name: nombre } = result;

      obra.clasificacion.grupos[index].grupo = { id, codigo, nombre };
      await setSubgroupsByGroup(codigo);
      return setGroupValue(`${codigo} ${nombre}`);
    } catch (error) {
      console.log(error);
    }
  };

  const getFormattedBudget = (value?: any) => {
    if (value) {
      let floatValue = parseFloat(value);
      if (!isNaN(floatValue)) {
        floatValue = Math.round(floatValue * 100) / 100;
        const parts = floatValue.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        if (parts.length == 2) {
          return parts[0] + ',' + parts[1].padEnd(2, '0');
        } else {
          return parts[0];
        }
      }
    }
    return null;
  };

  const handleChangeEstimatedBudget = (budget: any) => {
    const formattedBudget = budget.replace(/\./g, '').replace(',', '.');
    obra.clasificacion.grupos[index].importeEstimado = formattedBudget;
    saveContext.handleChangeSave(true);
  };

  const handleChangeFinalBudget = (budget: any) => {
    const formattedBudget = budget.replace(/\./g, '').replace(',', '.');
    obra.clasificacion.grupos[index].importeFinal = formattedBudget;
    saveContext.handleChangeSave(true);
  };

  const isFieldError = (field: string) => {
    return !!(errors && errors.find((e) => (e.field === field || e.fieldField === field) && e.index === index.toString()));
  };

  const budget = getFormattedBudget(group.importeEstimado);
  const finalBudget = isOnTime ? getFormattedBudget(group.importeFinal) : '-';

  return (
    <tr key={index} className={isEditorMode && handleDelete ? styles.containerGroupWithDelete : styles.containerGroup}>
      <td>
        <div className={styles.titleValue1}>
          <SearchAutocomplete
            isDropdown
            id={`groupAutoselect${index}`}
            title={t('details.classification.group') || ''}
            searchValue={group.grupo?.codigo ? `${group.grupo.codigo} ${group.grupo.nombre}` : ''}
            options={groups?.map((option: any) => option?.name)}
            editable={isEditorMode && !originCecoc}
            contrast={false}
            error={isFieldError('grupo')}
            inputChangeHandler={() => { return; }}
            handleChange={(value: any) => handleChangeGroup(value)}
            onFocus={() => setFocusAutocomplete(true)}
            onBlur={() => setFocusAutocomplete(false)}
          />
        </div>
      </td>
      <td>
        <div className={styles.titleValue2}>
          <SearchAutocomplete
            isDropdown
            id={`groupAutoselect${index}`}
            title={t('details.classification.subGroup') || ''}
            searchValue={group.subGrupo?.codigo ? `${group.subGrupo.codigo} ${group.subGrupo.nombre}` : ''}
            options={subGroups?.map((option: any) => option?.name)}
            editable={isEditorMode && !originCecoc}
            contrast={false}
            inputChangeHandler={() => { return; }}
            handleChange={(value: any) => handleChangeSubgroup(value)}
            groupBy={(option: any) => option[0]}
          />
        </div>
      </td>
      <td>
        <div className={styles.titleValue3}>
          <TitleValue
            identifier={`data-estimated-budget-${index}`}
            title={t('details.classification.estimatedBudget')}
            value={budget}
            handleChange={(data: any) => {
              handleChangeEstimatedBudget(data as number);
            }}
            editable={isEditorMode && !originCecoc}
            isDecimalNumber
            error={isFieldError('importeEstimado') && !budget}
          />
        </div>
      </td>
      <td>
        <div className={styles.titleValue4}>
          <TitleValue
            identifier={`data-final-budget-${index}`}
            title={t('details.classification.finalBudget')}
            value={finalBudget}
            handleChange={(data: any) => {
              handleChangeFinalBudget(data as number);
            }}
            editable={isEditorMode && isOnTime}
            isDecimalNumber
            error={isFieldError('importeFinal') && (!finalBudget || finalBudget === '-')}
          />
        </div>
        {isEditorMode && !originCecoc &&
          <div className={styles.trashBtn}>
            <DeleteButton id={`deleteBtn-${index}`} onClick={() => handleDelete(group, index)} />
          </div>
        }
      </td>
    </tr>
  );
};

export default DataGroup;
