import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { ObraProperties } from '../../../types/ObraDetails';
import InformationIcon from '../components/informationIcon/informationIcon';
import styles from './budget.module.scss';
import { VIEWER_VIP } from '../../../userRoles/userRoles';
import { AuthContext } from '../../../services/authContextProvider';
import Section from './components/section';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface BudgetProps {
  obra: ObraProperties;
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const Budget: FC<BudgetProps> = (props) => {
  const { obra } = props;
  const [t] = useTranslation();
  const authContext = useContext(AuthContext);

  const prepareBudget = (budget: number, moneda: string): string => {
    if (!budget) return t('details.notInformed');
    const [wholeNumber, decimal] = budget.toString().split('.');
    return `${wholeNumber.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}${decimal ? ',' + decimal.substring(0, 2) : ',00'} ` + moneda;
  };

  const isViewer = !authContext.hasRole(VIEWER_VIP) && !authContext.hasEditorRole();

  const parseCurrencyValue = (value:number): string => (value?.toFixed(5).toString().replace('.',','));

  return (
    <div className={styles.budgetContainer}>
      <Grid container direction="column" mt={2}>
        <Grid item container spacing={2} justifyContent="space-between" alignItems="stretch" xs>
          <Grid item xs>
            <Typography id='localCurrency-title' className={styles.heading}>
              {t('details.budget.localCurrency')}:
              <span className={styles.localCurrency} id='localCurrency'>{obra.presupuesto.monedaLocal || 'EUR'}</span>
              <InformationIcon message={t('help.budget.localCurrency')} />
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <Typography id='noTaxes' className={styles.noTaxes}>
              {t('details.budget.noTaxes')}
            </Typography>
          </Grid>
        </Grid>
        <Section
          id='awardAmount'
          idTitle='awardAmount-title'
          title={t('details.budget.awardAmount')}
          infoMsg={t('help.budget.awardAmount')}
          date={{
            id: 'awardDate',
            item: {
              title: t('details.budget.awardDate'),
              value: obra.presupuesto.fecAdjudicacion || t('details.notInformed')
            }
          }}
          amount={{
            id:'awardAmount',
            item: {
              title: t('details.budget.awardAmount'),
              value: `${prepareBudget(obra.presupuesto.importeAdjudicacion.monedaEur, 'EUR')}`
            },
            switchItem: `${prepareBudget(obra.presupuesto.importeAdjudicacion.monedaLocal, obra.presupuesto.monedaLocal)}`,
            switchData:(obra.presupuesto.importeAdjudicacion.monedaEur && obra.presupuesto.monedaLocal !== 'EUR') ? true : false
          }}
          accionaAmount={{
            id:'accionaAwardAmount',
            item:{ title: t('details.budget.accionaAwardAmount'), value: `${prepareBudget(obra.presupuesto.importeAdjudicacion.accionaMonedaEur, 'EUR')}` },
            switchItem:`${prepareBudget(obra.presupuesto.importeAdjudicacion.accionaMonedaLocal, obra.presupuesto.monedaLocal)}`,
            switchData: (obra.presupuesto.importeAdjudicacion.monedaEur && obra.presupuesto.monedaLocal !== 'EUR') ? true : false
          }}
          currencyExchange={{
            id: 'award-currencyExchange',
            item: {
              title: t('details.budget.currencyExchange'),
              value: obra.presupuesto.importeAdjudicacion.monedaEur ? (obra.presupuesto.monedaLocal === 'EUR' ? '-' : `1 EUR = ${parseCurrencyValue(obra.presupuesto.importeAdjudicacion.tipoCambio)} ${obra.presupuesto.monedaLocal}`) : '-'
            }
          }}
        />

        {isViewer || <>
          <Section
            id='updatedAmount'
            idTitle='updatedAmount-title'
            title={t('details.budget.updatedApprovedAmount')}
            infoMsg={t('help.budget.updatedApprovedAmount')}
            date={{
              id:'approvalDate',
              item: { title: t('details.budget.approvalDate'), value: obra.presupuesto.fecAprobacionEconomica || t('details.notInformed') }
            }}
            amount={{
              id:'updatedAmount',
              item: { title: t('details.budget.updatedAmount'), value: `${prepareBudget(obra.presupuesto.importeActualizado.monedaEur, 'EUR')}` },
              switchItem: `${prepareBudget(obra.presupuesto.importeActualizado.monedaLocal, obra.presupuesto.monedaLocal)}`,
              switchData: (obra.presupuesto.importeActualizado.monedaEur && obra.presupuesto.monedaLocal !== 'EUR') ? true : false
            }}
            accionaAmount={{
              id:'accionaUpdatedAmount',
              item: { title: t('details.budget.accionaUpdatedAmount'), value: `${prepareBudget(obra.presupuesto.importeActualizado.accionaMonedaEur, 'EUR')}` },
              switchItem: `${prepareBudget(obra.presupuesto.importeActualizado.accionaMonedaLocal, obra.presupuesto.monedaLocal)}`,
              switchData: (obra.presupuesto.importeActualizado.accionaMonedaEur && obra.presupuesto.monedaLocal !== 'EUR') ? true : false
            }}
            currencyExchange={{
              id: 'updated-currencyExchange',
              item: {
                title: t('details.budget.currencyExchange'),
                value: obra.presupuesto.importeActualizado.monedaEur ? (obra.presupuesto.monedaLocal === 'EUR' ? '-' : `1 EUR = ${parseCurrencyValue(obra.presupuesto.importeActualizado.tipoCambio)} ${obra.presupuesto.monedaLocal}`) : '-'
              }
            }}
          />

          <Section
            id='finalAmount'
            idTitle='finalAmount-title'
            title={t('details.budget.finalAmount')}
            infoMsg={t('help.budget.finalAmount')}
            date={{
              id:'finalDate',
              item: { title: t('details.budget.finalDate'), value: obra.presupuesto.fecAprobacionEconomicaFinal || t('details.notInformed') }
            }}
            amount={{
              id:'finalAmount',
              item: { title: t('details.budget.finalAmount'), value: `${prepareBudget(obra.presupuesto.importeActualizadoFinal.monedaEur, 'EUR')}` },
              switchItem: `${prepareBudget(obra.presupuesto.importeActualizadoFinal.monedaLocal, obra.presupuesto.monedaLocal)}`,
              switchData: (obra.presupuesto.importeActualizadoFinal.monedaEur && obra.presupuesto.monedaLocal !== 'EUR') ? true : false
            }}
            accionaAmount={{
              id:'accionaFinalAmount',
              item: { title: t('details.budget.accionaFinalAmount'), value: `${prepareBudget(obra.presupuesto.importeActualizadoFinal.accionaMonedaEur, 'EUR')}` },
              switchItem: `${prepareBudget(obra.presupuesto.importeActualizadoFinal.accionaMonedaLocal, obra.presupuesto.monedaLocal)}`,
              switchData: (obra.presupuesto.importeActualizadoFinal.accionaMonedaEur && obra.presupuesto.monedaLocal !== 'EUR') ? true : false
            }}
            currencyExchange={{
              id: 'final-currencyExchange',
              item: {
                title: t('details.budget.currencyExchange'),
                value: obra.presupuesto.importeActualizadoFinal.monedaEur ? (obra.presupuesto.monedaLocal === 'EUR' ? '-' : `1 EUR = ${parseCurrencyValue(obra.presupuesto.importeActualizadoFinal.tipoCambio)} ${obra.presupuesto.monedaLocal}`) : '-'
              }
            }}
          />
        </>}
      </Grid>
      <div className='footer' style={{marginBottom: '75px'}}></div>
    </div>
  );
};

export default Budget;
