/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC } from 'react';
import styles from './usosBim.module.scss';
import { ObraProperties, UsoBIM } from '../../../../types/ObraDetails';

import { useTranslation } from 'react-i18next';
import TitleHelp from '../../components/titleHelp/titleHelp';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ReactComponent as checkTrue } from '../../../../assets/check_true.svg';
import { SvgIcon } from '@mui/material';
import LevelBar from '../components/levelBar/LevelBar';
import { LEVEL_BAR_COLORS } from '../colorSchema';


interface UsosBimProps {
  obra: ObraProperties
}

const UsosBim: FC<UsosBimProps> = (props) => {
  const { obra } = props;
  const [t] = useTranslation();

  const groupByUsosBim = (results: UsoBIM[]) => {
    const groupedResults = results.reduce((groups: any, item: UsoBIM) => {
      const etapas = (groups[item.codigo]?.etapas || []);
      etapas.push(item.etapa);

      groups[item.codigo] = { ...item, etapas };
      return groups;
    }, {});
    return Object.values(groupedResults);
  };

  const rows = groupByUsosBim(obra.bim.usosBIM);

  const check = (value: boolean) => {
    return <div className={styles.check}>
      {value && <SvgIcon component={checkTrue} inheritViewBox />}
    </div>;
  };

  const getLevel = (level: string) => {
    if (level.toLowerCase() === 'muy bajo') {
      return 0;
    }
    else if (level.toLowerCase() === 'bajo') {
      return 1;
    }
    else if (level.toLowerCase() === 'medio') {
      return 2;
    }
    else if (level.toLowerCase() === 'alto') {
      return 3;
    }
    else if (level.toLowerCase() === 'muy alto') {
      return 4;
    }
    return 0;
  };

  const levelBar = (level: any, i: number) => {
    const style = LEVEL_BAR_COLORS[getLevel(level)];
    return <div className={styles.levelContainer}>
      <div
        className={styles.levelDescription}
        style={{ color: style.color, backgroundColor: style.backgroundColor }}>
        {t('details.bim.' + level)}
      </div>
      <LevelBar
        identifier={`level-${i}`}
        color={{ fairColor: style.backgroundColor, darkColor: style.color }}
        height={12}
        level={getLevel(level)} />
    </div>;
  };

  return (
    <div
      data-testid="usos-bim-test"
      className={styles.bimContainer} >


      <TitleHelp
        id='usos-bim'
        value={t('details.bim.usosBim')}
        help={t('help.bim.bimUses')}
      />
      <TableContainer component={Paper} className={styles.bimTable}
        sx={{
          '&.MuiPaper-root': {
            borderRadius: '0 !important',
            padding: '0px 30px',
            boxShadow: 'none',
            width: 'calc(100% - 60px)',
          }
        }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align='center' className={styles.accionaUseColumn}>{t('details.bim.accionaUse')}</TableCell>
              <TableCell align='center' className={styles.levelColumn}>{t('details.bim.level')}</TableCell>
              <TableCell align='center' className={styles.checkColumn}>{t('details.bim.bid')}</TableCell>
              <TableCell align='center' className={styles.checkColumn}>{t('details.bim.design')}</TableCell>
              <TableCell align='center' className={styles.checkColumn}>{t('details.bim.construction')}</TableCell>
              <TableCell align='center' className={styles.checkColumn}>{t('details.bim.operation')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: any, i: number) => (
              <TableRow
                key={`row-${i}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align='left'>
                  {row.descripcion}
                </TableCell>
                <TableCell align='center'>{levelBar(row.nivel, i)}</TableCell>
                <TableCell align='center'>{check(row.etapas.find((e: any) => e.codigo === 1))}</TableCell>
                <TableCell align='center'>{check(row.etapas.find((e: any) => e.codigo === 2))}</TableCell>
                <TableCell align='center'>{check(row.etapas.find((e: any) => e.codigo === 0))}</TableCell>
                <TableCell align='center'>{check(row.etapas.find((e: any) => e.codigo === 3))}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>


    </div >);
};

export default UsosBim;
