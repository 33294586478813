/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useState,
  useCallback,
  useContext,
} from 'react';
import { v4 as uuidv4 } from 'uuid';

import debounce from 'lodash.debounce';

import { withTranslation } from 'react-i18next';

import Autocomplete from '@mui/material/Autocomplete';

import { CustomPopper } from './CustomPopper/CustomPopper';
// commented to keep the scroll in the select.
/* import { CustomNoElevationPaper } from './CustomPaper/CustomNoElevationPaper';
import { CustomElevationPaper } from './CustomPaper/CustomElevationPaper'; */
import AutocompleteList from './AutocompleteList/AutocompleteList';
import AutocompleteTextField from './AutocompleteTextField/AutocompleteTextField';
import ObrasAPI from '../../../../../services/APIObras';
import { TFunction } from 'i18next';
import { FilterContext } from '../../../../../context/FilterProvider';
import { Mode } from '../../../../../types/FiltersTypes';
import FILTER_TYPES from '../../../filterTypes';

const fieldsNotLanguage = ['namePartner'];

interface Option {
  name: string,
}
interface CustomAutocompleteProps {
  filterType: string,
  disableScroll: (disable: boolean) => void,
  t: TFunction<'translations'>;
}
const CustomAutocomplete = (
  props: CustomAutocompleteProps,
) => {
  const { t, filterType, disableScroll } = props;
  const filterContext = useContext(FilterContext);
  const tags = filterContext.getTags(filterType);

  const NO_RESULTS = [{ name: `${t('input.noResults')}` }];

  const minCharaters = 3;

  const [options, setOptions] = useState<Array<Option>>([]);
  const [textFieldValue, setTextFieldValue] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const inputRequest = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length >= minCharaters) {
      const notLanguage = fieldsNotLanguage.some((e: string) => e === filterType);

      try {
        const api = ObrasAPI.getInstance();
        let results;
        if ([
          FILTER_TYPES.supercenter,
          FILTER_TYPES.zone,
        ].includes(filterType))
          results = await api.getAutocompleteMastersUrl(filterType, e.target.value);
        else
          results = await api.getAutocompleteUrl(filterType, e.target.value, notLanguage);
        results = results.length ? results : NO_RESULTS;
        return setOptions(results);
      } catch (error) {
        console.log(error);
      }
    }
    return setOptions([]);
  };

  const debounceInputHandler = useCallback(
    debounce(inputRequest, 500),
    [],
  );

  return (
    <Autocomplete
      size="medium"
      multiple
      freeSolo={true}
      open={openModal}
      renderTags={() => null}
      disableCloseOnSelect
      disableClearable
      data-testid={t(`filters.${filterType}`)}
      id={`${t(`filters.${filterType}`)}`}
      inputValue={textFieldValue}
      options={options}
      getOptionLabel={(option: any) => option.name}
      isOptionEqualToValue={(option, valueToEqual) => option.name === valueToEqual.name}
      filterOptions={(option) => option}
      PopperComponent={CustomPopper}
      getOptionDisabled={
        (option: Option) => (option.name === t('input.noResults'))
      }
      style={
        {
          padding: '0.75rem',
        }
      }
      value={tags}
      onChange={(e: any, newFilter: readonly any[]) => {
        const selectedCode = e.target.id;
        const filterValue = newFilter.find((f) => f.code === selectedCode);
        const modeFilter = !filterValue ? Mode.DELETE : Mode.ADD;
        filterContext.handleChange(filterType, filterValue || { code: selectedCode }, modeFilter);

      }}
      onOpen={() => {
        disableScroll(true);
      }}
      onClose={() => {
        disableScroll(false);
      }}
      onBlur={() => setOpenModal(false)}
      onFocus={() => setOpenModal(true)}
      // Commented to keep the scroll in the select.
      // PaperComponent={(options.length > 0) ? (props) => (<CustomElevationPaper {...props} id={`${t(`filters.${filterType}`)}`} />) : CustomNoElevationPaper}
      renderOption={(props, option: any) => (
        <AutocompleteList
          {...props}
          key={option.code || uuidv4()}
          props={props}
          option={option}
          selected={!!tags.some((o) => o.code === option.code)}
          clickOption={() => {
            if(option.name === t('input.noResults')) return;
            const selected = !!tags.some((o) => o.code === option.code);
            const modeFilter = selected ? Mode.DELETE : Mode.ADD;
            filterContext.handleChange(filterType, { name: option.name, code: option.code }, modeFilter);
          }}
        />
      )
      }
      renderInput={(params) => (
        <AutocompleteTextField
          filterType={filterType}
          params={params}
          debounceInputHandler={debounceInputHandler}
          textFieldValue={textFieldValue}
          setTextFieldValue={setTextFieldValue}
          setOptions={setOptions}
        />
      )}
    />
  );
};

export default withTranslation()(CustomAutocomplete);
