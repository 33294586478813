import React, { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './checkSaved.module.scss';
import { Button, Dialog, Stack } from '@mui/material';
import { SaveContext } from '../../context/SaveProvider';

interface headerProps {
  children?: JSX.Element;
  handleFunction: any;
  closeFunction: any;
  discardChanges?: any;
}

const CheckSaved: FC<headerProps> = (props) => {
  const { t } = useTranslation();
  const saveContext = useContext(SaveContext);
  const [open, setOpen] = React.useState(saveContext.saveDraft);

  useEffect(() => {
    if (saveContext.saveDraft === false) {
      setOpen(false);
      props.handleFunction();
    }
  }, [saveContext.saveDraft]);

  const handleClose = () => {
    setOpen(false);
    props.closeFunction();
  };

  return <div data-testid='checkSaved-test' className={styles.checkSaved}>
    <Dialog
      classes={{
        paper: styles.dialog
      }}
      BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.15)' } }}
      PaperProps={{ sx: { borderRadius: '20px', minWidth: '90vW' } }}
      open={open}
      onClose={() => handleClose()}
    >
      <div className={styles.dialogContent}>
        <p>{t('checkSaved.text')}</p>
        <Stack spacing={2} direction="row" justifyContent="center">
          <Button className={styles.buttonBack} onClick={() => handleClose()} disableRipple={true} variant="outlined">{t('checkSaved.buttonBack')}</Button>
          <Button className={styles.buttonDiscard} onClick={() => {
            handleClose();
            props.handleFunction();
            if (props.discardChanges) props.discardChanges();
          }} disableRipple={true} variant="contained">{t('checkSaved.buttonDiscard')}</Button>
        </Stack>
      </div>
    </Dialog>
  </div>;
};

export default CheckSaved;