/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react';
import NUMBER_RANGE_TYPE from '../numberRangeType';
import CustomNumberPickerWithButton from './customNumberPickerWithButton/customNumberPickerWithButton';
import { FilterContext } from '../../../../../../context/FilterProvider';
import { t } from 'i18next';
import styles from './rangeModeFieldsWithButton.module.scss';
import { Mode } from '../../../../../../types/FiltersTypes';
import { Option } from '../../../../../../types/Option';
import FILTER_TYPES, { FILTER_CONFIG } from '../../../../filterTypes';

interface RangeModeFieldsWithButtonProps {
  filterType: string,
  unitType: string,
  selectedWorkType?: Option,
  selectedUnit: Option,
  isDoubleSelector?: boolean;
}

const FILTERS_DOUBLE_SELECTOR = [
  FILTER_TYPES.workTypeTechnicalAspects,
  FILTER_TYPES.classificationEstimatedAmount,
  FILTER_TYPES.classificationFinalAmount,
];

const FILTERS_WITH_CURRENT = [
  FILTER_TYPES.estimatedAmount,
  FILTER_TYPES.finalAmount,
];

const FILTERS_WITH_GROUPS = [
  FILTER_TYPES.estimatedAmount,
  FILTER_TYPES.finalAmount,
];

const RangeModeFieldsWithButton = (props: RangeModeFieldsWithButtonProps) => {
  const { filterType, unitType, selectedWorkType, isDoubleSelector } = props;
  let { selectedUnit } = props;
  const filterContext = useContext(FilterContext);
  const [minValue, setMinValue] = useState<any>('');
  const [maxValue, setMaxValue] = useState<any>('');
  const [exactValue, setExactValue] = useState<any>('');
  const [minValueError, setMinValueError] = useState<any>(false);
  const [maxValueError, setMaxValueError] = useState<any>(false);
  const [exactValueError, setExactValueError] = useState<any>(false);

  const handleChangeMinValue = (newValue: string) => {
    setMinValue(newValue);
  };
  const handleChangeMaxValue = (newValue: string) => {
    setMaxValue(newValue);
  };
  const handleChangeExactValue = (newValue: string) => {
    setExactValue(newValue);
  };

  const clearInputs = () => {
    setMinValue('');
    setMaxValue('');
    setExactValue('');
  };

  useEffect(() => clearInputs(), [filterContext.filters[unitType]]);
  useEffect(() => clearInputs(), [filterContext.numberFilterType[filterType]]);
  useEffect(() => clearInputs(), [selectedUnit]);

  const generateDefaultUnit = () => {
    if(FILTERS_WITH_CURRENT.includes(filterType)){
      return FILTER_CONFIG.amountCurrency;
    }else if (selectedUnit) {
      const unitSymbol = selectedUnit.name;
      const [, text] = unitSymbol.match(/\(([^()]+)\)[^(]*$/) || [];
      let res = text || '';
      if (text.includes('2'))
        res = res.replace('2', '²');
      if (text.includes('3'))
        res = res.replace('3', '³');
      if (text === 'mes') res = 'mes/es';
      if (text === 'month') res = 'month(s)';
      if (text === 'mês') res = 'mês/es';
      return res;
    } else {
      return '';
    }
  };

  const defaultUnit = generateDefaultUnit();

  const getSanitizedNumber = (num: string) => {
    return num.replace(/[^\d,]/g, '').replace(',', '.');
  };

  const handleAddFilter = () => {
    try {
      let code, name, tag, rangeType;
      if (selectedUnit === null && isDoubleSelector) selectedUnit = { code: 'ALL', name: t('filters.filtersPlaceHolder.everyMagnitude') };
      if ((isDoubleSelector && selectedWorkType) || (!isDoubleSelector && selectedUnit)) {
        let prefix = `${selectedWorkType ? selectedWorkType.name + ': ' : ''}${selectedUnit.name}: `;
        if(FILTERS_WITH_GROUPS.includes(filterType)){
          prefix = selectedUnit && selectedUnit.code !== 'ALL' ? `${selectedUnit.name}: ` : (selectedWorkType ? `${selectedWorkType.name} : ` : '');
        }

        const exactFieldValue = getSanitizedNumber(exactValue);
        const minFieldValue = getSanitizedNumber(minValue);
        const maxFieldValue = getSanitizedNumber(maxValue);
        if (exactValue.length > 0) {
          code = `|min:${exactFieldValue}|max:${exactFieldValue}`;
          name = exactValue;
          tag = `${prefix}${exactValue}`;
          rangeType = 'exact';
        } else {
          code = `${minFieldValue ? `|min:${minFieldValue}` : ''}${maxFieldValue ? `|max:${maxFieldValue}` : ''}`;
          if (minValue.length > 0 && maxValue.length > 0) {
            if (Number(maxFieldValue) > Number(minFieldValue)){
              name = `min: ${minValue} - max: ${maxValue}`;
              tag = `${prefix}${t('numRange.from')}: ${minValue} - ${t('numRange.to')}: ${maxValue}`;
              rangeType = 'range';
            }
          } else if (minValue.length > 0) {
            name = `min: ${minValue}`;
            tag = `${prefix}${t('numRange.from')}: ${minValue}`;
            rangeType = 'bigger';
          } else if (maxValue.length > 0) {
            name = `max: ${maxValue}`;
            tag = `${prefix}${t('numRange.to')}: ${maxValue}`;
            rangeType = 'smaller';
          }
        }
        if (!name && !tag && !rangeType) {
          setMinValueError(true);
          setMaxValueError(true);
          setExactValueError(true);
        } else {
          clearInputs();
          setMinValueError(false);
          setMaxValueError(false);
          setExactValueError(false);
          const filterKey = `${unitType}${selectedWorkType ? selectedWorkType.code : ''}${selectedUnit.code}`;
          filterContext.handleCacheMasterFilter(FILTERS_DOUBLE_SELECTOR.includes(filterType) ? unitType : filterType, filterKey);
          filterContext.handleChange(
            filterKey,
            {
              code: code || '',
              name,
              tag,
              workTypeCode: selectedWorkType?.code,
              unitCode: selectedUnit.code,
            },
            Mode.REPLACE,
            undefined,
            rangeType);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const renderCustomNumberPicker = (numberRangeType: string, rangeType: string, value: string, handleChangeValue: any, error: boolean) => {
    return (<CustomNumberPickerWithButton
      filterType={filterType}
      numberRangeType={numberRangeType}
      suffix={defaultUnit}
      rangeType={rangeType}
      value={value}
      handleChangeValue={handleChangeValue}
      disabled={!defaultUnit}
      error={error}
    />);
  };

  const renderRangeModeFields = () => {
    let res = null;
    const filterRangeValue = filterContext.numberFilterType[filterType];
    if (filterRangeValue === 'range') {
      res = (<>
        {renderCustomNumberPicker(NUMBER_RANGE_TYPE.minAmount, 'range', minValue, handleChangeMinValue, minValueError)}
        {renderCustomNumberPicker(NUMBER_RANGE_TYPE.maxAmount, 'range', maxValue, handleChangeMaxValue, maxValueError)}
      </>);
    } else if (filterRangeValue === 'bigger')
      res = renderCustomNumberPicker(NUMBER_RANGE_TYPE.minAmount, 'bigger', minValue, handleChangeMinValue, minValueError);
    else if (filterRangeValue === 'smaller')
      res = renderCustomNumberPicker(NUMBER_RANGE_TYPE.maxAmount, 'smaller', maxValue, handleChangeMaxValue, maxValueError);
    else if (filterRangeValue === 'exact')
      res = renderCustomNumberPicker(NUMBER_RANGE_TYPE.exact, 'exact', exactValue, handleChangeExactValue, exactValueError);
    res = (<>
      {res}
      <div className={styles.addButtonWrapper}>
        <div className={styles.addButton} onClick={() => handleAddFilter()}>
          {t('filters.add')}
        </div>
      </div>
    </>);
    return res;
  };
  return renderRangeModeFields();
};

export default RangeModeFieldsWithButton;