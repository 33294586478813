import { TFunction } from 'i18next';
import * as yup from 'yup';
import { INFO_DESTACABLE_MAX_LENGTH, INFO_DESTACABLE_MAX_CHARS_PER_LINE } from '../pages/fullDetail/descriptionPhoto/description/description';

const stripHtmlTags = (str: any) => {
  if (typeof str !== 'string') {
    return '';
  }
  return str.replace(/<[^>]*>/g, '');
};

const countCharacters = (text: string) => {
  const lines = text.split('\n');
  let countCharacters = 0;
  lines.forEach((line) => {
    if (line.length === 0) {
      countCharacters += INFO_DESTACABLE_MAX_CHARS_PER_LINE;
    } else {
      countCharacters += Math.ceil(line.length / INFO_DESTACABLE_MAX_CHARS_PER_LINE) * INFO_DESTACABLE_MAX_CHARS_PER_LINE;
    }
  });
  return countCharacters;
};

const DescriptionSchema = (t: TFunction<'translations'>) => yup.object({
  descDetallada: yup.object().test(
    'Description.details',
    { text: `${t('details.validation.tab')} ${t('details.description.tab')}: ${t('details.validation.noData')} "${t('details.description.descDetallada')}"`, orden: 1 },
    (value: any, context) => {
      return value.es && value.es !== '' || value.en && value.en !== '' || value.pl && value.pl !== '' || value.pt && value.pt !== '';
    }
  ).required(),
  infoDestacable: yup.object().test(
    'Description.infoDestacable',
    {
      text: `${t('details.validation.tab')} ${t('details.description.tab')}: ${t('details.validation.noData')} "${t('details.description.infoDestacable')}"`,
      textField: `${t('details.validationError.infoDestacable')}`,
      orden: 1
    },
    (value: any, ctx) => value.es && value.es !== '' || value.en && value.en !== '' || value.pl && value.pl !== '' || value.pt && value.pt !== ''
  ).shape({
    es: yup.string().transform((value) => stripHtmlTags(value)).test('len', {
      text: `${t('details.validation.tab')} ${t('details.description.tab')}: ${t('details.validation.maxLen', {field: t('details.description.infoDestacable'), maxLen: INFO_DESTACABLE_MAX_LENGTH})}`,
      textField: `${t('details.validation.maxLen', {field: t('details.description.infoDestacable'), maxLen: INFO_DESTACABLE_MAX_LENGTH})}`,
      orden: 1
    },
    val => !val || val === '' || countCharacters(val) <= INFO_DESTACABLE_MAX_LENGTH),
    en: yup.string().transform((value) => stripHtmlTags(value)).test('len', {
      text: `${t('details.validation.tab')} ${t('details.description.tab')}: ${t('details.validation.maxLen', {field: t('details.description.infoDestacable'), maxLen: INFO_DESTACABLE_MAX_LENGTH})}`,
      textField: `${t('details.validation.maxLen', {field: t('details.description.infoDestacable'), maxLen: INFO_DESTACABLE_MAX_LENGTH})}`,
      orden: 1 },
    val => !val || val === '' || countCharacters(val) <= INFO_DESTACABLE_MAX_LENGTH),
    pl: yup.string().transform((value) => stripHtmlTags(value)).test('len', {
      text: `${t('details.validation.tab')} ${t('details.description.tab')}: ${t('details.validation.maxLen', {field: t('details.description.infoDestacable'), maxLen: INFO_DESTACABLE_MAX_LENGTH})}`,
      textField: `${t('details.validation.maxLen', {field: t('details.description.infoDestacable'), maxLen: INFO_DESTACABLE_MAX_LENGTH})}`,
      orden: 1 },
    val => !val || val === '' || countCharacters(val) <= INFO_DESTACABLE_MAX_LENGTH),
    pt: yup.string().transform((value) => stripHtmlTags(value)).test('len', {
      text: `${t('details.validation.tab')} ${t('details.description.tab')}: ${t('details.validation.maxLen', {field: t('details.description.infoDestacable'), maxLen: INFO_DESTACABLE_MAX_LENGTH})}`,
      textField: `${t('details.validation.maxLen', {field: t('details.description.infoDestacable'), maxLen: INFO_DESTACABLE_MAX_LENGTH})}`,
      orden: 1 },
    val => !val || val === '' || countCharacters(val) <= INFO_DESTACABLE_MAX_LENGTH),
  }),
  fotos: yup.array().test(
    'Description.fotos.iconica',
    { text: `${t('details.validation.tab')} ${t('details.description.tab')}: ${t('details.validation.noData')} "${t('details.validation.photo').charAt(0).toUpperCase() + t('details.validation.photo').slice(1)} ${t('details.carousel.fixedIconic').toLowerCase()}"`, orden: 1 },
    (value, context) => {
      return context.parent.fotos.filter((photo: any) => photo.iconica).length > 0;
    }
  ).required(),
  documentacionProyecto: yup.string().required({ text: `${t('details.validation.tab')} ${t('details.description.tab')}: ${t('details.validation.noData')} "${t('details.documentation.execution')}"`, orden: 1 }),
  webs: yup.array().min(1, { text: `${t('details.validation.tab')} ${t('details.description.tab')}: ${t('details.validation.noData')} "${t('details.documentation.web')}"`, optional: true, orden: 1 }).required(),
}).required();

export default DescriptionSchema;
