/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { type FC } from 'react';
import styles from './projectAutocomplete.module.scss';
import { t } from 'i18next';
import TitleValue from '../../../fullDetail/components/titleValue/titleValue';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import { CenterParams } from '../../../../types/Admin';
import CancelIcon from '@mui/icons-material/Cancel';

interface ProjectAutocompleteProps {
  options: any[];
  onInputChange: (value: string) => any;
  onChange: (params: CenterParams | undefined) => any;
  onClear: () => any;
  value?: string;
  freeSolo: boolean;
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const ProjectAutocomplete: FC<ProjectAutocompleteProps> = (props) => {
  const {options, onInputChange, onChange, onClear, value, freeSolo} = props;

  const handleChange = (newValue: string) => {
    const params = options.find(op => op.codigoNombre === newValue);
    onChange(params);
  };

  const handleInputChange = (newValue: string) => {
    onInputChange(newValue);
  };

  return (
    <div className={styles.autocompleteContainer}>
      <div className={styles.titleValue1}>
        <TitleValue
          identifier='InputAutocomplete'
          title={t('administration.project')}
          value={value}
          editable
          type='autocomplete'
        >
          <Autocomplete
            id='autocomplete-searchMethod'
            options={options.map(o => o.codigoNombre)}
            noOptionsText={t('input.noResults')}
            freeSolo={freeSolo}
            renderInput={(params) =>
              <TextField
                variant='standard'
                value={'adwawd'}
                {...params}
                InputProps={{
                  ...params.InputProps, disableUnderline: true, endAdornment: (
                    <InputAdornment position="end">
                      {value && value.length > 0
                        ? <CancelIcon
                          className={`${styles.cancelIcon} adornment`}
                          onClick={() => {
                            onClear();
                            onChange(undefined);
                          }}
                        />
                        : null
                      }
                    </InputAdornment>
                  )
                }}
              />}
            disableClearable
            forcePopupIcon={false}
            onInputChange={(e, newValue) => handleInputChange(newValue)}
            onChange={(e, newValue) => handleChange(newValue)}
            value={value}
            // onChange={(e, newValue) => onChange(newValue)}
          />
        </TitleValue>
      </div>
    </div>
  );
};

export default ProjectAutocomplete;

