import React, { useEffect, useState, type FC, useRef, useCallback, useContext } from 'react';
import styles from './usersTable.module.scss';
import ObrasAPI from '../../../services/APIObras';
import { Table, TableCell, TableHead, TableRow } from '@mui/material';
import { t } from 'i18next';
import UsersTableRow from './usersTableRow/usersTableRow';
import { Usuario } from '../../../types/Admin';
import LoadingSpinner from '../../../components/loadingSpinner/loadingSpinner';
import { AdminContext } from '../../../context/AdminProvider';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const unorm = require('unorm');


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UsersTableProps {
  userInput: string;
}

//let allUsers: Usuario[] = [];

/**
 * Remove accents from a text
 * @param text the text to normalize
 * @returns the text without accents
 */
const normalize = (text: string): string => {
  return unorm.nfd(text).replace(/[\u0300-\u036f]/g, '');
};

/**
 * Search if an input of words are the beginning of a list of users
 * @param list the list of users to search
 * @param input the input
 * @returns 
 */
const searchByText = (list: Usuario[], input: string) => {
  const wordsToSearch = normalize(input.toLowerCase()).split(' ');
  return list.filter(user => {
    const userNormalized = normalize(user.nombre.toLowerCase());
    return wordsToSearch.every((word: string) => {
      return userNormalized.split(' ').some(u => u.startsWith(word));
    });
  });
};

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const UsersTable: FC<UsersTableProps> = (props) => {
  const { userInput } = props;
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<Usuario[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const api = ObrasAPI.getInstance();
  const observer = useRef<IntersectionObserver>();
  const adminContext = useContext(AdminContext);

  const lastUserRef = useCallback((user: HTMLDivElement) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    // Using IntersectionObserver and a Ref on the last element of the list, we can load data when the last element is displayed.
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPageNumber(previous => previous + 1);
      }
    });
    if (user) observer.current.observe(user);
  }, [loading, hasMore]);

  useEffect(() => {
    const usersFiltered = searchByText(Array.from(adminContext.allUsers), userInput);
    setUsers(prevUsers => {
      return [... new Set([...prevUsers, ...usersFiltered.slice((pageNumber - 1) * 10, pageNumber * 10)])];
    });
    setLoading(false);
    setHasMore(pageNumber * 10 <= usersFiltered.length);
  }, [pageNumber]);

  useEffect(() => {
    setPageNumber(1);
    if (Array.from(adminContext.allUsers)?.length > 0) {
      const usersFiltered = searchByText(Array.from(adminContext.allUsers), userInput);
      setUsers(usersFiltered.slice(0, 10));
      setLoading(false);
      setHasMore(usersFiltered.length > 10);
    } else {
      setLoading(true);
      api.getUsers(pageNumber)
        .then(u => {
          if (!u.statusCode) {
            adminContext.handleCacheUsers(u);
            setUsers(u.slice(0, 10));
            setLoading(false);
            setHasMore(u.length > 10);
          } else {
            setLoading(false);
            adminContext.handleCacheUsers([]);
          }
        }).catch(err => {
          console.log(err);
        });
    }
  }, [userInput]);

  return (
    <div className={styles.usersTable}>
      <Table>
        <TableHead >
          <TableRow className={styles.tableHead}>
            <TableCell>{t('administration.table.name')}</TableCell>
            <TableCell>{t('administration.table.roles')}</TableCell>
            <TableCell align='right'>{t('administration.table.edit')}</TableCell>
          </TableRow>
        </TableHead>
      </Table>
      {users.length > 0 ?
        <div className={styles.tableBody}>
          {users.map((u, i) => {
            if (['viewer', 'viewer_vip'].every((role) => u.grupos.includes(role))) {
              u.grupos = u.grupos.filter(g => g !== 'viewer');
            }
            return (
              <div
                key={i}
                className={styles.tableRowReference}
                ref={users.length > i ? lastUserRef : null}
              >
                <UsersTableRow userInput={userInput} user={u} index={i} />
              </div>
            );
          })}
        </div>
        : loading ?
          <div className={styles.tableBody} style={{ height: '300px' }}>
            <LoadingSpinner />
          </div> :
          <div className={styles.tableBody} style={{
            height: '82px',
            display: 'flex',
            flexFlow: 'row',
            marginTop: '20px',
            width: '100%',
            marginLeft: '15px',
          }}>
            <p>{t('administration.table.notFound')}</p>
          </div>
      }
    </div >
  );
};

export default UsersTable;
