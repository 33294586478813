/**
* Autocomplete Filter Types
*/
const FILTER_TYPES = {
  nameCode: 'nameCode',
  country: 'country',
  supercenter: 'supercenter',
  workType: 'workType',
  workTypeKeyword: 'workTypeKeyword',
  workTypeGroupType: 'workTypeGroupType',
  projectStatus: 'projectStatus',
  adjudicationDate: 'AdjudicationDate', // If it is a combined filter (from, to, exact...), it must start with uppercase letter
  fromAdjudicationDate: 'fromAdjudicationDate',
  toAdjudicationDate: 'toAdjudicationDate',
  adjudicationAmount: 'AdjudicationAmount', // If it is a combined filter (from, to, exact...), it must start with uppercase letter
  fromAdjudicationAmount: 'fromAdjudicationAmount',
  toAdjudicationAmount: 'toAdjudicationAmount',
  exactAdjudicationAmount: 'exactAdjudicationAmount',
  accionaBudget: 'accionaBudget',
  fromaccionaBudget: 'fromaccionaBudget',
  toaccionaBudget: 'toaccionaBudget',
  exactaccionaBudget: 'exactaccionaBudget',
  updatedAccionaBudget: 'updatedAccionaBudget',
  fromupdatedAccionaBudget: 'fromupdatedAccionaBudget',
  toupdatedAccionaBudget: 'toupdatedAccionaBudget',
  exactupdatedAccionaBudget: 'exactupdatedAccionaBudget',
  finalAccionaBudget: 'finalAccionaBudget',
  fromfinalAccionaBudget: 'fromfinalAccionaBudget',
  tofinalAccionaBudget: 'tofinalAccionaBudget',
  exactfinalAccionaBudget: 'exactfinalAccionaBudget',
  updatedBudget: 'updatedBudget',
  fromupdatedBudget: 'fromupdatedBudget',
  toupdatedBudget: 'toupdatedBudget',
  exactupdatedBudget: 'exactupdatedBudget',
  finalBudget: 'finalBudget',
  fromfinalBudget: 'fromfinalBudget',
  tofinalBudget: 'tofinalBudget',
  exactfinalBudget: 'exactfinalBudget',
  updatedBudgetDate: 'updatedBudgetDate',
  fromupdatedBudgetDate: 'fromupdatedBudgetDate',
  toupdatedBudgetDate: 'toupdatedBudgetDate',
  finalBudgetDate: 'finalBudgetDate',
  fromfinalBudgetDate: 'fromfinalBudgetDate',
  tofinalBudgetDate: 'tofinalBudgetDate',
  localCurrency: 'localCurrency',
  projectName: 'projectName',
  manager: 'manager',
  tenderCode: 'tenderCode',
  businessActivity: 'businessActivity',
  zone: 'zone',
  accionaParticipation: 'AccionaParticipation', // If it is a combined filter (from, to, exact...), it must start with uppercase letter
  fromAccionaParticipation: 'fromAccionaParticipation',
  toAccionaParticipation: 'toAccionaParticipation',
  exactAccionaParticipation: 'exactAccionaParticipation',
  descGeneral: 'descGeneral',
  descDetallada: 'descDetallada',
  infoDestacable: 'infoDestacable',
  spaceType: 'spaceType',
  spaceLocation: 'spaceLocation',
  extinctionName: 'extinctionName',
  culturalName: 'culturalName',
  extinctionLevel: 'extinctionLevel',
  culturalLevel: 'culturalLevel',
  protectedArea: 'ProtectedArea', // If it is a combined filter (from, to, exact...), it must start with uppercase letter
  fromProtectedArea: 'fromProtectedArea',
  toProtectedArea: 'toProtectedArea',
  exactProtectedArea: 'exactProtectedArea',
  protectedProjectArea: 'ProtectedProjectArea', // If it is a combined filter (from, to, exact...), it must start with uppercase letter
  fromProtectedProjectArea: 'fromProtectedProjectArea',
  toProtectedProjectArea: 'toProtectedProjectArea',
  exactProtectedProjectArea: 'exactProtectedProjectArea',
  town: 'town',
  zipCode: 'zipCode',
  spaceName: 'spaceName',
  buildingProcess: 'buildingProcess',
  workUnit: 'workUnit',
  measurement: 'measurement',
  workTypeTechnicalAspects: 'workTypeTechnicalAspects',
  magnitudeUnit: 'magnitudeUnit',
  magnitude: 'magnitude',
  classificationEstimatedAmount: 'classificationEstimatedAmount',
  estimatedAmountUnit: 'estimatedAmountUnit',
  estimatedAmount: 'estimatedAmount',
  classificationFinalAmount: 'classificationFinalAmount',
  finalAmountUnit: 'finalAmountUnit',
  finalAmount: 'finalAmount',
  typeContract: 'typeContract',
  modelContract: 'modelContract',
  scopeContract: 'scopeContract',
  sourceFinancing: 'sourceFinancing',
  formPayment: 'formPayment',
  warrantyPeriod: 'WarrantyPeriod',
  fromWarrantyPeriod: 'fromWarrantyPeriod',
  toWarrantyPeriod: 'toWarrantyPeriod',
  exactWarrantyPeriod: 'exactWarrantyPeriod',
  namePartner: 'namePartner',
  numberPartner: 'NumberPartner',
  fromNumberPartner: 'fromNumberPartner',
  toNumberPartner: 'toNumberPartner',
  exactNumberPartner: 'exactNumberPartner',
  percentPartnerParticipation: 'PercentPartnerParticipation',
  fromPercentPartnerParticipation: 'fromPercentPartnerParticipation',
  toPercentPartnerParticipation: 'toPercentPartnerParticipation',
  exactPercentPartnerParticipation: 'exactPercentPartnerParticipation',
  clientName: 'clientName',
  clientDesignerScope: 'clientDesignerScope',
  scopeTechnicalAssistance: 'scopeTechnicalAssistance',
  technicalAssistanceEntity: 'technicalAssistanceEntity',
  entityDesignerWorkManagement: 'entityDesignerWorkManagement',
  clientDesignerEntity: 'clientDesignerEntity',
  scopeWorkManagementDesigner: 'scopeWorkManagementDesigner',
  technicalAssistanceStatus: 'technicalAssistanceStatus',
  workManagementStatus: 'workManagementStatus',
  clientDesignerStatus: 'clientDesignerStatus',
  clientCollaborationEndDate: 'ClientCollaborationEndDate',
  fromClientCollaborationEndDate: 'fromClientCollaborationEndDate',
  toClientCollaborationEndDate: 'toClientCollaborationEndDate',
  endDateCollaborationTechnicalAssistance: 'EndDateCollaborationTechnicalAssistance',
  fromEndDateCollaborationTechnicalAssistance: 'fromEndDateCollaborationTechnicalAssistance',
  toEndDateCollaborationTechnicalAssistance: 'toEndDateCollaborationTechnicalAssistance',
  startDateWorkManagement: 'StartDateWorkManagement',
  fromStartDateWorkManagement: 'fromStartDateWorkManagement',
  toStartDateWorkManagement: 'toStartDateWorkManagement',
  endDateCollaborationSiteManagement: 'EndDateCollaborationSiteManagement',
  fromEndDateCollaborationSiteManagement: 'fromEndDateCollaborationSiteManagement',
  toEndDateCollaborationSiteManagement: 'toEndDateCollaborationSiteManagement',
  startDateTechnicalAssistance: 'StartDateTechnicalAssistance',
  fromStartDateTechnicalAssistance: 'fromStartDateTechnicalAssistance',
  toStartDateTechnicalAssistance: 'toStartDateTechnicalAssistance',
  signatureContract: 'SignatureContract',
  fromSignatureContract: 'fromSignatureContract',
  toSignatureContract: 'toSignatureContract',
  stakeoutReport: 'StakeoutReport',
  fromStakeoutReport: 'fromStakeoutReport',
  toStakeoutReport: 'toStakeoutReport',
  beginningWork: 'BeginningWork',
  fromBeginningWork: 'fromBeginningWork',
  toBeginningWork: 'toBeginningWork',
  initialDeadline: 'InitialDeadline',
  fromInitialDeadline: 'fromInitialDeadline',
  toInitialDeadline: 'toInitialDeadline',
  exactInitialDeadline: 'exactInitialDeadline',
  updatedDeadline: 'UpdatedDeadline',
  fromUpdatedDeadline: 'fromUpdatedDeadline',
  toUpdatedDeadline: 'toUpdatedDeadline',
  exactUpdatedDeadline: 'exactUpdatedDeadline',
  plannedEnd: 'PlannedEnd',
  fromPlannedEnd: 'fromPlannedEnd',
  toPlannedEnd: 'toPlannedEnd',
  plannedEndUpdatedApproved: 'PlannedEndUpdatedApproved',
  fromPlannedEndUpdatedApproved: 'fromPlannedEndUpdatedApproved',
  toPlannedEndUpdatedApproved: 'toPlannedEndUpdatedApproved',
  receptionReport: 'ReceptionReport',
  fromReceptionReport: 'fromReceptionReport',
  toReceptionReport: 'toReceptionReport',
  endExecution: 'EndExecution',
  fromEndExecution: 'fromEndExecution',
  toEndExecution: 'toEndExecution',
  taxonomicCommitment: 'taxonomicCommitment',
  controversies: 'controversies',
  naceCode: 'naceCode',
  gisBudget: 'GisBudget',
  fromGisBudget: 'fromGisBudget',
  toGisBudget: 'toGisBudget',
  exactGisBudget: 'exactGisBudget',
  carbonBudget: 'CarbonBudget',
  fromCarbonBudget: 'fromCarbonBudget',
  toCarbonBudget: 'toCarbonBudget',
  exactCarbonBudget: 'exactCarbonBudget',
  taxonomicActivity: 'taxonomicActivity',
  socialSafeguards: 'socialSafeguards',
  socialCategory: 'socialCategory',
  gisPercentage: 'gisPercentage',
  eligibility: 'eligibility',
  customerDap: 'customerDap',
  accionaDap: 'accionaDap',
  gisExemption: 'gisExemption',
  gis: 'gis',
  maturityLevel: 'maturityLevel',
  categorization: 'categorization',
  requirements: 'requirements',
  cde: 'cde',
  typesBimApplications: 'typesBimApplications',
  complexityNote: 'ComplexityNote',
  fromComplexityNote: 'fromComplexityNote',
  toComplexityNote: 'toComplexityNote',
  exactComplexityNote: 'exactComplexityNote',
  noteComplexityBimApplications: 'NoteComplexityBimApplications',
  fromNoteComplexityBimApplications: 'fromNoteComplexityBimApplications',
  toNoteComplexityBimApplications: 'toNoteComplexityBimApplications',
  exactNoteComplexityBimApplications: 'exactNoteComplexityBimApplications',
  complexityNoteUsageManagement: 'ComplexityNoteUsageManagement',
  fromComplexityNoteUsageManagement: 'fromComplexityNoteUsageManagement',
  toComplexityNoteUsageManagement: 'toComplexityNoteUsageManagement',
  exactComplexityNoteUsageManagement: 'exactComplexityNoteUsageManagement',
  complexityNoteStandardUses: 'ComplexityNoteStandardUses',
  fromComplexityNoteStandardUses: 'fromComplexityNoteStandardUses',
  toComplexityNoteStandardUses: 'toComplexityNoteStandardUses',
  exactComplexityNoteStandardUses: 'exactComplexityNoteStandardUses',
};

export const FILTER_TYPES_ANALYTICS = {
  [FILTER_TYPES.nameCode]: 'Proyecto',
  [FILTER_TYPES.country]: 'País',
  [FILTER_TYPES.supercenter]: 'Supercentro',
  [FILTER_TYPES.workType]: 'Tipo de obra',
  [FILTER_TYPES.workTypeKeyword]: 'Tipo de obra',
  [FILTER_TYPES.workTypeGroupType]: 'Grupo de tipo de obra',
  [FILTER_TYPES.projectStatus]: 'Estado del proyecto',
  [FILTER_TYPES.adjudicationDate]: 'Fecha de adjudicación',
  [FILTER_TYPES.fromAdjudicationDate]: 'Fecha de adjudicación',
  [FILTER_TYPES.toAdjudicationDate]: 'Fecha de adjudicación',
  [FILTER_TYPES.adjudicationAmount]: 'Importe de adjudicación',
  [FILTER_TYPES.fromAdjudicationAmount]: 'Importe de adjudicación',
  [FILTER_TYPES.toAdjudicationAmount]: 'Importe de adjudicación',
  [FILTER_TYPES.exactAdjudicationAmount]: 'Importe de adjudicación',
  [FILTER_TYPES.accionaBudget]: 'Presupuesto Acciona',
  [FILTER_TYPES.fromaccionaBudget]: 'Presupuesto Acciona',
  [FILTER_TYPES.toaccionaBudget]: 'Presupuesto Acciona',
  [FILTER_TYPES.exactaccionaBudget]: 'Presupuesto Acciona',
  [FILTER_TYPES.updatedAccionaBudget]: 'Presupuesto Acciona actualizado',
  [FILTER_TYPES.fromupdatedAccionaBudget]: 'Presupuesto Acciona actualizado',
  [FILTER_TYPES.toupdatedAccionaBudget]: 'Presupuesto Acciona actualizado',
  [FILTER_TYPES.exactupdatedAccionaBudget]: 'Presupuesto Acciona actualizado',
  [FILTER_TYPES.finalAccionaBudget]: 'Presupuesto final Acciona',
  [FILTER_TYPES.fromfinalAccionaBudget]: 'Presupuesto final Acciona',
  [FILTER_TYPES.tofinalAccionaBudget]: 'Presupuesto final Acciona',
  [FILTER_TYPES.exactfinalAccionaBudget]: 'Presupuesto final Acciona',
  [FILTER_TYPES.updatedBudget]: 'Presupuesto actualizado',
  [FILTER_TYPES.fromupdatedBudget]: 'Presupuesto actualizado',
  [FILTER_TYPES.toupdatedBudget]: 'Presupuesto actualizado',
  [FILTER_TYPES.exactupdatedBudget]: 'Presupuesto actualizado',
  [FILTER_TYPES.finalBudget]: 'Presupuesto final',
  [FILTER_TYPES.fromfinalBudget]: 'Presupuesto final',
  [FILTER_TYPES.tofinalBudget]: 'Presupuesto final',
  [FILTER_TYPES.exactfinalBudget]: 'Presupuesto final',
  [FILTER_TYPES.updatedBudgetDate]: 'Fecha final de presupuesto actualizada',
  [FILTER_TYPES.fromupdatedBudgetDate]: 'Fecha final de presupuesto actualizada',
  [FILTER_TYPES.toupdatedBudgetDate]: 'Fecha final de presupuesto actualizada',
  [FILTER_TYPES.finalBudgetDate]: 'Fecha final de presupuesto',
  [FILTER_TYPES.fromfinalBudgetDate]: 'Fecha final de presupuesto',
  [FILTER_TYPES.tofinalBudgetDate]: 'Fecha final de presupuesto',
  [FILTER_TYPES.localCurrency]: 'Moneda',
  [FILTER_TYPES.projectName]: 'Proyecto',
  [FILTER_TYPES.manager]: 'Responsable',
  [FILTER_TYPES.tenderCode]: 'Código licitación',
  [FILTER_TYPES.businessActivity]: 'Actividad de negocio',
  [FILTER_TYPES.zone]: 'Zona',
  [FILTER_TYPES.accionaParticipation]: 'Participación Acciona',
  [FILTER_TYPES.fromAccionaParticipation]: 'Participación Acciona',
  [FILTER_TYPES.toAccionaParticipation]: 'Participación Acciona',
  [FILTER_TYPES.exactAccionaParticipation]: 'Participación Acciona',
  [FILTER_TYPES.descGeneral]: 'Descripción general',
  [FILTER_TYPES.descDetallada]: 'Descripción detallada',
  [FILTER_TYPES.infoDestacable]: 'Información destacable',
  [FILTER_TYPES.spaceType]: 'Tipo espacio',
  [FILTER_TYPES.spaceLocation]: 'Localización espacio',
  [FILTER_TYPES.extinctionName]: 'Nivel de peligro',
  [FILTER_TYPES.culturalName]: 'Nivel cultural',
  [FILTER_TYPES.extinctionLevel]: 'Nivel de peligro',
  [FILTER_TYPES.culturalLevel]: 'Nivel cultural',
  [FILTER_TYPES.protectedArea]: 'Superficie protegida',
  [FILTER_TYPES.fromProtectedArea]: 'Superficie protegida',
  [FILTER_TYPES.toProtectedArea]: 'Superficie protegida',
  [FILTER_TYPES.exactProtectedArea]: 'Superficie protegida',
  [FILTER_TYPES.protectedProjectArea]: 'Área protegida',
  [FILTER_TYPES.fromProtectedProjectArea]: 'Área protegida',
  [FILTER_TYPES.toProtectedProjectArea]: 'Área protegida',
  [FILTER_TYPES.exactProtectedProjectArea]: 'Área protegida',
  [FILTER_TYPES.town]: 'Municipio',
  [FILTER_TYPES.zipCode]: 'Código postal',
  [FILTER_TYPES.spaceName]: 'Espacio de protección',
  [FILTER_TYPES.buildingProcess]: 'Proceso constructivo',
  [FILTER_TYPES.workUnit]: 'Unidad de obra',
  [FILTER_TYPES.measurement]: 'Medida',
  [FILTER_TYPES.workTypeTechnicalAspects]: 'Aspectos técnicos',
  [FILTER_TYPES.magnitudeUnit]: 'Magnitud',
  [FILTER_TYPES.magnitude]: 'Magnitud',
  [FILTER_TYPES.classificationEstimatedAmount]: 'Clasificación de importe estimado',
  [FILTER_TYPES.estimatedAmountUnit]: 'Importe estimado',
  [FILTER_TYPES.estimatedAmount]: 'Importe estimado',
  [FILTER_TYPES.classificationFinalAmount]: 'Clasificación de importe final',
  [FILTER_TYPES.finalAmountUnit]: 'Importe final',
  [FILTER_TYPES.finalAmount]: 'Importe final',
  [FILTER_TYPES.typeContract]: 'Tipo de contrato',
  [FILTER_TYPES.modelContract]: 'Modelo de contrato',
  [FILTER_TYPES.scopeContract]: 'Alcance de contrato',
  [FILTER_TYPES.sourceFinancing]: 'Fuente de financiación',
  [FILTER_TYPES.formPayment]: 'Método de pago',
  [FILTER_TYPES.warrantyPeriod]: 'Periodo de garantía',
  [FILTER_TYPES.fromWarrantyPeriod]: 'Periodo de garantía',
  [FILTER_TYPES.toWarrantyPeriod]: 'Periodo de garantía',
  [FILTER_TYPES.exactWarrantyPeriod]: 'Periodo de garantía',
  [FILTER_TYPES.namePartner]: 'Socio',
  [FILTER_TYPES.numberPartner]: 'Socio',
  [FILTER_TYPES.fromNumberPartner]: 'Socio',
  [FILTER_TYPES.toNumberPartner]: 'Socio',
  [FILTER_TYPES.exactNumberPartner]: 'exactNumberPartner',
  [FILTER_TYPES.percentPartnerParticipation]: 'Participación de socio',
  [FILTER_TYPES.fromPercentPartnerParticipation]: 'Participación de socio',
  [FILTER_TYPES.toPercentPartnerParticipation]: 'Participación de socio',
  [FILTER_TYPES.exactPercentPartnerParticipation]: 'Participación de socio',
  [FILTER_TYPES.clientName]: 'Cliente',
  [FILTER_TYPES.clientDesignerScope]: 'Alcance de diseño de cliente',
  [FILTER_TYPES.scopeTechnicalAssistance]: 'Asistencia técnica',
  [FILTER_TYPES.technicalAssistanceEntity]: 'Entidad de asistencia técnica',
  [FILTER_TYPES.entityDesignerWorkManagement]: 'Gestión de diseño de obra',
  [FILTER_TYPES.clientDesignerEntity]: 'Entidad de diseño de cliente',
  [FILTER_TYPES.scopeWorkManagementDesigner]: 'Diseño de gestión de obra',
  [FILTER_TYPES.technicalAssistanceStatus]: 'Estado de asistencia técnica',
  [FILTER_TYPES.workManagementStatus]: 'Estado de gestión de obra',
  [FILTER_TYPES.clientDesignerStatus]: 'Estado de diseño de cliente',
  [FILTER_TYPES.clientCollaborationEndDate]: 'Fecha de fin de colaboración',
  [FILTER_TYPES.fromClientCollaborationEndDate]: 'Fecha de fin de colaboración',
  [FILTER_TYPES.toClientCollaborationEndDate]: 'Fecha de fin de colaboración',
  [FILTER_TYPES.endDateCollaborationTechnicalAssistance]: 'Fecha de fin de asistencia técnica',
  [FILTER_TYPES.fromEndDateCollaborationTechnicalAssistance]: 'Fecha de fin de asistencia técnica',
  [FILTER_TYPES.toEndDateCollaborationTechnicalAssistance]: 'Fecha de fin de asistencia técnica',
  [FILTER_TYPES.startDateWorkManagement]: 'Fecha de inicio de gestión de obra',
  [FILTER_TYPES.fromStartDateWorkManagement]: 'Fecha de inicio de gestión de obra',
  [FILTER_TYPES.toStartDateWorkManagement]: 'Fecha de inicio de gestión de obra',
  [FILTER_TYPES.endDateCollaborationSiteManagement]: 'Fecha de fin de colaboración en la gestión de obra',
  [FILTER_TYPES.fromEndDateCollaborationSiteManagement]: 'Fecha de fin de colaboración en la gestión de obra',
  [FILTER_TYPES.toEndDateCollaborationSiteManagement]: 'Fecha de fin de colaboración en la gestión de obra',
  [FILTER_TYPES.startDateTechnicalAssistance]: 'Fecha de inicio de asistencia técnica',
  [FILTER_TYPES.fromStartDateTechnicalAssistance]: 'Fecha de inicio de asistencia técnica',
  [FILTER_TYPES.toStartDateTechnicalAssistance]: 'Fecha de inicio de asistencia técnica',
  [FILTER_TYPES.signatureContract]: 'Fecha de contrato',
  [FILTER_TYPES.fromSignatureContract]: 'Fecha de contrato',
  [FILTER_TYPES.toSignatureContract]: 'Fecha de contrato',
  [FILTER_TYPES.stakeoutReport]: 'Fecha de replanteo',
  [FILTER_TYPES.fromStakeoutReport]: 'Fecha de replanteo',
  [FILTER_TYPES.toStakeoutReport]: 'Fecha de replanteo',
  [FILTER_TYPES.beginningWork]: 'Inicio',
  [FILTER_TYPES.fromBeginningWork]: 'Inicio',
  [FILTER_TYPES.toBeginningWork]: 'Inicio',
  [FILTER_TYPES.initialDeadline]: 'Plazo inicial',
  [FILTER_TYPES.fromInitialDeadline]: 'Plazo inicial',
  [FILTER_TYPES.toInitialDeadline]: 'Plazo inicial',
  [FILTER_TYPES.exactInitialDeadline]: 'Plazo inicial',
  [FILTER_TYPES.updatedDeadline]: 'Plazo actualizado',
  [FILTER_TYPES.fromUpdatedDeadline]: 'Plazo actualizado',
  [FILTER_TYPES.toUpdatedDeadline]: 'Plazo actualizado',
  [FILTER_TYPES.exactUpdatedDeadline]: 'Plazo actualizado',
  [FILTER_TYPES.plannedEnd]: 'Fin previsto',
  [FILTER_TYPES.fromPlannedEnd]: 'Fin previsto',
  [FILTER_TYPES.toPlannedEnd]: 'Fin previsto',
  [FILTER_TYPES.plannedEndUpdatedApproved]: 'Fin previsto y aprobado',
  [FILTER_TYPES.fromPlannedEndUpdatedApproved]: 'Fin previsto y aprobado',
  [FILTER_TYPES.toPlannedEndUpdatedApproved]: 'Fin previsto y aprobado',
  [FILTER_TYPES.receptionReport]: 'Acta de recepción',
  [FILTER_TYPES.fromReceptionReport]: 'Acta de recepción',
  [FILTER_TYPES.toReceptionReport]: 'Acta de recepción',
  [FILTER_TYPES.endExecution]: 'Fecha final de ejecución',
  [FILTER_TYPES.fromEndExecution]: 'Fecha final de ejecución',
  [FILTER_TYPES.toEndExecution]: 'Fecha final de ejecución',
  [FILTER_TYPES.taxonomicCommitment]: 'Compromiso taxonómico',
  [FILTER_TYPES.controversies]: 'Controversias',
  [FILTER_TYPES.naceCode]: 'Código NACE',
  [FILTER_TYPES.gisBudget]: 'Presupuesto GIS',
  [FILTER_TYPES.fromGisBudget]: 'Presupuesto GIS',
  [FILTER_TYPES.toGisBudget]: 'Presupuesto GIS',
  [FILTER_TYPES.exactGisBudget]: 'Presupuesto GIS',
  [FILTER_TYPES.carbonBudget]: 'Presupuesto de carbono',
  [FILTER_TYPES.fromCarbonBudget]: 'Presupuesto de carbono',
  [FILTER_TYPES.toCarbonBudget]: 'Presupuesto de carbono',
  [FILTER_TYPES.exactCarbonBudget]: 'Presupuesto de carbono',
  [FILTER_TYPES.taxonomicActivity]: 'Actividad taxonómica',
  [FILTER_TYPES.socialSafeguards]: 'Salvaguardas sociales',
  [FILTER_TYPES.socialCategory]: 'Categorización social',
  [FILTER_TYPES.gisPercentage]: 'Porcentaje GIS',
  [FILTER_TYPES.eligibility]: 'Elegibilidad',
  [FILTER_TYPES.customerDap]: 'DAP Cliente',
  [FILTER_TYPES.accionaDap]: 'DAP Acciona',
  [FILTER_TYPES.gisExemption]: 'Excención GIS',
  [FILTER_TYPES.gis]: 'GIS',
  [FILTER_TYPES.maturityLevel]: 'Madurez',
  [FILTER_TYPES.categorization]: 'Categorización',
  [FILTER_TYPES.requirements]: 'Requisitos',
  [FILTER_TYPES.cde]: 'CDE',
  [FILTER_TYPES.typesBimApplications]: 'Usos BIM',
  [FILTER_TYPES.complexityNote]: 'Nota global',
  [FILTER_TYPES.fromComplexityNote]: 'Nota global',
  [FILTER_TYPES.toComplexityNote]: 'Nota global',
  [FILTER_TYPES.exactComplexityNote]: 'Nota global',
  [FILTER_TYPES.noteComplexityBimApplications]: 'Nota usos BIM',
  [FILTER_TYPES.fromNoteComplexityBimApplications]: 'Nota usos BIM',
  [FILTER_TYPES.toNoteComplexityBimApplications]: 'Nota usos BIM',
  [FILTER_TYPES.exactNoteComplexityBimApplications]: 'Nota usos BIM',
  [FILTER_TYPES.complexityNoteUsageManagement]: 'Nota usos de gestión',
  [FILTER_TYPES.fromComplexityNoteUsageManagement]: 'Nota usos de gestión',
  [FILTER_TYPES.toComplexityNoteUsageManagement]: 'Nota usos de gestión',
  [FILTER_TYPES.exactComplexityNoteUsageManagement]: 'Nota usos de gestión',
  [FILTER_TYPES.complexityNoteStandardUses]: 'Nota usos estandarización',
  [FILTER_TYPES.fromComplexityNoteStandardUses]: 'Nota usos estandarización',
  [FILTER_TYPES.toComplexityNoteStandardUses]: 'Nota usos estandarización',
  [FILTER_TYPES.exactComplexityNoteStandardUses]: 'Nota usos estandarización'
};

export const FILTER_CONFIG = {
  amountCurrency: 'EUR',
  percentage: '%',
  hectare: 'ha',
  period: 'month',
  number: 'number', // Allow numbers with ',' or. Example 2,5
  integer: 'integer', // Allow only integers. Example 2
};

export const BASIC_FILTERS = [
  FILTER_TYPES.adjudicationAmount,
  FILTER_TYPES.toAdjudicationAmount,
  FILTER_TYPES.fromAdjudicationAmount,
  FILTER_TYPES.exactAdjudicationAmount,
  FILTER_TYPES.adjudicationDate,
  FILTER_TYPES.toAdjudicationDate,
  FILTER_TYPES.fromAdjudicationDate,
  FILTER_TYPES.country,
  FILTER_TYPES.nameCode,
  FILTER_TYPES.projectStatus,
  FILTER_TYPES.supercenter,
  FILTER_TYPES.workType,
  FILTER_TYPES.workTypeGroupType,
  FILTER_TYPES.workTypeKeyword,
];

export const GENERAL_DATA_FILTERS = [
  FILTER_TYPES.projectName,
  FILTER_TYPES.manager,
  FILTER_TYPES.tenderCode,
  FILTER_TYPES.businessActivity,
  FILTER_TYPES.zone,
  FILTER_TYPES.accionaParticipation,
  FILTER_TYPES.toAccionaParticipation,
  FILTER_TYPES.fromAccionaParticipation,
  FILTER_TYPES.exactAccionaParticipation,
];

export const LOCATION_FILTERS = [
  FILTER_TYPES.spaceType,
  FILTER_TYPES.spaceLocation,
  FILTER_TYPES.extinctionName,
  FILTER_TYPES.culturalName,
  FILTER_TYPES.extinctionLevel,
  FILTER_TYPES.culturalLevel,
  FILTER_TYPES.protectedArea,
  FILTER_TYPES.fromProtectedArea,
  FILTER_TYPES.toProtectedArea,
  FILTER_TYPES.exactProtectedArea,
  FILTER_TYPES.protectedProjectArea,
  FILTER_TYPES.fromProtectedProjectArea,
  FILTER_TYPES.toProtectedProjectArea,
  FILTER_TYPES.exactProtectedProjectArea,
  FILTER_TYPES.town,
  FILTER_TYPES.zipCode,
  FILTER_TYPES.spaceName,
];

export const BUDGET_FILTERS = [
  FILTER_TYPES.fromaccionaBudget,
  FILTER_TYPES.toaccionaBudget,
  FILTER_TYPES.exactaccionaBudget,
  FILTER_TYPES.accionaBudget,
  FILTER_TYPES.fromfinalAccionaBudget,
  FILTER_TYPES.tofinalAccionaBudget,
  FILTER_TYPES.exactfinalAccionaBudget,
  FILTER_TYPES.finalAccionaBudget,
  FILTER_TYPES.fromfinalBudget,
  FILTER_TYPES.tofinalBudget,
  FILTER_TYPES.exactfinalBudget,
  FILTER_TYPES.finalBudget,
  FILTER_TYPES.fromfinalBudgetDate,
  FILTER_TYPES.tofinalBudgetDate,
  FILTER_TYPES.finalBudgetDate,
  FILTER_TYPES.fromupdatedAccionaBudget,
  FILTER_TYPES.toupdatedAccionaBudget,
  FILTER_TYPES.exactupdatedAccionaBudget,
  FILTER_TYPES.updatedAccionaBudget,
  FILTER_TYPES.fromupdatedBudget,
  FILTER_TYPES.toupdatedBudget,
  FILTER_TYPES.exactupdatedBudget,
  FILTER_TYPES.updatedBudget,
  FILTER_TYPES.fromupdatedBudgetDate,
  FILTER_TYPES.toupdatedBudgetDate,
  FILTER_TYPES.updatedBudgetDate,
  FILTER_TYPES.localCurrency
];

export const DESCRIPTION_FILTERS = [
  FILTER_TYPES.descGeneral,
  FILTER_TYPES.descDetallada,
  FILTER_TYPES.infoDestacable,
];

export const TECHINCAL_ASPECTS_FILTERS = [
  FILTER_TYPES.buildingProcess,
  FILTER_TYPES.workUnit,
  FILTER_TYPES.measurement,
  FILTER_TYPES.workTypeTechnicalAspects,
  FILTER_TYPES.magnitudeUnit,
  FILTER_TYPES.magnitude,
];

export const CONTRACT_FILTERS = [
  FILTER_TYPES.typeContract,
  FILTER_TYPES.modelContract,
  FILTER_TYPES.scopeContract,
  FILTER_TYPES.sourceFinancing,
  FILTER_TYPES.formPayment,
  FILTER_TYPES.warrantyPeriod,
  FILTER_TYPES.fromWarrantyPeriod,
  FILTER_TYPES.toWarrantyPeriod,
  FILTER_TYPES.exactWarrantyPeriod,
];

export const PARTNERS_FILTERS = [
  FILTER_TYPES.namePartner,
  FILTER_TYPES.numberPartner,
  FILTER_TYPES.fromNumberPartner,
  FILTER_TYPES.toNumberPartner,
  FILTER_TYPES.exactNumberPartner,
  FILTER_TYPES.percentPartnerParticipation,
  FILTER_TYPES.fromPercentPartnerParticipation,
  FILTER_TYPES.toPercentPartnerParticipation,
  FILTER_TYPES.exactPercentPartnerParticipation,
];

export const CLIENTS_FILTERS = [
  FILTER_TYPES.clientName,
  FILTER_TYPES.clientDesignerScope,
  FILTER_TYPES.scopeTechnicalAssistance,
  FILTER_TYPES.technicalAssistanceEntity,
  FILTER_TYPES.entityDesignerWorkManagement,
  FILTER_TYPES.clientDesignerEntity,
  FILTER_TYPES.scopeWorkManagementDesigner,
  FILTER_TYPES.technicalAssistanceStatus,
  FILTER_TYPES.workManagementStatus,
  FILTER_TYPES.clientDesignerStatus,
  FILTER_TYPES.clientCollaborationEndDate,
  FILTER_TYPES.fromClientCollaborationEndDate,
  FILTER_TYPES.toClientCollaborationEndDate,
  FILTER_TYPES.endDateCollaborationTechnicalAssistance,
  FILTER_TYPES.fromEndDateCollaborationTechnicalAssistance,
  FILTER_TYPES.toEndDateCollaborationTechnicalAssistance,
  FILTER_TYPES.startDateWorkManagement,
  FILTER_TYPES.fromStartDateWorkManagement,
  FILTER_TYPES.toStartDateWorkManagement,
  FILTER_TYPES.endDateCollaborationSiteManagement,
  FILTER_TYPES.fromEndDateCollaborationSiteManagement,
  FILTER_TYPES.toEndDateCollaborationSiteManagement,
  FILTER_TYPES.startDateTechnicalAssistance,
  FILTER_TYPES.fromStartDateTechnicalAssistance,
  FILTER_TYPES.toStartDateTechnicalAssistance,
];

export const DATES_FILTERS = [
  FILTER_TYPES.signatureContract,
  FILTER_TYPES.fromSignatureContract,
  FILTER_TYPES.toSignatureContract,
  FILTER_TYPES.stakeoutReport,
  FILTER_TYPES.fromStakeoutReport,
  FILTER_TYPES.toStakeoutReport,
  FILTER_TYPES.beginningWork,
  FILTER_TYPES.fromBeginningWork,
  FILTER_TYPES.toBeginningWork,
  FILTER_TYPES.initialDeadline,
  FILTER_TYPES.fromInitialDeadline,
  FILTER_TYPES.toInitialDeadline,
  FILTER_TYPES.exactInitialDeadline,
  FILTER_TYPES.updatedDeadline,
  FILTER_TYPES.fromUpdatedDeadline,
  FILTER_TYPES.toUpdatedDeadline,
  FILTER_TYPES.exactUpdatedDeadline,
  FILTER_TYPES.plannedEnd,
  FILTER_TYPES.fromPlannedEnd,
  FILTER_TYPES.toPlannedEnd,
  FILTER_TYPES.plannedEndUpdatedApproved,
  FILTER_TYPES.fromPlannedEndUpdatedApproved,
  FILTER_TYPES.toPlannedEndUpdatedApproved,
  FILTER_TYPES.receptionReport,
  FILTER_TYPES.fromReceptionReport,
  FILTER_TYPES.toReceptionReport,
  FILTER_TYPES.endExecution,
  FILTER_TYPES.fromEndExecution,
  FILTER_TYPES.toEndExecution,
];

export const ESG_FILTERS = [
  FILTER_TYPES.taxonomicCommitment,
  FILTER_TYPES.controversies,
  FILTER_TYPES.naceCode,
  FILTER_TYPES.gisBudget,
  FILTER_TYPES.fromGisBudget,
  FILTER_TYPES.toGisBudget,
  FILTER_TYPES.exactGisBudget,
  FILTER_TYPES.carbonBudget,
  FILTER_TYPES.fromCarbonBudget,
  FILTER_TYPES.toCarbonBudget,
  FILTER_TYPES.exactCarbonBudget,
  FILTER_TYPES.socialSafeguards,
  FILTER_TYPES.socialCategory,
  FILTER_TYPES.gisPercentage,
  FILTER_TYPES.eligibility,
  FILTER_TYPES.customerDap,
  FILTER_TYPES.accionaDap,
  FILTER_TYPES.gisExemption,
  FILTER_TYPES.gis,
];

export const BIM_FILTERS = [
  FILTER_TYPES.maturityLevel,
  FILTER_TYPES.categorization,
  FILTER_TYPES.requirements,
  FILTER_TYPES.cde,
  FILTER_TYPES.typesBimApplications,
  FILTER_TYPES.complexityNote,
  FILTER_TYPES.fromComplexityNote,
  FILTER_TYPES.toComplexityNote,
  FILTER_TYPES.exactComplexityNote,
  FILTER_TYPES.noteComplexityBimApplications,
  FILTER_TYPES.fromNoteComplexityBimApplications,
  FILTER_TYPES.toNoteComplexityBimApplications,
  FILTER_TYPES.exactNoteComplexityBimApplications,
  FILTER_TYPES.complexityNoteUsageManagement,
  FILTER_TYPES.fromComplexityNoteUsageManagement,
  FILTER_TYPES.toComplexityNoteUsageManagement,
  FILTER_TYPES.exactComplexityNoteUsageManagement,
  FILTER_TYPES.complexityNoteStandardUses,
  FILTER_TYPES.fromComplexityNoteStandardUses,
  FILTER_TYPES.toComplexityNoteStandardUses,
  FILTER_TYPES.exactComplexityNoteStandardUses,
];

export const CLASSIFICATION_FILTERS = [
  FILTER_TYPES.classificationEstimatedAmount,
  FILTER_TYPES.estimatedAmountUnit,
  FILTER_TYPES.estimatedAmount,
  FILTER_TYPES.classificationFinalAmount,
  FILTER_TYPES.finalAmountUnit,
  FILTER_TYPES.finalAmount,
];

// export const OVERLAY_FILTERS = Object.values(FILTER_TYPES);
export const OVERLAY_FILTERS = Object.values(FILTER_TYPES)
  .filter((ft) => !ft.startsWith('from')
    && (!ft.startsWith('to') || ft === FILTER_TYPES.town)
    && !ft.startsWith('exact')
    && !ft.startsWith('start')
    && !ft.startsWith('end'));

export default FILTER_TYPES;
