/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC } from 'react';
import styles from './partners.module.scss';
import { ObraProperties } from '../../../types/ObraDetails';
import partnerPicture from '../../../assets/client.svg';
import { useTranslation } from 'react-i18next';
import InformationIcon from '../components/informationIcon/informationIcon';
import PartnerBox from './components/PartnerBox/PartnerBox';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PartnersProps {
  obra: ObraProperties
}

const Partners: FC<PartnersProps> = (props) => {
  const { obra } = props;
  const [t] = useTranslation();

  const calculaParticipacion = (participacion: number) => {
    const [wholeNumber, decimal] = participacion.toString().split('.');
    return `${wholeNumber.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}${decimal && decimal.substring(0, 2) !== '00' ? ',' + decimal.substring(0, 2) : ''}%`;
  };

  // Sort the partners by their participation from highest to lowest, giving priority to "Acciona Construcción".
  const partnersSorted = obra.socios.empresasActualizadas?.sort((a, b) => {
    if (b.participacion !== a.participacion) {
      return b.participacion - a.participacion;
    } else {
      const aIsAccionaConstruccion = a.nombre.includes('Acciona Construcción');
      const bIsAccionaConstruccion = b.nombre.includes('Acciona Construcción');

      if (aIsAccionaConstruccion && !bIsAccionaConstruccion) {
        return -1;
      } else if (!aIsAccionaConstruccion && bIsAccionaConstruccion) {
        return 1;
      } else {
        return 0;
      }
    }
  });

  return (
    <div className={styles.partnersContainer}>
      <div className={styles.partnerWrapper}>
        <div id='partners' className={styles.heading}>{t('details.partners.tab')}
          <InformationIcon message={t('help.partners.partners')} />
        </div>
        <div className={styles.lastUpdate} id='lastUpdate'>{t('details.partners.lastUpdate')}:
          <span className={styles.lastUpdateDate} id='lastUpdateDate'> {obra.socios.fechaParticipacionActualizacion}</span>
        </div>
      </div>
      {partnersSorted && partnersSorted.length > 0 ? (
        <div className={styles.boxes}>
          {partnersSorted.map((e) => (
            <div className={styles.boxContainer} key={e.id}>
              <PartnerBox
                picture={partnerPicture}
                item={{ title: e.id, value: e.nombre }}
                participationItem={{ title: t('details.partners.participation'), value: calculaParticipacion(e.participacion) }}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.boxContainer}>
          <PartnerBox picture={partnerPicture} item={{ title: t('details.partners.notApplied') }} />
        </div>
      )}
      <div className='footer' style={{ marginBottom: '75px' }}></div>
    </div>
  );
};

export default Partners;
