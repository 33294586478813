/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC } from 'react';
import styles from './descriptionPhoto.module.scss';
import { ObraProperties } from '../../../types/ObraDetails';
import Carousel from './carousel/carousel';
import { Description } from './description/description';
import { Documentation } from './documentation/documentation';
import { useTranslation } from 'react-i18next';


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DescriptionPhoto {
  obra: ObraProperties,
  errors?: any
}

const DescriptionPhoto: FC<DescriptionPhoto> = (props) => {
  const { obra, errors } = props;
  const { t, i18n } = useTranslation();

  return (<div className={styles.descriptionPhotoContainer} data-testid="descriptionPhoto-test">
    <div>
      <Description data={obra.descripcion as any} errors={errors}/>
      <Carousel data={obra.descripcion.fotos} codCentro={obra.general.codCentro} error={errors && errors.filter((e: any) => e.field === 'fotos').length > 0 ? t('details.validationError.photo') : null}/>
      <Documentation data={obra.descripcion} error={errors && errors.filter((e: any) => e.field === 'documentacionProyecto').length > 0 ? t('details.validationError.document') : null}/>
      <div className='footer' style={{marginBottom: '75px'}}></div>
    </div>
  </div>);
};

export default DescriptionPhoto;
