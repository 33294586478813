import React, { useContext, useState, type FC } from 'react';
import Header from '../../components/header/header';
import DetailsViewProvider from '../../context/DetailsViewProvider';
import TabMenuAdvancedSearch from '../../components/tabMenuAdvancedSearch/tabMenuAdvancedSearch';
import BasicFilters from './basicFilters/basicFilters';
import styles from './advancedSearch.module.scss';
import GeneralDataFilters from './generalDataFilters/generalDataFilters';
import DescriptionFilters from './descriptionFilters/descriptionFilters';
import LocationEnvironmentFilters from './locationEnvironmentFilters/locationEnvironmentFilters';
import BudgetFilters from './budgetFilters/budgetFilters';
import ContractFilters from './contractFilters/contractFilters';
import ClientsFilters from './clientsFilters/clientsFilters';
import PartnersFilters from './partnersFilters/partnersFilters';
import TechnicalAspectsFilters from './technicalAspectsFilters/technicalAspectsFilters';
import DatesFilters from './datesFilters/datesFilters';
import EsgFilters from './esgFilters/esgFilters';
import BimFilters from './bimFilters/bimFilters';
import ClassificationFilters from './classificationFilters/classificationFilters';
import EfficiencyFilters from './efficiencyFilters/efficiencyFilters';
import HumanTeamFilters from './humanTeamFilters/humanTeamFilters';
import { FilterContext } from '../../context/FilterProvider';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AdvancedSearchProps { }

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const AdvancedSearch: FC<AdvancedSearchProps> = () => {
  const filterContext = useContext(FilterContext);
  const [openSections, setOpenSections] = useState(filterContext.getDefaultOpenSection());

  const handleClickOpen = (index: number, newVal: number) => {
    const newArr = openSections.map((_val, i) => {
      return i === index ? newVal : 0;
    });
    setOpenSections(newArr);
  };

  return (
    <DetailsViewProvider>
      <div data-testid="advancedSearch-test" className={styles.advancedSearch}>
        <Header>
          <TabMenuAdvancedSearch />
        </Header>
        <div className={styles.advancedSearchContainer}>
          <div className={styles.filtersList}>
            <div className={styles.section}>
              <BasicFilters
                open={openSections[0]===1}
                handleClickOpen={(newVal: number) => handleClickOpen(0, newVal)}
              />
            </div>
            <div className={styles.section}>
              <GeneralDataFilters
                open={openSections[1]===1}
                handleClickOpen={(newVal: number) => handleClickOpen(1, newVal)}
              />
            </div>
            <div className={styles.section}>
              <DescriptionFilters
                open={openSections[2]===1}
                handleClickOpen={(newVal: number) => handleClickOpen(2, newVal)}
              />
            </div>
            <div className={styles.section}>
              <LocationEnvironmentFilters
                open={openSections[3]===1}
                handleClickOpen={(newVal: number) => handleClickOpen(3, newVal)}
              />
            </div>
            <div className={styles.section}>
              <BudgetFilters
                open={openSections[4]===1}
                handleClickOpen={(newVal: number) => handleClickOpen(4, newVal)}
              />
            </div>
            <div className={styles.section}>
              <ContractFilters
                open={openSections[5]===1}
                handleClickOpen={(newVal: number) => handleClickOpen(5, newVal)}
              />
            </div>
            <div className={styles.section}>
              <ClientsFilters
                open={openSections[6]===1}
                handleClickOpen={(newVal: number) => handleClickOpen(6, newVal)}
              />
            </div>
            <div className={styles.section}>
              <PartnersFilters
                open={openSections[7]===1}
                handleClickOpen={(newVal: number) => handleClickOpen(7, newVal)}
              />
            </div>
            <div className={styles.section}>
              <TechnicalAspectsFilters
                open={openSections[8]===1}
                handleClickOpen={(newVal: number) => handleClickOpen(8, newVal)}
              />
            </div>
            <div className={styles.section}>
              <DatesFilters
                open={openSections[9]===1}
                handleClickOpen={(newVal: number) => handleClickOpen(9, newVal)}
              />
            </div>
            <div className={styles.section}>
              <EsgFilters
                open={openSections[10]===1}
                handleClickOpen={(newVal: number) => handleClickOpen(10, newVal)}
              />
            </div>
            <div className={styles.section}>
              <BimFilters
                open={openSections[11]===1}
                handleClickOpen={(newVal: number) => handleClickOpen(11, newVal)}
              />
            </div>
            <div className={styles.section}>
              <ClassificationFilters
                open={openSections[12]===1}
                handleClickOpen={(newVal: number) => handleClickOpen(12, newVal)}
              />
            </div>
            <div className={styles.section}>
              <EfficiencyFilters
                open={openSections[13]===1}
                handleClickOpen={(newVal: number) => handleClickOpen(13, newVal)}
              />
            </div>
            <div className={styles.section}>
              <HumanTeamFilters
                open={openSections[14]===1}
                handleClickOpen={(newVal: number) => handleClickOpen(14, newVal)}
              />
            </div>
          </div>
        </div>
      </div >
    </DetailsViewProvider >
  );
};

export default AdvancedSearch;

