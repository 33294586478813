/* eslint-disable react/react-in-jsx-scope */
import styles from './loadingSpinner.module.scss';
import spinner from '../../assets/spinner.svg';

// Check https://loading.io/css for the source of this spinner.
const LoadingSpinner = () => {
  return (
    <div>
      <div data-testid='LoadingSpinner' className={`${styles.spinner}`}>
        <img src={spinner} />
      </div>
      <div className={styles.layerSpinner} />
    </div >
  );
};

export default LoadingSpinner;