import es from '../../assets/flags/es.svg';
import en from '../../assets/flags/en.svg';
import pl from '../../assets/flags/pl.svg';
import pt from '../../assets/flags/pt.svg';

export const DESCRIPTION_LAN = {
  es: 'es',
  en: 'en',
  pt: 'pt',
  pl: 'pl'
};

export const DESCRIPTION_LAN_NAME = {
  es: 'spanish',
  en: 'english',
  pt: 'portuguese',
  pl: 'polish'
};

export const FLAG_COUNTRY_CODE = {
  es: es,
  en: en,
  pt: pt,
  pl: pl
};