import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './controversies.module.scss';
import { ObraProperties } from '../../../../types/ObraDetails';
import TitleHelp from '../../components/titleHelp/titleHelp';
import Description from '../../../../components/Description';

interface ControversiesProps {
  obra: ObraProperties,
}

const Controversies = ({obra}: ControversiesProps) => {
  const { t } = useTranslation();

  return (
    <div data-testid='esg-controversies' id='esg-controversies' className={styles.container}>
      <TitleHelp
        id='controversies'
        value={t('details.esg.controversies.helper')}
        help={t('help.esg.controversies')}
      />
      <Description
        id='controversiesText'
        text={obra.esg.controversias && obra.esg.controversias !== '' ? obra.esg.controversias : t('details.notInformed')}
        isBold
        customCss={{marginBottom: '54px', marginTop: '14px', minHeight: '95px'}}
      />
    </div>
  );
};


export default Controversies;
