import { Autocomplete, TextField } from '@mui/material';
import parse from 'html-react-parser';
import React, { FC, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TitleValue from '../titleValue/titleValue';
import styles from './noDataPicture.module.scss';
import { ReactComponent as SearchIcon } from './../../../../assets/search.svg';
import { t } from 'i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface NoDataPictureProps {
  id: string;
  picture: string;
  text: string;
  tip?: string;
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const NoDataPicture: FC<NoDataPictureProps> = (props) => {
  const { id, picture, text, tip } = props;
  

  return (
    <div id={id} className={styles.noDataPic}>
      <div className={styles.noDataWrapper}>
        <img src={picture} />
        <div className={styles.paragraphs}>
          <div className={styles.paragraph}>{parse(text)}</div>
          {tip && <div className={styles.paragraph}>{parse(tip)}</div>}
        </div>
      </div>
    </div>
  );
};

export default NoDataPicture;
