export interface NotificationType {
    id: number;
    idProyecto: number;
    idBorrador: number;
    codCentro: string;
    email: string;
    motivo: Motivo;
    descripcion: string;
    leido: boolean;
    fecCreacion: string;
    nomCentro: string;
}

export enum Motivo {
    NEW_PROJECT_VALIDATOR = 'new_project_notification_validator',
    NEW_PROJECT_EDITOR = 'new_project_notification_editor',
    ONE_MONTH_LEFT = 'one_month_left_notification',
    NEW_SUPERCENTER_VALIDATOR = 'new_supercenter_notification_validator',
    NEW_SUPERCENTER_EDITOR = 'new_supercenter_notification_editor',
    NEW_ZONE_VALIDATOR = 'new_zone_notification_validator',
    NEW_ZONE_EDITOR = 'new_zone_notification_editor',
    SEND_VALIDATION = 'send_validation',
    VALIDATE = 'validate',
    REJECT = 'reject',
}
