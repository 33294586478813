import { NotificationType } from './Notification';
import { Comentario, ObraGeneralInfo } from './ObraDetails';

export interface PendingActionType {
  ALL: number;
  PENDING: number;
  VALIDATED: number;
  COMMENTED: number;
  result: Result;
}

export interface Result {
  obras: (ActionObra & ObraGeneralInfo)[],
  hasMore: boolean,
}

export interface ActionObra {
  comentario?: Comentario,
  pending: boolean,
  nomCentro: string,
  fecModificacion?: string,
  action: string,
  notificacion: NotificationType,
  estadoPhia: string,
}

export const ACTION_FILTER = {
  ALL: 'ALL',
  UPDATING: 'UPDATING',
  VALIDATED: 'VALIDATED',
  COMMENTED: 'COMMENTED',
  VALIDATING: 'VALIDATING',
  FINAL_VALIDATING: 'FINAL_VALIDATING',
};

export const ACTION_FILTER_SORT = {
  [ACTION_FILTER.ALL]: 0,
  [ACTION_FILTER.VALIDATED]: 1,
  [ACTION_FILTER.UPDATING]: 2,
  [ACTION_FILTER.VALIDATING]: 3,
  [ACTION_FILTER.COMMENTED]: 4,
  [ACTION_FILTER.FINAL_VALIDATING]: 5,
};
