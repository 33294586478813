import React, { FC, useContext, useEffect } from 'react';
import styles from './languageSelect.module.scss';
import { FormControl, Select, MenuItem, ListItemIcon, ListItemText, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import es from '../../assets/flags/es.svg';
import en from '../../assets/flags/en.svg';
import pl from '../../assets/flags/pl.svg';
import pt from '../../assets/flags/pt.svg';
import './languageSelect.scss';
import { FilterContext } from '../../context/FilterProvider';
import DialogError from '../dialogError/DialogError';
import { ACCION } from '../../types/FiltersTypes';
import { WindowSharp } from '@mui/icons-material';
import { UserModeContext } from '../../context/UserModeProvider';
import { SaveContext } from '../../context/SaveProvider';
import CheckSaved from '../checkSaved/checkSaved';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LanguageSelectProps { }

const LanguageSelect: FC<LanguageSelectProps> = () => {
  const defaultLanguage = localStorage.getItem('language') && localStorage.getItem('language') !== '' ? localStorage.getItem('language') : 'es';
  const filterContext = useContext(FilterContext);
  const userModeContext = useContext(UserModeContext);
  const saveContext = useContext(SaveContext);
  const [language, setLanguage] = React.useState<any>(defaultLanguage);
  const [warningChanging, setWarningChanging] = React.useState<boolean>(false);
  const [t, i18n] = useTranslation();
  const [preselectedValue, setPreselectedValue] = React.useState<string>(defaultLanguage || 'es');
  const [hasChangedForm, setHasChangedForm] = React.useState<boolean>(false);

  useEffect(() => {
    const defaultLanguage = localStorage.getItem('language') && localStorage.getItem('language') !== '' ? localStorage.getItem('language') : 'es';
    setLanguage(defaultLanguage);
  }, [localStorage.getItem('language')]);

  const handleChange = (event: { target: { value: string; }; }) => {
    setPreselectedValue(event.target.value);
    // warning to change language if there are filters applied and the user is in the home page
    if ((Object.values(filterContext.filters).flat().length) && (['/', '/busqueda-avanzada'].includes(window.location.pathname))) {
      setWarningChanging(true);
    } else {
      userModeContext.userMode === 'editor' && saveContext.saveDraft ? setHasChangedForm(true) : handleContinueChange(event.target.value);
      setHasChangedForm(true);
    }
  };

  const continueEdit = () => {
    saveContext.saveDraft = false;
    saveContext.handleChangeSave(false);
    setHasChangedForm(false);
  };

  const discardChanges = () => {
    saveContext.saveDraft = false;
    saveContext.handleChangeSave(false);
    setHasChangedForm(false);
    handleContinueChange(preselectedValue);
  };

  const handleContinueChange = (value?: string) => {
    filterContext.finish(ACCION.DELETE);
    localStorage.setItem('language', value || preselectedValue);
    setWarningChanging(false);
    i18n.changeLanguage(value || preselectedValue);
  };

  const handleCancel = () => {
    setWarningChanging(false);
  };

  const languages = [
    { countryCode: 'es', name: t('language.spanish'), flag: es },
    { countryCode: 'en', name: t('language.english'), flag: en },
    { countryCode: 'pt', name: t('language.portuguese'), flag: pt },
    { countryCode: 'pl', name: t('language.polish'), flag: pl },
  ];

  return (
    <>
      <div id='languageselect' className={`${styles.LanguageSelect} language-select`} data-testid="LanguageSelect-test" >
        <FormControl fullWidth>
          <Select
            id="select-language"
            value={language}
            variant="standard"
            className='selectLanguageBox'
            disableUnderline={true}
            onChange={handleChange}
            renderValue={(selected) => {
              let valueSelected = t('language.spanish');
              let imageSelected = es;
              if (selected == 'en') {
                valueSelected = t('language.english');
                imageSelected = en;
              }
              else if (selected == 'pl') {
                valueSelected = t('language.polish');
                imageSelected = pl;
              }
              else if (selected == 'pt') {
                valueSelected = t('language.portuguese');
                imageSelected = pt;
              }
              // here you can add you logic for your selected value
              return <div id='languageselected' className={styles.LanguageSelected} style={{ display: 'flex' }}><img src={imageSelected} /><span>{valueSelected.substring(0, 3).toUpperCase()}</span></div>;
            }}
          >
            {languages.map((lan) => {
              return (<MenuItem className={styles.optionMenu} value={lan.countryCode} key={lan.countryCode}>
                <Box sx={{ display: 'flex', padding: '10px 22px' }}>
                  <ListItemIcon>
                    <img src={lan.flag} />
                  </ListItemIcon>
                  <ListItemText primary={lan.name} className={styles.optionsMenu} />
                </Box>
              </MenuItem>);
            })}
          </Select>
        </FormControl>
      </div>
      {
        (hasChangedForm) ?
          <CheckSaved
            handleFunction={() => {
              continueEdit();
            }}
            closeFunction={() => {
              setHasChangedForm(false);
            }}
            discardChanges={() => {
              discardChanges();
              handleContinueChange(preselectedValue);
            }}
          />
          : null
      }
      {warningChanging && <DialogError error={t('languageChangeWarning')} close={t('cancel') as string} handleAcept={handleContinueChange} handleCancel={handleCancel} />}
    </>
  );
};

export default LanguageSelect;
