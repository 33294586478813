/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useMemo, useContext, useRef } from 'react';
import { MarkerClustererEvents, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import { OverlappingMarkerSpiderfier, MarkerStatus } from 'ts-overlapping-marker-spiderfier';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import ReactDOMServer from 'react-dom/server';
import ReactGA from 'react-ga4';
import { hydrateRoot } from 'react-dom/client';
import { MarkerClustererCustom } from './MarkerClustererCustom';
import LoadingSpinner from '../../../loadingSpinner/loadingSpinner';
import Error from '../../../Error/Error';
import Infowindow from '../../../Infowindow/Infowindow';

import ObrasAPI from '../../../../services/APIObras';
import { getDiffBetweenClusters } from './utils';
import { ObraMap, ObraProperties, ResponseObrasMap, ResponseError } from '../../../../types/ObraMap';

import acciona_cluster from '../../../../assets/acciona_cluster.svg';
import acciona_marker from '../../../../assets/acciona_marker.svg';
import filterNotFound from '../../../../assets/grid/filter-notFound.svg';
import styles from './ClusterLayer.module.scss';
import { ViewContext } from '../../../../context/ViewProvider';
import { FilterContext } from '../../../../context/FilterProvider';
import { useNavigate } from 'react-router-dom';
import { VALIDATOR } from '../../../../userRoles/userRoles';
import { UserModeContext } from '../../../../context/UserModeProvider';
import { AuthContext } from '../../../../services/authContextProvider';


interface ClusterLayerProps {
  mapInstance: google.maps.Map;
  t: TFunction<'translations'>;
  enableExport: any;
  setNoProjects(value:any):any;
}

function ClusterLayer(props: ClusterLayerProps) {
  const { t, mapInstance, enableExport, setNoProjects } = props;
  const infowindow = new google.maps.InfoWindow();
  const navigate = useNavigate();
  const omsRef = useRef<OverlappingMarkerSpiderfier>();
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const filterContext = useContext(FilterContext);
  const [layerReady, setLayerReady] = useState(false);
  const [noMapProjects, setNoMapProjects] = useState(false);
  const [dataError, setDataError] = useState(false);
  const [filtersApplied, setFiltersApplied]: any = useState();
  const [mapCoordinates, setMapCoordinates] = useState(new Map<string, number>()); // Manage map coordinates
  const userModeContext = useContext(UserModeContext);

  let projectsMarkers: google.maps.Marker[];

  const createMarkerCluster = () => {
    // add clustered markers
    const markerClusterer = new MarkerClustererCustom({
      algorithm: new SuperClusterAlgorithm({ radius: 200 }),
      renderer: {
        render: ({ count, position }: any) => {
          // change size if this cluster has more markers than 10
          const size: number = count >= 15 ? 70 : 45;

          return new google.maps.Marker({

            position,
            icon: {
              url: acciona_cluster,
              scaledSize: new google.maps.Size(size, size),
            },
            label: {
              text: String(count),
              color: 'red',
              fontSize: '18px',
              fontWeight: '600',
            },
            // adjust zIndex to be above other markers
            zIndex: 1000 + count,
          });
        },
      },
      onClusterClick: (
        _: google.maps.MapMouseEvent,
        cluster: any,
        map: google.maps.Map
      ): void => {
        const zoom = map.getZoom();
        const clusterBounds = cluster.bounds;

        if (zoom) {
          map.fitBounds(clusterBounds);
        }
      }
    });

    let uploadingData: ReturnType<typeof setTimeout>;
    google.maps.event.addListener(markerClusterer, MarkerClustererEvents.CLUSTERING_END, () => {
      if (uploadingData) {
        clearTimeout(uploadingData);
      }
      uploadingData = setTimeout(async () => {
        setLayerReady(true);
        clearTimeout(uploadingData);
      }, 100);
    });

    return markerClusterer;
  };
  const markerCluster = useMemo(() => createMarkerCluster(), [mapInstance]);


  useEffect(() => {
    if (window.google && markerCluster && mapInstance) {
      if (filtersApplied === false && viewContext.mapIsSelected && viewContext.mainView) {
        // eslint-disable-next-line no-async-promise-executor
        new Promise(async (res) => {
          await addClusterToMap(filterContext.appliedFilters, true);
          setFiltersApplied(true);
          res(true);
        });
      }
    }

  }, [mapInstance, viewContext.mapIsSelected, viewContext.mainView]);

  useEffect(() => {
    if (window.google && markerCluster && mapInstance) {
      if (viewContext.mapIsSelected && viewContext.mainView) {
        const options = {
          markersWontMove: true, markersWontHide: true,
          legWeight: 3,
          keepSpiderfied: true,
          circleSpiralSwitchover: 40,
          circleFootSeparation: 40,
          spiralFootSeparation: 40,
          nudgeRadius: 40,
        };

        omsRef.current = new OverlappingMarkerSpiderfier(mapInstance, options);
        // eslint-disable-next-line no-async-promise-executor
        new Promise(async (res) => {
          setLayerReady(false);
          await addClusterToMap(filterContext.appliedFilters, filtersApplied);
          setFiltersApplied(true);
          res(true);
        });
      } else {
        setFiltersApplied(false);
      }
    }
  }, [filterContext.appliedFilters]);


  const addClusterToMap = async (filters?: any, fitBounds?: boolean) => {
    try {
      markerCluster.clearMarkers();
      google.maps.event.clearListeners(mapInstance, 'bounds_changed');
      markerCluster.setMap(mapInstance);

      const api = ObrasAPI.getInstance();
      const obrasMapa: ResponseObrasMap | ResponseError = await api.getObrasMapAPI(filterContext.transformMasterFilters(filters));
      const obras = 'obras' in obrasMapa ? obrasMapa?.obras : [];
      //** Update map coordinates */
      const mapObras = new Map<string, number>();
      obras.map((obra: ObraMap) => {
        const key = `${obra.coordenadas.lat}-${obra.coordenadas.lon}`;
        if (mapObras.has(key)) {
          mapObras.set(key, mapObras.get(key) as number + 1);
        } else {
          mapObras.set(key, 1);
        }
      });
      setMapCoordinates(mapObras);
      //*************************/
      enableExport(obras.length > 0);

      // there are no projects
      if (!obras.length) {

        setNoMapProjects(true);
        setNoProjects(true);
        setLayerReady(true);
        mapInstance.setZoom(0);
        mapInstance.setCenter({lat:0, lng:0});
      }else{
        setNoProjects(false);
        setNoMapProjects(false);
      }
      projectsMarkers = createProjectsMarkers(obras, fitBounds);
      // there are no projects with coordinates
      if (!projectsMarkers.length) {
        setLayerReady(true);
      }
      optimizeMarkersByBounds();
      drawOnMoveListener();
    } catch (e) {
      setDataError(true);
    }
  };

  // Event click on the markers
  const onClickMarker = async (marker: google.maps.Marker) => {
    infowindow.close();
    const label: any = marker.getLabel();
    const code = label?.className || label?.text;
    if (code) {
      const api = ObrasAPI.getInstance();
      const obraProp = await api.getObraPropertiesAPI(code);
      infowindow.open({
        anchor: marker,
        map: mapInstance,
      });
      infowindow.setContent(createInfowindowContent(obraProp, t));
    }
  };


  /**
 * @function createProjectsMarkers
 *
 * @description prepare markers from projects data to add to cluster
 *
 *  @param {projectsData} projectsData the projects from which create markers
 *
 */
  const createProjectsMarkers = (projectsData: ObraMap[], fitBounds?: boolean) => {
    // add markers and fit bounds to adapt to all obras
    let obrasBounds: google.maps.LatLngBounds | undefined;
    const markers = projectsData && projectsData.filter((obra: ObraMap) => obra.coordenadas?.lat && obra.coordenadas?.lon)
      .map((obra: ObraMap) => {
        if (!obrasBounds) {
          obrasBounds = new google.maps.LatLngBounds({ lat: obra.coordenadas.lat || 0, lng: obra.coordenadas.lon || 0 });
        } else {
          obrasBounds.extend({ lat: obra.coordenadas.lat || 0, lng: obra.coordenadas.lon || 0});
        }

        const countMarker = mapCoordinates.get(`${obra.coordenadas.lat || 0}-${obra.coordenadas.lon || 0}`) || 0;
        const iconUrl = countMarker > 1 ? acciona_cluster : acciona_marker;
        const text = countMarker > 1 ? String(countMarker) : obra.codCentro;
        const marker = new google.maps.Marker({
          position: { lat: obra.coordenadas.lat || 0, lng: obra.coordenadas.lon || 0},
          icon: {
            url: iconUrl,
          },
          label: {
            color: 'rgba(0,0,0,0)',
            text,
            className: obra.codCentro,
            ...(countMarker > 1 && { color: 'red', fontSize: '18px', fontWeight: '600' }),
          }
        });

        marker.addListener('click', async () => onClickMarker(marker));

        return marker;
      });

    if (fitBounds && obrasBounds) {
      mapInstance.fitBounds(obrasBounds);
      const zoom: number = mapInstance.getZoom() as number;
      if (zoom > 10) {
        mapInstance.setZoom(10);
      }
    }
    return markers || [];
  };

  const createInfowindowContent = (obra: ObraProperties, t: TFunction<'translations'>) => {
    const goDetails = (path: string) => {
      const api = ObrasAPI.getInstance();
      api.getRoles(obra.codCentro)
        .then((roles) => {
          if(roles.includes(VALIDATOR)) userModeContext.handleChangeSwitch('editor');
          else userModeContext.handleChangeSwitch('viewer');
          ReactGA.event('click_details', {
            event_category: 'map',
            role: authContext.mainRole(true),
            code: obra?.codCentro
          });
          navigate(path);
        })
        .catch((error) => console.log(error));
    };
    const node = document.createElement('div');
    node.innerHTML = ReactDOMServer.renderToString(Infowindow({ obra, goDetails, t }));
    hydrateRoot(node, Infowindow({ obra, goDetails, t }));
    const content = node;
    return content;
  };

  const optimizeMarkersByBounds = () => {
    const currentBounds = mapInstance.getBounds();
    const markersInBounds: google.maps.Marker[] = [];
    projectsMarkers.forEach((m: google.maps.Marker) => {
      const markerPos = m.getPosition()?.toJSON();
      if (markerPos && currentBounds?.contains(markerPos)) {
        markersInBounds.push(m);
      } else {
        m.setMap(null);
      }
    });
    // if there are less than 200 markers in bounds, use OverlappingMarkerSpiderfier
    if(markersInBounds.length < 200 && omsRef.current) {
      const mapObras = new Map<string, number>();
      markersInBounds.forEach((m: google.maps.Marker) => {
        const key = `${m.getPosition()?.lat() || 0}-${m.getPosition()?.lng() || 0}`;
        if (mapObras.has(key)) {
          mapObras.set(key, mapObras.get(key) as number + 1);
        } else {
          mapObras.set(key, 1);
        }
        omsRef.current && omsRef.current.addMarker(m, () => onClickMarker(m));
      });

      omsRef.current && omsRef.current.addListener('unspiderfy', function() {
        infowindow.close();
      });

      omsRef.current && omsRef.current.addListener('spiderfy', (markers: google.maps.Marker[]) => {
        // Here you can change the icon of the markers back to its original icon
        markers.forEach(marker => {
          marker.setIcon({url: acciona_marker});
          const label = marker.getLabel() as google.maps.MarkerLabel;
          marker.setLabel({
            color: 'rgba(0,0,0,0)',
            text: label.className || label.text,
          });
        });
      });

      omsRef.current && omsRef.current.addListener('format', (marker: google.maps.Marker, state: MarkerStatus) => {
        const countMarker = mapObras.get(`${marker.getPosition()?.lat() || 0}-${marker.getPosition()?.lng() || 0}`) || 0;
        const label = marker.getLabel() as google.maps.MarkerLabel;
        if(state === MarkerStatus.SPIDERFIABLE && countMarker > 1) {
          marker.setIcon({url: acciona_cluster});
          marker.setLabel({
            color: 'red',
            text: String(countMarker),
            fontSize: '18px',
            fontWeight: '600',
            className: label.className || label.text,
          });
        }else{
          marker.setIcon({url: acciona_marker});
          marker.setLabel({
            color: 'rgba(0,0,0,0)',
            text: label.className || label.text,
            className: label.className || label.text,
          });
        }
      });
    }else{
      omsRef.current && omsRef.current.removeAllMarkers();
      infowindow.close();
    }

    const clustersBefore = markerCluster.getClusters();
    const { clusters } = markerCluster.preCalculateClusters(markersInBounds, mapInstance);
    const { onlyInOld, onlyInNew }: any = getDiffBetweenClusters(clustersBefore, clusters);
    onlyInOld && markerCluster.removeClusters(onlyInOld);
    onlyInNew && markerCluster.addClusters(onlyInNew);
  };

  const drawOnMoveListener = () => {
    let boundsChanged: ReturnType<typeof setTimeout>;

    return google.maps.event.addListener(mapInstance, 'bounds_changed', () => {
      omsRef.current && omsRef.current.unspiderfy();
      google.maps.event.addListenerOnce(mapInstance, 'idle', () => {
        if (boundsChanged) {
          clearTimeout(boundsChanged);
        }
        boundsChanged = setTimeout(() => {
          optimizeMarkersByBounds();
          clearTimeout(boundsChanged);
        }, 0);
      });
    });
  };

  const renderLayer = () => {
    return <>{noMapProjects ?
      <div className={styles.filterNotFound}>
        <img src={filterNotFound} />
        <div id="titleerror" className={styles.titleError}>{t('homeList.notFoundTitle')}</div>
        <div id="titleerror2" className={styles.titleError2}>{t('homeList.notFoundTitle2')}</div>
        <p className={styles.descriptionError}>{t('homeList.notFoundDescription')}</p>
      </div>
      :
      <div data-testid='cluster-layer' className={styles.clusterLayer} >
        <div id='clusterlayer-loading' className={styles.clusterLayerLoading} style={{
          display: layerReady ? 'none' : 'block',
        }}>
          <LoadingSpinner />
        </div>
        <div id='clusterlayer-error' className={styles.clusterLayerError} style={{
          display: dataError ? 'flex' : 'none',
        }}>
          <Error />
        </div>
      </div >
    }
    </>;
  };


  return renderLayer();
}

export default withTranslation()(ClusterLayer);
