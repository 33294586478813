import React, { createContext, useState, useEffect, useContext } from 'react';
import { AuthContext } from '../services/authContextProvider';

export const NotificationContext = createContext<any>(null);

export function NotificationProvider({ children }: { children: React.ReactNode }) {
  const authContext = useContext(AuthContext);
  const [notifications, setNotifications] = useState<number>(0);

  useEffect(() => {
    if (authContext.isAuthenticated) {
      // Create a new EventSource connection with the server    
      const token = localStorage.getItem('tokenPhia');
      const sse = new EventSource(process.env.REACT_APP_BACKEND_HOST +  '/v1/notificaciones/sse?token=' + token);
      sse.addEventListener(
        'notification', () => {
          // increase the number of notifications. Only needed to reload the notifications component
          setNotifications(prevNotifications => prevNotifications + 1);
        }, false
      );
      sse.onopen = () => console.log('Connection opened');
      sse.onerror = (error) => console.log('Error:', error);

      // Cierra la conexión SSE cuando el componente se desmonta
      return () => {
        sse.close();
      };
    }
  }, [authContext.isAuthenticated]);

  return (
    <NotificationContext.Provider value={notifications}>
      {children}
    </NotificationContext.Provider>
  );
}
export default NotificationProvider;