/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useEffect, useState } from 'react';
import styles from './editClients.module.scss';
import { ObraProperties } from '../../../../types/ObraDetails';
import { useTranslation } from 'react-i18next';
import ObrasAPI from '../../../../services/APIObras';
import { SelectOption } from '../../../../types/Option';
import EditDesigners from './editDesigners/editDesigners';
import EditDirectors from './editDirectors/editDirectors';
import EditAssistance from './editAssistance/editAssistance';

interface EditClientsProps {
  obra: ObraProperties
  errors?: any[]
}

const EditClients: FC<EditClientsProps> = (props) => {
  const { obra, errors } = props;
  const [t] = useTranslation();
  const [clientStatus, setClientStatus] = useState<SelectOption[]>([]);

  const NO_RESULTS = [{ nombre: `${t('input.noResults')}`, id: 0 }];
  const getClientStatus = () => {
    const api = ObrasAPI.getInstance();
    api.getClientStatusMasters().then(
      (results: SelectOption[]) => {
        results = results?.length ? results : NO_RESULTS;
        setClientStatus(results);
      }
    ).catch((error: any) => {
      console.log(error);
      setClientStatus([]);
    });


  };
  useEffect(() => getClientStatus(), []);

  return (
    <div
      data-testid='edit-clients-test'
      className={styles.colabContainer}>

      <EditDesigners obra={obra} clientStatus={clientStatus} errors={errors?.filter(e => e.field === 'disenador')} />
      <EditDirectors obra={obra} clientStatus={clientStatus} errors={errors?.filter(e => e.field === 'direccionObra')} />
      <EditAssistance obra={obra} clientStatus={clientStatus} errors={errors?.filter(e => e.field === 'asistenciasTecnicas')} />

    </div>);
};

export default EditClients;
