import { TFunction } from 'i18next';
import * as yup from 'yup';

const LocationEnvironmentSchema = (t: TFunction<'translations'>) => yup.object({
  coordenadas: yup.object().shape({
    lat: yup
      .number()
      .required({
        text: `${t('details.validation.tab')} ${t('details.location.tab')}: ${t('details.validation.noData')} "${t('details.location.coordinatesLatitude')}"`,
        orden: 1,
        helpText: `${t('details.validation.coordinates.helpText')}`
      })
      .min(-90, {
        text: `${t('details.validation.tab')} ${t('details.location.tab')}: ${t('details.location.coordinatesLatitude')}. "${t('details.validation.coordinates.minValue', {minValue: '-90'})}"`,
        order: 1,
        helpText: `${t('details.validation.coordinates.minValue', {minValue: '-90'})}`,
      })
      .max(90, {
        text: `${t('details.validation.tab')} ${t('details.location.tab')}: ${t('details.location.coordinatesLatitude')}. "${t('details.validation.coordinates.maxValue', {maxValue: '90'})}"`,
        order: 1,
        helpText: `${t('details.validation.coordinates.maxValue', {maxValue: '90'})}`,
      }),
    lon: yup
      .number()
      .required({
        text: `${t('details.validation.tab')} ${t('details.location.tab')}: ${t('details.validation.noData')} "${t('details.location.coordinatesLongitude')}"`,
        orden: 1,
        helpText: `${t('details.validation.coordinates.helpText')}`
      })
      .min(-180, {
        text: `${t('details.validation.tab')} ${t('details.location.tab')}: ${t('details.location.coordinatesLongitude')}. "${t('details.validation.coordinates.minValue', {minValue: '-180'})}"`,
        order: 1,
        helpText: `${t('details.validation.coordinates.minValue', {minValue: '-180'})}`,
      })
      .max(180, {
        text: `${t('details.validation.tab')} ${t('details.location.tab')}: ${t('details.location.coordinatesLongitude')}. "${t('details.validation.coordinates.maxValue', {maxValue: '180'})}"`,
        order: 1,
        helpText: `${t('details.validation.coordinates.maxValue', {maxValue: '180'})}`,
      }),
  }).required({ text: `${t('details.validation.tab')} ${t('details.location.tab')}: ${t('details.validation.noData')} "${t('details.location.coordinates')}"`, orden: 1}),
  municipioPrincipal: yup.object({
    // nomMunicipio: yup.string().required({ text: `${t('details.validation.tab')} ${t('details.location.tab')}: ${t('details.validation.noData')} "${t('details.location.town')}"`}),
    codigoPostal: yup.string().required({ text: `${t('details.validation.tab')} ${t('details.location.tab')}: ${t('details.validation.noData')} "${t('details.location.zipCode')}"`, orden: 2}),
  }).required(),
  municipiosAdicionales: yup.array().min(1, { text: `${t('details.validation.tab')} ${t('details.location.tab')}: ${t('details.validation.noData')} "${t('details.location.otherTowns')}"`, optional: true, orden: 2 }).of(
    yup.object({
      nomMunicipio: yup.string().required({ text: `${t('details.validation.tab')} ${t('details.location.tab')}: ${t('details.validation.noData')} "${t('details.location.otherTowns')}-${t('details.location.town')}"`, orden: 2}),
      codigoPostal: yup.string().required({ text: `${t('details.validation.tab')} ${t('details.location.tab')}: ${t('details.validation.noData')} "${t('details.location.otherTowns')}-${t('details.location.zipCode')}"`, orden: 2}),
    })
  ).required(),
  espacioProteccion: yup.array().min(1, { text: `${t('details.validation.tab')} ${t('details.location.tab')}: ${t('details.validation.noData')} "${t('details.location.protectedSpace')}"`, optional: true, orden: 2 }).of(
    yup.object({
      areasProtegidas: yup.array().min(1, { text: `${t('details.validation.tab')} ${t('details.location.tab')}: ${t('details.validation.noData')} "${t('details.location.protectedSpace')} - ${t('details.location.space')}"`, orden: 2 }).required(),
      nombre: yup.string().required({ text: `${t('details.validation.tab')} ${t('details.location.tab')}: ${t('details.validation.noData')} "${t('details.location.protectedSpace')} - ${t('details.location.name')}"`, orden: 2}),
      superficieArea: yup.number().required({ text: `${t('details.validation.tab')} ${t('details.location.tab')}: ${t('details.validation.noData')} "${t('details.location.protectedSpace')} - ${t('details.location.protectedArea')}"`, orden: 2}),
      ubicacion: yup.object({
        nombre: yup.string().required({ text: `${t('details.validation.tab')} ${t('details.location.tab')}: ${t('details.validation.noData')} "${t('details.location.protectedSpace')} - ${t('details.location.location')}"`, orden: 2}),
      }).required(),
      superficieProyecto: yup.number().required({ text: `${t('details.validation.tab')} ${t('details.location.tab')}: ${t('details.validation.noData')} "${t('details.location.protectedSpace')} - ${t('details.location.projectArea')}"`, orden: 2}),
    })
  ).required(),
  especiesPeligro: yup.array().min(1, { text: `${t('details.validation.tab')} ${t('details.location.tab')}: ${t('details.validation.noData')} "${t('details.location.extinctionSpecies')}"`, optional: true, orden: 2 }).of(
    yup.object({
      nombre: yup.string().required({ text: `${t('details.validation.tab')} ${t('details.location.tab')}: ${t('details.validation.noData')} "${t('details.location.extinctionSpecies')} - ${t('details.location.name')}"`, orden: 2}),
      nivel: yup.object({
        nombre: yup.string().required({ text: `${t('details.validation.tab')} ${t('details.location.tab')}: ${t('details.validation.noData')} "${t('details.location.extinctionSpecies')} - ${t('details.location.dangerLevel')}"`, orden: 2}),
      }).required(),
    })
  ).required(),
  zonasProteccion: yup.array().min(1, { text: `${t('details.validation.tab')} ${t('details.location.tab')}: ${t('details.validation.noData')} "${t('details.location.protectionZones')}"`, optional: true, orden: 2 }).of(
    yup.object({
      nombre: yup.string().required({ text: `${t('details.validation.tab')} ${t('details.location.tab')}: ${t('details.validation.noData')} "${t('details.location.protectionZones')} - ${t('details.location.name')}"`, orden: 2}),
      proteccion: yup.object({
        nombre: yup.string().required({ text: `${t('details.validation.tab')} ${t('details.location.tab')}: ${t('details.validation.noData')} "${t('details.location.protectionZones')} - ${t('details.location.protection')}"`, orden: 2}),
      }).required(),
    })
  ).required(),
}).required();

export default LocationEnvironmentSchema;