/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC } from 'react';
import styles from './InfoBoxFooter.module.scss';


interface Field {
  title: string;
  value: string | number;
}

interface Color {
  backgroundColor: string;
  color: string;
}

interface InfoBoxFooterProps {
  item: Field;
  identifier?: any;
  footer: string;
  style: Color;
}

const InfoBoxFooter: FC<InfoBoxFooterProps> = (props) => {
  const { item, identifier, footer, style } = props;

  return (
    <div className={styles.box}>

      <div className={styles.field}>
        <div id={`${identifier}-title`} className={styles.title}>{item?.title}</div>
        <div id={`${identifier}-value`} className={styles.value}>{item?.value}</div>
      </div>
      <div className={styles.footer} style={style}>
        {footer}
      </div>

    </div >
  );
};

export default InfoBoxFooter;
