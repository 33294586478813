/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC } from 'react';
import styles from './LevelBar.module.scss';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

interface Color {
  fairColor: string;
  darkColor: string;

}

interface LevelBarProps {
  identifier?: any;
  color: Color;
  height?: number;
  level: number;
  isPorcentualValue?: boolean;
}

const LevelBar: FC<LevelBarProps> = (props) => {
  const { identifier, level, color, height, isPorcentualValue } = props;

  const getProgress = () => {
    if (isPorcentualValue) {
      return level * 10;
    }
    switch (level) {
      case 0:
        return 5;
      case 1:
        return 25;
      case 2:
        return 50;
      case 3:
        return 75;
      case 4:
        return 95;
      default:
        return 50;
    }
  };

  return (
    <div id={`${identifier}-bar`} className={styles.barBox} >
      <LinearProgress variant='determinate' value={getProgress()}
        sx={{
          height: height || 20,
          borderRadius: 15,
          [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: color.fairColor,
          },
          [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 15,
            backgroundColor: color.darkColor,
          },
        }} />
    </div >
  );
};

export default LevelBar;
