/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react';
import { FilterCollapsableWrapper } from '../../FiltersWrappers/FilterCollapsableWrapper/FilterCollapsableWrapper';
import { FormGroup } from '@mui/material';
import SelectField from './components/selectField/selectField';
import { Option } from '../../../../types/Option';
import CustomAutocomplete from '../../../../pages/advancedSearch/components/Autocomplete/CustomAutocomplete/CustomAutocomplete';
import ObrasAPI from '../../../../services/APIObras';
import styles from './selectorAndAutocomplete.module.scss';
import { useTranslation } from 'react-i18next';
import { Mode } from '../../../../types/FiltersTypes';
import { FilterContext } from '../../../../context/FilterProvider';

interface SelectorAndAutocompleteProps {
  filterType: string;
  noBottomDivider?: boolean;
}
export const SelectorAndAutocomplete = (props: SelectorAndAutocompleteProps) => {
  const { filterType, noBottomDivider } = props;
  const [options, setOptions] = useState<Option[]>([]);
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const [selectedAutocomplete, setSelectedAutocomplete] = useState<any>(null);
  const [t] = useTranslation();
  const filterContext = useContext(FilterContext);

  useEffect(() => {
    try {
      const api = ObrasAPI.getInstance();
      api.getTaxonomicObjectives().then((results) => setOptions(results));
      api.getTaxonomicObjectives().then((results) => setOptions(results));
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleAddFilter = () => {
    if (selectedValue && selectedAutocomplete) {
      try {
        const code = `${selectedValue.code}|${selectedAutocomplete.code}`;
        const name = `objective: ${selectedValue.name}|activity: ${selectedAutocomplete.name}`;
        const tag = `${selectedValue.name}: ${selectedAutocomplete.name}`;
        const filterKey = `${filterType}${selectedValue.code}${selectedAutocomplete.code}`;
        filterContext.handleCacheMasterFilter(filterType, filterKey);
        filterContext.handleChange(
          filterKey,
          {
            code: code || '',
            name,
            tag,
            workTypeCode: selectedValue.code,
            unitCode: selectedAutocomplete.code,
          },
          Mode.REPLACE,
          undefined,
        );
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <>
      <FilterCollapsableWrapper
        filterType={filterType}
        noBottomDivider={noBottomDivider}
      >
        <FormGroup>
          <SelectField options={options} selectedValue={selectedValue} handleSelect={(newValue) => setSelectedValue(newValue)} />
          <CustomAutocomplete
            filterType={filterType}
            handleSelect={(newValue) => {
              setSelectedAutocomplete(newValue);
            }}
          />
          <div className={styles.addButtonWrapper}>
            <div className={styles.addButton} onClick={() => handleAddFilter()}>
              {t('filters.add')}
            </div>
          </div>
        </FormGroup>
      </FilterCollapsableWrapper>
    </>
  );
};

export default SelectorAndAutocomplete;
