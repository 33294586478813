/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useContext, useEffect, useState } from 'react';
import styles from './viewClients.module.scss';
import { ObraProperties } from '../../../../types/ObraDetails';
import { useTranslation } from 'react-i18next';
import TitleHelp from '../../components/titleHelp/titleHelp';
import Colaborador from '../components/colaborator/colaborator';
import NoDataPicture from '../../components/noDataPicture/noDataPicture';
import technicalNoData from '../../../../assets/technicalNoData.svg';
import designerNoData from '../../../../assets/designerNoData.svg';
import assitanceNoData from '../../../../assets/assitanceNoData.svg';
import { AuthContext } from '../../../../services/authContextProvider';
import { UserModeContext } from '../../../../context/UserModeProvider';

interface ViewClientsProps {
  obra: ObraProperties
}

const ViewClients: FC<ViewClientsProps> = (props) => {
  const { obra } = props;
  const [t] = useTranslation();
  const authContext = useContext(AuthContext);
  const hasEditorMode = authContext.hasEditorRole();
  const userModeContext = useContext(UserModeContext);


  return (
    <div
      data-testid='view-clients-test'
      className={styles.colabContainer}>

      <div data-testid="designer-view-test" >
        <TitleHelp
          id='designer'
          value={t('details.clients.designer')}
          help={t('help.clients.designer')}
        />
        {
          obra.cliente.disenador.length > 0 ?
            <>
              <Colaborador editable={false} colaborador={obra.cliente.disenador} obra={obra} />
              {hasEditorMode && !obra.cliente.disenador.find((d) => d.origen === 'phia') && <NoDataPicture
                id='designer-nodata'
                picture={designerNoData}
                text={userModeContext.userMode !== 'editorDisabled' ? t('details.clients.changeUserAddDesigner') : (t('details.clients.noData') + '. ' + t('details.validation.noEditMode'))}
              />}
            </>
            :
            <NoDataPicture
              id='designer-nodata'
              picture={designerNoData}
              text={userModeContext.userMode !== 'editorDisabled'
                ? (hasEditorMode
                  ? t('details.clients.changeUserAddNewDesigner')
                  : t('details.clients.noData'))
                : t('details.clients.noData') + '. ' + t('details.validation.noEditMode')}
            />
        }
      </div >

      <div data-testid="director-view-test">
        <TitleHelp
          id='director'
          value={t('details.clients.director')}
          help={t('help.clients.director')}
        />
        {
          obra.cliente.direccionObra.length > 0 ?
            <>
              <Colaborador editable={false} colaborador={obra.cliente.direccionObra} />

            </>
            :
            <NoDataPicture
              id='director-nodata'
              picture={technicalNoData}
              text={userModeContext.userMode !== 'editorDisabled'
                ? (hasEditorMode
                  ? t('details.clients.changeUserAddDirector')
                  : t('details.clients.noData'))
                : t('details.clients.noData') + '. ' + t('details.validation.noEditMode')}
            />
        }
      </div >

      <div data-testid="assistance-view-test">
        <TitleHelp
          id='assistance'
          value={t('details.clients.assistance')}
          help={t('help.clients.assistance')}
        />
        {
          obra.cliente.asistenciasTecnicas.length > 0 ?
            <>
              <Colaborador editable={false} colaborador={obra.cliente.asistenciasTecnicas} />

            </>
            :
            <NoDataPicture
              id='director-nodata'
              picture={assitanceNoData}
              text={userModeContext.userMode !== 'editorDisabled'
                ? (hasEditorMode
                  ? t('details.clients.changeUserAddAssistance')
                  : t('details.clients.noData'))
                : t('details.clients.noData') + '. ' + t('details.validation.noEditMode')}
            />
        }
      </div >

    </div>);
};

export default ViewClients;
