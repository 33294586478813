/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC } from 'react';
import styles from './contractInfo.module.scss';

interface Field {
  title: string;
  value: string;
}

interface ContractInfoProps {
  picture?: string;
  tipo?: Field | null;
  modelo?: Field | null;
  alcance?: Field | null;
  pago?: Field | null;
  identifier?: any
}

const ContractInfo: FC<ContractInfoProps> = (props) => {
  const { picture, tipo,modelo, alcance, pago, identifier} = props;

  return (
    <div className={styles.box}>
      {picture &&
        <img src={picture} className={styles.picture} id={`${identifier}-picture`}/>
      }
      <div className={styles.boxContainer}>
        {tipo !== undefined &&
          <div className={styles.field}>
            <div id={`${identifier}-title`} className={styles.title}>{tipo?.title}</div>
            <div id={`${identifier}-value`} className={styles.value}>{tipo?.value}</div>
          </div>
        }
        {modelo !== undefined &&
          <div className={styles.field}>
            <div id={`${identifier}-title`} className={styles.title}>{modelo?.title}</div>
            <div id={`${identifier}-value`} className={styles.value}>{modelo?.value}</div>
          </div>
        }
        {alcance !== undefined &&
          <div className={styles.field}>
            <div id={`${identifier}-title`} className={styles.title}>{alcance?.title}</div>
            <div id={`${identifier}-value`} className={styles.value}>{alcance?.value}</div>
          </div>
        }
        {pago !== undefined &&
          <div className={styles.field}>
            <div id={`${identifier}-title`} className={styles.title}>{pago?.title}</div>
            <div id={`${identifier}-value`} className={styles.value}>{pago?.value}</div>
          </div>
        }
      </div>
    </div>
  );
};

export default ContractInfo;
