import { FC, useContext, useState } from 'react';
import { ObraProperties } from '../../../types/ObraDetails';
import { UserModeContext } from '../../../context/UserModeProvider';
import ViewLocalizationEnvironment from './viewLocalizationEnvironment/viewLocalizationEnvironment';
import EditLocalizationEnvironment from './editLocalizationEnvironment/editLocalizationEnvironment';
import styles from './localizationEnvironment.module.scss';
import { DetailsViewContext } from '../../../context/DetailsViewProvider';
import Map from '../../../components/Map/Map';
import MarkerLayer from '../../../components/Map/MapLayers/Marker/MarkerLayer';
import KMZLayer from '../../../components/Map/MapLayers/KMZ/KMZLayer';
import CustomControl from '../../../components/Map/MapLayers/CustomControl/CustomControl';
import KMZButton from './components/kmzButton/kmzButton';
import { useTranslation } from 'react-i18next';
import { SaveContext } from '../../../context/SaveProvider';
import DialogError from '../../../components/dialogError/DialogError';


interface LocalizationEnvironmentProps {
  obra: ObraProperties;
  errors?: any[];
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const LocalizationEnvironment: FC<LocalizationEnvironmentProps> = (props) => {
  const { obra, errors } = props;
  const [t] = useTranslation();
  const viewContext = useContext(DetailsViewContext);
  const userModeContext = useContext(UserModeContext);
  const saveContext = useContext(SaveContext);
  const isEditorMode = userModeContext.userMode === 'editor';
  const [kmz, setKmz] = useState(obra?.localizacion?.kmz);
  const [kmzLoadingError, setKmzLoadingError] = useState(false);

  const handleChangeKMZ = (newkmz: string) => {
    setKmzLoadingError(false);
    setKmz(newkmz);
  };

  const callbackKmzLayer = (res?: string) => {
    if (isEditorMode) {
      if (res === 'INVALID_DOCUMENT') {
        setKmzLoadingError(true);
      } else if (res && res !== obra?.localizacion?.kmz) {
        obra.localizacion.kmz = res;
        saveContext.handleChangeSave(true);
      }
    }
  };

  return (
    <div
      data-testid="locationEnvironment-test"
      className={styles.localizationEnvironment}
      style={viewContext?.mapIsSelected ? { display: 'block' } : { display: 'none' }}
    >
      {/* MAP SECTION  */}
      {obra.localizacion?.coordenadas && <div className={`${styles.contentMap} content-map`}>
        <Map context={DetailsViewContext}>
          {(mapInstance: google.maps.Map) => (
            <>
              <MarkerLayer mapInstance={mapInstance} coordinates={obra?.localizacion?.coordenadas} />
              <KMZLayer mapInstance={mapInstance} url={kmz} obra={obra} callback={callbackKmzLayer} />
              <CustomControl mapInstance={mapInstance} show={isEditorMode} >
                <KMZButton obra={obra} t={t} handleChange={handleChangeKMZ} />
              </CustomControl>
              {kmzLoadingError && <DialogError error={t('details.location.invalidKMZ') as string} />}
            </>
          )}
        </Map>

      </div>}

      {isEditorMode ? <EditLocalizationEnvironment obra={obra} errors={errors} /> : <ViewLocalizationEnvironment obra={obra} />}
      <div className='footer' style={{marginBottom: '75px'}}></div>
    </div>);
};

export default LocalizationEnvironment;
