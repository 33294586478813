import React, { useContext, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { FilterContext } from '../../../../../context/FilterProvider';
import { Mode } from '../../../../../types/FiltersTypes';

import TextField from '@mui/material/TextField';
import styles from './containKeywordTextField.module.scss';
import { TFunction } from 'i18next';


interface ContainKeywordTextFieldProps {
  t: TFunction<'translations'>;
  filterType: string;
  isNumber?: boolean;
  isExactWord?: boolean;
  placeholder?: string;
}
const ContainKeywordTextField = (props: ContainKeywordTextFieldProps) => {
  const [textFieldValue, setTextFieldValue] = useState('');
  const { t, filterType, isNumber, placeholder, isExactWord } = props;
  const filterContext = useContext(FilterContext);

  const handleAddKeyword = (value: string) => {
    const normalizedValue = value
      .trim()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
    if (normalizedValue.length >= 1) {
      const keywords = normalizedValue.split(/\s+/);
      const filteredKeywords = keywords.filter(keyword => keyword.length >= 3);
      if (filteredKeywords.length > 0) {
        filteredKeywords.forEach(keyword => {
          const inputCode = keyword;
          const inputName = `${t(isExactWord ? 'filters.matches' : 'filters.contain')}: ${value}`;
          filterContext.handleChange(filterType, { code: inputCode, name: inputName }, Mode.ADD);
        });
        setTextFieldValue('');
      }
    }
  };

  return (
    <div data-testid='test-keywordfield' id='keywordfield' className={styles.keywordTextField}>
      <TextField
        value={textFieldValue}
        onChange={
          (e) => {
            setTextFieldValue(
              isNumber ? e.target.value.replace(/[^0-9.]/gi, '') : e.target.value
            );
          }
        }
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            handleAddKeyword(textFieldValue);
          }
        }}
        placeholder={placeholder || t('filters.filtersPlaceHolder.keyword') as string}
        variant='outlined'
        InputProps={
          {
            endAdornment: <button
              disabled={textFieldValue.trim().length < 3}
              onClick={() => {
                const trimmedValue = textFieldValue.trim();
                if (trimmedValue.length > 0) handleAddKeyword(trimmedValue);
              }}>
              {`${t('filters.add')}`}
            </button>,
          }
        }
        sx={{
          '& .MuiOutlinedInput-root': {
            backgroundColor: '#FFFFFF',
            height: '45px',
            '&.Mui-focused fieldset': {
              border: '1px solid #7A7A7A',
              color: 'rgba(122, 122, 122, 1)',
            },
          },
        }}
      />
    </div>
  );
};

export default withTranslation()(ContainKeywordTextField);
