import React, { FC, useContext, useEffect, useState } from 'react';
import styles from './editAdditionalTowns.module.scss';
import { Municipio, ObraProperties } from '../../../../../types/ObraDetails';
import TitleValue from '../../../components/titleValue/titleValue';
import { SaveContext } from '../../../../../context/SaveProvider';
import { t } from 'i18next';
import DeleteButton from '../../../components/deleteButton/deleteButton';
import TitleHelp from '../../../components/titleHelp/titleHelp';
import parse from 'html-react-parser';
import otherTowns from '../../../../../assets/otherTowns.svg';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface EditAdditionalTownsProps {
  obra: ObraProperties;
  data: Municipio[];
  errors?: any[];
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const EditAdditionalTowns: FC<EditAdditionalTownsProps> = (props) => {
  const { obra, data, errors } = props;
  const saveContext = useContext(SaveContext);
  const [additionalTowns, setAdditionalTowns] = useState(data);


  const handleAddTown = () => {
    const newTown = { nomMunicipio: '', codigoPostal: '' };
    setAdditionalTowns([...additionalTowns, newTown]);
    if (obra.localizacion?.municipiosAdicionales) {
      obra.localizacion.municipiosAdicionales.push(newTown);
    } else {
      obra.localizacion.municipiosAdicionales = [newTown];
    }
    saveContext.handleChangeSave(true);
  };

  return (
    <div className={styles.additionalTownsContainter}>
      <TitleHelp
        id='othertowns'
        value={t('details.location.otherTowns')}
        help={t('help.localization.municipalities')}
        addItemText={t('details.location.add') as string}
        handleAddItem={handleAddTown}
      />
      {additionalTowns.length > 0 ?
        <>
          <div className={styles.otherTownsContainer}>
            {additionalTowns.map((_town, i) => {

              const handleChangeTown = (s: string) => {
                obra.localizacion.municipiosAdicionales[i].nomMunicipio = s;
                saveContext.handleChangeSave(true);
              };

              const handleChangeZip = (s: string) => {
                obra.localizacion.municipiosAdicionales[i].codigoPostal = s;
                saveContext.handleChangeSave(true);
              };

              const handleDeleteTown = () => {
                obra.localizacion.municipiosAdicionales.splice(i, 1);
                setAdditionalTowns([...obra.localizacion.municipiosAdicionales]);
                saveContext.handleChangeSave(true);
              };

              return (<div key={i} className={styles.additionalTown}>
                <div className={styles.town}>
                  <TitleValue
                    identifier={`aditional-town-${i}`}
                    title={t('details.location.town')}
                    value={obra.localizacion.municipiosAdicionales[i]?.nomMunicipio}
                    error={errors && errors.length > 0 && !obra.localizacion.municipiosAdicionales[i]?.nomMunicipio}
                    editable={true}
                    handleChange={handleChangeTown}
                    noSpecialCharacters
                  />
                </div>
                <div className={styles.zipcode}>
                  <TitleValue
                    identifier={`aditional-zipcode-${i}`}
                    title={t('details.location.zipCode')}
                    value={obra.localizacion.municipiosAdicionales[i]?.codigoPostal}
                    error={errors && errors.length > 0 && !obra.localizacion.municipiosAdicionales[i]?.codigoPostal}
                    editable={true}
                    handleChange={handleChangeZip}
                    valueMaxLength={25}
                    parseText='[^a-zA-Z0-9-]'
                  />
                </div>
                <DeleteButton id={`deleteBtn${i}`} onClick={handleDeleteTown} />
              </div>);
            })}
          </div>
        </>
        :
        <div id='othertowns-nodata' className={styles.noDataPic}>
          <img src={otherTowns} />
          <p>{parse(t('details.location.addOtherTowns'))}</p>
        </div>
      }
    </div>
  );
};

export default EditAdditionalTowns;