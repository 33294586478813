import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { ObraProperties, UnidadObra } from '../../../../../../types/ObraDetails';
import { SaveContext } from '../../../../../../context/SaveProvider';
import SearchAutocomplete from '../../../../components/searchAutocomplete/searchAutocomplete';
import SimpleSelect from '../../../../components/simpleSelect/simpleSelect';
import DeleteButton from '../../../../components/deleteButton/deleteButton';
import ObrasAPI from '../../../../../../services/APIObras';
import { TechnicalAspectsContext } from '../../../technicalAspects';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DataProcessProps {
  obra: ObraProperties;
  process: any;
  index: number;
  handleDelete: any;
  error?: any[];
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const DataProcess: FC<DataProcessProps> = (props) => {
  const { obra, process, index, handleDelete, error } = props;
  const [t] = useTranslation();
  const saveContext = useContext(SaveContext);
  const [options, setOptions] = useState<any>([]);
  const [chapters, setChapters] = useState<any>([]);
  const [groups, setGroups] = useState<any>([]);
  const [processField, setProcessField] = useState<any>([]);
  const [, setSelectProcessField] = useState<any>(process.procesoConstructivo);
  const api = ObrasAPI.getInstance();
  const { setBuildingProcessUnits } = useContext(TechnicalAspectsContext);
  const [ errorProcess, setErrorProcess ] = useState<any>(error);

  useEffect(() => {
    setErrorProcess(error);
  }, [error]);

  if (!chapters || chapters.length === 0) {
    api.getAllChapter().then((data) => {
      data.map((d: any) => {
        d.id = parseInt(d.codigo);
      });
      setChapters(data);
    });
  }

  if ((!groups || groups.length === 0) && process.capitulo?.id) {
    api.getGroupChapter(process.capitulo.id).then((data) => {
      setGroups(data);
    });
  }

  const changeGroup = (codigo: string) => {
    api.getChapter(codigo).then((data) => {
      if (!process.capitulo) process.capitulo = {};
      if (data && data.length > 0) {
        process.capitulo.id = data[0].id;
        process.capitulo.nombre = data[0].nombre;
      }
      setProcessField([]);
    });
  };

  const deleteOldUnits = () => {
    return obra.aspectosTecnicos?.procesosConstructivos.filter((_pc, i) => i !== index)
      .map(((pc, i) => { return { index: i < index ? i : i + 1, units: pc.unidades?.map(unit => unit) }; })) || [];
  };

  /*
    Depending on whether the value code belongs to a group or not, it sets either the idGrupoProceso or
    the idProceso in the new units. Every time a building process or a building group changes, the old
    units are deleted before create the new ones and set them to the state of BuildingProcessUnits.
  */
  const getUnitsByValue = (value: any) => {
    const isGroup = value.codigo.length === 4;
    const promise = isGroup
      ? api.getUnitsByBuildingProcessGroup(value.codigo)
      : api.getUnitsByBuildingProcess(value.codigo);
    promise.then((items) => {
      const newUnits: UnidadObra[] = [];
      items.forEach((unit: any) => {
        const hasMatchInUnidades = obra.aspectosTecnicos.unidades.some(
          (unidad: any) => unidad.unidad?.codUnidad === unit.code
        );
        const hasMatchInProcesosConstructivos = obra.aspectosTecnicos.procesosConstructivos.some(
          (proceso: any) =>
            proceso.unidades.some((unidad: any) => unidad.unidad?.codUnidad === unit.code)
        );
        const hasMatchInNewUnits = newUnits.some(
          (newUnit: any) => newUnit.unidad?.codUnidad === unit.code
        );
        if (!hasMatchInUnidades && !hasMatchInProcesosConstructivos && !hasMatchInNewUnits) {
          newUnits.push({
            id: undefined,
            idGrupoProceso: isGroup ? undefined : value.id,
            idProceso: isGroup ? value.id : undefined,
            unidad: {
              id: unit.id,
              codUnidad: unit.code,
              nomUnidad: unit.name
            },
            medicion: undefined
          });
        }
      });
      process.unidades = newUnits;
      const newBuildingProcessUnits = deleteOldUnits();
      setBuildingProcessUnits([...newBuildingProcessUnits, { index, units: newUnits }]);
    });
  };

  const onClickDelete = (process: any, index: number) => {
    setProcessField([]);
    handleDelete(process, index);
  };

  const handleChange = (reference: any, parameter: string, value: string | any) => {
    if(errorProcess){
      setErrorProcess(null);
    }
    if (parameter === 'search') {
      let optionSelected;
      for (const i in options) {
        if (options[i].codigo === value) {
          optionSelected = options[i];
          break;
        }
      }
      getUnitsByValue(optionSelected);
      if (optionSelected.groupCode) {
        process.id = optionSelected.groupId;
        process.codigo = optionSelected.groupCode;
        process.nombre = optionSelected.groupName;
        process.procesoConstructivo = {
          id: optionSelected.codigo,
          codigo: optionSelected.codigo,
          nombre: optionSelected.nombre,
        };
        setSelectProcessField(process.procesoConstructivo);
        changeGroup(optionSelected.groupCode);

      } else {
        process.id = optionSelected.id;
        process.codigo = optionSelected.codigo;
        process.nombre = optionSelected.nombre;
        changeGroup(optionSelected.codigo);
      }
    } else if (parameter === 'id_codigo') {
      getUnitsByValue(value);
      process.id = value.id;
      process.codigo = value.codigo;
      process.nombre = value.nombre;
      setProcessField(value.elementos || []);
    } else if (parameter === 'id_capitulo') {
      // In this case, we don't want to get the unit of anything. We only need to delete the existing ones.
      const newBuildingProcessUnits = deleteOldUnits();
      setBuildingProcessUnits([...newBuildingProcessUnits]);
      if (!process.capitulo) process.capitulo = {};
      process.capitulo.id = value.id;
      process.capitulo.nombre = value.nombre;
      api.getGroupChapter(process.capitulo.id).then((data) => {
        setProcessField([]);
        delete process.codigo;
        delete process.nombre;
        delete process.procesoConstructivo;
        setSelectProcessField(null);
        setGroups(data);
      });
    } else if (parameter === 'id_process_field') {
      getUnitsByValue(value);
      process.procesoConstructivo = {
        id: value.codigo,
        codigo: value.codigo,
        nombre: value.nombre,
      };
      setSelectProcessField(process.procesoConstructivo);
    }
    reference[parameter] = value;
    saveContext.handleChangeSave(true);
  };

  const mergeInPlace = (a: any, b: any, i = 0) => a.splice(i, 0, ...b);

  const onChangeAutocomplete = async (value: string) => {
    try {
      const results = await api.getAutocompleteProcess(value) || [];
      results.forEach((option: any, index: number) => {
        if (option.procesos && option.procesos.length > 0) {
          option.procesos.map((field: any) => {
            field.id = parseInt(field.codigo);
            field.groupCode = option.codigo;
            field.groupId = option.id;
            field.groupName = option.nombre;
            return field;
          });
          mergeInPlace(results, option.procesos, index + 1);
        }
      });
      return setOptions(results);
    } catch (error) {
      console.log(error);
    }
    return setOptions([]);
  };

  const selectedProcessField = { id: process.procesoConstructivo?.codigo, nombre: process.procesoConstructivo?.nombre };
  const selectedChapter = { id: process.capitulo?.id, nombre: process.capitulo?.nombre };

  if (!process.codigo) {
    process.codigo = '';
  }

  const selectedGroup = { id: process.codigo, nombre: process.nombre };

  if (selectedGroup.id) {
    for (const opt of options) {
      if (selectedGroup.id === opt.codigo && ((opt.procesos && opt.procesos.length > 0 && processField.length === 0) || (!opt.procesos || opt.procesos.length === 0 && processField.length > 0))) {
        setProcessField(opt.procesos);
        break;
      }
    }
  }

  return (
    <>
      <Grid item xs={1.5}>
        <SearchAutocomplete
          id={`processAutoselect${index}`}
          title={t('details.technical.buildingProcess.code') || ''}
          searchValue={process?.procesoConstructivo && process.procesoConstructivo?.codigo ? process.procesoConstructivo.codigo.toString() : process.codigo}
          options={options.map((option: any) => option.codigo)}
          editable={true}
          contrast={true}
          error={errorProcess && errorProcess?.filter((e: any) => e.fieldField === 'codigo').length > 0 && !process.codigo}
          inputChangeHandler={(value: string) => onChangeAutocomplete(value)}
          handleChange={(value: any) => handleChange(process, 'search', value)} />
      </Grid>
      <Grid item xs={4}>
        <SimpleSelect
          id={`data-process-chapter-${index}`}
          options={chapters}
          value={selectedChapter}
          label={t('details.technical.buildingProcess.chapter')}
          contrast={true}
          error={errorProcess && errorProcess?.filter((e: any) => e.fieldField === 'capitulo').length > 0 && !selectedChapter.id }
          handleChangeValue={(data) => {
            handleChange(process, 'id_capitulo', data);
          }}
          editable={true} />
      </Grid>
      <Grid item xs={3}>
        <SimpleSelect
          id={`data-process-group-${index}`}
          options={groups}
          value={selectedGroup}
          label={t('details.technical.buildingProcess.group')}
          contrast={true}
          handleChangeValue={(data) => {
            handleChange(process, 'id_codigo', data);
          }}
          error={groups && groups.length > 0 && errorProcess && errorProcess?.filter((e: any) => e.fieldField === 'codigo').length > 0 && !selectedGroup.id }
          editable={groups && groups.length > 0} />
      </Grid>
      <Grid item xs={3}>
        <SimpleSelect
          id={`data-process-process-${index}`}
          options={processField}
          value={selectedProcessField}
          label={t('details.technical.buildingProcess.process')}
          contrast={true}
          handleChangeValue={(data) => {
            handleChange(process, 'id_process_field', data);
          }}
          editable={processField && processField.length > 0} />
      </Grid>
      <Grid item xs={0.5}>
        <DeleteButton
          id={`deleteBtn-${index}`}
          onClick={() => {
            const newBuildingProcessUnits = deleteOldUnits();
            setBuildingProcessUnits([...newBuildingProcessUnits]);
            onClickDelete(process, index);
          }} />
      </Grid>
    </>
  );
};

export default DataProcess;
