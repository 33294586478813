/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unknown-property */
import './DragDropFile.scss';
import React, { useState } from 'react';
import fileSource from '../../assets/carousel/fileUploadIcon.svg';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ObrasAPI from '../../services/APIObras';


interface DragDropProps {
  codCentro: string;
  handleUpload: (imageField: any) => void;
}

// drag drop file component
function DragDropFile(props: DragDropProps) {
  const { t } = useTranslation();
  const { handleUpload } = props;
  // drag state
  const [dragActive, setDragActive] = React.useState(false);

  const inputRef = React.useRef<any>(null);
  const [image, setImage] = useState(fileSource);
  const [errorImage, setErrorImage] = useState<any>('');
  const [isLoading, setLoading] = useState(false);

  // handle drag events
  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (!e.dataTransfer.files || !e.dataTransfer.files[0].type.includes('image')) {
      setErrorImage(t('details.carousel.errorTypeImage'));
      return;
    }
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setErrorImage('');
      handleFiles(e.dataTransfer.files[0]);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e: any) {
    e.preventDefault();
    if (!e.target.files || !e.target.files[0].type.includes('image')) {
      setErrorImage(t('details.carousel.errorTypeImage'));
      return;
    }
    if (e.target.files && e.target.files[0]) {
      setErrorImage('');
      handleFiles(e.target.files[0]);
    }
  };

  const handleFiles = async (file: any) => {
    //Initiate the JavaScript Image object.
    const _URL = window.URL || window.webkitURL;

    const objectUrl = _URL.createObjectURL(file);
    const image = new Image();

    image.src = objectUrl;
    image.onload = async function () {
      uploadFile(file);
    };
    image.onerror = () => {
      setErrorImage(t('details.carousel.errorImage'));
    };

  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current && inputRef.current.click();
  };



  const uploadFile = async (file: any) => {
    setLoading(true);
    const api = ObrasAPI.getInstance();
    const response = await api.uploadFile(file, props.codCentro, 'image');
    if (response.statusCode === 200) {
      handleUpload({ url: response.url });
      setImage(response.url);
      setErrorImage('');
    }
    else if (response.statusCode === 413) {
      handleUpload({ error: 413 });
      setImage(fileSource);
      setErrorImage('');
    } else {
      handleUpload({ error: response.statusCode });
      setImage(fileSource);
      setErrorImage('Error uploading image');
    }
    setLoading(false);
  };

  return (
    <form id='form-file-upload' onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>

      <input ref={inputRef} type='file' id='input-file-upload' multiple={true} onChange={handleChange} />
      <label id='label-file-upload' htmlFor='input-file-upload' className={dragActive ? 'drag-active' : ''}>
        <div>
          {isLoading ? <div className='loading'><CircularProgress /></div> : <img width='150px' height='150px' alt='' src={image} />}
          <button className='upload-button' onClick={onButtonClick}>{t('details.carousel.choose')}</button>
          <p>{t('details.carousel.dragImage')}</p>
        </div>
      </label>
      <span className='error'>{errorImage !== '' ? errorImage : ''}</span>
      {dragActive && <div id='drag-file-element' onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}

    </form>
  );
}

export default DragDropFile;
