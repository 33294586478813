/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable indent */
import { TFunction, use } from 'i18next';
import React, { useContext, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import styles from './validateProject.module.scss';
import { Button, Dialog, Stack, Tooltip, Typography } from '@mui/material';
import ObrasAPI from '../../services/APIObras';
import { SaveContext } from '../../context/SaveProvider';
import { ObraProperties, Comentario } from '../../types/ObraDetails';
import send from '../../assets/send.svg';
import fingerUp from '../../assets/fingerUp.svg';
import fingerDown from '../../assets/fingerDown.svg';
import optionalIcon from '../../assets/validation_optional.svg';
import requiredIcon from '../../assets/validation_required.svg';
import { AuthContext } from '../../services/authContextProvider';
import { ADMIN, VALIDATOR } from '../../userRoles/userRoles';
import draftValidation, { draftValidationFinal } from '../../validations/draft';
import { APPROVE, REJECT, REJECT_SERVER_ERROR, VALIDATION_SERVER_ERROR, VALIDATION_SERVER_APPROVE_ERROR } from './validationTypes';
import RejectedDialog from './components/rejectedDialog/RejectedDialog';
import ServerErrorDialog from './components/serverErrorDialog/ServerErrorDialog';
import CheckSaved from '../checkSaved/checkSaved';
import moment from 'moment';
import parse from 'html-react-parser';
import { UserModeContext } from '../../context/UserModeProvider';
import LoadingSpinner from '../loadingSpinner/loadingSpinner';


interface ValidateProjectProps {
  t: TFunction<'translations'>;
  obra: ObraProperties;
  handleTabErrors?: (errors: any) => void;
}

function checkStatus(obra: ObraProperties) {
  const authContext = useContext(AuthContext);
  const isAuthorizedValidation = authContext.hasRole(ADMIN) || authContext.hasRole(VALIDATOR);

  let status = 'enable';

  // [PENDING] => Status INITIAL - IN UPDATE or [VALIDATED] => Status INITIAL - VALIDATED || UPDATED - VALIDATED || FINAL - VALIDATED [REJECTED] => UPDATED - REJECTED || FINAL - REJECTED
  if (obra.general.codEstadoPhia === 1 || obra.general.codEstadoPhia === 4 || obra.general.codEstadoPhia === 7 ||
    obra.general.codEstadoPhia === 3 || obra.general.codEstadoPhia === 5 || obra.general.codEstadoPhia === 8
    || obra.general.codEstadoPhia === 6 || obra.general.codEstadoPhia === 9) {
    status = 'enable';
    if (isAuthorizedValidation) {
      status = 'validateFingerUp';
    }
  }

  // [PENDING] => Status INITIAL - PENDING || UPDATED - PENDING || FINAL - PENDING
  if (obra.general.codEstadoPhia === 2 || obra.general.codEstadoPhia === 4) {
    status = 'disable';
    // no puede usar ningún botón
    if (isAuthorizedValidation) {
      // validador 2 botones enables
      status = 'validate';
    }
  }
  //Status FINAL - PENDING 
  if (obra.general.codEstadoPhia === 7) {
    status = 'disable';
    // no puede usar ningún botón
    if (isAuthorizedValidation) {
      // validador 2 botones enables
      status = 'validateFinal';
    }
  }

  return status;
}

function ValidateProject(props: ValidateProjectProps) {
  const { t, obra, handleTabErrors } = props;
  const api = ObrasAPI.getInstance();
  const [open, setOpen] = React.useState<boolean>(false);
  const userModeContext = useContext(UserModeContext);

  const [required, setRequired] = React.useState<string[]>([]);
  const [optional, setOptional] = React.useState<string[]>([]);

  const [isValidation, setIsValidation] = React.useState<boolean>(false);
  const [isValidationFinal, setIsValidationFinal] = React.useState<boolean>(false);

  const saveContext = useContext(SaveContext);
  const authContext = useContext(AuthContext);
  const status = checkStatus(obra);
  const [dialogContent, setDialogContent] = React.useState<string>();
  const [unsavedChanges, setUnsavedChanges] = React.useState(false);
  const [loading, setLoading] = React.useState(false);


  const validateProject = async () => {
    const unitsOfProcess = obra?.aspectosTecnicos.procesosConstructivos.filter((p) => p.unidades.length > 0).length;
    const hasProcessWithUnits = unitsOfProcess && unitsOfProcess > 0 ? true : false; // If some process has one or more related units, we modify the validation of the units
    const validationPromises = [draftValidation(t, hasProcessWithUnits).validate(obra, { abortEarly: false }),
    validateProjectFinal()];
    const results = await Promise.all(validationPromises.map(p => p.catch(e => e)));
    const validationErrors = results.filter(result => (result instanceof Error));
    if (!validationErrors.length) {
      setOpen(true);
    } else {
      const requiredError: React.SetStateAction<string[]> = [], optionalError: React.SetStateAction<string[]> = [];
      const requiredErrorsTabs: any = {};
      const errorsByPromise = validationErrors.map((err) => (err.errors));
      const errors = [].concat(...errorsByPromise);
      const sortedErrors = errors.sort((a: any, b: any) => a.orden - b.orden);
      sortedErrors.forEach((error: any) => {
        if (error.optional && !requiredError.find((e) => e === error.text)) {
          optionalError.push(error.text);
        } else if (!requiredError.find((e) => e === error.text)) {
          requiredError.push(error.text);
          if (error.tab) {
            requiredErrorsTabs[error.tab] = (requiredErrorsTabs[error.tab] || []).concat(error);
          }
        }
      });

      await generateRequiredErrorsTabs(requiredErrorsTabs, validationErrors);

      setRequired(requiredError);
      setOptional(optionalError);
      setOpen(true);
      setIsValidation(false);
      saveContext.handleValidationClicked(true);
    }
  };

  const generateRequiredErrorsTabs = async (requiredErrorsTabs: any = {}, validationErrors?: any) => {
    if (!validationErrors) {
      const unitsOfProcess = obra?.aspectosTecnicos.procesosConstructivos.filter((p) => p.unidades.length > 0).length;
      const hasProcessWithUnits = unitsOfProcess && unitsOfProcess > 0 ? true : false; // If some process has one or more related units, we modify the validation of the units
      const validationPromises = [draftValidation(t, hasProcessWithUnits).validate(obra, { abortEarly: false }), validateProjectFinal()];
      const results = await Promise.all(validationPromises.map(p => p.catch(e => e)));
      validationErrors = results.filter(result => (result instanceof Error));
    }
    if (validationErrors.length) {
      for (const v of validationErrors) {
        for (const error of v.inner) {
          if (!error.message.optional) {
            const data = error.path.split('.');
            if (!requiredErrorsTabs[data[0]]) {
              requiredErrorsTabs[data[0]] = [];
            }
            const dataSplit = data[1].split('[');
            let fieldField, index = 0;
            if (dataSplit.length > 1 && data.length > 2) {
              const endSplit = dataSplit[1].split(']');
              index = endSplit[0];
              fieldField = data[data.length - 1];
              if (fieldField === 'nombre') fieldField = data[data.length - 2];
            }
            requiredErrorsTabs[data[0]].push({
              field: dataSplit[0],
              text: error.message.text,
              textField: error.message.textField,
              index,
              fieldField
            });
          }
        }
      }
    }
    handleTabErrors && handleTabErrors(requiredErrorsTabs);
  };

  const validateProjectFinal = async () => {
    const finishTime = obra.fechasPlazos.fecFinalizacionActualizada ?
      moment(obra.fechasPlazos.fecFinalizacionActualizada, 'DD/MM/YYYY') :
      moment(obra.fechasPlazos.fecFinalizacionInicial, 'DD/MM/YYYY');
    const isOnTime = moment().isAfter(finishTime.subtract(30, 'days'));
    if (isOnTime) {
      return draftValidationFinal(t).validate(obra, { abortEarly: false });
    }
    return true;
  };

  const updateValidateProjectFinal = async () => {
    try {
      await validateProjectFinal();
      await generateRequiredErrorsTabs();
    } catch (error: any) {
      const requiredErrorsTabs: any = {};
      error.errors.forEach((error: any) => {
        if (!error.optional) {
          if (error.tab) {
            requiredErrorsTabs[error.tab] = (requiredErrorsTabs[error.tab] || []).concat(error);
          }
        }
      });
      await generateRequiredErrorsTabs(requiredErrorsTabs);
    }
  };

  // update tab errors while edit draft( FINAL VVALIDATION)
  useEffect(() => {
    if (saveContext.saveDraft) {
      updateValidateProjectFinal();
    }
  }, [saveContext.update]);


  const requestValidation = async () => {
    setLoading(true);
    const status = await api.requestValidation(obra, true);
    setLoading(false);
    if (!status) {
      setDialogContent(VALIDATION_SERVER_ERROR);
      setOpen(true);
    } else {
      ReactGA.event('new_status', {
        event_category: 'send_validation',
        role: authContext.mainRole(false),
        code: obra.general.codCentro,
        status,
      });
      setOpen(false);
      saveContext.handleReloadData();
      saveContext.handleChangeSave(false);
      saveContext.handleValidationClicked(false);
    }
  };

  const getDialogContent = () => {
    switch (dialogContent) {
      case REJECT:
        return <RejectedDialog handleCancel={() => setOpen(false)} handleAccept={handleReject} />;
      case REJECT_SERVER_ERROR:
        return <ServerErrorDialog handleCancel={() => setOpen(false)} handleAccept={reject} />;
      case VALIDATION_SERVER_ERROR:
        return <ServerErrorDialog handleCancel={() => setOpen(false)} handleAccept={() => {
          setOpen(false);
          requestValidation();
        }} text={t('details.validation.errorSendValidation')} />;
      case VALIDATION_SERVER_APPROVE_ERROR:
        return <ServerErrorDialog handleCancel={() => setOpen(false)} handleAccept={() => {
          setOpen(false);
          approve();
        }} text={t('details.validation.errorSendValidation')} />;
      default:
        return <div className={styles.dialogContentSendError}>
          {
            required.length === 0 && optional.length === 0 ?
              <div>
                {isValidation ?
                  <>
                    <div className={styles.titleConfirm}>{t('details.validation.approveConfirm')}</div>
                    <div className={styles.buttonSection}>
                      <Stack spacing={2} direction="row" justifyContent="center">
                        <Button id='buttoncancel' className={styles.buttonCancel}
                          onClick={() => {
                            setIsValidation(false);
                            setOpen(false);
                          }}
                          disableRipple={true} variant="outlined">{t('cancel')}</Button>
                        <Button id='buttonapprove' className={styles.buttonApprove} onClick={() => {
                          approve();
                          setOpen(false);
                        }} disableRipple={true} variant="contained">{t('approve')}</Button>
                      </Stack>
                    </div>
                  </>
                  : isValidationFinal ?
                    <>
                      <div className={styles.titleConfirm}>{parse(t('details.validation.approveFinal'))}</div>
                      <div className={styles.buttonSection}>
                        <Stack spacing={2} direction="row" justifyContent="center">
                          <Button id='buttoncancel' className={styles.buttonCancel}
                            onClick={() => {
                              setIsValidationFinal(false);
                              setOpen(false);
                            }}
                            disableRipple={true} variant="outlined">{t('cancel')}</Button>
                          <Button id='buttonapprove' className={styles.buttonApprove} onClick={() => {
                            approve();
                            setOpen(false);
                          }} disableRipple={true} variant="contained">{t('validate')}</Button>
                        </Stack>
                      </div>
                    </>
                    :
                    <>
                      <div className={styles.titleConfirm}>{t('details.validation.titleConfirm')}</div>
                      <div className={styles.descriptionConfirm}>{t('details.validation.descriptionConfirm')}</div>
                      <div className={styles.buttonSection}>
                        <Stack spacing={2} direction="row" justifyContent="center">
                          <Button id='buttoncancel' className={styles.buttonCancel} onClick={() => setOpen(false)} disableRipple={true} variant="outlined">{t('cancel')}</Button>
                          <Button id='buttonsend' className={styles.buttonConfirm} onClick={() => {
                            requestValidation();
                          }} disableRipple={true} variant="contained">{t('send')}</Button>
                        </Stack>
                      </div>
                    </>
                }
              </div>
              :
              <div>
                <div className={styles.titleError}>{(isValidationFinal && required.length === 0 && optional.length > 0) ?
                  parse(t('details.validation.titleErrorFinal'))
                  : t('details.validation.titleError')}
                </div>
                <div className={styles.validationSection}>
                  {
                    required.length > 0 ?
                      <div className={styles.errorSection}>
                        <img className={styles.iconError} src={requiredIcon} />
                        <div><div className={styles.titleContentError}>Obligatorios:</div>{parse(required.join('.<br/> '))}</div>
                      </div>
                      : null
                  }
                  {
                    optional.length > 0 ?
                      <div className={styles.errorSection}>
                        <img className={styles.iconError} src={optionalIcon} />
                        <div><div className={styles.titleContentError}>Opcionales:</div>{parse(optional.join('.<br/> '))}</div>
                      </div>
                      : null
                  }
                </div>
                <div className={styles.buttonSection}>
                  {
                    required.length == 0 ?
                      <Stack spacing={2} direction="row" justifyContent="center">
                        <Button id='buttoncancel' className={styles.buttonCancel} onClick={() => setOpen(false)} disableRipple={true} variant="outlined">{t('cancel')}</Button>
                        <Button id='buttonsend' className={styles.buttonConfirm} onClick={() => {
                          dialogContent === APPROVE ? approve() : requestValidation();
                          setOpen(false);
                        }} disableRipple={true} variant="contained">{isValidationFinal ? t('validate') : t('continue')}</Button>
                      </Stack>
                      :
                      <Stack spacing={2} direction="row" justifyContent="center">
                        <Button className={styles.buttonConfirm} onClick={() => {
                          setOpen(false);
                        }} disableRipple={true} variant="contained">{t('accept')}</Button>
                      </Stack>
                  }
                </div>
              </div>
          }
        </div>;
    }
  };

  const approve = async () => {
    setLoading(true);
    const status = await api.approve(obra);
    setLoading(false);
    if (!status) {
      setDialogContent(VALIDATION_SERVER_APPROVE_ERROR);
      setOpen(true);
    } else {
      ReactGA.event('new_status', {
        event_category: 'approve',
        role: authContext.mainRole(false),
        code: obra.general.codCentro,
        status,
      });
      setOpen(false);
      saveContext.handleReloadData();
      saveContext.handleChangeSave(false);
      saveContext.handleValidationClicked(false);
      setUnsavedChanges(false);
    }
  };

  const handleReject = async (comment: Comentario) => {
    obra.comentario = comment;
    await reject();
  };

  const reject = async () => {
    setLoading(true);
    const status = await api.reject(obra);
    setLoading(false);
    if (!status) {
      setDialogContent(REJECT_SERVER_ERROR);
    } else {
      ReactGA.event('new_status', {
        event_category: 'reject',
        role: authContext.mainRole(false),
        code: obra.general.codCentro,
        status,
      });
      setOpen(false);
      saveContext.handleReloadData();
      saveContext.handleChangeSave(false);
      saveContext.handleValidationClicked(false);
    }
  };

  return (
    <div data-testid='validation-test' className={styles.validateContainer}>
      {
        loading ?
          <LoadingSpinner />
          : null
      }
      {
        status === 'enable' ?
          <Tooltip
              enterDelay={1000}
              arrow
              title={
                <Typography>
                  {t('header.adminPanel')}
                </Typography>
              }
              followCursor
            >
            <button
              id='tabmenu-send'
              onClick={() => {
                setUnsavedChanges(false);
                setDialogContent('');
                validateProject();
              }}
              disabled={userModeContext.userMode === 'editorDisabled'}
            >
              <img src={send} /> {t('details.send')}
            </button>
          </Tooltip>
          : status === 'validate' || status === 'validateFingerUp' || status === 'validateFinal' ?
            <div className={styles.approveRejectContainer}>
              {status === 'validateFinal' ?
              <button
                className={styles.approveButtonFinal}
                id='buttonApproveFinal'
                onClick={() => {
                  setIsValidationFinal(true);
                  setDialogContent(APPROVE);
                  validateProject();
                }}
              >
                <img src={fingerUp} />
                <div>Validar ficha</div>
              </button>
              :
              <Tooltip
                enterDelay={1000}
                arrow
                title={
                  <Typography>
                    {t('header.validate')}
                  </Typography>
                }
              >
                <button
                  className={styles.approveButton}
                  id='buttonApprove'
                  onClick={() => {
                    setUnsavedChanges(false);
                    setIsValidation(true);
                    setDialogContent(APPROVE);
                    validateProject();
                  }}
                >
                  <img src={fingerUp} />
                </button>
                </Tooltip>
              }
              <div data-testid='validation-reject-test' className={styles.rejectedButton}>
              <Tooltip
                enterDelay={1000}
                arrow
                title={
                  <Typography>
                    {t('header.rejected')}
                  </Typography>
                }
                disableHoverListener={status === 'validateFingerUp'}
              >
                <button
                  id='buttonReject'
                  onClick={() => {
                    setUnsavedChanges(false);
                    setDialogContent(REJECT);
                    setOpen(true);
                  }}
                  disabled={status === 'validateFingerUp'}
                >
                  <img src={fingerDown} />
                </button>
              </Tooltip>
                {
                  (unsavedChanges) ?
                    <CheckSaved
                      handleFunction={() => {
                        setDialogContent(REJECT);
                        setOpen(true);
                        setUnsavedChanges(false);
                        saveContext.handleChangeSave(false);
                        saveContext.handleValidationClicked(false);
                      }}
                      closeFunction={() => {
                        setUnsavedChanges(false);
                      }} />
                    : null
                }
              </div>
            </div>
            :
            <button
              id='tabmenu-send'
              onClick={() => {
                // sendValidateProject();
                setOpen(true);
              }}
              disabled={saveContext.saveDraft || status === 'disable'}
            >
              <img src={send} /> {t('details.send')}
            </button>
      }
      <Dialog
        classes={{
          paper: styles.dialogError
        }}
        BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.15)' } }}
        PaperProps={{ sx: { borderRadius: '20px', minWidth: '700px' } }}
        open={open}
        onClose={() => setOpen(false)}
      >

      </Dialog>
      <Dialog
        classes={{
          paper: styles.dialog
        }}
        BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.15)' } }}
        PaperProps={{ sx: { borderRadius: '20px', minWidth: '90vW' } }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <div className={styles.dialogContenError}>
          {getDialogContent()}
        </div>
      </Dialog>
    </div >
  );
}

export default withTranslation()(ValidateProject);
