import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UnidadObra } from '../../../../../types/ObraDetails';
import TitleValue from '../../../components/titleValue/titleValue';
import styles from './workUnit.module.scss';
import { UserModeContext } from '../../../../../context/UserModeProvider';
import ObrasAPI from '../../../../../services/APIObras';
import debounce from 'lodash.debounce';
import DeleteButton from '../../../components/deleteButton/deleteButton';
import AutocompleteFields from '../../../components/autocompleteFields/autocompleteFields';
import { SaveContext } from '../../../../../context/SaveProvider';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface WorkUnitProps {
  key?: number;
  unit: UnidadObra | undefined;
  handleChangeUnit?: (id: number, codUnidad: string, nomUnidad: string) => void;
  handleChangeMeasuring?: (measuring: string) => void;
  handleDeleteItem?: () => void;
  editable?: boolean;
  error: boolean;
  master: any[]
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const WorkUnit: FC<WorkUnitProps> = (props) => {
  const { key, unit, handleChangeUnit, handleChangeMeasuring, handleDeleteItem, editable, error, master } = props;
  const [t] = useTranslation();
  const [options, setOptions] = useState<any>(master);
  const userModeContext = useContext(UserModeContext);
  const isEditorMode = userModeContext.userMode === 'editor';
  const saveContext = useContext(SaveContext);

  useEffect(() => {
    setOptions(master);
  }, [master]);
  // queries elasticsearch looking for all the work subtypes by its code or name
  const codeInputRequest = async (newValue: string, type: string, length: number) => {
    if (newValue.length >= length) {
      try {
        const api = ObrasAPI.getInstance();
        let results = type === 'code'
          ? await api.getAutocompleteWorkUnitsByCode(newValue)
          : await api.getAutocompleteWorkUnitsByName(newValue);
        results = results.length ? results.filter((r: any) => (master.map(m => m.code).includes(r.code))) : [];
        return setOptions(results);
      } catch (error) {
        console.log(error);
      }
    }
    return setOptions([]);
  };

  // Calls the query to elasticsearch each time that the autoselect input changes
  const inputChangeHandler = useCallback(
    debounce(codeInputRequest, 0),
    [],
  );

  const handleChange = (id: number, code: string, name: string) => {
    if (handleChangeUnit) {
      handleChangeUnit(id, code, name);
      saveContext.forceUpdate();
    }
  };

  const getFormattedMedicion = (value?: any) => {
    if (value) {
      let medicion;
      const parts = value.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      if (parts.length == 2) {
        medicion = parts[0] + ',' + parts[1];
      }
      else {
        medicion = parts[0];
      }
      return medicion;
    } else if (value === 0) {
      return 0;
    }
    return null;
  };

  return (
    <>
      {<tr key={key} className={editable ? styles.workUnitWithDelete : styles.workUnit}>
        <td>
          <div className={styles.units}>
            <AutocompleteFields
              id={`unitAutoselect${key}`}
              codeValue={unit?.unidad?.codUnidad?.toString()}
              nameValue={unit?.unidad?.nomUnidad}
              options={options}
              errors={saveContext.isValidation && !unit?.unidad?.nomUnidad ? [{ fieldField: 'nomUnidad'}] : []}
              inputChangeHandler={inputChangeHandler}
              handleChange={handleChange}
              editable={editable}
              isUnits={true}
              isTable
            />
          </div>
        </td>
        <td>
          <div className={styles.titleValue3}>
            <TitleValue
              identifier={`workUnits-measuring-${key}`}
              title={t('details.technical.measuring')}
              value={unit?.medicion || unit?.medicion === 0 ? getFormattedMedicion(unit?.medicion.toString()) : ''}
              editable={isEditorMode}
              error={error}
              isDecimalNumber
              handleChange={handleChangeMeasuring}
              contrast
              onlyDisable={!isEditorMode}
              isTable
            />
          </div>
          {editable && handleDeleteItem &&
            <div className={styles.trashBtn}>
              <DeleteButton id={`deleteBtn-${key}`} onClick={() => handleDeleteItem()} />
            </div>
          }
        </td>
      </tr>}
    </>
  );
};

export default WorkUnit;
