/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import DATE_PICKER_RANGE_TYPE from './DatePickerRangeType';

import { FilterCollapsableWrapper } from '../../FiltersWrappers/FilterCollapsableWrapper/FilterCollapsableWrapper';
import { CustomDatePicker } from './CustomDatePicker/CustomDatePicker';
import { Box } from '@mui/material';


export const DatePickerRange = ({ filterType, noBottomDivider }: any) => {
  return (
    <>
      <FilterCollapsableWrapper
        filterType={filterType}
        noBottomDivider={noBottomDivider}
      >
        <CustomDatePicker
          filterType={filterType}
          datePickerRangeType={DATE_PICKER_RANGE_TYPE.startDate}
        />
        <Box style={{ width: '2%' }}></Box>
        <CustomDatePicker
          filterType={filterType}
          datePickerRangeType={DATE_PICKER_RANGE_TYPE.endDate}
        />

      </FilterCollapsableWrapper>
    </>
  );
};
