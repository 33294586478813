/* eslint-disable @typescript-eslint/no-empty-function */
import { t } from 'i18next';
import React, { createContext, useState } from 'react';
import { Permiso, Usuario, UsuarioProyecto } from '../types/Admin';

export const selectOptions = [
  { id: 0, nombre: t('administration.user') },
  { id: 1, nombre: t('administration.project') },
];
export interface EmailandName{
  email?:string;
  name?:string;
}
export interface Proyecto{
  codEmpresa: string;
  codZona: string;
  codSupercentro: string;
  codCentro: string;
}
export interface UpdatedPermissions{
  [key: string]: string[];
}
interface AdminContextValues {
  searchMethod: number;
  allUsers: Set<Usuario>;
  allUsersNoPhia: Usuario[];
  userToEdit?: EmailandName;
  isEdition: boolean;
  projectToAdd?: Proyecto;
  hasProject: boolean;
  permissionToEdit?: Permiso;
  usersPerProject: UsuarioProyecto[];
  updatedPermissions: UpdatedPermissions | undefined;
  handleChangeSearchMethod: (value: number) => void;
  handleSetAllUsers: (value: Usuario[]) => void,
  handleCacheUsers: (value: Usuario[]) => void,
  handleCacheUsersNoPhia: (value: Usuario[]) => void,
  handleChangeUserToEdit: (user: EmailandName) => void,
  handleSetIsEdition: (value: boolean) => void,
  handleChangeProjectToAdd: (value: Proyecto) => void,
  handleChangeHasProject: (value: boolean) => void,
  handleChangePermissionToEdit: (value: Permiso | undefined) => void,
  handleChangeUsersPerProject: (value: UsuarioProyecto[]) => void,
  handleChangeUpdatedPermissions: (value: UpdatedPermissions) => void,
}

const defaultAdminContextValues: AdminContextValues = {
  searchMethod: 0,
  allUsers: new Set(),
  allUsersNoPhia: [],
  userToEdit: undefined,
  isEdition: false,
  projectToAdd: undefined,
  hasProject: false,
  permissionToEdit: undefined,
  usersPerProject: [],
  updatedPermissions: {},
  handleChangeSearchMethod: () => { },
  handleSetAllUsers: () => { },
  handleCacheUsers: () => { },
  handleCacheUsersNoPhia: () => { },
  handleChangeUserToEdit: () => { },
  handleSetIsEdition: () => { },
  handleChangeProjectToAdd: () => { },
  handleChangeHasProject: () =>  { },
  handleChangePermissionToEdit: () => {},
  handleChangeUsersPerProject: () => {},
  handleChangeUpdatedPermissions: () => {},
};

export const AdminContext = createContext<AdminContextValues>(
  defaultAdminContextValues
);


interface AdminProviderProps {
  /**
   * The elements wrapped by the auth context.
   */
  children: JSX.Element;
}
const AdminProvider = (props: AdminProviderProps) => {
  const [searchMethod, setSearchMethod] = useState(0);
  const [allUsers, setAllUsers] = useState<Set<Usuario>>(new Set());
  const [allUsersNoPhia, setAllUsersNoPhia] = useState<Usuario[]>([]);
  const [userToEdit, setUserToEdit] = useState<EmailandName| undefined>(undefined);
  const [isEdition, setIsEdition] = useState<boolean>(false);
  const [projectToAdd, setProjectToAdd] = useState<Proyecto | undefined>(undefined);
  const [hasProject, setHasProject] = useState<boolean>(false);
  const [permissionToEdit, setPermissionToEdit] = useState<Permiso | undefined>(undefined);
  const [usersPerProject, setUsersPerProject] = useState<UsuarioProyecto[]> ([]);
  const [updatedPermissions, setUpdatedPermissions] = useState<UpdatedPermissions>({});

  const handleChangeSearchMethod = (opt: number) => {
    setSearchMethod(opt);
  };

  const handleSetAllUsers = (values: Usuario[]) => {
    setAllUsers(new Set([...values]));
  };

  const handleCacheUsers = (values: Usuario[]) => {
    setAllUsers(prevUsers => new Set([...prevUsers, ...values]));
  };

  const handleCacheUsersNoPhia = (values: Usuario[]) => {
    setAllUsersNoPhia(values);
  };

  const handleChangeUserToEdit = (user: EmailandName) => {
    setUserToEdit(user);
  };

  const handleSetIsEdition = (value: boolean) => {
    setIsEdition(value);
  };

  const handleChangeProjectToAdd = (value: Proyecto) => {
    setProjectToAdd(value);
  };

  const handleChangeHasProject = (value: boolean) => {
    setHasProject(value);
  };

  const handleChangePermissionToEdit = (value: Permiso | undefined) => {
    setPermissionToEdit(value);
  };

  const  handleChangeUsersPerProject = (value: UsuarioProyecto[]) => {
    setUsersPerProject(value);
  };

  const  handleChangeUpdatedPermissions = (value: UpdatedPermissions) => {
    setUpdatedPermissions(value);
  };
  // Setup the context provider
  return (
    <AdminContext.Provider
      value={
        {
          searchMethod,
          allUsers,
          allUsersNoPhia,
          userToEdit,
          isEdition,
          projectToAdd,
          hasProject,
          permissionToEdit,
          usersPerProject,
          updatedPermissions,
          handleChangeSearchMethod,
          handleSetAllUsers,
          handleCacheUsers,
          handleCacheUsersNoPhia,
          handleChangeUserToEdit,
          handleSetIsEdition,
          handleChangeProjectToAdd,
          handleChangeHasProject,
          handleChangePermissionToEdit,
          handleChangeUsersPerProject,
          handleChangeUpdatedPermissions,
        }}
    >
      {props.children}
    </AdminContext.Provider>
  );
};

export default AdminProvider;
