/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useEffect, useState } from 'react';

interface ViewContextValues {
  mapIsSelected: boolean,
  handleChangeSwitch: () => void,
  mainView: boolean,
  isActionsView: boolean,
  setActionsView: (actionsView: boolean) => void,
}

const defaultViewContextValues: ViewContextValues = {
  mapIsSelected: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleChangeSwitch: () => { },
  mainView: false,
  isActionsView: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActionsView: () => { },
};

export const ViewContext = createContext<ViewContextValues>(
  defaultViewContextValues
);


interface ViewContextProviderProps {
  /**
   * The elements wrapped by the auth context.
   */
  children: JSX.Element;
  mainView: boolean;
}
const ViewProvider = (props: ViewContextProviderProps) => {
  const [mapIsSelected, setMapIsSelected] = useState<any>();
  const [isActionsView, setIsActionsView] = useState<boolean>(false);
  useEffect(() => {
    if (props.mainView && mapIsSelected !== false) {
      setMapIsSelected(true);
    }
  }, [props.mainView]);

  const handleChangeSwitch = () => {
    setMapIsSelected(!mapIsSelected);
  };

  const setActionsView = (actionsView: boolean) => {
    setIsActionsView(actionsView);
  };
  // Setup the context provider
  return (
    <ViewContext.Provider
      value={
        {
          mapIsSelected,
          handleChangeSwitch,
          mainView: props.mainView,
          isActionsView,
          setActionsView,
        }}
    >
      {props.children}
    </ViewContext.Provider>
  );
};

export default ViewProvider;
