/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC } from 'react';
import styles from './deleteButton.module.scss';
import {ReactComponent as TrashIcon} from '../../../../assets/trash.svg';

interface DeleteButtonProps {
  id?: string;
  onClick: () => any;
}

const DeleteButton: FC<DeleteButtonProps> = (props) => {
  const { id, onClick } = props;

  return (
    <div className={styles.delete}>
      <button className={styles.deleteBtn} id={id} onClick={onClick}>
        <TrashIcon />
      </button>
    </div>
  );
};

export default DeleteButton;
