import React, { FC, useEffect, useState } from 'react';
import CollapsableField from '../../../../components/collapsableField/collapsableField';
import { AdvancedFilter } from '../../../../context/FilterProvider';
import TitleHelp from '../../../fullDetail/components/titleHelp/titleHelp';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CollapsableFilterProps {
  id: string;
  value: string;
  help: string;
  open: boolean;
  handleClickOpen: (newVal: number) => void;
  children: JSX.Element;
  advancedFilter: AdvancedFilter;
}

export interface CollapsableFilterChildrenProps {
  open: boolean;
  handleClickOpen: (newVal: number) => void;
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const CollapsableFilter: FC<CollapsableFilterProps> = (props) => {
  const { id, value, help, open, handleClickOpen, children, advancedFilter } = props;
  const [isOpen, setIsOpen] = useState<boolean>(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <CollapsableField
      id={`${id}Collapsable`}
      open={isOpen}
      handleClickOpen={handleClickOpen}
      title={
        <TitleHelp
          id={`${id}-title`}
          value={value}
          help={help}
        />
      }
      advancedFilter={advancedFilter}
    >
      {children}
    </CollapsableField>
  );
};

export default CollapsableFilter;
