import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ObraProperties } from '../../../../types/ObraDetails';
import CollapsableField from '../../../../components/collapsableField/collapsableField';
import TitleHelp from '../../components/titleHelp/titleHelp';
import styles from './buildingProcess.module.scss';
import { UserModeContext } from '../../../../context/UserModeProvider';
import EditBuildingProcess from './edit/editBuildingProcess';
import ViewBuildingProcesses from './view/viewBuildingProcesses';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface buildingProcessProps {
  obra: ObraProperties;
  userMode: string;
  errors?: any[];
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const buildingProcess: FC<buildingProcessProps> = (props) => {
  const { obra, userMode, errors } = props;
  const [t] = useTranslation();
  const isEditorMode = userMode === 'editor';
  const emptyProcceses = isEditorMode && obra.aspectosTecnicos.procesosConstructivos.length < 1;
  const someProcessIsEmpty = obra.aspectosTecnicos.procesosConstructivos.filter((pc) => !pc.codigo).length > 0;

  return (
    <div className={styles.container}>
      <CollapsableField
        id='buildingProcessCollapsable'
        title={
          <TitleHelp
            id='buildingProcessCollapsable-title'
            value={t('details.technical.buildingProcess.titleCollapsable')}
            help={t('help.technicalAspects.buildingProcesses')}
            error={errors && errors.filter((err) => err.fieldField !== 'medicion').length > 0 && (someProcessIsEmpty || emptyProcceses) ? ' ' : null}
          />
        }>
        <>
          {isEditorMode ?
            <EditBuildingProcess obra={obra} errors={errors}/>
            :
            <ViewBuildingProcesses obra={obra} userMode={userMode} />
          }
        </>
      </CollapsableField>
    </div>
  );
};

export default buildingProcess;
