import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './bimFilters.module.scss';
import CollapsableFilter, { CollapsableFilterChildrenProps } from '../components/collapsableFilter/collapsableFilter';
import { CheckSelector } from '../../../components/Filters/Components/checkSelector/checkSelector';
import { SimpleSelector } from '../../../components/Filters/Components/simpleSelector/simpleSelector';
import { AutocompleteMultipleChoice } from '../../../components/Filters/Components/AutocompleteMultichoice/AutocompleteMultichoice';
import NumberRange from '../../../components/Filters/Components/numberRangeFilters/numberRange/numberRange';
import FILTER_TYPES, {FILTER_CONFIG} from '../../../components/Filters/filterTypes';

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const BimFilters: FC<CollapsableFilterChildrenProps> = (props) => {
  const { open, handleClickOpen } = props;
  const [t] = useTranslation();

  return (
    <CollapsableFilter
      id='bim'
      value={t('advancedFilters.bim')}
      help={t('advancedFilters.bim')}
      handleClickOpen={handleClickOpen}
      open={open}
      advancedFilter={'bim'}
    >
      <div className={styles.bimFiltersContainer}>
        <div className={styles.collapsableComponent}>
          <CheckSelector filterType={FILTER_TYPES.maturityLevel} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <NumberRange filterType={FILTER_TYPES.complexityNote} defaultUnit={FILTER_CONFIG.number} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <CheckSelector filterType={FILTER_TYPES.categorization} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <CheckSelector filterType={FILTER_TYPES.requirements} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <SimpleSelector filterType={FILTER_TYPES.cde} disableScroll={true} noBottomDivider={true} />
        </div>
        <div className={styles.collapsableComponent}>
          <CheckSelector filterType={FILTER_TYPES.typesBimApplications} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <NumberRange filterType={FILTER_TYPES.noteComplexityBimApplications} defaultUnit={FILTER_CONFIG.number} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <NumberRange filterType={FILTER_TYPES.complexityNoteUsageManagement} defaultUnit={FILTER_CONFIG.number} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <NumberRange filterType={FILTER_TYPES.complexityNoteStandardUses} defaultUnit={FILTER_CONFIG.number} noBottomDivider />
        </div>
      </div>
    </CollapsableFilter>
  );
};

export default BimFilters;
