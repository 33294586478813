import React, { FC } from 'react';
import styles from './editLocalizationEnvironment.module.scss';
import './editLocalizationEnvironment.scss';
import { ObraProperties } from '../../../../types/ObraDetails';
import EditAdditionalTowns from './editAdditionalTowns/editAdditionalTowns';
import EditSpeciesAndZones from './editSpeciesAndZones/editSpeciesAndZones';
import EditMainTown from './editMainTown/editMainTown';
import EditProtectedSpaces from './editProtectedSpaces/editProtectedSpaces';

interface EditLocalizationEnvironmentProps {
  obra: ObraProperties,
  errors?: any[];
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const EditLocalizationEnvironment: FC<EditLocalizationEnvironmentProps> = (props) => {
  const { obra, errors } = props;

  return (
    <div data-testid="locationEnvironment-edit-test">

      <EditMainTown obra={obra} errors={errors} />

      <div className={styles.section}>
        <EditAdditionalTowns obra={obra} data={obra.localizacion?.municipiosAdicionales || []} errors={errors?.filter(e => e.field === 'municipiosAdicionales')} />
      </div>
      <div className={styles.section}>
        <EditProtectedSpaces obra={obra} errors={errors?.filter(e => e.field === 'espacioProteccion')} />
      </div>
      <div className={styles.section}>
        <EditSpeciesAndZones obra={obra} errors={errors?.filter(e => e.field === 'especiesPeligro' || e.field === 'zonasProteccion')} />
      </div>
    </div >
  );
};

export default EditLocalizationEnvironment;
