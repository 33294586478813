/**
* Autocomplete Filter Types
*/
export const MASTERS_PATHS = {
  nameCode: 'centros',
  chapter: 'capitulos',
  country: 'paises',
  zone: 'zonas',
  allZones: 'total_zonas',
  supercenter: 'supercentros',
  workType: 'tipos_obra',
  workSubtype: 'subtipos',
  workSubtypeAutocomplete: 'subtipos_obra',
  workUnitAutocomplete: 'unidades_obra',
  workUnit: 'unidades_obra',
  contractStatus: 'estados_contrato',
  contractTypes: 'tipos_contrato',
  contractModels: 'modelos_contrato',
  contractScope: 'alcance_contrato',
  contractFormPayment: 'forma_pago',
  contractSourceFinance: 'fuente_financiacion',
  contractWarrantyPeriod: 'warranty_period',
  businessActivities: 'actividades_negocio',
  photoTypes: 'tipos_foto',
  protectedSpace: 'areas_protegidas',
  dangerLevels: 'niveles_peligro',
  process: 'procesos_constructivos',
  processGroup: 'grupos_procesos_constructivos',
  protectionZones: 'zonas_proteccion',
  protectedSpaceLocations: 'ubicaciones',
  magnitudes: 'magnitudes',
  clientStatus: 'client_status',
  clientDesignerStatus: 'client_status',
  classificationGroups: 'grupos_clasificacion',
  classificationSubgroups: 'subgrupos_clasificacion',
  certificates: 'certificados',
  certificatesLevel: 'niveles_certificados',
  localCurrency: 'moneda_local',
  buildingProcess: 'procesos_constructivos',
  namePartner: 'nombre_socios',
  clientName: 'clientes',
  typesBimApplications: 'bim_usos',
  maturityLevel: 'nivel_madurez',
  categorization: 'categorizacion',
  requirements: 'bim_requirements',
  taxonomicObjective: 'objetivos_taxonomicos',
  taxonomicActivity: 'codigos_actividades_taxonomicas',
  socialCategories: 'categorizaciones_sociales',
  gisPercentage: 'porcentajes_gis',
};

export const FILTERS_TYPES = {
  nameCode: 'cod_centro',
  country: 'cod_pais',
  supercenter: 'cod_supercentro',
  workTypeKeyword: 'texto_tipo_obra',
  workTypeGroupType: 'cod_subtipo_obra',
  projectStatus: 'cod_estado_contrato',
  businessActivities: 'cod_actividad_negocio',
  fromAdjudicationDate: 'fec_adjudicacion_desde',
  toAdjudicationDate: 'fec_adjudicacion_hasta',
  fromAdjudicationAmount: 'importe_act_desde',
  toAdjudicationAmount: 'importe_act_hasta',
  fromaccionaBudget: 'presupuesto_acciona_desde',
  toaccionaBudget: 'presupuesto_acciona_hasta',
  fromupdatedAccionaBudget: 'presupuesto_act_acciona_desde',
  toupdatedAccionaBudget: 'presupuesto_act_acciona_hasta',
  fromfinalAccionaBudget: 'presupuesto_fin_acciona_desde',
  tofinalAccionaBudget: 'presupuesto_fin_acciona_hasta',
  fromupdatedBudget: 'presupuesto_act_desde',
  toupdatedBudget: 'presupuesto_act_hasta',
  fromfinalBudget: 'presupuesto_fin_desde',
  tofinalBudget: 'presupuesto_fin_hasta',
  fromupdatedBudgetDate: 'fec_act_desde',
  toupdatedBudgetDate: 'fec_act_hasta',
  fromfinalBudgetDate: 'fec_fin_presupuesto_desde',
  tofinalBudgetDate: 'fec_fin_presupuesto_hasta',
  localCurrency: 'moneda_local',
  projectName: 'nom_oficial',
  descGeneral: 'desc_general',
  descDetallada: 'desc_detallada',
  infoDestacable: 'info_destacable',
  manager: 'nom_responsable',
  tenderCode: 'cod_licitacion',
  businessActivity: 'actividad_negocio',
  zone: 'cod_zona',
  fromAccionaParticipation: 'participacion_acciona_desde',
  toAccionaParticipation: 'participacion_acciona_hasta',
  spaceType: 'tipo_espacio',
  spaceLocation: 'ubicacion_espacio',
  extinctionName: 'especie_peligro_ext',
  culturalName: 'nombre_prot_cultural',
  extinctionLevel: 'nivel_peligro_ext',
  culturalLevel: 'nivel_prot_cultural',
  fromProtectedArea: 'superficie_protegida_desde',
  toProtectedArea: 'superficie_protegida_hasta',
  fromProtectedProjectArea: 'superficie_proyecto_desde',
  toProtectedProjectArea: 'superficie_proyecto_hasta',
  town: 'municipio',
  zipCode: 'codigo_postal',
  spaceName: 'nombre_espacio',
  Measurement: 'medida',
  buildingProcess: 'proceso_constructivo',
  Magnitude: 'magnitud',
  EstimatedAmount: 'clasificacion_importe_estimado',
  FinalAmount: 'clasificacion_importe_final',
  typeContract: 'tipo_contrato',
  modelContract: 'modelo_contrato',
  scopeContract: 'alcance_contrato',
  sourceFinancing: 'fuente_financiacion',
  formPayment: 'forma_pago',
  fromWarrantyPeriod: 'periodo_garantia_desde',
  toWarrantyPeriod: 'periodo_garantia_hasta',
  namePartner: 'nombre_socio',
  fromNumberPartner: 'numero_socios_desde',
  toNumberPartner: 'numero_socios_hasta',
  fromPercentPartnerParticipation: 'porcentaje_participacion_socio_desde',
  toPercentPartnerParticipation: 'porcentaje_participacion_socio_hasta',
  clientName: 'nombre_cliente',
  clientDesignerScope: 'alcance_disenador_cliente',
  scopeTechnicalAssistance: 'alcance_asistencias_tecnicas',
  technicalAssistanceEntity: 'entidad_asistencias_tecnicas',
  entityDesignerWorkManagement: 'entidad_disenador_direccion_obra',
  clientDesignerEntity: 'entidad_disenador_cliente',
  scopeWorkManagementDesigner: 'alcance_disenador_direccion_obra',
  technicalAssistanceStatus: 'estado_asistencias_tecnicas',
  workManagementStatus: 'estado_direccion_obra',
  clientDesignerStatus: 'estado_disenador_cliente',
  fromClientCollaborationEndDate: 'fecha_fin_colaboracion_cliente_desde',
  toClientCollaborationEndDate: 'fecha_fin_colaboracion_cliente_hasta',
  fromEndDateCollaborationTechnicalAssistance: 'fecha_fin_colaboracion_asistencias_tecnicas_desde',
  toEndDateCollaborationTechnicalAssistance: 'fecha_fin_colaboracion_asistencias_tecnicas_hasta',
  fromStartDateWorkManagement: 'fecha_inicio_direccion_obra_desde',
  toStartDateWorkManagement: 'fecha_inicio_direccion_obra_hasta',
  fromEndDateCollaborationSiteManagement: 'fecha_fin_colaboracion_direccion_obra_desde',
  toEndDateCollaborationSiteManagement: 'fecha_fin_colaboracion_direccion_obra_hasta',
  fromStartDateTechnicalAssistance: 'fecha_inicio_asistencias_tecnicas_desde',
  toStartDateTechnicalAssistance: 'fecha_inicio_asistencias_tecnicas_hasta',
  fromSignatureContract: 'fecha_firma_contrato_desde',
  toSignatureContract: 'fecha_firma_contrato_hasta',
  fromStakeoutReport: 'fecha_acta_replanteo_desde',
  toStakeoutReport: 'fecha_acta_replanteo_hasta',
  fromBeginningWork: 'fecha_inicio_obra_desde',
  toBeginningWork: 'fecha_inicio_obra_hasta',
  fromInitialDeadline: 'plazo_inicial_desde',
  toInitialDeadline: 'plazo_inicial_hasta',
  fromUpdatedDeadline: 'plazo_actualizado_desde',
  toUpdatedDeadline: 'plazo_actualizado_hasta',
  fromPlannedEnd: 'fecha_fin_prevista_desde',
  toPlannedEnd: 'fecha_fin_prevista_hasta',
  fromPlannedEndUpdatedApproved: 'fecha_fin_prevista_actualizada_aprobada_desde',
  toPlannedEndUpdatedApproved: 'fecha_fin_prevista_actualizada_aprobada_hasta',
  fromReceptionReport: 'fecha_acta_recepcion_desde',
  toReceptionReport: 'fecha_acta_recepcion_hasta',
  fromEndExecution: 'fecha_fin_ejecucion_desde',
  toEndExecution: 'fecha_fin_ejecucion_hasta',
  typesBimApplications: 'tipos_usos_bim',
  maturityLevel: 'nivel_madurez',
  categorization: 'categorizacion',
  requirements: 'requisitos',
  cde: 'cde',
  fromComplexityNote: 'nota_complejidad_desde',
  toComplexityNote: 'nota_complejidad_hasta',
  fromNoteComplexityBimApplications: 'nota_usos_bim_desde',
  toNoteComplexityBimApplications: 'nota_usos_bim_hasta',
  fromComplexityNoteUsageManagement: 'nota_gestion_usos_desde',
  toComplexityNoteUsageManagement: 'nota_gestion_usos_hasta',
  fromComplexityNoteStandardUses: 'nota_usos_estandar_desde',
  toComplexityNoteStandardUses: 'nota_usos_estandar_hasta',
  taxonomicCommitment: 'compromiso_taxonomico',
  controversies: 'controversia',
  naceCode: 'codigo_nace',
  fromGisBudget: 'presupuesto_gis_desde',
  toGisBudget: 'presupuesto_gis_hasta',
  fromCarbonBudget: 'presupuesto_carbono_desde',
  toCarbonBudget: 'presupuesto_carbono_hasta',
  taxonomicActivity: 'actividad_taxonomica',
  socialSafeguards: 'salvaguarda_social',
  taxonomicObjective: 'objetivo_taxonomico',
  socialCategory: 'categoria_social',
  gisPercentage: 'porcentaje_gis',
  eligibility: 'elegibilidad',
  customerDap: 'dap_cliente',
  accionaDap: 'dap_acciona',
  gisExemption: 'exencion_gis',
  gis: 'gis',
};

function generateSchema(props: any) {
  return {
    id: props.id || 'id',
    code: props.code || 'codigo',
    name: props.name || 'nombre',
    ...props.extraProps
  };
}

export const MASTERS_RESPONSE_SCHEMA = {
  nameCode: generateSchema({ name: 'codigoNombre' }),
  country: generateSchema({}),
  zone: generateSchema({ code: 'codZona', name: 'nomZona', extraProps: { codname: 'codNomZona' } }),
  supercenter: generateSchema({ code: 'codSupercentro', name: 'nomSupercentro', extraProps: { codname: 'codNomSupercentro' } }),
  workType: generateSchema({}),
  workSubtype: generateSchema({ extraProps: { elements: 'elementos' }}),
  classificationGroup: generateSchema({ extraProps: { elements: 'elementos' }}),
  classificationSubgroup: generateSchema({ extraProps: { groupCode: 'codGrupo' }}),
  workUnit: generateSchema({}),
  contractStatus: generateSchema({}),
  clientDesignerStatus: generateSchema({}),
  contractTypes: generateSchema({}),
  contractModels: generateSchema({}),
  contractScope: generateSchema({}),
  contractFormPayment: generateSchema({}),
  contractSourceFinance: generateSchema({}),
  businessActivities: generateSchema({}),
  photoType: generateSchema({ id: 'codigo', name: 'nombre' }),
  protectedSpace: generateSchema({}),
  dangerLevels: generateSchema({}),
  protectionZones: generateSchema({}),
  clientStatus: generateSchema({}),
  localCurrency: generateSchema({}),
  namePartner: generateSchema({ code: 'nombre' }),
  clientName: generateSchema({}),
  buildingProcess: generateSchema({}),
  maturityLevel: generateSchema({}),
  categorization: generateSchema({}),
  typesBimApplications: generateSchema({}),
  requirements: generateSchema({ code: 'id', extraProps: { codigos: 'codigos' }}),
  taxonomicObjective: generateSchema({}),
  taxonomicActivity: generateSchema({}),
  socialCategories: generateSchema({}),
  gisPercentage: generateSchema({}),
};

type FiltersCombined = {
  [key: string]: any;
}

export const FILTERS_COMBINED: FiltersCombined = {
  AdjudicationDate: [{ key: 'fromAdjudicationDate' }, { key: 'toAdjudicationDate' }],
  updatedBudgetDate:  [{ key: 'fromupdatedBudgetDate' }, { key: 'toupdatedBudgetDate' }],
  finalBudgetDate:  [{ key: 'fromfinalBudgetDate' }, { key: 'tofinalBudgetDate' }],
  AdjudicationAmount: [{ key: 'fromAdjudicationAmount' }, { key: 'toAdjudicationAmount' }, {key: 'exactAdjudicationAmount'}],
  updatedBudget: [{ key: 'fromupdatedBudget'},  {key: 'toupdatedBudget'},  {key: 'exactupdatedBudget'}],
  finalBudget: [{ key: 'fromfinalBudget'},  {key: 'tofinalBudget'},  {key: 'exactfinalBudget'}],
  accionaBudget: [{ key: 'fromaccionaBudget'},  {key: 'toaccionaBudget'},  {key: 'exactaccionaBudget'}],
  WarrantyPeriod: [{ key: 'fromWarrantyPeriod'},  {key: 'toWarrantyPeriod'},  {key: 'exactWarrantyPeriod'}],
  NumberPartner: [{ key: 'fromNumberPartner'},  {key: 'toNumberPartner'},  {key: 'exactNumberPartner'}],
  PercentPartnerParticipation: [{ key: 'fromPercentPartnerParticipation'},  {key: 'toPercentPartnerParticipation'},  {key: 'exactPercentPartnerParticipation'}],
  updatedAccionaBudget: [{ key: 'fromupdatedAccionaBudget'},  {key: 'toupdatedAccionaBudget'},  {key: 'exactupdatedAccionaBudget'}],
  finalAccionaBudget: [{ key: 'fromfinalAccionaBudget'},  {key: 'tofinalAccionaBudget'},  {key: 'exactfinalAccionaBudget'}],
  AccionaParticipation: [{ key: 'fromAccionaParticipation' }, { key: 'toAccionaParticipation' }, {key: 'exactAccionaParticipation'}],
  ProtectedArea: [{ key: 'fromProtectedArea' }, { key: 'toProtectedArea' }, {key: 'exactProtectedArea'}],
  ProtectedProjectArea: [{ key: 'fromProtectedProjectArea' }, { key: 'toProtectedProjectArea' }, {key: 'exactProtectedProjectArea'}],
  ClientCollaborationEndDate: [{ key: 'fromClientCollaborationEndDate' }, { key: 'toClientCollaborationEndDate' }],
  EndDateCollaborationTechnicalAssistance: [{ key: 'fromEndDateCollaborationTechnicalAssistance' }, { key: 'toEndDateCollaborationTechnicalAssistance' }],
  StartDateWorkManagement: [{ key: 'fromStartDateWorkManagement' }, { key: 'toStartDateWorkManagement' }],
  EndDateCollaborationSiteManagement: [{ key: 'fromEndDateCollaborationSiteManagement' }, { key: 'toEndDateCollaborationSiteManagement' }],
  StartDateTechnicalAssistance: [{ key: 'fromStartDateTechnicalAssistance' }, { key: 'toStartDateTechnicalAssistance' }],
  SignatureContract: [{ key: 'fromSignatureContract' }, { key: 'toSignatureContract' }],
  StakeoutReport: [{ key: 'fromStakeoutReport' }, { key: 'toStakeoutReport' }],
  BeginningWork: [{ key: 'fromBeginningWork' }, { key: 'toBeginningWork' }],
  InitialDeadline: [{ key: 'fromInitialDeadline' }, { key: 'toInitialDeadline' }, {key: 'exactInitialDeadline'}],
  UpdatedDeadline: [{ key: 'fromUpdatedDeadline' }, { key: 'toUpdatedDeadline' }, {key: 'exactUpdatedDeadline'}],
  PlannedEnd: [{ key: 'fromPlannedEnd' }, { key: 'toPlannedEnd' }],
  PlannedEndUpdatedApproved: [{ key: 'fromPlannedEndUpdatedApproved' }, { key: 'toPlannedEndUpdatedApproved' }],
  ReceptionReport: [{ key: 'fromReceptionReport' }, { key: 'toReceptionReport' }],
  EndExecution: [{ key: 'fromEndExecution' }, { key: 'toEndExecution' }],
  ComplexityNote: [{ key: 'fromComplexityNote'},  {key: 'toComplexityNote'},  {key: 'exactComplexityNote'}],
  NoteComplexityBimApplications: [{ key: 'fromNoteComplexityBimApplications'},  {key: 'toNoteComplexityBimApplications'},  {key: 'exactNoteComplexityBimApplications'}],
  ComplexityNoteUsageManagement: [{ key: 'fromComplexityNoteUsageManagement'},  {key: 'toComplexityNoteUsageManagement'},  {key: 'exactComplexityNoteUsageManagement'}],
  ComplexityNoteStandardUses: [{ key: 'fromComplexityNoteStandardUses'},  {key: 'toComplexityNoteStandardUses'},  {key: 'exactComplexityNoteStandardUses'}],
  GisBudget: [{ key: 'fromGisBudget'},  {key: 'toGisBudget'},  {key: 'exactGisBudget'}],
  CarbonBudget: [{ key: 'fromCarbonBudget'},  {key: 'toCarbonBudget'},  {key: 'exactCarbonBudget'}],
};

export const FILTERS_UNIQUE_VALUE = [
  'fromAdjudicationDate',
  'toAdjudicationDate',
  'fromupdatedBudgetDate',
  'toupdatedBudgetDate',
  'fromfinalBudgetDate',
  'tofinalBudgetDate',
  'fromAdjudicationAmount',
  'toAdjudicationAmount',
  'exactAdjudicationAmount',
  'fromupdatedBudget',
  'toupdatedBudget',
  'exactupdatedBudget',
  'fromfinalBudget',
  'tofinalBudget',
  'exactfinalBudget',
  'fromaccionaBudget',
  'toaccionaBudget',
  'exactaccionaBudget',
  'fromupdatedAccionaBudget',
  'toupdatedAccionaBudget',
  'exactupdatedAccionaBudget',
  'fromfinalAccionaBudget',
  'tofinalAccionaBudget',
  'exactfinalAccionaBudget',
  'fromAdjudicationAmount',
  'toAdjudicationAmount',
  'exactAdjudicationAmount',
  'fromAccionaParticipation',
  'toAccionaParticipation',
  'exactAccionaParticipation',
  'fromProtectedArea',
  'toProtectedArea',
  'exactProtectedArea',
  'fromProtectedProjectArea',
  'toProtectedProjectArea',
  'exactProtectedProjectArea',
  'fromWarrantyPeriod',
  'toWarrantyPeriod',
  'exactWarrantyPeriod',
  'fromNumberPartner',
  'toNumberPartner',
  'exactNumberPartner',
  'fromPercentPartnerParticipation',
  'toPercentPartnerParticipation',
  'exactPercentPartnerParticipation',
  'fromClientCollaborationEndDate',
  'toClientCollaborationEndDate',
  'fromEndDateCollaborationTechnicalAssistance',
  'toEndDateCollaborationTechnicalAssistance',
  'fromStartDateWorkManagement',
  'toStartDateWorkManagement',
  'fromEndDateCollaborationSiteManagement',
  'toEndDateCollaborationSiteManagement',
  'fromStartDateTechnicalAssistance',
  'toStartDateTechnicalAssistance',
  'fromSignatureContract',
  'toSignatureContract',
  'fromStakeoutReport',
  'toStakeoutReport',
  'fromBeginningWork',
  'toBeginningWork',
  'fromInitialDeadline',
  'toInitialDeadline',
  'exactInitialDeadline',
  'fromUpdatedDeadline',
  'toUpdatedDeadline',
  'exactUpdatedDeadline',
  'fromPlannedEnd',
  'toPlannedEnd',
  'fromPlannedEndUpdatedApproved',
  'toPlannedEndUpdatedApproved',
  'fromReceptionReport',
  'toReceptionReport',
  'fromEndExecution',
  'toEndExecution',
  'fromComplexityNote',
  'toComplexityNote',
  'exactComplexityNote',
  'fromNoteComplexityBimApplications',
  'toNoteComplexityBimApplications',
  'exactNoteComplexityBimApplications',
  'fromComplexityNoteUsageManagement',
  'toComplexityNoteUsageManagement',
  'exactComplexityNoteUsageManagement',
  'fromComplexityNoteStandardUses',
  'toComplexityNoteStandardUses',
  'fromGisBudget',
  'toGisBudget',
  'exactGisBudget',
  'fromCarbonBudget',
  'toCarbonBudget',
  'exactCarbonBudget',
  'exactComplexityNoteStandardUses',
];

export const ACCUMULATIVE_NUMBER_FILTERS = [
  'Measurement',
  'Magnitude',
  'taxonomicActivity',
  'EstimatedAmount',
  'FinalAmount',
];

export const ACCUMULATIVE_FILTERS = {
  measurement: 'Measurement',
  magnitude: 'Magnitude',
  estimatedAmount: 'EstimatedAmount',
  finalAmount: 'FinalAmount',
};