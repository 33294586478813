/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useContext, useEffect, useState, useRef } from 'react';
import { ObraDescripcion, TipoDocumento } from '../../../../../types/ObraDetails';
import { useTranslation } from 'react-i18next';

import { SaveContext } from '../../../../../context/SaveProvider';
import TitleHelp from '../../../components/titleHelp/titleHelp';
import technicalNoData from '../../../../../assets/technicalNoData.svg';
import NoDataPicture from '../../../components/noDataPicture/noDataPicture';
import styles from './edit.module.scss';
import SimpleSelect from '../../../components/simpleSelect/simpleSelect';
import TitleValue from '../../../components/titleValue/titleValue';
import DeleteButton from '../../../components/deleteButton/deleteButton';

interface EditDocumentationProps {
  data: ObraDescripcion;
  error?: string | null
}

const EditDocumentation: FC<EditDocumentationProps> = (props) => {
  const { data, error } = props;
  const [t] = useTranslation();
  const saveContext = useContext(SaveContext);
  const documentsContainerRef = useRef<HTMLDivElement>(null);
  const [scrollToBottom, setScrollToBottom] = useState(false);

  /**
  * Renders the values in multi-language text depends of the ID
  * @param type 
  * @returns 
  */
  const renderSelectText = (type: number): string => {
    switch (type) {
      case TipoDocumento.WEB:
        return t('details.documentation.web');
      case TipoDocumento.EJECUCION:
        return t('details.documentation.docEjecucion');
      case TipoDocumento.LICITACIÓN:
        return t('details.documentation.docLicitacion');
      case TipoDocumento.EMPTY:
        return '';
      default:
        return '';
    }
  };

  const tipos = Object.values(TipoDocumento)
    .map((tipo: any) => ({ id: tipo, nombre: renderSelectText(tipo) }));

  // load the documents already stored in the project
  const loadInitialDocuments = () => {
    const initialDocuments = [];
    // if licitacion exists, added first
    if (data.documentacionFaseLicitacion) {
      initialDocuments.push({ type: tipos.find((t) => t.id === TipoDocumento.LICITACIÓN), url: data.documentacionFaseLicitacion.trim() });
    }
    // if execution exists
    if (data.documentacionProyecto) {
      initialDocuments.push({ type: tipos.find((t) => t.id === TipoDocumento.EJECUCION), url: data.documentacionProyecto.trim() });
    }
    // if webs exist, added laters
    if (data.webs && data.webs.length > 0) {
      data.webs.forEach((web: string) => {
        initialDocuments.push({ type: tipos.find((t) => t.id === TipoDocumento.WEB), url: web });
      });
    }
    return initialDocuments;
  };

  const [documents, setDocuments] = useState(loadInitialDocuments());

  useEffect(() => {
    if (scrollToBottom && documentsContainerRef.current) {
      const lastChild = documentsContainerRef.current.lastElementChild;
      if (lastChild) {
        lastChild.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
        // Disable scrolling to the bottom of the page after performing it
        setScrollToBottom(false);
      }
    }
  }, [scrollToBottom, documents]);

  const parseDocumentsToSave = () => {
    data.webs = [];
    data.documentacionProyecto = '';
    documents.forEach((doc) => {
      // if licitacion exists, added first
      if (doc?.type?.id === TipoDocumento.LICITACIÓN) {
        data.documentacionFaseLicitacion = doc.url;
      }
      // if execution exists
      if (doc?.type?.id === TipoDocumento.EJECUCION) {
        data.documentacionProyecto = doc.url;
      }

      // if webs exist, added laters
      if (doc?.type?.id === TipoDocumento.WEB) {
        data.webs = data.webs.concat(doc.url);
      }
    });
  };

  const handleAddDocument = () => {
    const newDocument = { type: tipos.find((t) => t.id === TipoDocumento.EMPTY) || { id: 3, nombre: '' }, url: '' };
    setDocuments([...documents, newDocument]);
    parseDocumentsToSave();
    saveContext.handleChangeSave(true);
    setScrollToBottom(true);
  };

  const handleDelete = (i: number) => {
    documents.splice(i, 1);
    setDocuments([...documents]);
    parseDocumentsToSave();
    saveContext.handleChangeSave(true);
  };

  const handleChange = (data: any, i: number, field: string) => {
    documents[i] = { ...documents[i], [field]: data };
    parseDocumentsToSave();
    const newDocuments: any[] = [];
    documents.map((d) => newDocuments.push(d));
    setDocuments(newDocuments);
    if (error && documents[i].type?.id === TipoDocumento.EJECUCION) {
      saveContext.forceUpdate();
    }
    saveContext.handleChangeSave(true);
  };

  /**
  * Return if the execution type is not added to the documents
  * @param type 
  * @returns 
  */
  const executionTypeIsNotAdded = () => {
    return documents.every((doc: any) => doc.type?.id !== TipoDocumento.EJECUCION);
  };

  const optionsTypes = tipos.filter((type) => type.id === TipoDocumento.WEB || (type.id === TipoDocumento.EJECUCION && executionTypeIsNotAdded()));

  return (
    <div
      data-testid="documentation-edit-test"
      className={styles.documentsContainer}
      ref={documentsContainerRef}
    >
      <TitleHelp
        id='documentation'
        value={t('details.documentation.title')}
        help={t('help.description.documentationAndWeb')}
        addItemText={t('details.documentation.add') as string}
        error={error}
        handleAddItem={handleAddDocument}
      />
      {
        documents.length > 0 ?
          <div >
            {documents
              .sort((a, b) => a.type?.id - b.type?.id)
              .map((e, i) => {
                const editable = e.type?.id !== TipoDocumento.LICITACIÓN;
                return <div
                  key={`${e.type}-${i}`}
                  className={`${styles.documentRow} ${editable ? styles.editableDocument : styles.noEditableDocument}`}>
                  <div id={styles.ps1}>
                    <SimpleSelect
                      id={`document-type-${i}`}
                      options={optionsTypes}
                      value={e?.type}
                      label={t('details.documentation.type')}
                      handleChangeValue={(data: any) => handleChange(data, i, 'type')}
                      editable={editable} />
                  </div>
                  <div id={styles.ps2}>
                    <TitleValue
                      identifier='document-url'
                      title={t('details.documentation.link')}
                      value={e.url}
                      editable={editable}
                      handleChange={(data: string) => handleChange(data, i, 'url')}
                      valueMaxLength={255}
                    />
                  </div>
                  {editable && <div className={styles.delete}>
                    <DeleteButton id={`deleteBtn${i}`} onClick={() => handleDelete && handleDelete(i)} />
                  </div>
                  }
                </div>;
              })
            }
          </div>
          :
          <NoDataPicture
            id='document-nodata'
            picture={technicalNoData}
            text={t('details.clients.addDesigner')}
          />
      }

    </div >);
};

export default EditDocumentation;
