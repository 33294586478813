import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import styles from './impact.module.scss';
import { ObraProperties } from '../../../../types/ObraDetails';
import TitleHelp from '../../components/titleHelp/titleHelp';
import InfoBoxBoolean from '../../components/InfoBoxBoolean/InfoBoxBoolean';
import InfoBox from '../../components/infoBox/infoBox';
import { UserModeContext } from '../../../../context/UserModeProvider';

interface ImpactProps {
  obra: ObraProperties,
}

const Impact: FC<ImpactProps> = (props) => {
  const { obra } = props;
  const { t } = useTranslation();

  const userModeContext = useContext(UserModeContext);

  const exempt = obra.esg.exentoGIS ? t('details.esg.impact.yes') : t('details.esg.impact.no');

  const getFormattedBudget = (value?: any) => {
    if (value) {
      let budget;
      const parts = value.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      if (parts[1] && parts[1].length > 2) {
        parts[1] = parts[1].slice(0, 2);
      }
      if (parts.length == 2) {
        budget = parts[0] + ',' + parts[1];
      }
      else {
        budget = parts[0];
      }
      return budget;
    }
    return null;
  };

  return (
    <div data-testid='esg-test' id='esg-nodata' className={styles.container}>
      <TitleHelp
        id='impact'
        value={t('details.esg.impact.helper')}
        help={t('help.esg.socialImpactManagement')}
      />
      <Grid
        container
        spacing={2}
        direction="column"
        mb='54px'
        mt='0'
      >
        <Grid container item columnSpacing={3} rowSpacing={2} xs>
          {
            exempt === t('details.esg.impact.no') && <Grid item xs={4} display="flex" flexDirection="column">
              <InfoBoxBoolean
                identifier='impact'
                item={{ title: t('details.esg.impact.management'), value: obra.esg.gis ? t('details.esg.impact.yes') : t('details.esg.impact.no') }}
                value={obra.esg.gis || false}
                positionIcon={'left'}
                styles={
                  { box: styles.boxBoolean, value: styles.valueBoolean}
                }
              />
            </Grid>
          }
          <Grid item xs={4} display="flex" flexDirection="column">
            <InfoBoxBoolean
              identifier={exempt === t('details.esg.impact.no') ? 'exemptNo' : 'exempt'}
              item={{ title: t('details.esg.impact.exempt'), value: exempt }}
              value={!(exempt === t('details.esg.impact.no'))}
              positionIcon={'left'}
              styles={
                { box: styles.boxBoolean, value: styles.valueBoolean}
              }
            />
          </Grid>
        </Grid>

        {
          exempt === t('details.esg.impact.no') &&
            <>
              <Grid container item columnSpacing={3} rowSpacing={2} xs>
                <Grid item xs={4}>
                  <InfoBox
                    identifier='impact-category'
                    item={{ title: t('details.esg.impact.socialCategory'), value: obra.esg.categorizacionSocial ? t('details.esg.impact.category.' + obra.esg.categorizacionSocial) : t('details.notInformed') }} 
                    customStyle={{ box: styles.box, title: styles.title }}
                    overwriteStyle={{ marginBottom: 'auto', height: 'auto', flex: 1 }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InfoBox
                    identifier='impact-budget'
                    item={{ title: t('details.esg.impact.budget'), value: obra.esg.presupuestoGIS ? getFormattedBudget(obra.esg.presupuestoGIS).toString() + '€' : t('details.notInformed') }} 
                    customStyle={{ box: styles.box, title: styles.title }}
                    overwriteStyle={{ marginBottom: 'auto', height: 'auto', flex: 1 }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InfoBox
                    identifier='impact-percent'
                    item={{ title: t('details.esg.impact.percent'), value: obra.esg.porcentajeGIS ? getFormattedBudget(obra.esg.porcentajeGIS).toString() + '%' : t('details.notInformed') }} 
                    customStyle={{ box: styles.box, title: styles.title }}
                    overwriteStyle={{ marginBottom: 'auto', height: 'auto', flex: 1 }}
                  />
                </Grid>
              </Grid>
            </>
        }
      </Grid>
    </div>
  );
};


export default Impact;
