import React, { FC, useState } from 'react';
import { Button } from '@mui/material';
import { ReactComponent as KmzGlobe } from '../../../../../assets/kmzGlobe.svg';
import { ObraProperties } from '../../../../../types/ObraDetails';
import ObrasAPI from '../../../../../services/APIObras';

import styles from './kmzButton.module.scss';
import DialogError from '../../../../../components/dialogError/DialogError';

interface KMZButtonProps {
  obra: ObraProperties;
  t: any;
  handleChange: (kmz: string) => void;
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const KMZButton: FC<KMZButtonProps> = (props) => {
  const { obra, t, handleChange } = props;

  const [kmz, setKmz] = useState(obra?.localizacion?.kmz);
  const [errorKmz, setErrorKmz] = useState('');

  const handleUploadKMZ = async (file: File) => {
    const api = ObrasAPI.getInstance();
    const response = await api.uploadFile(file, obra.general.codCentro, 'kmz');
    if (response.statusCode === 200) {
      setKmz(response.url);
      handleChange(response.url);
    }
    else if (response.statusCode === 415) {
      setErrorKmz(t('details.location.invalidKMZ') as string);
    } else {
      setErrorKmz(t('details.location.kmzUploadError') as string);
    }
  };

  const onInputClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const element = event.target as HTMLInputElement;
    element.value = '';
  };

  return (
    <div>
      <div className={styles.updateKMZ}>
        <Button
          id='upload-btn'
          variant="contained"
          component="label"
          disableRipple
          sx={{
            color: '#111111',
            backgroundColor: '#FFFFFF',
            textTransform: 'none',
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: '400',
            borderRadius: '10px',
            '&:hover': {
              backgroundColor: '#FFFFFF',
            },
          }}
        >
          <KmzGlobe className={styles.kmzGlobe} />{kmz ? t('details.location.updateKMZ') : t('details.location.addKMZ')}
          <input type="file" accept=".kmz" onClick={onInputClick} onChange={(e) => handleUploadKMZ(e.target.files![0])} hidden />
        </Button>
      </div>
      <div>
        {
          (errorKmz.length > 0) ?
            <DialogError error={errorKmz} />

            : null
        }
      </div>
    </div>
  );
};

export default KMZButton;
