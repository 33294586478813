import React, { FC, useContext, useEffect } from 'react';
import { EspacioProteccion, ObraProperties } from '../../../../../types/ObraDetails';
import { SelectOption } from '../../../../../types/Option';
import { useTranslation } from 'react-i18next';
import { SaveContext } from '../../../../../context/SaveProvider';
import styles from './protectedSpace.module.scss';
import ObrasAPI from '../../../../../services/APIObras';
import ProtectedSpaceRow from './protectedSpaceRow/protectedSpaceRow';

interface ProtectedSpaceProps {
  obra: ObraProperties,
  editable: boolean,
  handleDelete?: (i: number) => void,
  errors?: any[];
}
const ProtectedSpace: FC<ProtectedSpaceProps> = (props) => {
  const { obra, editable, handleDelete, errors } = props;
  const [t] = useTranslation();
  const saveContext = useContext(SaveContext);
  const [protectedSpacesRows, setProtectedSpacesRows] = React.useState<EspacioProteccion[]>(obra.localizacion.espacioProteccion || []);
  const [protectedSpaces, setProtectedSpaces] = React.useState<SelectOption[]>([]);
  const [protectedSpacesLocations, setProtectedSpacesLocations] = React.useState<SelectOption[]>([]);
  const NO_RESULTS = [{ nombre: `${t('input.noResults')}`, id: 0 }];
  const getProtectedSpaces = () => {
    const api = ObrasAPI.getInstance();
    api.getProtectedSpaces().then(
      (results: SelectOption[]) => {
        results = results?.length ? results : NO_RESULTS;
        setProtectedSpaces(results);
      }
    ).catch((error: any) => {
      console.log(error);
      setProtectedSpaces([]);
    });
    api.getProtectedSpacesLocations().then(
      (results: SelectOption[]) => {
        results = results?.length ? results : NO_RESULTS;
        setProtectedSpacesLocations(results);
      }
    ).catch((error: any) => {
      console.log(error);
      setProtectedSpacesLocations([]);
    });

  };
  useEffect(() => getProtectedSpaces(), []);

  useEffect(() => {
    const newSpaces: EspacioProteccion[] = [];
    obra.localizacion.espacioProteccion.map((mun) => newSpaces.push(mun));
    setProtectedSpacesRows(newSpaces);
  }, [obra.localizacion.espacioProteccion.length]);

  return <div className={styles.protectedSpaceContainer}>
    {protectedSpacesRows.map((e, i) => {
      const handleChange = (data: any, field: string) => {
        obra.localizacion.espacioProteccion[i] = { ...obra.localizacion.espacioProteccion[i], [field]: data };
        saveContext.handleChangeSave(true);
      };
    
      const handleChangeArea = (data: any, field: string) => {
        const formattedArea = parseFloat(data.replace(/\./g, '').replace(',', '.'));
        obra.localizacion.espacioProteccion[i] = { ...obra.localizacion.espacioProteccion[i], [field]: formattedArea };
        saveContext.handleChangeSave(true);
      };

      return <ProtectedSpaceRow
        key={i}
        space={e}
        editable={editable}
        protectedSpaces={protectedSpaces}
        errors={errors}
        protectedSpacesLocations={protectedSpacesLocations}
        handleChangeArea={handleChangeArea}
        handleChange={handleChange}
        handleDelete={() => { if (handleDelete) handleDelete(i); }}
      />;
    })}
  </div>;
};

export default ProtectedSpace;