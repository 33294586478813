/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react';
import { t } from 'i18next';
import { MenuItem, Select } from '@mui/material';
import { FilterContext } from '../../../../../../context/FilterProvider';

interface SelectRangeModeProps {
  filterType: string
}

export const SelectRangeMode = (props: SelectRangeModeProps) => {
  const { filterType } = props;
  const filterContext = useContext(FilterContext);
  const options = ['range', 'bigger', 'smaller', 'exact'];

  return (
    <Select
      style={{
        margin: '10px 12px 9px 12px',
        textAlign: 'left',
      }}
      value={filterContext.numberFilterType[filterType]}
      onChange={(e) => filterContext.handleChangeNumberFilterType(filterType, e.target.value)}
      sx={{
        backgroundColor: '#FFFFFF',
        height: '45px',
        '&.MuiOutlinedInput-root': {
          marginBottom: '11px',
          '&.Mui-focused fieldset': {
            border: '1px solid #7A7A7A',
            color: 'rgba(122, 122, 122, 1)'
          },
        },
      }}
    >
      {
        options.map((op) => (
          <MenuItem key={op} value={op}>
            <div
              id='worktypeselector-item'
              className={'menu-item'}
              style= {{ color: '#696969'}}
            >
              {t(`filters.filtersSelector.${op}`)}
            </div>
          </MenuItem>
        ))
      }
    </Select >
  );
};

export default SelectRangeMode;
