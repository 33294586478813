import { FC, useContext, useMemo } from 'react';
import styles from './description.module.scss';
import DescriptionMultiLan from '../../../../components/DescriptionMultiLan/DescriptionMultiLan';
import { UserModeContext } from '../../../../context/UserModeProvider';
import { useTranslation } from 'react-i18next';

/**
 * Maximum characters per line in the destacable info that will be shown in the PDF.
 */
export const INFO_DESTACABLE_MAX_CHARS_PER_LINE = 85;

/**
 * Maximum lines in the PDF for the destacable info.
 */
const INFO_DESTACABLE_MAX_LINES_PDF = 8;

/**
 * Maximum length of the destacable info that will be shown in the PDF.
 */
export const INFO_DESTACABLE_MAX_LENGTH = INFO_DESTACABLE_MAX_CHARS_PER_LINE * INFO_DESTACABLE_MAX_LINES_PDF;

interface DescriptionLanguage {
  [language: string]: string
}

interface DescriptionData {
  descGeneral: DescriptionLanguage,
  descDetallada: DescriptionLanguage,
  infoDestacable: DescriptionLanguage
}
interface DescriptionProps {
  data: DescriptionData;
  errors?: any
}
export const Description: FC<DescriptionProps> = (props) => {
  const { data, errors } = props;
  const { t } = useTranslation();
  const userModeContext = useContext(UserModeContext);
  const isEditorMode = userModeContext.userMode === 'editor';

  const errorInfoDestacable = useMemo(() => errors && errors.find((e: any) => e.field === 'infoDestacable'), [errors]);

  return (
    <div
      className={styles.descriptionContainer}>
      <DescriptionMultiLan
        description={{ title: 'descGeneral', data: data.descGeneral }}
        rows={5}
        editable={false}
        modeView={!isEditorMode}
        titleMessage={t('help.description.generalDesc')}
      />
      <DescriptionMultiLan
        description={{ title: 'infoDestacable', data: data.infoDestacable}}
        rows={12}
        editable={isEditorMode}
        modeView={!isEditorMode}
        error={errorInfoDestacable ? (errorInfoDestacable?.textField) : null}
        titleMessage={t('help.description.infoDestacable')}
        maxLen={INFO_DESTACABLE_MAX_LENGTH}
      />
      <DescriptionMultiLan
        description={{ title: 'descDetallada', data: data.descDetallada }}
        rows={12}
        editable={isEditorMode}
        modeView={!isEditorMode}
        error={errors && errors.filter((e: any) => e.field === 'descDetallada').length > 0 ? t('details.validationError.detailDescription') : null}
        titleMessage={t('help.description.detailedDesc')}
      />
    </div>
  );
};
