import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './efficiency.module.scss';
import efficiencyHumanTeamNoData from '../../../assets/efficiencyHumanTeamNoData.svg';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface EfficiencyProps { }

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const Efficiency: FC<EfficiencyProps> = () => {
  const [t] = useTranslation();

  return (
    <div data-testid='efficiency-test' id='efficiency-nodata' className={styles.noData}>
      <img src={efficiencyHumanTeamNoData} />
      <p>{t('details.efficiency.noData')}</p>
      <div className='footer' style={{marginBottom: '75px'}}></div>
    </div>
  );
};

export default Efficiency;
