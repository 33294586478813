import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../../../loadingSpinner/loadingSpinner';
import Error from '../../../Error/Error';
import styles from './MarkerLayer.module.scss';
import { Coordinates } from '../../../../types/ObraDetails';

interface MarkerLayerProps {
  mapInstance: google.maps.Map;
  coordinates: Coordinates;
}

function MarkerLayer(props: MarkerLayerProps) {
  const { mapInstance, coordinates } = props;
  const [marker, setMarker] = useState<google.maps.Marker | null>(null);
  const [layerReady, setLayerReady] = useState(false);
  const [dataError, setDataError] = useState(false);

  useEffect(() => {
    if (window.google && mapInstance) {
      setLayerReady(true);
    }
  }, [mapInstance]);

  useEffect(() => {
    try {
      if (window.google && mapInstance) {
        if(!coordinates.lat || !coordinates.lon){
          marker && marker.setMap(null);
          setMarker(null);
        }else{
          const position = { lat: coordinates.lat, lng: coordinates.lon };
          marker ? marker.setPosition(position) : setMarker(new google.maps.Marker({
            position,
            map: mapInstance
          }));
          mapInstance.panTo(position);
        }
      }
    } catch (error) {
      setDataError(true);
    }
  }, [coordinates]);

  const renderLayer = () => {
    return <div data-testid='marker-layer' className={styles.markerLayer} >
      <div id='markerlayer-loading' className={styles.markerLayerLoading} style={{
        display: layerReady ? 'none' : 'block',
      }}>
        <LoadingSpinner />
      </div>
      <div id='markerlayer-error' className={styles.markerLayerError} style={{
        display: dataError ? 'flex' : 'none',
      }}>
        <Error />
      </div>
    </div >;
  };


  return renderLayer();
}

export default MarkerLayer;
