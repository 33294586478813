import React from 'react';
import { Checkbox } from '@mui/material';

interface SelectGroupAndTypeProps {
  checked: boolean;
  indeterminate?: boolean;
}

const CustomCheckbox = (props: SelectGroupAndTypeProps) => {
  const { checked, indeterminate } = props;

  return (
    <Checkbox data-testid="customCheckbox-test"
      checked={checked}
      indeterminate={indeterminate}
      disableRipple
      sx={{
        color: '#7A7A7A',
        padding: '0',
        paddingRight: '1rem',
        '& .MuiSvgIcon-root': { fontSize: 20 },
        '&.Mui-checked': {
          color: 'red',
        },
        '&.MuiCheckbox-indeterminate': {
          color: 'red',
        },
      }}
    />
  );
};

export default CustomCheckbox;
