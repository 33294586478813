/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react';

import { withTranslation } from 'react-i18next';
import { FilterContext } from '../../../../../context/FilterProvider';

import { TFunction } from 'i18next';
import { MenuItem, Select } from '@mui/material';
import ObrasAPI from '../../../../../services/APIObras';

import styles from './selectGroupAndType.module.scss';

import { v4 as uuidv4 } from 'uuid';

import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';
import CheckboxSelector from '../../../../checkboxSelector/checkboxSelector';
import { Option } from '../../../../../types/Option';

interface SelectGroupAndTypeProps {
  filterType: string;
  t: TFunction<'translations'>;
}

const SelectGroupAndType = (props: SelectGroupAndTypeProps) => {
  const { t, filterType } = props;
  const filterContext = useContext(FilterContext);

  const [groupCode, setGroupCode] = useState('0');
  const [, setTypes] = useState(['0']);
  const [groupOptions, setGroupOptions] = useState<Array<Option>>([]);
  const [subTypesOptions, setSubTypesOptions] = useState<Array<Option>>([]);
  const [menuHeight, setMenuHeight] = useState(70);
  const NO_RESULTS = [{ name: `${t('input.noResults')}` }];

  useEffect(() => {
    const api = ObrasAPI.getInstance();
    api.getGroups().then(
      (results) => {
        results = results.length ? results : NO_RESULTS;
        setGroupOptions(results);
      }
    ).catch((error) => {
      console.log(error);
      setGroupOptions([]);
    });
  }, []);

  useEffect(() => {
    if (groupCode !== '0') {
      const api = ObrasAPI.getInstance();
      api.getSubtypes(groupCode).then(
        (results) => {
          results = results.length ? results : NO_RESULTS;
          setSubTypesOptions(results);
        }
      ).catch((error) => {
        console.log(error);
        setSubTypesOptions([]);
      });
    }
  }, [groupCode]);

  const openMenuHandler = (e: any) => {
    const rect = e?.target && e.target.getBoundingClientRect();
    const top = (rect?.bottom || 0) + 80;
    setMenuHeight(top);
  };

  return (
    <div>
      <div data-testid='selectGroupType-test' className={styles.selectorContainer}>
        <Select
          className={styles.selectorGroupAndType}
          value={groupCode}
          onChange={(e) => {
            setGroupCode(e.target.value);
            setTypes(['0']);
          }}
          onOpen={(e) => openMenuHandler(e)}
          MenuProps={{
            PaperProps: {
              sx: {
                minHeight: '150px',
                maxHeight: `calc(100vh - ${menuHeight}px)`,
                width: 241,
              }
            }
          }}
          sx={{
            backgroundColor: '#FFFFFF',
            height: '45px',
            '&.MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                border: '1px solid #7A7A7A',
                color: 'rgba(122, 122, 122, 1)'
              },
            },
          }}
        >
          <option value='0' disabled hidden>{t('filters.filtersSelector.selectGroup')}</option>
          {groupOptions.map(({ code, name }) => {
            return (
              <MenuItem className={styles.item} key={code} value={code}
              >
                <Tooltip
                  key={uuidv4()}
                  enterDelay={1000}
                  title={
                    <Typography
                      style={{ padding: '0.625rem' }}
                    >
                      {name}
                    </Typography>
                  }
                  followCursor>
                  <Typography
                    style={{ paddingTop: '0.1rem', width: '100%' }}
                    noWrap
                  >
                    <div id='groupselector-item' className={'menu-item'}>{name}</div>
                  </Typography>
                </Tooltip>
              </MenuItem>
            );
          })}
        </Select>
      </div>
      <CheckboxSelector
        filterType={filterType}
        filterContext={filterContext}
        options={subTypesOptions}
        placeholder={t('filters.filtersSelector.selectType') as string}
        selectAllOption={true}
        disabled={!(subTypesOptions.length >= 1)}
        groupCode={groupCode}
      />
    </div>
  );
};

export default withTranslation()(SelectGroupAndType);
