/* eslint-disable react/react-in-jsx-scope */
import { useContext, useEffect} from 'react';
import { AuthContext } from './services/authContextProvider';
import LoadingSpinner from './components/loadingSpinner/loadingSpinner';
import Home from './pages/home/home';
import './App.scss';
import { Outlet, useLocation } from 'react-router-dom';
import ViewProvider from './context/ViewProvider';
import SaveProvider from './context/SaveProvider';
import FilterProvider from './context/FilterProvider';
import UserModeProvider from './context/UserModeProvider';
import AdminProvider from './context/AdminProvider';
import NotificationProvider from './context/NotificationProvider';
import ReactGA4 from 'react-ga4';


function App() {

  const authContext = useContext(AuthContext);

  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA_TRACKING_ID as string);
  }, []);

  // Show the loading spinner while the user is not authenticated
  if (!authContext.isAuthenticated) {
    return <LoadingSpinner />;
  }
  // If the user is authenticated display the home component
  else {
    const location = useLocation();
    const hidden = location.pathname !== '/';
    return <ViewProvider mainView={!hidden}>
      <AdminProvider>
        <UserModeProvider>
          <SaveProvider>
            <FilterProvider>
              <NotificationProvider>
                <div data-testid="app-test" className='App-page'>
                  <Home hidden={hidden} />
                  <Outlet />
                </div>
              </NotificationProvider>
            </FilterProvider>
          </SaveProvider>
        </UserModeProvider>
      </AdminProvider>
    </ViewProvider>;
  }
}

export default App;
