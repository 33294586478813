/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useContext, useEffect } from 'react';
import styles from './adminPanelButton.module.scss';
import adminIcon from '../../assets/adminIcon.svg';
import { AuthContext } from '../../services/authContextProvider';
import { useTranslation } from 'react-i18next';
import ObrasAPI from '../../services/APIObras';

let emailCache = localStorage.getItem('email');

interface AdminPanelButtonProps {
  onClick: () => any;
}

const AdminPanelButton: FC<AdminPanelButtonProps> = (props) => {
  const { onClick } = props;
  const authContext = useContext(AuthContext);
  const { email } = authContext;
  const [t] = useTranslation();
  const api = ObrasAPI.getInstance();
  const [adminPermissions, setAdminPermissions] = React.useState<boolean>(false);
  
  useEffect(() => {
    if(email && emailCache !== '') emailCache = email;
    api.getAdminKeycloakAndDB(emailCache as string).then((response) => {
      setAdminPermissions(response);
    }).catch((error) => {
      console.error(error);
    });
  }, [email]);
  
  return (
    <>{adminPermissions &&
      <div id='header-adminPanel' className={styles.adminPanel} onClick={onClick} >
        <img src={adminIcon} />
        <div className={styles.adminPanelText}>{t('header.adminPanel')}</div>
      </div>
    }</>
  );
};

export default AdminPanelButton;
