/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react';
import NUMBER_RANGE_TYPE from '../numberRangeType';
import { CustomNumberPicker } from './customNumberPicker/customNumberPicker';
import { FilterContext } from '../../../../../../context/FilterProvider';

interface RangeModeFieldsProps {
  filterType: string,
  defaultUnit?: string,
}

export const RangeModeFields = (props: RangeModeFieldsProps) => {
  const { filterType, defaultUnit } = props;
  const filterContext = useContext(FilterContext);

  const renderCustomNumberPicker = (numberRangeType: string, rangeType: string) => {
    return (<CustomNumberPicker
      filterType={filterType}
      numberRangeType={numberRangeType}
      suffix={defaultUnit}
      rangeType={rangeType}
    />);
  };

  const renderRangeModeFields = () => {
    let res = null;
    const filterRangeValue = filterContext.numberFilterType[filterType];
    if (filterRangeValue === 'range') {
      res = (<>
        {renderCustomNumberPicker(NUMBER_RANGE_TYPE.minAmount, 'range')}
        {renderCustomNumberPicker(NUMBER_RANGE_TYPE.maxAmount, 'range')}
      </>);
    } else if (filterRangeValue === 'bigger')
      res = renderCustomNumberPicker(NUMBER_RANGE_TYPE.minAmount, 'bigger');
    else if (filterRangeValue === 'smaller')
      res = renderCustomNumberPicker(NUMBER_RANGE_TYPE.maxAmount, 'smaller');
    else if (filterRangeValue === 'exact')
      res = renderCustomNumberPicker(NUMBER_RANGE_TYPE.exact, 'exact');
    return res;
  };

  return renderRangeModeFields();
};

export default RangeModeFields;