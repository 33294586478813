import React from 'react';
import { FilterCollapsableWrapper } from '../../FiltersWrappers/FilterCollapsableWrapper/FilterCollapsableWrapper';
import CustomAutocomplete from './CustomAutocomplete/CustomAutocomplete';

export const AutocompleteMultipleChoice = ({ filterType, disableScroll, noBottomDivider }: any) => {
  return (
    <>
      <FilterCollapsableWrapper
        filterType={filterType}
        noBottomDivider={noBottomDivider}
      >
        <CustomAutocomplete
          filterType={filterType}
          disableScroll={disableScroll}
        />
      </FilterCollapsableWrapper>
    </>
  );
};
