/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useState } from 'react';
import styles from './partnerBox.module.scss';


interface Field {
  title: string;
  value?: string;
}

interface SwitchInfoBoxProps {
  picture?: string;
  item?: Field;
  participationItem?: Field;
}

const PartnerBox: FC<SwitchInfoBoxProps> = (props) => {
  const { picture, item, participationItem } = props;

  return (
    <div className={styles.box}>
      <div className={styles.picture}>
        <img src={picture} className={styles.picture}/>
      </div>
      <div className={styles.content}>       
        <div className={styles.field}>
          <div id="partner-title"  className={styles.title}>{item?.value}</div>
          <div id="partner-value" className={styles.value}>{item?.title}</div>
        </div>
        <div className={styles.participacion}>
          <div id="participation-title" className={styles.participationTitle}>{participationItem?.title}</div>
          <div id="participation-value" className={styles.participationValue}>{participationItem?.value}</div>
        </div>
      </div>
    </div>
  );
};

export default PartnerBox;