/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState } from 'react';
import { Button, Stack, TextField } from '@mui/material';
import parse from 'html-react-parser';

import styles from './rejectedDialog.module.scss';
import { useTranslation } from 'react-i18next';
import { Comentario } from '../../../../types/ObraDetails';

interface DialogErrorProps {
  handleAccept: (c: Comentario) => void;
  handleCancel: () => void;
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const RejectedDialog: FC<DialogErrorProps> = (props) => {
  const { handleAccept, handleCancel } = props;
  const [comment, setComment] = useState<any>();
  const { t } = useTranslation();

  const inputStyle = {
    '&.MuiTextField-root': {
      '.MuiInputLabel-root': {
        color: 'rgba(122, 122, 122, 1)'
      },
      '.Mui-focused': {
        '&::after': {
          borderBottom: '2px solid rgba(122, 122, 122, 1)'
        }
      },
      '.MuiFilledInput-root': {
        '&::after': {
          borderBottom: '1px solid rgba(122, 122, 122, 1)',
        },

      }
    }
  };

  const handleClose = () => {
    handleCancel();
  };

  const handleSend = () => {
    if (comment) {
      handleAccept(comment);
    }
  };

  const handleChange = (field: string, value: string) => {
    const oldComment = comment || {};
    setComment({ ...oldComment, [field]: value });
  };

  const isValidComment = () => {
    return comment && comment.titulo?.length && comment.detalles?.length;
  };

  return (
    <div className={styles.dialogContent}>
      <p>{parse(t('details.validation.reject.information'))}</p>
      <div className={styles.dialogForm}>
        <div className={styles.title}>
          <TextField
            label={t('details.validation.reject.title')}
            variant='filled'
            sx={inputStyle}
            onChange={(c) => handleChange('titulo', c.target.value)}
          />
        </div>
        <div className={styles.details}>
          <TextField
            label={t('details.validation.reject.details')}
            variant='filled'
            sx={inputStyle}
            multiline
            rows={4}
            onChange={(c) => handleChange('detalles', c.target.value)}
          />
        </div>
      </div>
      <Stack spacing={2} direction="row" justifyContent="center">
        <Button id='buttonclose' className={styles.buttonSecondary} onClick={() => handleClose()} disableRipple={true} variant="contained">
          {t('details.validation.reject.cancel')}
        </Button>
        <Button id='buttoncontinue'
          className={styles.buttonClose}
          onClick={() => handleSend()}
          disableRipple={true}
          variant="contained"
          disabled={!isValidComment()}>
          {t('details.validation.reject.send')}
        </Button>
      </Stack>
    </div>


  );
};

export default RejectedDialog;
