import React, { FC, useContext } from 'react';
import styles from './sustainability.module.scss';
import { ObraProperties } from '../../../../types/ObraDetails';
import { UserModeContext } from '../../../../context/UserModeProvider';
import ViewSustainability from './viewSustainability/viewSustainability';
import EditSustainability from './editSustainability/editSustainability';

interface SustainabilityProps {
  obra: ObraProperties,
  errors?: any
}

const Sustainability: FC<SustainabilityProps> = (props) => {
  const { obra, errors } = props;
  const userModeContext = useContext(UserModeContext);
  const isEditorMode = userModeContext.userMode === 'editor';
  return (
    <div data-testid='esg-test' id='esg-nodata' className={styles.container}>
      <div className={styles.sectionContainer}>
        {
          isEditorMode ?
            <EditSustainability obra={obra} errors={errors} />
            :
            <ViewSustainability obra={obra} />
        }
      </div>
    </div>
  );
};


export default Sustainability;
