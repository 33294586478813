/* eslint-disable react/jsx-key */
import React, { FC, useContext, useEffect, useReducer, useState } from 'react';
import Slider from 'react-slick';
import Dialog from '@mui/material/Dialog';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './carousel.scss';
import styles from './carousel.module.scss';
import emptyPhoto from '../../../../assets/carousel/emptyPhoto.svg';
import screen from '../../../../assets/carousel/screen.svg';
import fullScreenIcon from '../../../../assets/carousel/fullScreen.svg';
import CloseIcon from '../../../../assets/carousel/closeIcon.svg';
import leftArrowDialog from '../../../../assets/carousel/leftArrowDialog.svg';
import rightArrowDialog from '../../../../assets/carousel/rightArrowDialog.svg';
import parse from 'html-react-parser';
import { ADMIN, EDITOR, VIEWER } from '../../../../userRoles/userRoles';
import { AuthContext } from '../../../../services/authContextProvider';

import { Button, IconButton, SvgIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UserModeContext } from '../../../../context/UserModeProvider';
import { PopupImage } from './popupImage/popupImage';
import StarIcon from '@mui/icons-material/Star';
import { ReactComponent as delete_icon } from '../../../../assets/carousel/delete.svg';
import { SaveContext } from '../../../../context/SaveProvider';
import { Action } from './carousel.enums';
import { Foto } from '../../../../types/ObraDetails';
import ErrorIcon from '@mui/icons-material/Error';
import InformationIcon from '../../components/informationIcon/informationIcon';


interface CarouselProps {
  data: Foto[];
  codCentro: string;
  error?: string | null
}

const Carousel: FC<CarouselProps> = (props) => {
  const saveContext = useContext(SaveContext);
  const { error } = props;
  const sliderRef = React.useRef<any>(null);
  const { t, i18n } = useTranslation();
  const [fullScreen, setFullScreen] = React.useState(false);
  const [currentSlide, setCurrentSlide] = React.useState(Math.max(props.data.findIndex((d) => d.iconica), 0));
  const userModeContext = useContext(UserModeContext);
  const authContext = useContext(AuthContext);
  const [slides, setSlides] = React.useState(props.data.filter((d) => d.url));
  const [updateNow, setUpdateNow] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [action, setAction] = React.useState('');
  const slideHidden = { url: '', descripcion: '', iconica: false };
  let hiddenSlideAdded = 0;

  const forceUpdate = (noChanges?: boolean, action: string = Action.DELETE) => {
    setDeleting(false);
    if (!noChanges) {
      setAction(action);
      saveContext.handleChangeSave(true);
      setUpdateNow(!updateNow);
    }
  };
  // update values when props change
  useEffect(() => {
    setCurrentSlide(Math.max(props.data.findIndex((d) => d.iconica), 0));
    setSlides(props.data.filter((d) => d.url));
  }, [props.data]);

  useEffect(() => {
    hiddenSlideAdded = 0;
    const newSlides = props.data.filter((d) => d.url);
    // if iconic, position doesn't change
    if (action !== Action.ICONIC) {
      let newCurrentSlide = currentSlide;
      if (action === Action.ADD) {
        // if a new image is added, we set the carousel in the last picture
        newCurrentSlide = newSlides.length - 1;
      } else if (action === Action.DELETE) {
        // if a image is deleted, we set the carousel in the nearest picture
        if (currentSlide === newSlides.length - 1) {
          // if we remove the first one, we set the new first one picture
          newCurrentSlide = Math.max(currentSlide - 1, 0);
        } else {
          // if it's not the first image deleted, we set the same index except if we remove the last one because the index is lower
          newCurrentSlide = Math.min(currentSlide, newSlides.length - 1);
        }
      }
      if (action !== '') {
        sliderRef?.current && sliderRef.current.slickGoTo(newCurrentSlide, true);
      }
      setCurrentSlide(newCurrentSlide);
    }
    setSlides(newSlides);
  }, [updateNow]);

  const isEditorMode = userModeContext.userMode === 'editor';


  const handleClickFullScreen = () => {
    setFullScreen(true);
  };

  const handleClose = () => {
    setFullScreen(false);
  };


  const renderSlides = () =>

    slides.filter((d) => !!d).map((data, index) => {
      let className = 'slideHide2';
      if (index === currentSlide + 1
        || index === currentSlide - 1
        || currentSlide === 0 && index === slides.length - 1
        || currentSlide === slides.length - 1 && index === 0) {
        className = 'slide2';
      }

      if (index === currentSlide + 2
        || index === currentSlide - 2
        || currentSlide + 2 > slides.length - 1 && index === currentSlide + 2 - slides.length
        || currentSlide - 2 < 0 && index === slides.length - 2 + currentSlide) {
        className = 'slideSmall';
      }

      if (index === currentSlide + 3
        || index === currentSlide - 3
        || currentSlide + 3 > slides.length - 1 && index === currentSlide + 3 - slides.length
        || currentSlide - 3 < 0 && index === slides.length - 3 + currentSlide) {
        className = 'slideHide';
      }

      if (slides.length === 3 && index === 1) {
        className = 'activeSlide';
      } else if (slides.length !== 3 && currentSlide === index) {
        className = 'activeSlide';
      }
      if (slides.length === 2) {
        className += ' two-items';
      }

      if (!data.url) {
        hiddenSlideAdded += 1;
        return <div className={'slideHide noShow'}></div>;
      } else {
        return (
          <div className={'boxSlide ' + className}>
            <img src={data.url} />
          </div>
        );
      }
    });

  function NextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: disableRightArrow() ? 'none' : 'block', right: '-50px' }}
        onClick={onClick}
      />
    );
  }

  function PrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: disableLeftArrow() ? 'none' : 'block', left: '-50px' }}
        onClick={onClick}
      />
    );
  }

  /**
   * Disable the Left Arrow if the first slide is in the center
   */
  const disableLeftArrow = () => currentSlide === 0;

  /**
   * Disable the Right Arrow if the last slide is in the center
   */
  const disableRightArrow = () => currentSlide === slides.length - hiddenSlideAdded - 1;

  /**
   * We add hidden slides in order to improve the visualization with a few slides
   */
  const addHiddenSlides = () => {
    if (slides.length > 0 && slides[slides.length - 1].url) {
      for (let i = 1; i < 7; i++) {
        slides.push(slideHidden);
      }
    }
  };

  const calculateInitialSlide = () => {
    const iconica = slides.findIndex((s) => s.iconica);
    if (iconica > -1) {
      return iconica;
    }
    return slides.findIndex((s) => s.url);
  };

  const slidesToShow = slides.length === 1 ? 1 : 5;

  // we add hidden slides in order to improve the user experience with the carousel if the slider has a few photos
  addHiddenSlides();


  const settings = {
    ref: sliderRef,
    initialSlide: calculateInitialSlide(),
    infinite: slides.length > 1 ? true : false,
    centerMode: slides.length > 1 ? true : false,
    slidesToShow,
    slidesToScroll: slides.length > 1 ? 1 : 0,
    focusOnSelect: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    swipeToSlide: false,
    draggable: false,
    speed: 250,
    afterChange: (current: number) => {
      setCurrentSlide(current);
    }
  };

  const handlePrevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const handleNextSlide = () => {
    sliderRef.current.slickNext();
  };

  const handleIconic = () => {
    // when fix a image as iconic the rest of them must not be iconic
    if (props.data[currentSlide]?.iconica) {
      props.data[currentSlide].iconica = false;
    } else {
      props.data.forEach((slide, i) => {
        if (i === currentSlide) {
          slide.iconica = true;
          if (error) {
            saveContext.forceUpdate();
          }
        } else {
          slide.iconica = false;
        }
      });
    }
    forceUpdate(false, Action.ICONIC);
  };

  const handleDelete = () => {
    setDeleting(true);
  };

  return (
    <div className={styles.container} data-testid="carousel-test">
      <div className={styles.heading}>
        <div>
          {t('details.carousel.title')}
          <InformationIcon message={t('help.description.gallery')} />
          {
            error ? <div className={styles.errorContainer} ><ErrorIcon className={styles.errorIcon} /><div className={styles.errorText} >{error}</div></div> : null
          }
        </div>
        {isEditorMode && <PopupImage codCentro={props.codCentro} data={props.data} forceUpdate={forceUpdate} currentError={deleting && 'DELETE'} currentImage={currentSlide} />}

      </div>

      {
        slides.length > 0 ? <div className={styles.carouselContainer}>
          <Slider {...settings}>
            {renderSlides()}
          </Slider>
          <div className={styles.screenBox}>
            <img src={screen} />
          </div>
          <div className={`${styles.buttons} buttons-current-slide`}>
            <div id='fullscreenbutton' className={styles.fullScreenButton} onClick={handleClickFullScreen}>
              <img src={fullScreenIcon} />
              <span> {t('details.carousel.fullScreen')}</span>
            </div>
            {isEditorMode &&
              <Button className={styles.iconicButton} variant='contained' startIcon={<StarIcon />}
                onClick={handleIconic}>
                <span>{props.data[currentSlide]?.iconica ? t('details.carousel.fixedIconic') : t('details.carousel.fixIconic')}</span>
              </Button>}
            {isEditorMode &&
              <Button className={styles.deleteButton} variant='contained'
                onClick={handleDelete} >
                <SvgIcon component={delete_icon} inheritViewBox />
              </Button>}
          </div>
          <Dialog
            fullWidth={true}
            classes={{
              paper: 'dialog-paper'
            }}
            BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.15)' } }}
            PaperProps={{ sx: { borderRadius: '20px', minWidth: '90vW' } }}
            open={fullScreen}
            onClose={handleClose}>


            <div id='fullscreencontent' className={styles.fullScreenContent}>
              <div className={styles.imageContainer}>
                <img src={slides[currentSlide]?.url} />
              </div>
              <div className={styles.fullScreenFooter}>
                <div className={styles.info}>
                  <div className={styles.fullScreenTitleContainer}>
                    <p className={styles.fullScreenTitle}>{slides[currentSlide]?.tipo?.tipo}</p>
                  </div>
                  <div className={styles.fullScreenSubTitleContainer}>
                    <p className={styles.fullScreenSubTitle}>{[slides[currentSlide]?.descripcion] || ' '}</p>
                  </div>
                </div>
                {isEditorMode && <div className={`${styles.buttons} buttons-current-slide`}>
                  <Button className={styles.iconicButton} variant='contained' startIcon={<StarIcon />}
                    onClick={() => {
                      handleIconic();
                      handleClose();
                    }}>
                    <span >{props.data[currentSlide]?.iconica ? t('details.carousel.fixedIconic') : t('details.carousel.fixIconic')}</span>
                  </Button>
                  <Button className={styles.deleteButton} variant='contained'
                    onClick={() => {
                      handleDelete();
                      handleClose();
                    }} >
                    <SvgIcon component={delete_icon} inheritViewBox />
                  </Button>
                </div>}
              </div>
            </div>
            <div id='closefullscreen' className={styles.closeFullScreen}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <img src={CloseIcon} />
              </IconButton>
            </div>
            <div id='leftarrow' className={styles.dialogLeftArrow}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handlePrevSlide}
                aria-label="close"
                sx={{
                  'display': disableLeftArrow() ? 'none' : 'block',
                  '&:hover': {
                    'backgroundColor': 'rgba(0, 0, 0, 0);'
                  }
                }}
              >
                <img src={leftArrowDialog} />
              </IconButton>
            </div>
            <div id='rigtharrow' className={styles.dialogRigthArrow}>
              <IconButton
                onClick={handleNextSlide}
                sx={{
                  'display': disableRightArrow() ? 'none' : 'block',
                  '&:hover': {
                    'backgroundColor': 'rgba(0, 0, 0, 0);'
                  }
                }}
              >
                <img src={rightArrowDialog} />
              </IconButton>
            </div>
          </Dialog>
        </div>
          : <div id='emptyphoto' className={styles.emptyPhotoContainer}>
            <img src={emptyPhoto} />
            <p>{(userModeContext.userMode === 'editor')
              ? parse(t('details.carousel.emptyPhotoEditor'))
              : authContext.hasEditorRole() && userModeContext.userMode !== 'editorDisabled'
                ? parse(t('details.carousel.emptyPhotoEditorModoViewer'))
                : userModeContext.userMode !== 'editorDisabled'
                  ? parse(t('details.carousel.emptyPhoto')) : parse(t('details.carousel.emptyPhoto') + t('details.validation.noEditMode'))}</p>
          </div>

      }
    </div >

  );
};

export default Carousel;
