import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';

interface CustomControlProps {
  mapInstance: google.maps.Map;
  position?: google.maps.ControlPosition;
  show: boolean;
  children: JSX.Element;

}

function CustomControl(props: CustomControlProps) {
  const { mapInstance, position = google.maps.ControlPosition.TOP_RIGHT, show, children } = props;

  useEffect(() => {
    if (window.google && mapInstance) {
      addControlToMap();
    }
  }, [mapInstance]);

  useEffect(() => {
    if (show) {
      addControlToMap();
    } else {
      mapInstance.controls[position] && mapInstance.controls[position].clear();
    }
  }, [show]);

  const addControlToMap = async () => {

    mapInstance.controls[position] && mapInstance.controls[position].clear();
    const controlDiv = document.createElement('div');
    const root = createRoot(controlDiv as HTMLElement);
    root.render(children);
    mapInstance.controls[position].push(controlDiv);
  };

  const renderControl = () => {
    return <div data-testid='custom-control'  >

    </div >;
  };


  return renderControl();
}

export default (CustomControl);
