/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC } from 'react';
import styles from './bim.module.scss';
import { ObraProperties } from '../../../types/ObraDetails';
import DataBim from './dataBim/dataBim';
import NoDataPicture from '../components/noDataPicture/noDataPicture';
import { useTranslation } from 'react-i18next';
import bimNoData from '../../../assets/bim_no_data.svg';
import bimNoEvaluated from '../../../assets/bim_no_evaluated.svg';
import UsosBim from './usosBim/usosBim';


interface BimProps {
  obra: ObraProperties
}

const Bim: FC<BimProps> = (props) => {
  const { obra } = props;
  const [t] = useTranslation();
  let noBimData = false;
  let noData: any = {
    picture: bimNoEvaluated,
    text: t('details.bim.noEvaluatedData')
  };
  if (!Object.values(obra.bim)?.length || !obra.bim.requisitos) {
    noBimData = true;
    noData = {
      picture: bimNoData,
      text: t('details.bim.noData')
    };
  } else if (obra.bim.requisitos === 1) {
    noBimData = true;
  }
  return (
    <div
      data-testid="bim-test"
      className={styles.bimContainer} >

      {
        !noBimData ?
          <>
            <DataBim obra={obra} />
            <div className={styles.section}>
              {obra.bim?.usosBIM?.length > 0 && <UsosBim obra={obra} />}
            </div>
          </>
          : <NoDataPicture
            id='bim-nodata'
            picture={noData.picture}
            text={noData.text}
          />
      }
      <div className='footer' style={{marginBottom: '75px'}}></div>
    </div >);
};

export default Bim;
