export const COLORS: any = {
  1: {
    color: 'rgba(243, 156, 18, 1)',
    backgroundColor: 'rgba(243, 156, 18, 0.15)'
  },
  2: {
    color: 'rgba(0, 111, 245, 1)',
    backgroundColor: 'rgba(0, 111, 245, 0.15)'
  },
  3: {
    color: 'rgba(26, 188, 156, 1)',
    backgroundColor: 'rgba(26, 188, 156, 0.15)'
  },
  4: {
    color: 'rgba(97, 188, 26, 1)',
    backgroundColor: 'rgba(97, 188, 26, 0.15)'
  },
  5: {
    color: 'rgba(255, 192, 1, 1)',
    backgroundColor: 'rgba(255, 192, 1, 0.15)'
  },
  6: {
    color: 'rgba(255, 0, 0, 1)',
    backgroundColor: 'rgba(255, 0, 0, 0.15)'
  },
  7: {
    color: 'rgba(183, 77, 96, 1)',
    backgroundColor: 'rgba(183, 77, 96, 0.15)'
  },
  8: {
    color: 'rgba(66, 189, 211, 1)',
    backgroundColor: 'rgba(66, 189, 211, 0.15)'
  },
  9: {
    color: 'rgba(119, 148, 255, 1)',
    backgroundColor: 'rgba(119, 148, 255, 0.15)'
  },
  10: {
    color: 'rgba(143, 0, 255, 1)',
    backgroundColor: 'rgba(143, 0, 255, 0.15)'
  },
  11: {
    color: 'rgba(243, 104, 224, 1)',
    backgroundColor: 'rgba(243, 104, 224, 0.15)'
  },
  12: {
    color: 'rgba(10, 189, 227, 1)',
    backgroundColor: 'rgba(10, 189, 227, 0.15)'
  },
  13: {
    color: 'rgba(52, 31, 151, 1)',
    backgroundColor: 'rgba(52, 31, 151, 0.15)'
  },
  14: {
    color: 'rgba(1, 163, 164, 1)',
    backgroundColor: 'rgba(1, 163, 164, 0.15)'
  },
  15: {
    color: 'rgba(10, 61, 98, 1)',
    backgroundColor: 'rgba(10, 61, 98, 0.15)'
  },
  16: {
    color: 'rgba(74, 105, 189, 1)',
    backgroundColor: 'rgba(74, 105, 189, 0.15)'
  },
  17: {
    color: 'rgba(241, 144, 102, 1)',
    backgroundColor: 'rgba(241, 144, 102, 0.15)'
  },
  18: {
    color: 'rgba(245, 59, 87, 1)',
    backgroundColor: 'rgba(245, 59, 87, 0.15)'
  }
};