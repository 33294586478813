/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useState } from 'react';

interface SaveContextValues {
  saveDraft: boolean,
  handleChangeSave: (value: boolean) => void,
  reloadData: boolean,
  handleReloadData: () => void,
  update: boolean,
  isValidation: boolean,
  forceUpdate: () => void,
  handleValidationClicked: (value: boolean) => void,
}

const defaultSaveContextValues: SaveContextValues = {
  saveDraft: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleChangeSave: () => { },
  reloadData: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleReloadData: () => { },
  update: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  forceUpdate: () => { },
  isValidation: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleValidationClicked: () => { },
};

export const SaveContext = createContext<SaveContextValues>(
  defaultSaveContextValues
);


interface SaveContextProviderProps {
  /**
   * The elements wrapped by the auth context.
   */
  children: JSX.Element;
}
const SaveProvider = (props: SaveContextProviderProps) => {
  const [saveDraft, setSaveDraft] = useState<any>();
  const [reloadData, setReloadData] = useState<boolean>(false);
  const [update, setUpdate] = useState<boolean>(false);
  const [isValidation, setValidation] = useState<boolean>(false);

  const handleChangeSave = (value: boolean) => {
    setSaveDraft(value);
  };

  const handleReloadData = () => {
    setReloadData(!reloadData);
  };

  const forceUpdate = () => {
    setUpdate(!update);
  };

  const handleValidationClicked = (value: boolean) => {
    setValidation(value);
  };

  // Setup the context provider
  return (
    <SaveContext.Provider
      value={
        {
          saveDraft,
          handleChangeSave,
          handleReloadData,
          reloadData,
          forceUpdate,
          update,
          isValidation,
          handleValidationClicked,
        }}
    >
      {props.children}
    </SaveContext.Provider>
  );
};

export default SaveProvider;
