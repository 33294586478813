import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './popupImage.module.scss';
import './popupImage.scss';
import DragDropFile from '../../../../../components/DragDropFile/DragDropFile';
import { Foto } from '../../../../../types/ObraDetails';
import ErrorIcon from '@mui/icons-material/Error';
import { SaveContext } from '../../../../../context/SaveProvider';
import { Action } from '../carousel.enums';
import ObrasAPI from '../../../../../services/APIObras';
const LIMIT_IMAGES = 10;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PopupImageProps {
  codCentro: string;
  data: Foto[];
  forceUpdate: (noChanges?: boolean, action?: string) => void,
  currentError: string | false,
  currentImage: number,
}


export const PopupImage: FC<PopupImageProps> = (props) => {
  const saveContext = useContext(SaveContext);
  const { t } = useTranslation();
  const { data, forceUpdate, currentError, currentImage } = props;
  const [open, setOpen] = React.useState(!!currentError);
  const [uploadDisabled, setUploadDisabled] = useState(true);
  const [tiposFoto, setTiposFoto] = useState([]);
  const [imageData, setImageData] = useState<Foto>({
    descripcion: '',
    url: '',
    iconica: false,
  });
  const [error, setError] = useState(currentError);

  useEffect(() => {
    setOpen(!!currentError);
    setError(currentError);
  }, [currentError]);

  const handleOpen = () => {
    setOpen(true);
    setImageData({
      descripcion: '',
      url: '',
      iconica: false,
    });
  };

  const handleClose = () => {
    setError('');
    setOpen(false);
    forceUpdate(true);
    setImageData({ ...imageData, url: '' });
  };

  const handleUpload = () => {
    if (data.length === LIMIT_IMAGES) {
      setError('LIMIT');
    } else {
      data.push(imageData);
      setOpen(false);
      forceUpdate(false, Action.ADD);
    }
    // disable upload button after add one picture
    setUploadDisabled(true);
  };

  const handleChange = (imageField: any) => {
    saveContext.handleChangeSave(true);
    const newImageData = { ...imageData, ...imageField };
    setImageData(newImageData);
    if (!newImageData.url || !newImageData.tipo) {
      setUploadDisabled(true);
    } else {
      setUploadDisabled(false);
    }

  };

  const handleUploadUrl = (imageField: any) => {
    if (imageField?.url) {
      // retrieve photo types for select component
      const api = ObrasAPI.getInstance();
      api.getPhotoTypes().then((results) => {
        setTiposFoto(results);
      }).catch((error) => {
        console.log(error);
        setTiposFoto([]);
      });
      handleChange({ url: imageField?.url });
    } else if (imageField?.error) {
      setError(imageField?.error.toString());
    }
  };

  const handleUploadNew = () => {
    setError('');
  };

  const handleDelete = () => {
    props.data.splice(currentImage, 1);
    forceUpdate(false, Action.DELETE);
    setError('');
  };

  const dragDropComponent = () => {
    return <div className='drag-drop'>
      <DialogTitle>{t('details.carousel.uploadImage')}</DialogTitle>
      <DialogContent>
        <DragDropFile codCentro={props.codCentro} handleUpload={handleUploadUrl} />
      </DialogContent>
    </div>;
  };

  const imageUploadedComponent = () => {
    return <div>
      <DialogTitle>{t('details.carousel.uploadedImage')}</DialogTitle>
      <DialogContent>
        <div className={styles.uploadedImage}>
          <img src={imageData.url} />
        </div>
        <div className='select-type'>
          <FormControl fullWidth variant='standard'>
            <div className='input-label'>
              <InputLabel
                id='select-type-image'
                shrink
                sx={!imageData.tipo ? { color: 'red' } : { color: 'rgba(122, 122, 122, 1)' }}
              >{t('details.carousel.imageType')}
              </InputLabel>
              <Select
                MenuProps={{ style: { height: '250px' } }}
                labelId='select-type-image'
                className={styles.selectorSearchMode}
                value={imageData.tipo && imageData.tipo}
                onChange={(e: any) => handleChange({ tipo: e.target.value })}>
                {
                  tiposFoto.map((tipo: any) => (
                    <MenuItem key={tipo.id} value={tipo}>
                      <div className={'menu-item'}>{tipo.tipo}</div>
                    </MenuItem>
                  ))
                }
              </Select >
            </div>
            {!imageData.tipo && <FormHelperText>
              <ErrorIcon />
              <span>{t('details.carousel.missingType')}
              </span>
            </FormHelperText>}
          </FormControl>
        </div>
        <div className='image-description'>
          <FormControl fullWidth variant='standard'>
            <TextField
              id='outlined-multiline-static'
              variant='standard'
              placeholder={t('details.carousel.imageDescription') || ''}
              multiline
              rows={4}
              onChange={(e: any) => handleChange({ descripcion: e.target.value })}
            />
          </FormControl>
        </div>
      </DialogContent >
    </div >;
  };

  const errorComponent = () => {
    if (error === '413') {
      return <div className={styles.errorDialog}>
        <DialogTitle>{t('details.carousel.errorSizeImage')}</DialogTitle>
        <DialogActions className={styles.buttonFooter}>
          <Button onClick={handleClose} className={styles.cancelButton}>{t('details.carousel.cancel')}</Button>
          <Button onClick={handleUploadNew} className={styles.uploadButton} >{t('details.carousel.uploadNew')}</Button>
        </DialogActions>
      </div>;
    } else if (error === 'DELETE') {
      return <div className={styles.errorDialog}>
        <DialogTitle>{`${t('details.carousel.errorDeleteImage')} "${data[currentImage]?.tipo?.tipo}"?`}</DialogTitle>
        <DialogActions className={styles.buttonFooter}>
          <Button onClick={handleClose} className={styles.cancelButton}>{t('details.carousel.cancel')}</Button>
          <Button onClick={handleDelete} className={styles.uploadButton} >{t('details.carousel.delete')}</Button>
        </DialogActions>
      </div>;
    } else {
      return <div className={styles.errorDialog}>
        <DialogTitle>{error === 'LIMIT' ? t('details.carousel.errorLimitImages') : t('details.carousel.errorGeneric')}</DialogTitle>
        <DialogActions className={styles.buttonFooter}>
          <Button onClick={error === 'LIMIT' ? handleClose : handleUploadNew} className={styles.uploadButton} >{t('details.carousel.acept')}</Button>
        </DialogActions>
      </div>;
    }
  };

  return (
    <div className={styles.popup}>
      <Button
        className={styles.addImages}
        onClick={handleOpen}>

        <span>+ {t('details.carousel.addImages')}</span>
      </Button>
      <Dialog className='popup-content' open={open} onClose={handleClose}>
        {error ?
          errorComponent() :
          (open ?
            <div>
              {(!imageData.url ? dragDropComponent() : imageUploadedComponent())}
              <DialogActions className={styles.buttonFooter}>
                <Button onClick={handleClose} className={styles.cancelButton}>{t('details.carousel.cancel')}</Button>
                <Button onClick={handleUpload} className={styles.uploadButton} disabled={uploadDisabled}>{t('details.carousel.upload')}</Button>
              </DialogActions>
            </div> : '')
        }
      </Dialog>
    </div>
  );
};
