/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC } from 'react';
import styles from './InfoBoxBoolean.module.scss';
import parse from 'html-react-parser';
import { ReactComponent as checkTrue } from '../../../../../../assets/check_true.svg';
import { ReactComponent as checkFalse } from '../../../../../../assets/check_false.svg';
import { SvgIcon } from '@mui/material';

interface Field {
  title: string;
  value: string;
}


interface InfoBoxBooleanProps {
  item: Field;
  identifier?: any;
  value: boolean;
}

const InfoBoxBoolean: FC<InfoBoxBooleanProps> = (props) => {
  const { item, identifier, value } = props;

  return (
    <div className={styles.box}>

      <div className={styles.field} >
        <div id={`${identifier}-title`} className={styles.title}>{parse(item?.title)}</div>
        <div id={`${identifier}-value`} className={styles.value}>{item?.value}</div>
      </div>
      <div className={styles.check}>
        <SvgIcon component={value ? checkTrue : checkFalse} inheritViewBox />
      </div>


    </div >
  );
};

export default InfoBoxBoolean;
