import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { t } from 'i18next';
import React, { FC, useContext, useEffect, useState } from 'react';
import CollapsableTable from './collapsableTable/collapsableTable';
import styles from './usersTableRow.module.scss';
import { Usuario } from '../../../../types/Admin';
import ObrasAPI from '../../../../services/APIObras';
import { AdminContext, EmailandName } from '../../../../context/AdminProvider';
import { Button, Dialog, Stack, SvgIcon } from '@mui/material';
import { ReactComponent as error } from '../../../../assets/error.svg';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UsersTableRowProps {
	user: Usuario;
  index: number;
  userInput: string;
 }

export const renderRole = (role: string) => {
  switch(role) {
    case 'viewer':
    case 'viewer_vip':
      return t('administration.table.viewer');
    case 'editor':
      return t('administration.table.editor');
    case 'validator':
      return t('administration.table.validator');
    case 'administrator':
      return t('administration.table.admin');
    case 'download':
      return t('administration.table.download');
  }
};

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const UsersTableRow: FC<UsersTableRowProps> = (props) => {
  const { user, index, userInput } = props;
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [permisos, setPermisos] = useState([]);
  const api = ObrasAPI.getInstance();
  const adminContext = useContext(AdminContext);
  const updatedPermissions = adminContext.updatedPermissions ? adminContext.updatedPermissions[user.email] : undefined;
  const [groups, setGroups] = useState<string[]>(user.grupos);
  const [openError, setOpenError] = useState(false);

  const [collapsable, setCollapsable] = useState(user.grupos.filter((g) => ['validator', 'editor', 'administrator', 'download'].includes(g)).length > 0);

  useEffect(() => {
    setGroups(user.grupos);
    setCollapsable(user.grupos.filter((g) => ['validator', 'editor', 'administrator', 'download'].includes(g)).length > 0);
  }, [user]);
 
  useEffect(() => {
    setOpen(false);
  }, [userInput]);

  const deleteRole = async (id: number | undefined, email: string, grupo: string | undefined, index: number) => {
    if(id && grupo){
      setLoading(true);
      const response = await api.deleteRole(id, email, grupo);
      if (response.error) {
        setOpenError(true);
      } else {
        setGroups(response);
        setCollapsable(response.filter((g: string) => ['validator', 'editor', 'administrator', 'download'].includes(g)).length > 0);
        setPermisos(permisos.filter((_perm, i) => i !== index));
      }
      setLoading(false);
    }
  };

  const handleClickRow = () => {
    if (!open) {
      setLoading(true);
      api.getPermissionsByUserEmail(user.email)
        .then((result) => {
          setPermisos(result);
          setLoading(false);
        }).catch(err => {
          console.log(err);
        });
    }
    setOpen(!open);
  };

  const handleEditUser = () => {
    const userName = [...adminContext.allUsers].find((u) => u.email === user.email)?.nombre;
    const userToEdit: EmailandName = {email: user?.email, name:userName};
    if (userName) {
      adminContext.handleChangeUserToEdit(userToEdit);
      adminContext.handleChangePermissionToEdit(undefined);
      adminContext.handleChangeHasProject(false);
      adminContext.handleSetIsEdition(true);
    }
  };

  const processRole = (role: string) => {
    const userGroups = updatedPermissions || groups;
    return (role === 'administrator' && userGroups.includes('administrator')) || 
      (role === 'viewer' && userGroups.includes('viewer_vip') && !userGroups.includes('administrator')) || 
      (role !== 'administrator' && userGroups.includes(role) && !userGroups.includes('administrator')) ?
      <div
        key={role}
        className={`${styles[role]}`}
      >
        {renderRole(role)}
      </div>
      : null;
  };

  const renderErrorDelete = () => {
    return <Dialog
      classes={{
        paper: styles.dialogError
      }}
      BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.15)' } }}
      PaperProps={{ sx: { borderRadius: '20px', minWidth: '700px' } }}
      open={openError}
      onClose={() => setOpenError(false)}
    >
      <div className={styles.dialogContentError}>
        <div data-testid='error-test' className={styles.serverError}>
          <div className={styles.serverErrorContent}>
            <SvgIcon component={error} inheritViewBox />
            <div id='error-title' className={styles.title}>{t('administration.errorDeleteTitle')}</div>
            <div id='error-subtitle' className={styles.subtitle}>{t('administration.errorDescription')}</div>
          </div>
        </div>
        <Stack spacing={2} direction="row" justifyContent="center">
          <Button className={styles.buttonConfirm} onClick={() => {
            setOpenError(false);
          }} disableRipple={true} variant="contained">{t('administration.acceptButton')}</Button>
        </Stack>
      </div>
    </Dialog>;
  };

  return <div className={styles.tableRow}>
    {renderErrorDelete()}
    <div className={styles.tableRowParent}>
      <div className={collapsable ? styles.nameCellCollapsable : styles.nameCell} onClick={handleClickRow}>
        <div className={styles.nameContainer}>
          {collapsable &&
            <div className={styles.nameContainerIcon}>
              {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            </div>}
          {user.nombre}
        </div>
      </div>
      <div className={styles.rolesCell}>
        <div className={styles.rolesWrapper}>
          { processRole('viewer') }
          { processRole('editor') }
          { processRole('validator') }
          { processRole('download') }
          { processRole('administrator') }
        </div>
      </div>
      <div className={styles.addRoleCell}>
        <div
          id={`addRoleBtn${index}`}
          className={styles.editBtn}
          onClick={handleEditUser}
        > + {t('administration.table.addRole')}
        </div>
      </div>
    </div>
    {collapsable && permisos &&
      <div className={styles.tableRowChild}>
        <CollapsableTable open={open} loading={loading} permissions={permisos} user={user} deleteRole={deleteRole} />
      </div>
    }
  </div>;
};
export default UsersTableRow;