import React, { FC, useContext } from 'react';
import styles from './viewDocumentation.module.scss';
import { useTranslation } from 'react-i18next';
import documentRed from '../../../../../assets/documentIconRed.svg';
import documentBlue from '../../../../../assets/documentIconBlue.svg';
import documentOrange from '../../../../../assets/documentIconOrange.svg';
import arrowGoto from '../../../../../assets/arrowGoto.svg';
import parse from 'html-react-parser';
import { AuthContext } from '../../../../../services/authContextProvider';
import { UserModeContext } from '../../../../../context/UserModeProvider';
import InformationIcon from '../../../components/informationIcon/informationIcon';

interface ViewDocumentationProps {
  data: any;
}

export const ViewDocumentation: FC<ViewDocumentationProps> = (props) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const userModeContext = useContext(UserModeContext);

  function DocumentComponent(props: any) {
    const { text, url, urlText, icon } = props;
    return (
      <div className={styles.containerDoc}>
        <img
          src={icon}
          className={styles.containerImage}
        />
        <div className={styles.containerBody}>
          <p>{text}</p>
          <p>{!url ? <b>{t('details.documentation.noInfo')}</b> : <a target='_blank' href={url} rel="noreferrer">{urlText ? urlText : t('details.documentation.goWeb')}
            <img src={arrowGoto} />
          </a>}</p>
        </div>
      </div>
    );
  }

  function MoreInfoComponent(props: any) {
    const { text, icon } = props;
    return (
      <div className={styles.containerMoreInfo}>
        <img
          src={icon}
          className={styles.containerImageMoreInfo}
        />
        <div className={styles.containerBodyMoreInfo}>
          <p>{text}</p>
        </div>
      </div>
    );
  }


  const { data } = props;

  const showAddMoreInfo = () => {
    return ((!data.webs || data.webs.length === 0) && (!data.documentacionProyecto || data.documentacionProyecto === ''));
  };

  return (
    <div className={styles.container}>
      <div className={styles.heading}>{t('details.documentation.title')}
        <InformationIcon message={t('help.description.documentationAndWeb')} />
      </div>
      <div className={styles.containerDocumentation}>
        {
          (data.documentacionFaseLicitacion && data.documentacionFaseLicitacion != '') ?
            <DocumentComponent text={t('details.documentation.bidding')} url={data.documentacionFaseLicitacion} urlText={t('details.documentation.goRepository')} icon={documentRed} />
            : false
        }
        {
          (data.documentacionProyecto && data.documentacionProyecto != '') ?
            <DocumentComponent text={t('details.documentation.execution')} url={data.documentacionProyecto} urlText={t('details.documentation.goRepository')} icon={documentRed} />
            : false
        }
        {
          (authContext.hasEditorRole() && showAddMoreInfo() && userModeContext.userMode !== 'editorDisabled') ?
            <MoreInfoComponent text={parse(t('details.documentation.moreInfo'))} icon={documentOrange} />
            : false
        }
        {
          (data.webs && data.webs.length > 0) ?
            data.webs.map((web: any, index: number) => (
              // eslint-disable-next-line react/jsx-key
              <DocumentComponent text={t('details.documentation.projectWeb')} url={web} icon={documentBlue} />
            ))
            : false
        }
      </div>
    </div >
  );
};