import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CollapsableFilter, { CollapsableFilterChildrenProps } from '../components/collapsableFilter/collapsableFilter';
import styles from './partnersFilters.module.scss';
import { AutocompleteMultipleChoice } from '../../../components/Filters/Components/AutocompleteMultichoice/AutocompleteMultichoice';
import NumberRange from '../../../components/Filters/Components/numberRangeFilters/numberRange/numberRange';
import FILTER_TYPES, { FILTER_CONFIG } from '../../../components/Filters/filterTypes';

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const PartnersFilters: FC<CollapsableFilterChildrenProps> = (props) => {
  const { open, handleClickOpen } = props;
  const [t] = useTranslation();

  return (
    <CollapsableFilter
      id='partners'
      value={t('advancedFilters.partners')}
      help={t('advancedFilters.partners')}
      handleClickOpen={handleClickOpen}
      open={open}
      advancedFilter={'partners'}
    >
      <div className={styles.partnersFiltersContainer}>
        <div className={styles.collapsableComponent}>
          <AutocompleteMultipleChoice filterType={FILTER_TYPES.namePartner} disableScroll={true} noBottomDivider={true} />
        </div>
        <div className={styles.collapsableComponent}>
          <NumberRange filterType={FILTER_TYPES.percentPartnerParticipation} defaultUnit={FILTER_CONFIG.percentage} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <NumberRange filterType={FILTER_TYPES.numberPartner} defaultUnit={FILTER_CONFIG.integer} noBottomDivider />
        </div>
      </div>
    </CollapsableFilter>
  );
};

export default PartnersFilters;
