import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './esgFilters.module.scss';
import CollapsableFilter, { CollapsableFilterChildrenProps } from '../components/collapsableFilter/collapsableFilter';
import KeywordInput from '../../../components/Filters/Components/keywordInput/keywordInput';
import FILTER_TYPES, { FILTER_CONFIG } from '../../../components/Filters/filterTypes';
import NumberRange from '../../../components/Filters/Components/numberRangeFilters/numberRange/numberRange';
import { AutocompleteMultipleChoice } from '../../../components/Filters/Components/AutocompleteMultichoice/AutocompleteMultichoice';
import { CheckSelector } from '../../../components/Filters/Components/checkSelector/checkSelector';
import { SimpleSelector } from '../../../components/Filters/Components/simpleSelector/simpleSelector';
import SelectorAndAutocomplete from '../../../components/Filters/Components/selectorAndAutocomplete/selectorAndAutocomplete';

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const EsgFilters: FC<CollapsableFilterChildrenProps> = (props) => {
  const { open, handleClickOpen } = props;
  const [t] = useTranslation();
  const [, setScrollDisabled] = useState(false);

  const disableScroll = (disable: boolean) => {
    setScrollDisabled(disable);
  };

  return (
    <CollapsableFilter
      id='esg'
      value={t('advancedFilters.esg')}
      help={t('advancedFilters.esg')}
      handleClickOpen={handleClickOpen}
      open={open}
      advancedFilter={'esg'}
    >
      <div className={styles.esgFiltersContainer}>
        <div className={styles.collapsableComponent}>
          <SimpleSelector filterType={FILTER_TYPES.eligibility} disableScroll={true} noBottomDivider={true} />
        </div>
        <div className={styles.collapsableComponent}>
          <KeywordInput filterType={FILTER_TYPES.naceCode} noBottomDivider isExactWord placeholder={t('filters.filtersPlaceHolder.code') as string}/>
        </div>
        <div className={styles.collapsableComponent}>
          <SelectorAndAutocomplete filterType={FILTER_TYPES.taxonomicActivity} noBottomDivider/>
        </div>
        <div className={styles.collapsableComponent}>
          <AutocompleteMultipleChoice filterType={FILTER_TYPES.socialSafeguards} disableScroll={disableScroll} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <SimpleSelector filterType={FILTER_TYPES.gisExemption} disableScroll={true} noBottomDivider={true} />
        </div>
        <div className={styles.collapsableComponent}>
          <SimpleSelector filterType={FILTER_TYPES.gis} disableScroll={true} noBottomDivider={true} />
        </div>
        <div className={styles.collapsableComponent}>
          <CheckSelector filterType={FILTER_TYPES.socialCategory} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <NumberRange filterType={FILTER_TYPES.gisBudget} defaultUnit={FILTER_CONFIG.amountCurrency} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <CheckSelector filterType={FILTER_TYPES.gisPercentage} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <KeywordInput filterType={FILTER_TYPES.controversies} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <NumberRange filterType={FILTER_TYPES.carbonBudget} defaultUnit={FILTER_CONFIG.amountCurrency} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <KeywordInput filterType={FILTER_TYPES.taxonomicCommitment} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <SimpleSelector filterType={FILTER_TYPES.customerDap} disableScroll={true} noBottomDivider={true} />
        </div>
        <div className={styles.collapsableComponent}>
          <SimpleSelector filterType={FILTER_TYPES.accionaDap} disableScroll={true} noBottomDivider={true} />
        </div>
      </div>
    </CollapsableFilter>
  );
};

export default EsgFilters;
