/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useContext, useEffect, useState, useRef } from 'react';
import { AlcanceEntidad, ObraProperties } from '../../../../../types/ObraDetails';
import { useTranslation } from 'react-i18next';
import { SelectOption } from '../../../../../types/Option';
import { SaveContext } from '../../../../../context/SaveProvider';
import TitleHelp from '../../../components/titleHelp/titleHelp';
import assitanceNoData from '../../../../../assets/assitanceNoData.svg';
import NoDataPicture from '../../../components/noDataPicture/noDataPicture';
import Colaborator from '../../components/colaborator/colaborator';

interface EditClientsProps {
  obra: ObraProperties;
  clientStatus: SelectOption[];
  errors?: any[]
}

const EditAssistance: FC<EditClientsProps> = (props) => {
  const { obra, clientStatus, errors } = props;
  const [t] = useTranslation();
  const saveContext = useContext(SaveContext);
  const [assistances, setAssistances] = useState(obra.cliente?.asistenciasTecnicas || []);
  const assistanceContainerRef = useRef<HTMLDivElement>(null); // Referencia al contenedor de asistencias
  const pageContainerRef = useRef<HTMLDivElement>(null); // Referencia al contenedor principal de la página

  useEffect(() => {
    const newAssistances: any = [];
    obra.cliente.asistenciasTecnicas.map((d) => newAssistances.push(d));
    setAssistances(newAssistances);
  }, [obra.cliente.asistenciasTecnicas.length]);

  const handleAddAssistance = () => {
    const newAssistance = { id: undefined, origen: 'phia', entidad: '', alcance: '', estado: { id: 0, nombre: '' } };
    const newAssistances = [...assistances, newAssistance];
    setAssistances(newAssistances);
    if (obra.cliente?.asistenciasTecnicas) {
      obra.cliente.asistenciasTecnicas.push(newAssistance);
    } else {
      obra.cliente.asistenciasTecnicas = [newAssistance];
    }

    saveContext.handleChangeSave(true);
    setTimeout(() => {
      if (pageContainerRef.current) {
        pageContainerRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      }
    }, 100);
  };

  const handleDelete = (i: number) => {
    const newAssistances = [...assistances];
    newAssistances.splice(i, 1);
    setAssistances(newAssistances);
    obra.cliente.asistenciasTecnicas.splice(i, 1);
    saveContext.handleChangeSave(true);
  };

  const handleChange = (assistances: AlcanceEntidad[]) => {
    obra.cliente.asistenciasTecnicas = assistances;
  };

  return (
    <div
      id="edit-assistance-container"
      data-testid="director-edit-test"
    >
      <TitleHelp
        id='asistenciasTecnicas'
        value={t('details.clients.assistance')}
        help={t('help.clients.assistance')}
        addItemText={t('details.clients.addNewAssistance') as string}
        handleAddItem={handleAddAssistance}
      />
      {
        assistances.length > 0 ?
          <Colaborator editable={true} clientStatus={clientStatus} handleDelete={handleDelete} colaborador={assistances} handleChangeData={handleChange} errors={errors}/>
          :
          <NoDataPicture
            id='assistance-nodata'
            picture={assitanceNoData}
            text={t('details.clients.addAssistance')}
          />
      }

      <div ref={pageContainerRef}></div>
    </div >);
};

export default EditAssistance;
