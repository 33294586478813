import React, { useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { FilterContext } from '../../../../../context/FilterProvider';
import CheckboxSelector from '../../../../checkboxSelector/checkboxSelector';
import ObrasAPI from '../../../../../services/APIObras';
import { Option } from '../../../../../types/Option';
import FILTER_TYPES from '../../../filterTypes';

interface CustomCheckSelectorProps {
  filterType: string;
  t: TFunction<'translations'>;
}
const CustomCheckSelector = ({ t, filterType }: CustomCheckSelectorProps) => {
  const filterContext = useContext(FilterContext);
  const [options, setOptions] = useState<Array<Option>>([]);

  const NO_RESULTS = [{ name: `${t('input.noResults')}` }];

  useEffect(() => {
    const api = ObrasAPI.getInstance();
    const filterTypeToApiFunction = {
      [FILTER_TYPES.projectStatus]: api.getContractStatus,
      [FILTER_TYPES.businessActivity]: api.getBusinessActivities,
      [FILTER_TYPES.spaceType]: api.getProtectedSpaces,
      [FILTER_TYPES.spaceLocation]: api.getProtectedSpacesLocations,
      [FILTER_TYPES.extinctionLevel]: api.getDangerLevels,
      [FILTER_TYPES.culturalLevel]: api.getProtectionZones,
      [FILTER_TYPES.typeContract]: api.getContractTypes,
      [FILTER_TYPES.modelContract]: api.getContractModels,
      [FILTER_TYPES.scopeContract]: api.getContractScope,
      [FILTER_TYPES.formPayment]: api.getFormPayment,
      [FILTER_TYPES.sourceFinancing]: api.getSourceFinance,
      [FILTER_TYPES.maturityLevel]: api.getMaturityLevel,
      [FILTER_TYPES.categorization]: api.getCategorization,
      [FILTER_TYPES.requirements]: api.getRequirements,
      [FILTER_TYPES.taxonomicActivity]: api.getTaxonomicObjectives,
      [FILTER_TYPES.socialCategory]: api.getSocialCategories,
      [FILTER_TYPES.gisPercentage]: api.getGISPercentage,
      [FILTER_TYPES.typesBimApplications]: api.getTypeBimApplicationsMasters,
    };
    const promise = filterTypeToApiFunction[filterType] ? filterTypeToApiFunction[filterType]() : api.getContractStatus();

    promise.then(
      (results) => {
        results = results.sort((a: { id: string; },b: { id: any; }) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
        results = results.length ? results : NO_RESULTS;
        setOptions(results);
      }
    ).catch((error) => {
      console.log(error);
      setOptions([]);
    });
  }, []);

  return (
    <>
      <CheckboxSelector
        filterType={filterType}
        options={options}
        filterContext={filterContext}
        placeholder={t('filters.filtersPlaceHolder.select') as string}
      />
    </>
  );
};

export default withTranslation()(CustomCheckSelector);