import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ObraProperties } from '../../../types/ObraDetails';
import styles from './classification.module.scss';
import { SaveContext } from '../../../context/SaveProvider';
import AddItemButton from '../components/addItemButton/addItemButton';
import DataGroup from './dataGroup/dataGroup';
import technicalNoData from '../../../assets/technicalNoData.svg';
import { UserModeContext } from '../../../context/UserModeProvider';
import { AuthContext } from '../../../services/authContextProvider';
import { EDITOR } from '../../../userRoles/userRoles';
import NoDataPicture from '../components/noDataPicture/noDataPicture';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ClassificationProps {
  obra: ObraProperties;
  errors?: any[];
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const Classification: FC<ClassificationProps> = (props) => {
  const { obra, errors } = props;
  const [t] = useTranslation();
  const saveContext = useContext(SaveContext);
  const [groups, setGroups] = useState(obra.clasificacion?.grupos || []);
  const userModeContext = useContext(UserModeContext);
  const authContext = useContext(AuthContext);
  const isEditorMode = userModeContext.userMode === 'editor';

  // adds a new worktype registry with empty data
  const handleAddItem = () => {
    const newGroup = { origen: 'phia', importeEstimado: undefined, importeFinal: undefined };
    setGroups([...groups, newGroup]);
    if (obra.clasificacion?.grupos) {
      obra.clasificacion.grupos.push(newGroup);
    } else {
      obra.clasificacion.grupos = [newGroup];
    }
    saveContext.handleChangeSave(true);
  };

  return (
    <div className={styles.containerGroup} data-testid="classification-test">
      <div className={styles.addBtnContainer}>
        {isEditorMode &&
          <div className={styles.addBtn}>
            <AddItemButton
              addItemText={t('details.classification.add') as string}
              handleAddItem={handleAddItem}
            />
          </div>
        }
      </div>
      {
        groups.length === 0 ?
          <NoDataPicture
            id='groups-noDataAdded'
            picture={technicalNoData}
            text={
              isEditorMode
                ? t('details.classification.editorNoDataAdded')
                : (authContext.hasEditorRole()
                  ? (userModeContext.userMode === 'editorDisabled' ?
                    t('details.classification.noDataAdded') + '. ' + t('details.validation.noEditMode')
                    : t('details.classification.editorChangeNoDataAdded'))
                  : t('details.classification.noDataAdded')
                )
            }
          />
          :
          <>
            <table>
              {
                groups.sort(
                  (a,b) => (a.origen > b.origen) ? 1: ((b.origen > a.origen) ? -1 : 0)
                ).map((group: any, index) => {
                  const handleDelete = () => {
                    obra.clasificacion.grupos.splice(index, 1);
                    setGroups([...obra.clasificacion.grupos]);
                    saveContext.handleChangeSave(true);
                  };
                  return group.origen === 'cecoc'
                    ? <DataGroup key={index} obra={obra} group={group} index={index} errors={errors} />
                    : <DataGroup key={index} obra={obra} group={group} index={index} handleDelete={handleDelete} errors={errors} />;
                })
              }
            </table>
            {
              groups.filter((group) => group.origen !== 'cecoc').length === 0
                && authContext.hasEditorRole()
                ? <NoDataPicture
                  id='groups-noPhiaDataAdded'
                  picture={technicalNoData}
                  text={isEditorMode
                    ? t('details.classification.noPhiaDataAdded')
                    : t('details.classification.noPhiaDataAddedChangeMode')}
                />
                : null
            }
          </>
      }
      <div className='footer' style={{marginBottom: '75px'}}></div>
    </div>
  );
};

export default Classification;
