/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC } from 'react';
import { SvgIcon } from '@mui/material';
import stylesDefault from './InfoBoxBoolean.module.scss';
import parse from 'html-react-parser';
import { ReactComponent as checkTrue } from '../../../../assets/check_true.svg';
import { ReactComponent as checkFalse } from '../../../../assets/check_false.svg';

interface Field {
  title: string;
  value: string;
}


interface InfoBoxBooleanProps {
  item: Field;
  identifier?: any;
  value: boolean;
  iconFalse?: any,
  iconTrue?: any,
  styles?: any;
  positionIcon?: string;
}

const InfoBoxBoolean: FC<InfoBoxBooleanProps> = (props) => {
  const { item, identifier, value, styles, iconFalse, iconTrue, positionIcon } = props;

  return (
    <div className={styles.box + ' ' + stylesDefault?.box}>
      {
        positionIcon === 'left' ?
          <div className={styles.check + ' ' + stylesDefault?.check}>
            <SvgIcon component={value ? iconTrue || checkTrue : iconFalse || checkFalse} inheritViewBox />
          </div>
          : null
      }
      <div className={styles.field + ' ' + stylesDefault?.field} >
        <div id={`${identifier}-title`} className={styles.title + ' ' + stylesDefault?.title}>{parse(item?.title)}</div>
        <div id={`${identifier}-value`} className={styles.value + ' ' + stylesDefault?.value}>{item?.value}</div>
      </div>
      {
        !positionIcon || positionIcon === 'right' ?
          <div className={styles.check + ' ' + stylesDefault?.check}>
            <SvgIcon component={value ? iconTrue || checkTrue : iconFalse || checkFalse} inheritViewBox />
          </div>
          : null
      }
    </div >
  );
};

export default InfoBoxBoolean;
