import React from 'react';
import { Popper } from '@mui/material';
import './customPopper.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomPopper = (props: any) => {
  return (
    <Popper
      {...props}
      className='root'
      placement='bottom'

    // style={{ height: 0 }}
    />);
};
