/* eslint-disable @typescript-eslint/no-explicit-any */
import { v4 as uuidv4 } from 'uuid';

import React, { ReactElement, useContext } from 'react';

import { withTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as document } from '../../../../../assets/document.svg';

import Chip from '@mui/material/Chip';

import { TFunction } from 'i18next';
import { Button, Tooltip, Typography } from '@mui/material';
import { FilterContext } from '../../../../../context/FilterProvider';
import { FilterValue, Mode } from '../../../../../types/FiltersTypes';
import styles from './tags.module.scss';

interface TagsProps {
  filterType: string,
  tags: FilterValue[],
  debounceUnSelectHandler: any,
  handleClickButton: any,
  buttonText: string,
  tagsDisabled: any,
  children?: JSX.Element;
  t: TFunction<'translations'>;
}
const Tags = (props: TagsProps) => {
  const {
    t,
    tags,
    filterType,
    handleClickButton,
    buttonText,
    tagsDisabled,
  } = props;
  const filterContext = useContext(FilterContext);
  const Icon: ReactElement = <SvgIcon component={document} inheritViewBox />;

  const unSelectHandler = (value: FilterValue) => {
    filterContext.handleChange(filterType, value, Mode.DELETE);
  };

  const buttonDialogOpen = <Button
    size={'small'}
    className='rootGreyTags'
    onClick={handleClickButton}
  >
    {t(`button.${buttonText}`)}
  </Button>;

  let tagsByStyleChipsType = tags.map((v: FilterValue) => {
    if (v && v.name && v.name.length > 0) {
      return (
        <Tooltip
          key={uuidv4()}
          enterDelay={2000}
          title={
            <Typography
              style={{ padding: '0.625rem' }}
            >
              {v.name}
            </Typography>
          }
          followCursor>
          < Box mb={1}
            key={uuidv4()}
            className={styles.tag}
          >
            {
              <Chip
                size='small'
                icon={Icon}
                key={v.code}
                label={v.name.length > 60 ? `${v.name.slice(0, 60)}...` : v.name}
                onDelete={() => unSelectHandler(v)}
              />

            }
          </ Box >
        </Tooltip>
      );
    }
    return null;
  });

  tagsByStyleChipsType = handleClickButton
    ? [...tagsByStyleChipsType, buttonDialogOpen]
    : tagsByStyleChipsType;

  return (
    < Box
      sx={{
        ml: 1.6,
        mt: 'inherit',
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(:last-child)': { mr: 1 },
        '& > *': { mr: 1 },
      }}
    >
      {!tagsDisabled && tagsByStyleChipsType}
    </Box >);
};

export default withTranslation()(Tags);
