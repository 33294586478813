import React, { FC, useContext, useEffect } from 'react';
import { AlcanceEntidad, ObraProperties } from '../../../../../types/ObraDetails';
import { SelectOption } from '../../../../../types/Option';
import { useTranslation } from 'react-i18next';
import { SaveContext } from '../../../../../context/SaveProvider';
import TitleValue from '../../../components/titleValue/titleValue';
import styles from './colaborator.module.scss';
import SimpleSelect from '../../../components/simpleSelect/simpleSelect';
import DeleteButton from '../../../components/deleteButton/deleteButton';
import moment from 'moment';
import { UserModeContext } from '../../../../../context/UserModeProvider';

interface ColaboradorProps {
  editable: boolean,
  clientStatus?: SelectOption[],
  handleDelete?: (i: number) => void,
  colaborador: AlcanceEntidad[],
  handleChangeData?: (colab: AlcanceEntidad[]) => void,
  obra?: ObraProperties
  errors?: any[]
}

const Colaborator: FC<ColaboradorProps> = (props) => {
  const { editable, clientStatus, handleDelete, colaborador, handleChangeData, obra, errors } = props;
  const [t] = useTranslation();
  const saveContext = useContext(SaveContext);
  const [designerRows, setDesignerRows] = React.useState<AlcanceEntidad[]>(colaborador || []);
  const [minDate, setMinDate] = React.useState<any>();
  let finalValidated = false;

  const userModeContext = useContext(UserModeContext);
  const isViewMode = userModeContext.userMode !== 'editor';
  const isEditable = editable && !isViewMode;

  if (obra && obra?.general.codEstadoPhia == 8) {
    finalValidated = true;
  }

  useEffect(() => {
    const newDesigners: AlcanceEntidad[] = [];
    colaborador.map((d) => newDesigners.push(d));
    setDesignerRows(newDesigners);
  }, [colaborador]);

  const handleChange = (data: any, i: number, field: string) => {
    if (field === 'fecInicioColaboracion' || field === 'fecFinColaboracion') {
      data = data ? moment.utc(data, 'DD/MM/YYYY') : data;
      if (field === 'fecInicioColaboracion') {
        setMinDate(data);
        const newDesigners: AlcanceEntidad[] = colaborador.map(d => ({ ...d }));
        setDesignerRows(newDesigners);
        if (colaborador[i].fecFinColaboracion && data > moment.utc(colaborador[i].fecFinColaboracion, 'DD/MM/YYYY')) {
          handleChange('', i, 'fecFinColaboracion');
          const newDesigners: AlcanceEntidad[] = colaborador.map(d => ({ ...d }));
          setDesignerRows(newDesigners);
        }
      }
    }
    colaborador[i] = { ...colaborador[i], [field]: data };
    saveContext.handleChangeSave(true);

    if (field === 'estado') {
      const date = data.id === 1 ? undefined : new Date();
      handleChange(date, i, 'fecFinColaboracion');
      const newDesigners: AlcanceEntidad[] = [];
      colaborador.map((d) => newDesigners.push(d));
      setDesignerRows(newDesigners);
    }
    handleChangeData && handleChangeData(colaborador);
  };

  return <div className={styles.designerContainer}> {
    designerRows.map((e, i) => {
      const defaultValue = !editable ? '-' : '';
      const defaultValueEntity = !editable ? (e.origen === 'cecoc' ? t('details.notInformed') : '-') : '';
      const valueFecInicio = e.fecInicioColaboracion || defaultValue;
      const valueFecFin = finalValidated ? obra?.fechasPlazos.fecFinalizacionActualizada : (e.estado.id === 2 ? e.fecFinColaboracion || '-' : '-');

      const error: any = errors?.filter(error => parseInt(error.index) === i);
      return <div
        data-testid='colaborador-test'
        key={`${e.entidad}-${i}`}
        className={`${styles.designerRow} ${e.origen === 'cecoc' ? styles.cecocDesigner : styles.phiaDesigner}`}
      >
        <div className={isEditable || e.origen !== 'cecoc' ? styles.grid : styles.gridCecoc}>
          <div className={styles.rangeColab}>
            <TitleValue
              identifier='range-colab'
              title={t('details.clients.range')}
              value={e.alcance || defaultValue}
              editable={editable}
              handleChange={(data) => handleChange(data, i, 'alcance')}
              valueMaxLength={255}
            />
          </div>
          <div className={styles.entityColab}>
            <TitleValue
              identifier='entity-colab'
              title={t('details.clients.entity')}
              value={e.entidad || defaultValueEntity}
              error={error && error?.filter((e: any) => e.fieldField === 'entidad').length > 0 && !(e.entidad || defaultValueEntity) && e.alcance}
              editable={editable && e.origen !== 'cecoc'}
              handleChange={(data) => handleChange(data, i, 'entidad')}
              valueMaxLength={25}
            />
          </div>
          <div className={styles.dateColab}>
            {e.origen !== 'cecoc' &&
              <div className={styles.dateColabInit}>
                <TitleValue
                  type='date'
                  maxDate={new Date()}
                  minDate={null}
                  handleChange={(data) => handleChange(data, i, 'fecInicioColaboracion')}
                  editable={editable}
                  identifier='date-initial-colab'
                  title={t('details.clients.initialDate')}
                  value={valueFecInicio}
                  error={error && error?.filter((e: any) => e.fieldField === 'fecInicioColaboracion').length > 0 && (!valueFecInicio || valueFecInicio === defaultValue)}
                  startIcon />
              </div>}
            <div className={styles.dateColabStatus}>
              <SimpleSelect
                id={`clients-designer-status-${i}`}
                options={clientStatus || []}
                value={e?.estado}
                label={t('details.clients.status')}
                handleChangeValue={(data: any) => handleChange(data, i, 'estado')}
                error={error && error?.filter((e: any) => e.fieldField === 'estado').length > 0 && !e.estado?.nombre}
                editable={editable} />
            </div>
            <div className={styles.dateColabFinal}>
              <TitleValue
                key={Math.random()}
                type='date'
                maxDate={null}
                minDate={minDate ? minDate : valueFecInicio}
                handleChange={(data) => handleChange(data, i, 'fecFinColaboracion')}
                editable={editable && !!(e.estado.id === 2)}
                identifier='date-final-colab'
                title={t('details.clients.finalDate')}
                value={valueFecFin}
                startIcon />
            </div>
            {
              editable && e.origen !== 'cecoc' &&
              // <div item xs={1}>
              <div className={styles.delete}>
                <DeleteButton id={`deleteBtn${i}`} onClick={() => handleDelete && handleDelete(i)} />
              </div>
            }
          </div>
        </div>
      </div>;
    })
  }
  </div >;

};

export default Colaborator;
