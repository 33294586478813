/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FC, useContext } from 'react';
import styles from './contract.module.scss';
import { ObraProperties } from '../../../types/ObraDetails';
import ContractInfo from './components/contractInfo';
import InfoBox from '../components/infoBox/infoBox';
import fundingPicture from '../../../assets/funding.svg';
import contractPicture from '../../../assets/contract.svg';
import statePicture from '../../../assets/state.svg';
import guaranteePicture from '../../../assets/guarantee.svg';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../services/authContextProvider';
import InformationIcon from '../components/informationIcon/informationIcon';
import { VIEWER_VIP } from '../../../userRoles/userRoles';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ContractProps {
  obra: ObraProperties
}

const Contract: FC<ContractProps> = (props) => {
  const { obra } = props;
  const {t} = useTranslation();
  const authContext = useContext(AuthContext);
  const valueGuarantee = (obra.contrato.periodoGarantia && obra.contrato.periodoGarantia !== '' && obra.contrato.periodoGarantia !== '0') ?
    `${obra.contrato.periodoGarantia} ${obra.contrato.periodoGarantia === '1' ? t('dateMeasures.month') : t('dateMeasures.months')}`
    : t('details.notInformed');

  const isViewer = !authContext.hasRole(VIEWER_VIP) && !authContext.hasEditorRole();
  return (<div className={styles.contractContainer}>
    <div id='contract' className={styles.heading}>{t('details.contract.tab')}
      <InformationIcon message={t('help.contract.contract')} />
    </div>
    <div className={styles.boxes}>
      <div className={styles.boxContainer}>
        <ContractInfo
          identifier='contract-properties'
          picture={contractPicture}
          tipo={{ title: t('details.contract.type'), value: obra.contrato.tipos?.length > 0 ? obra.contrato.tipos.join(' / ') : t('details.notInformed') }}
          modelo={{ title: t('details.contract.model'), value: obra.contrato.modelo?.length > 0 ? obra.contrato.modelo.join(' / ') : t('details.notInformed') }}
          alcance={{ title: t('details.contract.range'), value: obra.contrato.alcance?.length > 0 ? obra.contrato.alcance.join(' / ') : t('details.notInformed') }}
          pago={{ title: t('details.contract.payment'), value: obra.contrato.pago?.length > 0 ? obra.contrato.pago.join(' / ') : t('details.notInformed') }} />
      </div>
      <div className={styles.boxContainer}>
        <div className={styles.boxRow}>
          <div className={isViewer ? styles.stateBox : styles.boxContainerChild}>
            <InfoBox identifier='funding' picture={fundingPicture} item={{ title: t('details.contract.funding'), value: (obra.contrato.fuentesFinanciacion?.length > 0) ? obra.contrato.fuentesFinanciacion.join(' / ') : t('details.notInformed') }} />
          </div>
          {!isViewer &&
            <div className={styles.boxContainerChild}>
              <InfoBox identifier='guarantee' picture={guaranteePicture} item={{ title: t('details.contract.guarantee'), value: valueGuarantee}} />
            </div>
          }
        </div>
        <div className={styles.boxRow}>
          <div className={styles.stateBox}>
            <InfoBox identifier='state' picture={statePicture} item={{ title: t('details.contract.state'), value: obra.contrato.nomEstado || t('details.notInformed') }} />
          </div>
        </div>
      </div>
    </div>
    <div className='footer' style={{marginBottom: '75px'}}></div>
  </div>);
};

export default Contract;
