import { useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { FilterContext } from '../../../../../context/FilterProvider';
import SimpleSelector from '../../../../simpleSelector';
import ObrasAPI from '../../../../../services/APIObras';
import { Option } from '../../../../../types/Option';
import FILTER_TYPES from '../../../filterTypes';

interface CustomCheckSelectorProps {
  filterType: string;
  t: TFunction<'translations'>;
}
const CustomSimpleSelector = ({ t, filterType }: CustomCheckSelectorProps) => {
  const filterContext = useContext(FilterContext);
  const [options, setOptions] = useState<Array<Option>>([]);

  const NO_RESULTS = [{ name: `${t('input.noResults')}` }];

  useEffect(() => {
    const api = ObrasAPI.getInstance();
    const getPromise = () => {
      switch (filterType) {
        case FILTER_TYPES.clientDesignerStatus:
        case FILTER_TYPES.workManagementStatus:
        case FILTER_TYPES.technicalAssistanceStatus:
          return api.getClientStatusMasters();
        case FILTER_TYPES.cde:
          return api.getCDE(t);
        default:
          return api.getContractStatus();
      }
    };
  
    if ([
      FILTER_TYPES.eligibility,
      FILTER_TYPES.gisExemption,
      FILTER_TYPES.gis,
      FILTER_TYPES.accionaDap,
      FILTER_TYPES.customerDap,
      FILTER_TYPES.cde,
    ].includes(filterType)) {
      setOptions([
        { code: 'true', name: t('filters.yes') },
        { code: 'false', name: t('filters.no') }
      ]);
    } else {
      getPromise()
        .then((results) => {
          results.sort((a: { id: string; }, b: { id: string; }) =>  a.id.toString().localeCompare(b.id.toString()));
          setOptions(results.length ? results : NO_RESULTS);
        })
        .catch((error) => {
          console.log(error);
          setOptions([]);
        });
    }
  }, []);

  return (
    <>
      <SimpleSelector
        filterType={filterType}
        options={options}
        filterContext={filterContext}
        placeholder={t('filters.filtersPlaceHolder.select') as string}
      />
    </>
  );
};

export default withTranslation()(CustomSimpleSelector);