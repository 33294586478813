/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useState,
  useCallback,
  useContext,
} from 'react';
import { v4 as uuidv4 } from 'uuid';

import debounce from 'lodash.debounce';

import { withTranslation } from 'react-i18next';

import Autocomplete from '@mui/material/Autocomplete';

import AutocompleteList from './AutocompleteList/AutocompleteList';
import AutocompleteTextField from './AutocompleteTextField/AutocompleteTextField';
import ObrasAPI from '../../../../../services/APIObras';
import { TFunction } from 'i18next';
import { FilterContext } from '../../../../../context/FilterProvider';
import { Mode } from '../../../../../types/FiltersTypes';
import FILTER_TYPES from '../../../../../components/Filters/filterTypes';

const DOUBLE_SELECTOR_FILTERS = [
  FILTER_TYPES.classificationEstimatedAmount,
  FILTER_TYPES.classificationFinalAmount,
  FILTER_TYPES.measurement,
  FILTER_TYPES.workTypeTechnicalAspects,
  FILTER_TYPES.taxonomicActivity
];

interface Option {
  name: string,
}
interface CustomAutocompleteProps {
  filterType: string,
  handleSelect?: (newValue: Option) => any;
  t: TFunction<'translations'>;
}
const CustomAutocomplete = (
  props: CustomAutocompleteProps,
) => {
  const { t, filterType, handleSelect } = props;
  const filterContext = useContext(FilterContext);
  const tags = filterContext.getTags(filterType);

  const NO_RESULTS = [{ name: `${t('input.noResults')}` }];

  const minCharaters = 3;


  const [options, setOptions] = useState<Array<Option>>([]);
  const [textFieldValue, setTextFieldValue] = useState('');
  const [openModal, setOpenModal] = useState(true);

  const inputRequest = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length >= minCharaters) {

      try {
        const api = ObrasAPI.getInstance();
        let results;
        if(filterType === 'supercenter'){
          results = await api.getAutocompleteMastersUrl(filterType, e.target.value);
        } else if (filterType === FILTER_TYPES.workTypeTechnicalAspects) {
          results = await api.getAutocompleteWorkSubtypesByName(e.target.value);
        }else if(filterType === FILTER_TYPES.classificationEstimatedAmount || filterType === FILTER_TYPES.classificationFinalAmount){
          results = await api.getAutocompleteGroupsClassificationByName(e.target.value);
        } else {
          let filterParam;
          if (filterType === FILTER_TYPES.measurement) filterParam = FILTER_TYPES.workUnit;
          results = await api.getAutocompleteUrl(filterParam || filterType, e.target.value);
        }
        results = results.length ? results : NO_RESULTS;
        setOpenModal(true);
        return setOptions(results);
      } catch (error) {
        console.log(error);
      }
    }
    return setOptions([]);
  };

  const debounceInputHandler = useCallback(
    debounce(inputRequest, 500),
    [],
  );

  return (
    <Autocomplete
      size="medium"
      freeSolo={true}
      open={openModal}
      renderTags={() => null}
      disableClearable
      data-testid={t(`filters.${filterType}`)}
      id={`${t(`filters.${filterType}`)}`}
      inputValue={textFieldValue}
      options={options}
      getOptionLabel={(option: any) => option.name}
      isOptionEqualToValue={(option, valueToEqual) => option.name === valueToEqual.name}
      filterOptions={(option) => option}
      getOptionDisabled={(option: Option) => option.name === t('input.noResults')}
      style={
        {
          padding: `0.75rem 0.75rem ${filterType === FILTER_TYPES.localCurrency ? '0.75rem' : '0'} 0.75rem`,
        }
      }
      value={tags[0]}
      onBlur={() => setOpenModal(false)}
      onFocus={() => setOpenModal(true)}
      renderOption={(props, option: any, { selected }) => (
        <AutocompleteList
          {...props}
          key={option.code || uuidv4()}
          props={{...props, disabled: option.name === t('input.noResults')}}
          option={option}
          selected={!!tags.find((o) => o.code === option.code)}
          clickOption={() => {
            if(option.name === t('input.noResults')) return;
            const elements = option.elements?.map((el: any) => ({ code: el.id || el.codigo, name: el.nombre }));
            filterContext.handleChange(filterType, { name: option.name, code: option.code, elements }, Mode.REPLACE);
            if (DOUBLE_SELECTOR_FILTERS.includes(filterType) && handleSelect) handleSelect(option);
            setTextFieldValue(filterType === FILTER_TYPES.localCurrency ? '' : option.name);
            setOpenModal(false);
          }}
        />
      )}
      renderInput={(params) => (
        <AutocompleteTextField
          filterType={filterType}
          params={params}
          debounceInputHandler={debounceInputHandler}
          textFieldValue={textFieldValue}
          setTextFieldValue={setTextFieldValue}
          setOptions={setOptions}
          keepTextOnBlur
        />
      )}
    />
  );
};

export default withTranslation()(CustomAutocomplete);
