import React from 'react';
import { FilterCollapsableWrapper } from '../../FiltersWrappers/FilterCollapsableWrapper/FilterCollapsableWrapper';
import CustomCheckSelector from './customCheckSelector/customCheckSelector';

export const CheckSelector = ({ filterType,  noBottomDivider }: any) => {
  return (
    <>
      <FilterCollapsableWrapper
        filterType={filterType}
        noBottomDivider={noBottomDivider}
      >
        <CustomCheckSelector filterType={filterType} />
      </FilterCollapsableWrapper>
    </>
  );
};