import { useContext, useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import { FilterContext } from '../../context/FilterProvider';
import styles from './tagsOverlay.module.scss';
import { useTranslation } from 'react-i18next';
import { OVERLAY_FILTERS } from '../Filters/filterTypes';
import { Box, Tooltip, Typography } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TagsOverlayProps { }

const TagsOverlay = (props: TagsOverlayProps) => {
  const filterContext = useContext(FilterContext);
  const [t] = useTranslation();

  const filtersTags: any = [];
  for (const filter of OVERLAY_FILTERS) {
    const tag = filterContext.getAppliedTags(filter);
    if (tag.length > 0) {
      filtersTags.push({ tags: tag, key: filter});
    }
  }

  return (
    <div>
      <div className={styles.tagsContainer}>
        { filtersTags.map((filter: any) => { 
          return filter.tags.map((tag:any, i: number) => {
            const tagOverlayKey = [
              'measurement',
              'workTypeTechnicalAspects',
              'classificationEstimatedAmount',
              'classificationFinalAmount'
            ].includes(filter?.key) ? '' : `${t(`tagsOverlay.${filter?.key}`)}: `;
            const tagOverlay = `${tagOverlayKey}${tag.name.replace(/:/g, '')}`;
            return <Tooltip
              enterDelay={1000}
              key={filter?.key + i}
              title={
                <Typography
                  style={{ padding: '0.625rem' }}
                >
                  {tagOverlay}
                </Typography>
              }
              followCursor>
              < Box mb={1}>
                {
                  <Chip
                    style={{
                      backgroundColor: '#FFFFFF',
                      borderRadius: '16px',
                      padding: '4px 8px 4px 12px',
                      color: '#000000',
                      fontFamily: 'Roboto',
                      fontWeight: 400,
                      fontSize: '15px',
                      lineHeight: '24px',
                      letterSpacing: '0.5px',
                      width: 'fit-content',
                      margin: '0 8px 8px 0',
                      maxWidth: 600,
                    }}
                    label={tagOverlay} // Update translations.json if a new key is added
                    onDelete={() => {
                      filterContext.handleRemoveAppliedFilterTag(tag, filter.key);
                    }}
                  />
                }
              </Box>
            </Tooltip>;
          });
        })}
      </div>
    </div>
  );
};

export default TagsOverlay;