import { Collapse } from '@mui/material';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowIcon } from '../../assets/collapse-arrow.svg';
import { AdvancedFilter, FilterContext } from '../../context/FilterProvider';
import styles from './collapsableField.module.scss';

interface CollapsableFieldProps {
  id?: string,
  children: JSX.Element,
  title: JSX.Element,
  open?: boolean,
  handleClickOpen?: (newVal: number) => void,
  advancedFilter?: AdvancedFilter,
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const CollapsableField: FC<CollapsableFieldProps> = (props) => {
  const { id, children, title, open, handleClickOpen, advancedFilter } = props;
  const [isOpen, setIsOpen] = useState<boolean>(open || false);
  const [t] = useTranslation();
  const filterContext = useContext(FilterContext);
  const [appliedFiltersCount, setAppliedFiltersCount] = useState(advancedFilter ? filterContext.getFieldsCountByFilter(advancedFilter) : 0);

  useEffect(() => {
    setIsOpen(open || false);
  }, [open]);

  useEffect(() => {
    setAppliedFiltersCount(advancedFilter ? filterContext.getFieldsCountByFilter(advancedFilter) : 0);
  }, [filterContext.filters]);

  const handleClick = () => {
    if (handleClickOpen) handleClickOpen(isOpen ? 0 : 1);
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.collapsableContainer}>
      <div className={styles.collapsableTitleWrapper}>
        <div className={styles.collapsableTitle} onClick={() => handleClick()}>
          <div id={`${id}-arrowBtn`} role='button' className={isOpen ? styles.arrowBtnRotate : styles.arrowBtn}>
            {<ArrowIcon />}
          </div>
          <div id={`${id}-title`} className={styles.title}>
            {title}
          </div>
          {appliedFiltersCount > 0 ? <div className={styles.appliedFilters}>{appliedFiltersCount}</div> : null}
        </div>
        { advancedFilter &&
          <div
            className={appliedFiltersCount > 0 ? styles.deleteFilters : styles.deleteFiltersDisabled}
            onClick={() => {
              if (appliedFiltersCount > 0) filterContext.clearFiltersByType(advancedFilter);
            }}
          >
            {t('advancedFilters.collapsibleDelete')}
          </div>
        }
      </div>
      <Collapse
        in={isOpen}
        timeout="auto"
        unmountOnExit
      >
        {children}
      </Collapse>
    </div>
  );
};

export default CollapsableField;
