import React, { useEffect, useState, type FC, useContext } from 'react';
import ReactGA from 'react-ga4';
import moment from 'moment';
import Header from '../../components/header/header';
import TabMenu from '../../components/tabMenu/tabMenu';
import { useParams, useNavigate} from 'react-router-dom';
import ObrasAPI from '../../services/APIObras';
import { useTranslation } from 'react-i18next';
import GeneralData from './generalData/generalData';
import './fullDetail.scss';
import DescriptionPhoto from './descriptionPhoto/descriptionPhoto';
import Dates from './dates/dates';
import Efficiency from './efficiency/efficiency';
import HumanTeam from './humanTeam/humanTeam';
import LocationEnvironment from './localizationEnvironment/localizationEnvironment';
import { DetailsViewContext } from '../../context/DetailsViewProvider';
import { UserModeContext } from '../../context/UserModeProvider';
import Budget from './budget/budget';
import Contract from './contract/contract';
import Partners from './partners/partners';
import TechnicalAspects from './technicalAspects/technicalAspects';
import ErrorDetails from './components/ErrorDetails/ErrorDetails';
import LoadingSpinner from '../../components/loadingSpinner/loadingSpinner';
import Clients from './clients/clients';
import Classification from './classification/classification';
import Bim from './bim/bim';
import { AuthContext } from '../../services/authContextProvider';
import { SaveContext } from '../../context/SaveProvider';
import draftValidation, { draftValidationFinal } from '../../validations/draft';
import ESG from './esg/esg';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface HomeProps { 
  tab: number;
}

export const tabMap = {
  'general': 0,
  'descripcion': 1,
  'localizacion': 2,
  'presupuesto': 3,
  'fechas': 4,
  'contrato': 5,
  'cliente': 6,
  'socios': 7,
  'aspectos-tecnicos': 8,
  'esg': 9,
  'bim': 10,
  'clasificacion': 11,
  'rendimientos': 12,
  'equipo-humano': 13,
};

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const FullDetail: FC<HomeProps> = (props) => {
  const { codCentro } = useParams<string>();
  const { tab } = props;
  const [t, i18n] = useTranslation();
  const userModeContext = useContext(UserModeContext);
  const {obra, setObra} = useContext(DetailsViewContext);
  const [selectedTab, setSelectedTab] = useState(tab);
  const [isMapLoaded, setMapLoaded] = useState(false);
  const [activateErrors, setActivateErrors] = useState(false);
  const [numberError, setNumberError] = useState(0);
  const authContext = useContext(AuthContext);
  const defaultMode = authContext.defaultMode();
  const [changeMode, setUserMode] = useState(userModeContext.userMode?.toString() || defaultMode);
  const [loadError, setLoadError] = useState<any>();
  const saveContext = useContext(SaveContext);
  const navigate = useNavigate();

  const getProjectFromAPI = () => {
    // eslint-disable-next-line no-async-promise-executor
    const api = ObrasAPI.getInstance();
    Promise.all([api.getObraFullDetailsAPI(codCentro!, changeMode === 'editor' || changeMode === 'editorDisabled' ? 'editor' : 'viewer'), api.getRoles(codCentro!)]).then(([project, roles]) => {
      authContext.setRoles(roles);
      setObra(project);
    }).catch((e) => {
      setLoadError({ general: { codCentro: codCentro || '' } });
    });
  };

  /**
   * Only show errors when it's edition mode and button send to validate it's clicked
   * or one month left for end of the project and the status is not validated final
   * @returns true, show errors in the components
   */
  const hasToShowErrors = () => {
    const isFinalValidated = obra?.general?.codEstadoPhia === 8;
    return userModeContext.userMode === 'editor' && (saveContext.isValidation || (!isFinalValidated && oneMonthLeft()));
  };

  /**
   * Check if one month left for the end of the project
   * @returns
   */
  const oneMonthLeft = () => {
    let isOnTime = false;
    if (obra) {
      const finishTime = obra.fechasPlazos.fecFinalizacionActualizada ?
        moment(obra.fechasPlazos.fecFinalizacionActualizada, 'DD/MM/YYYY') :
        moment(obra.fechasPlazos.fecFinalizacionInicial, 'DD/MM/YYYY');
      isOnTime = moment().isAfter(finishTime.subtract(30, 'days'));
    }
    return isOnTime;
  };

  const options = [
    { tabLabel: '', title: t('details.generalData.tab'), component: <GeneralData obra={obra!} /> },
    { tabLabel: 'descripcion', title: t('details.description.tab'), component: <DescriptionPhoto obra={obra!} /> },
    { tabLabel: 'localizacion', title: t('details.location.tab'), component: <LocationEnvironment obra={obra!} /> },
    { tabLabel: 'presupuesto', title: t('details.budget.tab'), component: <Budget obra={obra!} /> },
    { tabLabel: 'fechasPlazos', title: t('details.dates.tab'), component: <Dates obra={obra!} /> },
    { tabLabel: 'contrato', title: t('details.contract.tab'), component: <Contract obra={obra!} /> },
    { tabLabel: 'cliente', title: t('details.clients.tab'), component: <Clients obra={obra!} /> },
    { tabLabel: 'socios', title: t('details.partners.tab'), component: <Partners obra={obra!} /> },
    { tabLabel: 'aspectosTecnicos', title: t('details.technical.tab'), component: <TechnicalAspects obra={obra!} userMode={changeMode || 'viewer'} /> },
    { tabLabel: 'esg', title: t('details.esg.tab'), component: <ESG obra={obra!} /> },
    { tabLabel: 'bim', title: t('details.bim.tab'), component: <Bim obra={obra!} /> },
    { tabLabel: 'clasificacion', title: t('details.classification.tab'), component: <Classification obra={obra!} /> },
    { tabLabel: '', title: t('details.efficiency.tab'), component: <Efficiency /> },
    { tabLabel: '', title: t('details.humanTeam.tab'), component: <HumanTeam /> },
  ];
  // call only the first time
  useEffect(() => {
    setLoadError(undefined);
    setObra(undefined);
    saveContext.handleValidationClicked(false);
    getProjectFromAPI();
    setTitles(options.map((o) => (o.title)));
    setParsedOptions(options.map((o) => ({ ...o, error: false })));
  }, [userModeContext.userMode, changeMode, i18n.language, saveContext.reloadData ]);

  useEffect(() => {
    setActivateErrors(hasToShowErrors());
  }, [saveContext.isValidation, obra]);

  useEffect(() => {
    if (activateErrors) {
      checkError();
    } else {
      setNumberError(0);
    }
  }, [activateErrors]);

  const changeModeUser = (value: string) => {
    setObra(undefined);
    setUserMode(value);
  };

  useEffect(() => {
    const tab = window.location.pathname.split('/')[3]; 
    const tabIndex = tabMap[tab as keyof typeof tabMap] || 0;
    if (tabIndex !==  selectedTab) {
      setSelectedTab(tabIndex); 
    }
  }, [window.location.pathname]);


  const [parsedOptions, setParsedOptions] = useState(options.map((o) => ({ ...o, error: false })));
  const [titles, setTitles] = useState(options.map((o) => (o.title)));
  const handleClickTab = (tabNumber: number) => {
    if (tabNumber === 2) {
      setMapLoaded(true);
    }
    setSelectedTab(tabNumber);
    const tab = Object.keys(tabMap).find(key => tabMap[key as keyof typeof tabMap] === tabNumber) as keyof typeof tabMap;
    ReactGA.event('click_tab', {
      event_category: tab,
      role: authContext.mainRole(true),
      code: obra?.general?.codCentro
    });
    navigate(`/${codCentro}/detalles/${tab}`);

  };

  const checkError = async () => {
    const unitsOfProcess = obra?.aspectosTecnicos.procesosConstructivos.filter((p) => p.unidades.length > 0).length;
    const hasProcessWithUnits = unitsOfProcess && unitsOfProcess > 0 ? true : false; // If some process has one or more related units, we modify the validation of the units
    const validationPromises = [draftValidation(t, hasProcessWithUnits).validate(obra, { abortEarly: false })];
    const results = await Promise.all(validationPromises.map((p: any) => p.catch((e: any) => e)));
    let validationErrors = results.filter(result => (result instanceof Error));
    let currentNumberError = 0;

    if (oneMonthLeft()) {
      const validationPromisesFinal = [draftValidationFinal(t).validate(obra, { abortEarly: false })];
      const resultsFinal = await Promise.all(validationPromisesFinal.map((p: any) => p.catch((e: any) => e)));
      const validationErrorsFinal = resultsFinal.filter(result => (result instanceof Error));
      validationErrors = validationErrors.concat(validationErrorsFinal);
    }

    if (validationErrors.length) {
      const requiredErrorsTabs: any = {};

      for (const v of validationErrors) {
        for (const error of v.inner) {
          if (!error.message.optional) {
            currentNumberError++;
            const data = error.path.split('.');
            if (!requiredErrorsTabs[data[0]]) {
              requiredErrorsTabs[data[0]] = [];
            }
            const dataSplit = data[1].split('[');
            let fieldField, index = 0;
            if (dataSplit.length > 1 && data.length > 2) {
              const endSplit = dataSplit[1].split(']');
              index = endSplit[0];
              fieldField = data[data.length - 1];
              if (fieldField === 'nombre') fieldField = data[data.length - 2];
            }
            requiredErrorsTabs[data[0]].push({
              field: dataSplit[0],
              text: error.message.text,
              textField: error.message.textField,
              index,
              fieldField
            });
          }
        }
      }
      if (numberError !== currentNumberError) {
        setNumberError(currentNumberError);
        handleFinalErrors(requiredErrorsTabs);
      }
    }
  };

  const renderTab = () => {

    if (activateErrors) {
      checkError();
    }

    const renderComponent = () => {
      if (activateErrors && parsedOptions[selectedTab].error) {
        return React.cloneElement(
          options[selectedTab].component,
          { errors: parsedOptions[selectedTab].error }
        );
      }
      return options[selectedTab].component;
    };
    return <>
      {renderComponent()}
    </>;
  };

  const handleFinalErrors = (errors: any) => {
    const opts = parsedOptions.map((opt) => {
      const tab = opt.tabLabel;
      if (tab && Object.keys(errors).includes(tab) && activateErrors) {
        return { ...opt, error: errors[tab] };
      }
      return { ...opt, error: false };
    });
    setParsedOptions(opts);
  };

  return (
    <>
      {!obra && !loadError ?
        <LoadingSpinner /> :
        <div data-testid="home-test" className='home'>

          <div>
            <Header>
              <TabMenu
                obra={obra || loadError}
                setUserMode={changeModeUser}
                options={parsedOptions}
                titles={titles}
                selectedTab={selectedTab}
                handleClickTab={handleClickTab}
                handleFinalErrors={handleFinalErrors}
              />
            </Header>
            {obra &&
                    <>
                      <div className='contentFullDetail'>
                        {renderTab()}
                      </div>
                    </>
            }
            {loadError &&
                    <ErrorDetails />
            }
          </div>
        </div>
      }
    </>
  );
};

export default FullDetail;

