import React, { FC, useContext } from 'react';
import { UserModeContext } from '../../../../context/UserModeProvider';
import EditDocumentation from './edit/edit';
import { ViewDocumentation } from './viewDocumentation/viewDocumentation';


interface DocumentationProps {
  data: any;
  error?: string | null
}

export const Documentation: FC<DocumentationProps> = (props) => {
  const userModeContext = useContext(UserModeContext);
  const isEditorMode = userModeContext.userMode === 'editor';

  return (

    <div>
      {
        (!isEditorMode) ?
          <ViewDocumentation data={props.data} />
          :
          <EditDocumentation data={props.data} error={props.error} />
      }
    </div>

  );
};
