import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckSelector } from '../../../components/Filters/Components/checkSelector/checkSelector';
import KeywordInput from '../../../components/Filters/Components/keywordInput/keywordInput';
import NumberRange from '../../../components/Filters/Components/numberRangeFilters/numberRange/numberRange';
import FILTER_TYPES, { FILTER_CONFIG } from '../../../components/Filters/filterTypes';
import CollapsableFilter, { CollapsableFilterChildrenProps } from '../components/collapsableFilter/collapsableFilter';
import styles from './locationEnvironmentFilters.module.scss';

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const LocationEnvironmentFilters: FC<CollapsableFilterChildrenProps> = (props) => {
  const { open, handleClickOpen } = props;
  const [t] = useTranslation();

  return (
    <CollapsableFilter
      id='locationEnvironment'
      value={t('advancedFilters.locationEnvironment')}
      help={t('advancedFilters.locationEnvironment')}
      handleClickOpen={handleClickOpen}
      open={open}
      advancedFilter={'location'}
    >
      <div className={styles.locationEnvironmentContainer}>
        <div className={styles.collapsableComponent}>
          <KeywordInput filterType={FILTER_TYPES.town} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <KeywordInput filterType={FILTER_TYPES.zipCode} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <CheckSelector filterType={FILTER_TYPES.spaceType} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <KeywordInput filterType={FILTER_TYPES.spaceName} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <NumberRange filterType={FILTER_TYPES.protectedArea} defaultUnit={FILTER_CONFIG.hectare} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <CheckSelector filterType={FILTER_TYPES.spaceLocation} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <NumberRange filterType={FILTER_TYPES.protectedProjectArea} defaultUnit={FILTER_CONFIG.hectare} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <KeywordInput filterType={FILTER_TYPES.extinctionName} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <CheckSelector filterType={FILTER_TYPES.extinctionLevel} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <KeywordInput filterType={FILTER_TYPES.culturalName} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <CheckSelector filterType={FILTER_TYPES.culturalLevel} noBottomDivider />
        </div>
      </div>
    </CollapsableFilter>
  );
};

export default LocationEnvironmentFilters;
