/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useContext } from 'react';
import styles from './dataBim.module.scss';
import { ObraProperties } from '../../../../types/ObraDetails';
import { useTranslation } from 'react-i18next';
import InfoBoxFooter from './components/InfoBoxFooter/InfoBoxFooter';
import { CATEGORY_COLORS, LEVEL_BAR_COLORS, LEVEL_COLORS } from '../colorSchema';
import InfoBoxBar from './components/InfoBoxBar/InfoBoxBar';
import InfoBoxBoolean from './components/InfoBoxBoolean/InfoBoxBoolean';




interface DataBimProps {
  obra: ObraProperties
}

const DataBim: FC<DataBimProps> = (props) => {
  const { obra } = props;
  const [t] = useTranslation();

  const getLevel = (note: number) => {
    if (note < 3) {
      return 0;
    }
    else if (note >= 3 && note < 5) {
      return 1;
    }
    else if (note >= 5 && note < 7) {
      return 2;
    }
    else if (note >= 7 && note < 9) {
      return 3;
    }
    else if (note >= 9) {
      return 4;
    }
    return 2;
  };

  const getRequirement = () => {
    switch (obra.bim.requisitos?.toString()) {
      case '6':
      case '7':
        return t('details.bim.acciona');
      case '4':
      case '5':
        return t('details.bim.client');
      default:
        return t('details.bim.clientAcciona');
    }
  };

  const toFixed = (value: any, fix: number) => {
    // if (typeof value === 'string') {
    //   //value = parseFloat(value);
    // }
    value = Number(value).toLocaleString('es-ES', { maximumFractionDigits: fix, useGrouping: true });
    return value;//.toFixed(fix).replace('.',',');
  };

  return (
    <div
      data-testid="data-bim-test"
      className={styles.dataBimContainer}>

      <div className={styles.box}>
        <div className={styles.boxContainerLeft}>
          <InfoBoxFooter
            identifier='notaGlobal'
            item={{ title: t('details.bim.globalNote'), value: toFixed(obra.bim.notaGlobal, 2) }}
            footer={`${t('details.bim.globalFooter')}  ${obra.bim.madurez?.descripcion}`}
            style={LEVEL_COLORS[obra.bim.madurez?.codigo]}
          />
          <InfoBoxFooter
            identifier='categorizacion'
            item={{ title: t('details.bim.category'), value: obra.bim.categorizacion?.descripcion }}
            footer={t('details.bim.categoryFooter')}
            style={CATEGORY_COLORS[obra.bim.categorizacion?.descripcion]}
          />
        </div>
        <div className={styles.boxContainerRight}>
          <div className={styles.upperBoxes}>
            <InfoBoxBoolean
              identifier='requisitos'
              item={{ title: t('details.bim.requirements'), value: getRequirement() }}
              value={!!obra.bim.requisitos}
            />
            <InfoBoxBoolean
              identifier='cde'
              item={{ title: t('details.bim.cde'), value: obra.bim.cde ? t('details.bim.yes') : t('details.bim.no') }}
              value={obra.bim.cde}
            />
          </div>
          <div className={styles.lowerBoxes}>
            <InfoBoxBar
              identifier='notaUsosBim'
              item={{ title: t('details.bim.bimUsesNote'), value: toFixed(obra.bim.notaUsosBIM, 2) }}
              style={LEVEL_BAR_COLORS[getLevel(obra.bim.notaUsosBIM)]}
              level={obra.bim.notaUsosBIM || 0.5}
            />
            <InfoBoxBar
              identifier='notaUsosGestion'
              item={{ title: t('details.bim.managementUsesNote'), value: toFixed(obra.bim.notaUsosGestion, 2) }}
              style={LEVEL_BAR_COLORS[getLevel(obra.bim.notaUsosGestion)]}
              level={obra.bim.notaUsosGestion || 0.5}
            />
            <InfoBoxBar
              identifier='notaUsosEstandarizacion'
              item={{ title: t('details.bim.standardizationUsesNote'), value: toFixed(obra.bim.notaUsosEstandarizacion, 2) }}
              style={LEVEL_BAR_COLORS[getLevel(obra.bim.notaUsosEstandarizacion)]}
              level={obra.bim.notaUsosEstandarizacion || 0.5}

            />
          </div>
        </div>
      </div>

    </div>);
};

export default DataBim;
