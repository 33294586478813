import { TFunction } from 'i18next';
import * as yup from 'yup';

export const ESGSchema = (t: TFunction<'translations'>) => yup.object({
  certificadosSostenibilidad: yup.array().min(1, { text: `${t('details.validation.tab')} ${t('details.esg.tab')}: ${t('details.validation.noData')} "${t('details.esg.sustainability.helper')}"`, optional: true, orden: 6  })
    .of(
      yup.object({
        codNivel: yup.string()
          .required({ text: `${t('details.validation.tab')} ${t('details.esg.tab')}: ${t('details.validation.noData')} "${t('details.esg.sustainability.helper')}" - "${t('details.esg.sustainability.level')}"`, orden: 6 }),
      }))
    .required(),
});

export const ESGFinalSchema = (t: TFunction<'translations'>) => yup.object({
  certificadosSostenibilidad: yup.array().test(
    'ESG.sustainability',
    { text: `${t('details.validation.tab')} ${t('details.esg.tab')}: ${t('details.validation.noData')} "${t('details.esg.sustainability.helper')}" - "${t('details.esg.sustainability.level')}"`, orden: 6 },
    (value) => {
      let valid = true;
      if (value && value.length > 0) {
        for (const cert of value) {
          if (cert.origen === 'cecoc' && cert.codNivel && !cert.codNivelActualizado) {
            valid = false;
          } else if (cert.origen !== 'cecoc' && !cert.codNivel) {
            valid = false;
          }
        }
      }
      return valid;
    }
  ).required(),
}).required();