/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react';
import { FilterCollapsableWrapper } from '../../../FiltersWrappers/FilterCollapsableWrapper/FilterCollapsableWrapper';
import { FormGroup } from '@mui/material';
import SelectRangeMode from '../components/selectRangeMode/selectRangeMode';
import SelectField from '../components/selectField/selectField';
import { Option } from '../../../../../types/Option';
import RangeModeFieldsWithButton from '../components/rangeModeFieldsWithButton/rangeModeFieldsWithButton';
import { FilterContext } from '../../../../../context/FilterProvider';
import styles from './doubleSelectorNumberRange.module.scss';
import CustomAutocomplete from '../../../../../pages/advancedSearch/components/Autocomplete/CustomAutocomplete/CustomAutocomplete';

interface DoubleSelectorNumberRangeProps {
  filterType: string;
  unitType: string;
  noBottomDivider?: boolean;
}
export const DoubleSelectorNumberRange = (props: DoubleSelectorNumberRangeProps) => {
  const { filterType, unitType, noBottomDivider } = props;
  const filterContext = useContext(FilterContext);
  const [magnitudes, setMagnitudes] = useState<Option[]>(filterContext.filters[filterType] ? filterContext.filters[filterType][0]?.elements || [] : []);
  const [selectedWorkType, setSelectedWorkType] = useState<any>(filterContext.selectedWorkType || null);
  const [selectedUnit, setSelectedUnit] = useState<any>(filterContext.selectedWorkUnit || null);

  useEffect(() => {
    setMagnitudes(filterContext.filters[filterType] ? filterContext.filters[filterType][0]?.elements || [] : []);
    setSelectedUnit(null);
  }, [filterContext.filters[filterType]]);

  return (
    <>
      <FilterCollapsableWrapper
        filterType={filterType}
        noBottomDivider={noBottomDivider}
      >
        <FormGroup>
          <CustomAutocomplete
            filterType={filterType}
            handleSelect={(newValue) => {
              setSelectedWorkType(newValue);
              setSelectedUnit(null);
            }}
          />
          <SelectField options={magnitudes} disabled={!selectedWorkType} selectedValue={selectedUnit} unitType={filterType} handleSelect={(newValue) => setSelectedUnit(newValue)} />
          <SelectRangeMode filterType={unitType} />
          <RangeModeFieldsWithButton filterType={unitType} unitType={filterType} selectedWorkType={selectedWorkType} selectedUnit={selectedUnit} isDoubleSelector />
        </FormGroup>
      </FilterCollapsableWrapper>
    </>
  );
};

export default DoubleSelectorNumberRange;
