import parse from 'html-react-parser';
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ObraProperties } from '../../../../../types/ObraDetails';
import TitleValue from '../../../components/titleValue/titleValue';
import styles from './viewBuildingProcesses.module.scss';
import technicalNoData from '../../../../../assets/technicalNoData.svg';
import { EDITOR } from '../../../../../userRoles/userRoles';
import { AuthContext } from '../../../../../services/authContextProvider';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface BuildingProcessesProps {
  obra: ObraProperties;
  userMode: string;
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const ViewBuildingProcesses: FC<BuildingProcessesProps> = (props) => {
  const { obra, userMode } = props;
  const [t] = useTranslation();
  const authContext = useContext(AuthContext);
  const language = localStorage.getItem('language') && localStorage.getItem('language') !== '' ? localStorage.getItem('language') : 'es';

  return (
    <div className={styles.buildingProcessesContainer}>
      {obra.aspectosTecnicos.procesosConstructivos?.length > 0 ?
        <table> {
          obra.aspectosTecnicos.procesosConstructivos.map((pc: any, i) => {
            const process = pc.procesoConstructivo?.codigo ? pc.procesoConstructivo : pc;
            return (
              <tr key={i}>
                <td>
                  <div key={i} className={styles.buildingProcesses}>
                    <div className={styles.titleValue1}>
                      <TitleValue
                        identifier={`buildingProcesses-code-${i}`}
                        title={t('details.technical.code')}
                        value={process.codigo?.toString()}
                        onlyDisable
                        isTable
                      />
                    </div>
                    <div className={styles.titleValue2}>
                      <TitleValue
                        identifier={`buildingProcesses-name-${i}`}
                        title={t('details.technical.name')}
                        value={process.nombre}
                        onlyDisable
                        isTable
                      />
                    </div>
                  </div>
                </td>

              </tr>
            );
          })
        }
        </table>
        :
        <div id='buildingProcesses-nodata' className={styles.noDataPic}>
          <img src={technicalNoData} />
          {authContext.hasEditorRole() ?
            <>
              {userMode === 'viewer' && <p>{parse(t('details.technical.changeUserAddBuildingProcesses'))}</p>}
              {userMode === 'editor' && <p>{parse(t('details.technical.addBuildingProcesses'))}</p>}
              {userMode === 'editorDisabled' && <p>{parse(t('details.technical.noData') + '. ' + t('details.validation.noEditMode'))}</p>}
            </>
            :
            <p>{t('details.technical.noData')}</p>
          }
        </div>
      }
    </div>
  );
};

export default ViewBuildingProcesses;
