/* eslint-disable linebreak-style */
import { useState } from 'react';
import { MenuItem, Select, Typography, Tooltip } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { Mode } from '../../types/FiltersTypes';
import styles from './simpleSelector.module.scss';

import { Option } from '../../types/Option';

interface SimpleSelectorProps {
  filterType: string;
  filterContext: any;
  options: Option[];
  disabled?: boolean;
  placeholder?: string;
}

const SimpleSelector = (props: SimpleSelectorProps) => {
  const {
    filterType,
    filterContext,
    options,
    disabled,
    placeholder,
  } = props;

  const handleChangeFilters = (option: Option): void => {
    const { code, name } = option;
    const dblSelection =
      filterContext.filters[filterType] &&
      filterContext.filters[filterType].find(
        (filter: Option) => filter.code === code
      );
    const modeFilter = dblSelection ? Mode.DELETE : Mode.REPLACE;
    filterContext.handleChange(
      filterType,
      { code, name },
      modeFilter,
    );
  };
  return (
    <div data-testid="simpleSelector-test">
      <div className={styles.selectorContainer}>
        <Select
          className={styles.selector}
          value={[-1]}
          disabled={disabled}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: '50vh',
                width: 241,
                '.MuiMenuItem-root:hover': {
                  '& .MuiSvgIcon-root': { color: '#FF0000' },
                },
              },
            },
          }}
          sx={{
            backgroundColor: disabled ? '#f7f8fa' : '#FFFFFF',
            height: '45px',
            '&.MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                border: '1px solid #7A7A7A',
                color: 'rgba(122, 122, 122, 1)',
              },
            },
          }}
        >
          <option value="-1" disabled hidden>
            {placeholder}
          </option>
          {options.map(({ code, name }) => {
            return (
              <MenuItem
                key={code}
                value={code}
                onClick={() => {
                  handleChangeFilters({ code, name });
                }}
                sx={{
                  paddingLeft: '0.5rem',
                  paddingRight: '1rem',
                  '& .MuiSvgIcon-root:focus': { color: '#FF0000' },
                }}
              >
                <div className={'menu-item'}>
                  <Tooltip
                    key={uuidv4()}
                    enterDelay={2000}
                    title={
                      <Typography style={{ padding: '0.625rem' }}>
                        {name}
                      </Typography>
                    }
                    followCursor
                  >
                    <Typography
                      style={{ paddingTop: '0.1rem', width: '100%' }}
                      noWrap
                    >
                      {name}
                    </Typography>
                  </Tooltip>
                </div>
              </MenuItem>
            );
          })}
        </Select>
      </div>
    </div>
  );
};

export default SimpleSelector;
