import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { ObraProperties } from '../../../../types/ObraDetails';
import TitleHelp from '../../components/titleHelp/titleHelp';
import InfoBox from '../../components/infoBox/infoBox';
import amountIcon from '../../../../assets/amount_icon.svg';
import Description from '../../../../components/Description';

interface GreenFinancingProps {
  obra: ObraProperties,
}

const GreenFinancing = ({obra}: GreenFinancingProps) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ flexGrow: 1 }} data-testid='esg-test'>
      <Grid container columnSpacing={3} rowSpacing={2} mb='54px' data-testid='esg-test' id='esg-nodata'>
        <Grid item md={12} lg={4}>
          <TitleHelp
            id='greenFinancing'
            value={t('details.esg.greenFinancing.helper')}
            help={t('help.esg.greenFinancing')}
          />
          <InfoBox
            identifier='amount'
            picture={amountIcon} item={{ title: t('details.esg.greenFinancing.budget'), value: obra.esg.presupuestoCarbono ? obra.esg.presupuestoCarbono.toString() : t('details.notInformed') }} 
            overwriteStyle={{ marginBottom: 'auto', height: 'auto', marginTop: '14px' }}
          />
        </Grid>
        <Grid item md={12} lg={8} display="flex" flexDirection="column">
          <TitleHelp
            id='commitments'
            value={t('details.esg.commitments.helper')}
            help={t('help.esg.taxonomicAndGreenFinancingCommitments')}
          />
          <Description
            id='commitmentsText'
            text={obra.esg.compromisosTaxonomicos && obra.esg.compromisosTaxonomicos !== '' ? obra.esg.compromisosTaxonomicos : t('details.notInformed')}
            isBold
            customCss={{marginTop: '14px'}}
          />
        </Grid>
      </Grid>
    </Box>
  );
};


export default GreenFinancing;
