import React, { FC } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import styles from './informationIcon.module.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface InformationIconProps {
  message: string;
}

const InformationIcon: FC<InformationIconProps> = (props) => {
  const { message } = props;
  return (
    <Tooltip
      data-testid='informationIcon-test'
      key={uuidv4()}
      enterDelay={500}
      style={{fontFamily: 'Bitter', fontWeight: 700}}
      title={
        <Typography
          style={{ padding: '0.625rem' }}
        >
          {message}
        </Typography>
      }
      placement='right'
      arrow>
      <InfoOutlinedIcon className={styles.info} />
    </Tooltip>);
};

export default InformationIcon;