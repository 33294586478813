/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @function addObrasCluster
 *
 * @description get obras and add to map
 *
 * @param {mapInstance} mapInstance the google maps instance
 *  @param {filter} filter the filter to get obras
 *
 *
 */
const getDiffBetweenClusters = (oldCluster: any, newCluster: any) => {
  // A comparer used to determine if two entries are equal.
  const isSameCluster = (a: any, b: any) => a.markers.length === b.markers.length && a.position.toString() === b.position.toString();

  // Get items that only occur in the left array,
  // using the compareFunction to determine equality.
  const onlyInLeft = (left: any, right: any, compareFunction: any) =>
    left.filter((leftValue: any) =>
      !right.some((rightValue: any) =>
        compareFunction(leftValue, rightValue)));

  const onlyInOld = onlyInLeft(oldCluster, newCluster, isSameCluster);
  const onlyInNew = onlyInLeft(newCluster, oldCluster, isSameCluster);

  return { onlyInOld, onlyInNew };

};



export {
  getDiffBetweenClusters,
};
