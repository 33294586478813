import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { ObraProperties, ProcesoConstructivo } from '../../../../../types/ObraDetails';
import styles from './editBuildingProcess.module.scss';
import { SaveContext } from '../../../../../context/SaveProvider';
import AddItemButton from '../../../components/addItemButton/addItemButton';
import DataProcess from './dataProcess/dataProcess';
import { TechnicalAspectsContext } from '../../technicalAspects';
import technicalNoData from '../../../../../assets/technicalNoData.svg';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface EditBuildingProcessProps {
  obra: ObraProperties;
  errors?: any[];
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const EditBuildingProcess: FC<EditBuildingProcessProps> = (props) => {
  const { obra, errors } = props;
  const [t] = useTranslation();
  const saveContext = useContext(SaveContext);
  const [buildingProcess, setbuildingProcess] = useState(obra.aspectosTecnicos?.procesosConstructivos || []);
  const { buildingProcessUnits, setBuildingProcessUnits } = useContext(TechnicalAspectsContext);
  const [options, setOptions] = useState<any>([]);

  // adds a new worktype registry with empty data
  const handleAddItem = () => {
    const newProcess: ProcesoConstructivo = { nombre: '', codigo: '', unidades: [] };
    // adds the units related to the new building process
    const newUnits: any = [];
    newProcess.unidades = newUnits;
    setBuildingProcessUnits([...buildingProcessUnits, newUnits]);
    setbuildingProcess([...buildingProcess, newProcess]);
    if (obra.aspectosTecnicos?.procesosConstructivos) {
      obra.aspectosTecnicos.procesosConstructivos.push(newProcess);
    } else {
      obra.aspectosTecnicos.procesosConstructivos = [newProcess];
    }
    setOptions([]);
    saveContext.handleChangeSave(true);
  };

  const handleDelete = (data: any, index: number) => {
    obra.aspectosTecnicos.procesosConstructivos.splice(index, 1);
    setbuildingProcess([...obra.aspectosTecnicos.procesosConstructivos]);
    // Refreshes the units to exclude those related to the deleted building process
    setBuildingProcessUnits(obra.aspectosTecnicos?.procesosConstructivos.map((pc, i) => {
      return { index: i, units: pc.unidades?.map(unit => unit) };
    }) || []);
    saveContext.handleChangeSave(true);
  };

  return (
    <>
      <div className={styles.addBtnContainer}>
        <div className={styles.addBtn}>
          <AddItemButton
            addItemText={t('details.technical.buildingProcess.add') as string}
            handleAddItem={handleAddItem}
          />
        </div>
      </div>
      {
        buildingProcess.length === 0 ?
          <div id='buildingProcesses-noDataAdded' className={styles.noDataPic}>
            <img src={technicalNoData} />
            <p>{t('details.technical.noDataAdded')}</p>
          </div>
          : null
      }
      <Grid container columnSpacing="24px" rowSpacing="0">
        {
          (buildingProcess && buildingProcess.length > 0) ?
            buildingProcess.map((process: any, index) => {
              const error = errors?.filter(e => parseInt(e.index) === index);
              return <DataProcess key={index} obra={obra} process={process} index={index} handleDelete={handleDelete} error={error}/>;
            })
            : null
        }
      </Grid>
    </>
  );
};

export default EditBuildingProcess;
