import React, { useContext } from 'react';
import styles from './tabMenuAdvancedSearch.module.scss';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import { AuthContext } from '../../services/authContextProvider';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { SaveContext } from '../../context/SaveProvider';
import CheckSaved from '../checkSaved/checkSaved';
import { FilterContext } from '../../context/FilterProvider';
import { ACCION } from '../../types/FiltersTypes';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TabMenuAdvancedSearchProps { }

function TabMenuAdvancedSearch(props: TabMenuAdvancedSearchProps) {
  const authContext = useContext(AuthContext);
  const saveContext = useContext(SaveContext);
  const navigate = useNavigate();
  const filterContext = useContext(FilterContext);

  const [goBack, setGoBack] = React.useState<boolean>(false);

  const handleUnload = (event: any) => {
    if (saveContext.saveDraft !== undefined && !saveContext.saveDraft) {
      event.stopImmediatePropagation();
    } else if (saveContext.saveDraft) {
      event.returnValue = t('checkSaved.text');
      event.preventDefault();
    }
  };

  window.addEventListener('beforeunload', handleUnload);

  const handleApplyFilter = () => {
    filterContext.finish(ACCION.APPLY);
    navigate('/');
  };

  const handleClearFilter = () => {
    filterContext.finish(ACCION.CLEAR);
  };

  const goback = () => {
    saveContext.saveDraft = false;
    saveContext.handleChangeSave(false);
    setGoBack(false);
    navigate('/');
  };

  const discardChanges = () => {
    saveContext.saveDraft = false;
    saveContext.handleChangeSave(false);
    setGoBack(false);
  };

  return (
    <div data-testid='tabmenu-test' className={styles.tabMenuContainer}>

      <div id='goback' className={styles.goBack}>
        <span onClick={() => {
          saveContext.saveDraft ? setGoBack(true) : navigate('/');
        }}><ArrowBackIosIcon /></span>
        {
          (goBack) ?
            <CheckSaved
              handleFunction={() => {
                goback();
              }}
              closeFunction={() => {
                discardChanges();
              }
              } />
            : null
        }
      </div>
      <div id='tabmenu-idandname' className={styles.idAndName}>
        <Tooltip
          enterDelay={1000}
          arrow
          title={
            <Typography>
              {t('header.advancedSearch')}
            </Typography>
          }
          followCursor
        >
          <span>{t('header.advancedSearch')}</span>
        </Tooltip>
      </div>
      <div className={styles.filterHeaderOptions}>
        <div className={styles.cleanFiltersButton} onClick={handleClearFilter}>
          {t('advancedFilters.clean')}
        </div>
        <div className={styles.showResultsButton} onClick={handleApplyFilter}>
          {filterContext.filteredProjectsNumber === 0
            ? t('advancedFilters.noResults')
            : `${t('advancedFilters.show')} ${filterContext.filteredProjectsNumber || ''} ${t('advancedFilters.projects')}`}
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(TabMenuAdvancedSearch);
