/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { FilterCollapsableWrapper } from '../../../FiltersWrappers/FilterCollapsableWrapper/FilterCollapsableWrapper';
import { FormGroup } from '@mui/material';
import { SelectRangeMode } from '../components/selectRangeMode/selectRangeMode';
import RangeModeFields from '../components/rangeModeFields/rangeModeFields';

interface NumerRangeProps {
  filterType: string;
  defaultUnit?: string;
  noBottomDivider?: boolean;
}
export const NumberRange = (props: NumerRangeProps) => {
  const { filterType, defaultUnit, noBottomDivider } = props;
  return (
    <>
      <FilterCollapsableWrapper
        filterType={filterType}
        noBottomDivider={noBottomDivider}
      >
        <FormGroup>
          <SelectRangeMode filterType={filterType} />
          <RangeModeFields filterType={filterType} defaultUnit={defaultUnit}/>
        </FormGroup>
      </FilterCollapsableWrapper>
    </>
  );
};

export default NumberRange;