import React from 'react';
import {
  Box,
  Paper,
  Grid,
} from '@mui/material';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import styles from './filterListWrapper.module.scss';

interface FilterListWrapperProps {
  children?: JSX.Element[];
  t: TFunction<'translations'>;
}

const FilterListWrapper = (props: FilterListWrapperProps) => {
  const { children } = props;

  return (
    <Grid
      className={styles.filterContainer}
    >
      <Paper
        className={styles.filterPaper}
        elevation={0}
      >

        <Box
          className={styles.infiniteScroll}
        >
          {children?.map((c) => c)}
        </Box>
      </Paper>
    </Grid>
  );
};

export default withTranslation()(FilterListWrapper);
