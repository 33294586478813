import React, { FC, useState } from 'react';
import { Button, Stack, TextField } from '@mui/material';
import parse from 'html-react-parser';

import styles from './serverErrorDialog.module.scss';
import { useTranslation } from 'react-i18next';

interface DialogErrorProps {
  handleAccept: () => void;
  handleCancel: () => void;
  text?: string | null;
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const ServerErrorDialog: FC<DialogErrorProps> = (props) => {
  const { handleAccept, handleCancel, text } = props;

  const { t } = useTranslation();

  return (
    <div className={styles.dialogContent}>
      <p>{ text ? text : t('details.rejectError')}</p>
      <Stack spacing={2} direction="row" justifyContent="center">
        <Button id='buttoncancel' className={styles.buttonSecondary} onClick={() => handleCancel()} disableRipple={true} variant="outlined">{t('details.savedErrorButtonCancel')}</Button>
        <Button id='buttonretry' className={styles.buttonMain} onClick={() => {
          handleAccept();
        }} disableRipple={true} variant="contained">{t('details.savedErrorButtonRetry')}</Button>
      </Stack>
    </div>);
};

export default ServerErrorDialog;