import * as yup from 'yup';
import tecnicalAspectSchema from './technicalAspects';
import DescriptionSchema from './description';
import LocationEnvironmentSchema from './localizationEnvironment';
import DatesSchema, { DatesSchemaFinal } from './dates';
import ClientSchema from './client';
import ClassificationSchema, { ClassificationSchemaFinal } from './classification';
import { TFunction } from 'i18next';
import { ESGSchema, ESGFinalSchema } from './esg';
import GeneralSchema from './general';

const validationSchema = (t: TFunction<'translations'>, hasProcessWithUnits: boolean) =>
  yup.object({
    descripcion: DescriptionSchema(t),
    localizacion: LocationEnvironmentSchema(t),
    presupuesto: yup.object().required(),
    fechasPlazos: DatesSchema(t),
    contrato: yup.object().required(),
    cliente: ClientSchema(t),
    socios: yup.object().required(),
    aspectosTecnicos: tecnicalAspectSchema(t, hasProcessWithUnits),
    bim: yup.object().required(),
    clasificacion: ClassificationSchema(t),
    general: GeneralSchema(t),
    esg: ESGSchema(t),
  });

export const draftValidationFinal = (t: TFunction<'translations'>) =>
  yup.object({
    clasificacion: ClassificationSchemaFinal(t),
    fechasPlazos: DatesSchemaFinal(t),
    esg: ESGFinalSchema(t),
  });

export default validationSchema;
