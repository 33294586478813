import React, { FC, useState } from 'react';
import { Button, Dialog, Stack } from '@mui/material';

import styles from './dialogError.module.scss';
import { useTranslation } from 'react-i18next';

interface DialogErrorProps {
  error: string;
  close?: string;
  acept?: string;
  handleAcept?: () => void;
  handleCancel?: () => void;
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const DialogError: FC<DialogErrorProps> = (props) => {
  const { error, close, acept, handleAcept, handleCancel } = props;
  const { t } = useTranslation();

  const [errorText, setErrorText] = useState(error);

  const handleClose = () => {
    setErrorText('');
    handleCancel && handleCancel();
  };

  return (<Dialog
    classes={{
      paper: styles.dialog
    }}
    BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.15)' } }}
    PaperProps={{ sx: { borderRadius: '20px', minWidth: '90vW' } }}
    open={errorText.length > 0}
    onClose={() => handleClose()}
  >
    <div className={styles.dialogContent}>
      <p>{errorText}</p>
      <Stack spacing={2} direction="row" justifyContent="center">
        <Button id='buttonclose' className={handleAcept ? styles.buttonSecondary : styles.buttonClose} onClick={() => handleClose()} disableRipple={true} variant="contained">
          {close || t('close')}
        </Button>
        {handleAcept && <Button id='buttoncontinue' className={styles.buttonClose}
          onClick={() => handleAcept && handleAcept()} disableRipple={true} variant="contained">
          {acept || t('continue')}
        </Button>}
      </Stack>
    </div>
  </Dialog>

  );
};

export default DialogError;
