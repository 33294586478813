import React, { createContext, FC, useState } from 'react';
import { ObraProperties, UnidadObra } from '../../../types/ObraDetails';
import styles from './technicalAspects.module.scss';
import WorkTypesMagnitudes from './workTypesMagnitudes/workTypesMagnitudes';
import BuildingProcesses from './buildingProcesses/buildingProcess';
import WorkUnits from './workUnits/workUnits';
import NoDataPicture from '../components/noDataPicture/noDataPicture';
import coffeeBreak from '../../../assets/coffeeBreak.svg';
import { t } from 'i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TechnicalAspectsProps {
  obra: ObraProperties;
  userMode: string;
  errors?: any[];
}
interface TechnicalAspectsContextValues {
  buildingProcessUnits: BuildingProcessUnits[];
  setBuildingProcessUnits: React.Dispatch<React.SetStateAction<BuildingProcessUnits[]>>
}
interface BuildingProcessUnits {
    index: number;
    units: UnidadObra[];
}
// We need to create a context in order to pass the units related to a building process to the work units component
const defaultTechnicalAspectsContextValues: TechnicalAspectsContextValues = {
  buildingProcessUnits: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  setBuildingProcessUnits: () => { },
};
export const TechnicalAspectsContext = createContext<TechnicalAspectsContextValues>(defaultTechnicalAspectsContextValues);

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const TechnicalAspects: FC<TechnicalAspectsProps> = (props) => {
  const { obra, userMode, errors } = props;
  const technicalAspectsNotEmpty = obra.aspectosTecnicos?.tiposObras?.length > 0 ||
    obra.aspectosTecnicos?.procesosConstructivos?.length > 0 ||
    obra.aspectosTecnicos?.unidades?.length > 0;

  const [buildingProcessUnits, setBuildingProcessUnits] =
    useState(obra.aspectosTecnicos?.procesosConstructivos.map((pc, i) => {
      return {index: i, units: pc.unidades?.map(unit => unit)};
    }) || []);
    // This creates an array of objects with index and the arrays of units related to each building process as value.
    // The flatMap method can't be use in this situation because if some measuring changes, with a flatten array we can't know which building process
    // that measuring belongs to.
  return (
    <TechnicalAspectsContext.Provider value={{buildingProcessUnits, setBuildingProcessUnits}}>
      <div className={styles.technicalAspectsContainer}>
        {
          technicalAspectsNotEmpty
            ? <>
              <div className={styles.section}>
                <WorkTypesMagnitudes obra={obra} errors={errors?.filter(e=>e.field === 'tiposObras')}/>
              </div>
              <div className={styles.section}>
                <BuildingProcesses obra={obra} userMode={userMode} errors={errors?.filter(e=>e.field === 'procesosConstructivos')}/>
              </div>
              <div className={styles.section}>
                <WorkUnits obra={obra} errors={errors ? [
                  ...errors.filter(e=>e.field === 'unidades'),
                  ...errors.filter(e=>e.field === 'procesosConstructivos' && e.fieldField === 'medicion')
                ] : [] } />
              </div>
            </>
            :
            <NoDataPicture id='noWorkPicture'
              picture={coffeeBreak}
              text={t('details.technical.noMainWork')}
              tip={t('details.technical.noMainWorkTip') as string}
            />
        }
      </div>
      <div className='footer' style={{marginBottom: '75px'}}></div>
    </TechnicalAspectsContext.Provider>
  );
};

export default TechnicalAspects;


