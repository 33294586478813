import { TFunction } from 'i18next';
import * as yup from 'yup';

const ClassificationSchema = (t: TFunction<'translations'>) => yup.object({
  grupos: yup.array().of(
    yup.object({
      grupo: yup.object({
        codigo: yup.string().required({ text: `${t('details.validation.tab')} ${t('details.classification.tab')}: ${t('details.validation.noData')} "${t('details.classification.group')}"`, orden: 7}),
        nombre: yup.string().required({ text: `${t('details.validation.tab')} ${t('details.classification.tab')}: ${t('details.validation.noData')} "${t('details.classification.group')}"`, orden: 7}),
      }).required({ text: `${t('details.validation.tab')} ${t('details.classification.tab')}: ${t('details.validation.noData')} "${t('details.classification.group')}"`, tab: 'clasificacion', field: 'grupo', orden: 7 }),
      importeEstimado: yup.string().test(
        'Classification.estimatedBudget',
        { text: `${t('details.validation.tab')} ${t('details.classification.tab')}: ${t('details.validation.noData')} "${t('details.classification.estimatedBudget')}"`, tab: 'clasificacion', field: 'importeEstimado', orden: 7 },
        (value, context) => {
          return context.parent.origen === 'cecoc' || Boolean(value && value !== '');
        },
      ).nullable(),
    })
  ).nullable(),
}).required();

export const ClassificationSchemaFinal = (t: TFunction<'translations'>) => yup.object({
  grupos: yup.array().of(
    yup.object({
      grupo: yup.object({
        codigo: yup.string().required({ text: `${t('details.validation.tab')} ${t('details.classification.tab')}: ${t('details.validation.noData')} "${t('details.classification.group')}"`, orden: 7}),
        nombre: yup.string().required({ text: `${t('details.validation.tab')} ${t('details.classification.tab')}: ${t('details.validation.noData')} "${t('details.classification.group')}"`, orden: 7}),
      }).required({ text: `${t('details.validation.tab')} ${t('details.classification.tab')}: ${t('details.validation.noData')} "${t('details.classification.group')}"`, tab: 'clasificacion', field: 'grupo', orden: 7 }),
      importeFinal: yup.string().required({ text: `${t('details.validation.tab')} ${t('details.classification.tab')}: ${t('details.validation.noData')} "${t('details.classification.finalBudget')}"`, tab: 'clasificacion', field: 'importeFinal', orden: 7 }),
    })
  ).required(),
}).required();

export default ClassificationSchema;
