import React, { FC, useContext, useEffect, useState } from 'react';
import { Box, Chip, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { COLORS } from '../colorSchema';
import { SelectOption } from '../../../../../../types/Option';
import styles from './multipleSelect.module.scss';

import './multipleSelect.scss';
import { SaveContext } from '../../../../../../context/SaveProvider';
import { parse } from 'path';



interface MultipleSelectProps {
  editable: boolean,
  protectedSpaces: SelectOption[],
  selectedCodesProtectedSpaces: SelectOption[],
  error: boolean,
  handleChange: (data: any[]) => void
}

const MultipleSelect: FC<MultipleSelectProps> = (props) => {
  const { editable, protectedSpaces, selectedCodesProtectedSpaces, error } = props;
  const [errorClass, setErrorClass] = useState<boolean | undefined>(error);
  const [selectedProtectedSpaces, setSelectedProtectedSpaces] = React.useState<SelectOption[]>(selectedCodesProtectedSpaces);
  const saveContext = useContext(SaveContext);

  useEffect(() => {
    setErrorClass(error);
  }, [error]);

  useEffect(() => {
    // to make match between codes of selected ones and codes of masters
    // in early phases the initial codes doesn't match
    const mappedSelected = selectedCodesProtectedSpaces?.map((selectedCode: SelectOption) => {
      const selected = protectedSpaces.find((s: SelectOption) => s.id === selectedCode.id);
      return {
        id: selectedCode.id,
        nombre: selected?.nombre || selectedCode.nombre,
      };
    });
    setSelectedProtectedSpaces(mappedSelected);
  }, [protectedSpaces]);

  const handleError = (values: any[]) => {
    if (saveContext.isValidation && values.length > 0) {
      setErrorClass(false);
    } else if (saveContext.isValidation && values.length === 0) {
      setErrorClass(true);
    } 
  };

  const handleChangeProtectedSpace = (event: SelectChangeEvent<typeof selectedProtectedSpaces>) => {
    const {
      target: { value },
    } = event;
    // On autofill we get a stringified value.
    const values = typeof value === 'string' ? value.split(',') : value;
    const parsedValues = values.map((selectedValue: any) => {
      if (typeof selectedValue !== 'object') {
        const selected = protectedSpaces.find((s: SelectOption) => s.id === parseInt(selectedValue));
        return {
          id: selectedValue,
          nombre: selected?.nombre || selectedValue,
        };
      } else {
        return selectedValue;
      }
    });
    setSelectedProtectedSpaces(parsedValues);
    handleError(parsedValues);
    props.handleChange(parsedValues);
  };

  const handleDelete = (value: number) => {
    const parsedValues = selectedProtectedSpaces.filter((protectedSpace) => protectedSpace.id !== value);
    setSelectedProtectedSpaces(parsedValues);
    handleError(parsedValues);
    props.handleChange(parsedValues);
  };

  return <div className={'multiple-select'}>
    <Select
      className={errorClass ? styles.error : ''}
      multiple
      value={selectedProtectedSpaces}
      onChange={handleChangeProtectedSpace}
      input={<OutlinedInput />}
      disabled={!editable}

      renderValue={(selected: SelectOption[]) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selected.map((value: SelectOption) => {
            const editableProps = editable ? {
              onDelete: () => handleDelete(value.id),
              deleteIcon: <CloseIcon />,
              onMouseDown: (event: any) => {
                event.stopPropagation();
              },
            } : {};
            return <Chip
              {...editableProps}
              className='chip-protected-space'
              key={value.id}
              label={value.nombre}

              style={{
                backgroundColor: COLORS[value.id] && COLORS[value.id].backgroundColor,
                color: COLORS[value.id] && COLORS[value.id].color,
              }} />;
          })}
        </Box>
      )}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 48 * 4.5 + 8,
            width: 250,
          },
        },
      }}

    >
      {protectedSpaces.map((protectedSpace: SelectOption) => {
        const selected = !!selectedProtectedSpaces.find((p) => p.id?.toString() === protectedSpace.id?.toString());
        return <MenuItem
          key={protectedSpace.id}
          value={protectedSpace.id}
          disabled={selected}
        >
          <div id='protectedspaceselector-item' className={'menu-item'}>{protectedSpace.nombre}</div>
        </MenuItem>;
      })}
    </Select>
  </div>;

};

export default MultipleSelect;