/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextField } from '@mui/material';
import React from 'react';

export const CustomTextField = (
  {
    props,
    handleOpen,
  }: any,
) => {

  return (
    <TextField
      id='datepicker-textfield'
      {...props}
      InputProps={{
        ...props.InputProps,
        endAdornment: ''
      }}

      InputLabelProps={{ shrink: true }}
      onClick={() => handleOpen()}
      placeholder={'DD/MM/YYYY'}
      variant='outlined'
      sx={{
        paddingRight: '0.75rem',
        paddingLeft: '0.75rem',
        paddingTop: '0.35rem',
        paddingBottom: '0.35rem',
        display: 'block',
        //marginBottom: '9px',
        '& .MuiInputLabel-root': {
          fontFamily: 'Roboto',
          fontWeight: '400',
          fontSize: '12px',
          color: '#696969',
          top: '23px',
          left: '12px',
          '&.Mui-focused': {
            color: '#696969',
          }
        },
        '& .MuiInputBase-root': {
          width: '100%',
          '&.MuiOutlinedInput-root': {
            border: 'none',

            '& input': {
              paddingBottom: '7px',
              paddingTop: '21px',
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '12px',
              lineHeight: '18px',
              color: '#111111',
              zIndex: 1,
            },
            '& fieldset': {
              border: '1px solid rgba(192, 192, 192, 1)',
              legend: {
                display: 'none'
              },
              backgroundColor: '#FFFFFF',
              height: '45px',
            },
            '&:hover': {
              '& fieldset': {
                border: '1px solid rgba(0, 0, 0, 1)'
              }
            },
            '&.Mui-focused fieldset': {
              border: '1px solid #7A7A7A',
            }
          },
        },
      }}
      autoComplete='off'
    />
  );
};
