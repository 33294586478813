import styles from './editSustainabilityRow.module.scss';
import DeleteButton from '../../../../components/deleteButton/deleteButton';
import SimpleSelect from '../../../../components/simpleSelect/simpleSelect';
import { useTranslation } from 'react-i18next';

interface EditSustainabilityRowProps {
  index: number;
  cert: any;
  certificateOptions: any[];
  isFieldError: (field: string) => any;
  getLevelCECOC: (codCertificate: string) => any;
  handleChangeCertificate(data: any): any;
  handleChangeLevel(data: any): any;
  handleDelete?: () => any;
}

const EditSustainabilityRow = (props: EditSustainabilityRowProps) => {
  const {t} = useTranslation();
  const { index: key, cert, certificateOptions, isFieldError, getLevelCECOC, handleChangeCertificate, handleChangeLevel, handleDelete } = props;
  const originCecoc = cert.origen === 'cecoc';

  return (
    <div className={`${styles.certificates} ${key == 0 ? styles.firstElement : ''}`}>
      <div className={originCecoc ? styles.width3 : styles.width1}>
        <SimpleSelect
          id={`document-type-${key}`}
          options={certificateOptions}
          value={{ id: -1, nombre: cert.nomCertificado }}
          label={t('details.esg.sustainability.type')}
          handleChangeValue={(data: any) => handleChangeCertificate(data)}
          editable={!originCecoc}
        />
      </div>
      <div className={originCecoc ? styles.width3 : styles.width2}>
        <SimpleSelect
          id={`document-type-${key}`}
          options={
            cert.niveles && cert.niveles.length > 0
              ? cert.niveles
              : [{
                id: 'no', nombre: t('details.esg.sustainability.certificateNoSelected')
              }]}
          value={{ id: -1, nombre: cert.nomNivel || '-' }}
          label={t(`details.esg.sustainability.${originCecoc ? 'levelInit' : 'level'}`)}
          handleChangeValue={(data: any) => handleChangeLevel(data)}
          error={isFieldError('codNivel')}
          editable={!originCecoc} />
      </div>
      {originCecoc &&
        <div className={styles.width3}>
          <SimpleSelect
            id={`document-type-${key}`}
            options={cert.codCertificado ? getLevelCECOC(cert.codCertificado) : []}
            value={{ id: -1, nombre: cert.nomNivelActualizado || '-' }}
            label={t('details.esg.sustainability.levelUpdate')}
            handleChangeValue={(data: any) => handleChangeLevel(data)}
            error={isFieldError('codNivelActualizado')}
            editable={true} />
        </div>
      }
      {!originCecoc && handleDelete &&
        <div className={styles.trashBtn}>
          <DeleteButton id={`deleteBtn-${key}`} onClick={() => handleDelete()} />
        </div>
      }
    </div>
  );
};

export default EditSustainabilityRow;
