import { FC, useState } from 'react';
import { Grid } from '@mui/material';
import { EspacioProteccion } from '../../../../../../types/ObraDetails';
import { SelectOption } from '../../../../../../types/Option';
import { useTranslation } from 'react-i18next';
import MultipleSelect from '../multipleSelect/multipleSelect';
import MultipleSelectView from '../multipleSelectView';
import SimpleSelect from '../../../../components/simpleSelect/simpleSelect';
import DeleteButton from '../../../../components/deleteButton/deleteButton';
import TitleValue from '../../../../components/titleValue/titleValue';

interface ProtectedSpaceRowProps {
  key: number;
  space: EspacioProteccion;
  editable: boolean;
  protectedSpaces: SelectOption[];
  errors?: any[];
  protectedSpacesLocations: SelectOption[];
  handleChangeArea: (data: any, field: string) => void;
  handleChange: (data: any, field: string) => void;
  handleDelete?: () => void;
}

const ProtectedSpaceRow: FC<ProtectedSpaceRowProps> = (props) => {
  const {
    key,
    space,
    editable,
    protectedSpaces,
    errors,
    protectedSpacesLocations,
    handleChangeArea,
    handleChange,
    handleDelete,
  } = props;
  const [t] = useTranslation();
  const [name, setName] = useState(space.nombre);
  const [area, setArea] = useState(space.superficieArea);
  const [location, setLocation] = useState(space.ubicacion?.nombre);
  const [surface, setSurface] = useState(space.superficieProyecto);

  const getFormattedArea = (value?: any) => {
    if (value) {
      let area;
      const parts = value.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      if (parts.length == 2) {
        area = parts[0] + ',' + parts[1];
      } else {
        area = parts[0];
      }
      return area;
    } else if (value === 0) {
      return 0;
    }
    return null;
  };

  return (
    <Grid
      key={`${space.nombre}-${key}`}
      container
      spacing={2}
    >
      <Grid item xs>
        {editable ? (
          <MultipleSelect
            error={!!(errors && errors?.length > 0 && space.areasProtegidas?.length === 0)}
            editable={true}
            protectedSpaces={protectedSpaces}
            selectedCodesProtectedSpaces={space.areasProtegidas}
            handleChange={(data: any) => handleChange(data, 'areasProtegidas')}
          />
        ) : <MultipleSelectView id="protectedSpaces" label={t('details.location.labelProtectedSpaces')} values={space.areasProtegidas ?? []}/>}
      </Grid>
      <Grid item xs>
        <TitleValue
          identifier="location-name"
          title={t('details.location.name')}
          value={space.nombre}
          editable={editable}
          error={errors && errors?.length > 0 && !name}
          handleChange={(data) => {
            setName(data);
            handleChange(data, 'nombre');
          }}
          valueMaxLength={255}
        />{' '}
      </Grid>
      <Grid item xs>
        <TitleValue
          identifier="location-protectedArea"
          title={t('details.location.protectedArea')}
          error={errors && errors?.length > 0 && !area && area !== 0}
          value={getFormattedArea(space.superficieArea)}
          editable={editable}
          handleChange={(data) => {
            setArea(getFormattedArea(data));
            handleChangeArea(data, 'superficieArea');
          }}
          valueMaxLength={25}
          isDecimalNumber
        />
      </Grid>
      <Grid item xs>
        <SimpleSelect
          id={`location-location-${key}`}
          options={protectedSpacesLocations}
          value={space.ubicacion}
          error={errors && errors?.length > 0 && !location}
          label={t('details.location.location')}
          handleChangeValue={(data) => {
            setLocation(data.nombre);
            handleChange(data, 'ubicacion');
          }}
          editable={editable}
        />
      </Grid>
      <Grid item xs>
        <TitleValue
          identifier="location-projectArea"
          title={t('details.location.projectArea')}
          error={errors && errors?.length > 0 && !surface && surface !== 0}
          value={getFormattedArea(space.superficieProyecto)}
          editable={editable}
          handleChange={(data) => {
            setSurface(getFormattedArea(data));
            handleChangeArea(data, 'superficieProyecto');
          }}
          valueMaxLength={25}
          isDecimalNumber
        />
      </Grid>
      {editable && handleDelete && (
        <Grid item xs="auto">
          <DeleteButton id={`deleteBtn${key}`} onClick={() => handleDelete()} />
        </Grid>
      )}
    </Grid>
  );
};

export default ProtectedSpaceRow;
