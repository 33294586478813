import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CollapsableFilter, { CollapsableFilterChildrenProps } from '../components/collapsableFilter/collapsableFilter';
import styles from './clientsFilters.module.scss';
import FILTER_TYPES from '../../../components/Filters/filterTypes';
import KeywordInput from '../../../components/Filters/Components/keywordInput/keywordInput';
import { AutocompleteMultipleChoice } from '../../../components/Filters/Components/AutocompleteMultichoice/AutocompleteMultichoice';
import { SimpleSelector } from '../../../components/Filters/Components/simpleSelector/simpleSelector';
import { DatePickerRange } from '../../../components/Filters/Components/DatePickerRange/DatePickerRange';

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const ClientsFilters: FC<CollapsableFilterChildrenProps> = (props) => {
  const { open, handleClickOpen } = props;
  const [t] = useTranslation();

  return (
    <CollapsableFilter
      id='clients'
      value={t('advancedFilters.clients')}
      help={t('advancedFilters.clients')}
      handleClickOpen={handleClickOpen}
      open={open}
      advancedFilter={'clients'}
    >
      <div className={styles.clientsFiltersContainer}>
        <div className={styles.collapsableComponent}>
          <AutocompleteMultipleChoice filterType={FILTER_TYPES.clientName} disableScroll={true} noBottomDivider={true} />
        </div>
        <div className={styles.collapsableComponent}>
          <KeywordInput filterType={FILTER_TYPES.clientDesignerScope} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <KeywordInput filterType={FILTER_TYPES.clientDesignerEntity} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <SimpleSelector filterType={FILTER_TYPES.clientDesignerStatus} disableScroll={true} noBottomDivider={true} />
        </div>
        <div className={styles.collapsableComponent}>
          <DatePickerRange filterType={FILTER_TYPES.clientCollaborationEndDate}  noBottomDivider={true} />
        </div>
        <div className={styles.collapsableComponent}>
          <KeywordInput filterType={FILTER_TYPES.scopeWorkManagementDesigner} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <KeywordInput filterType={FILTER_TYPES.entityDesignerWorkManagement} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <DatePickerRange filterType={FILTER_TYPES.startDateWorkManagement}  noBottomDivider={true} />
        </div>
        <div className={styles.collapsableComponent}>
          <SimpleSelector filterType={FILTER_TYPES.workManagementStatus} disableScroll={true} noBottomDivider={true} />
        </div>
        <div className={styles.collapsableComponent}>
          <DatePickerRange filterType={FILTER_TYPES.endDateCollaborationSiteManagement}  noBottomDivider={true} />
        </div>
        <div className={styles.collapsableComponent}>
          <KeywordInput filterType={FILTER_TYPES.scopeTechnicalAssistance} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <KeywordInput filterType={FILTER_TYPES.technicalAssistanceEntity} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <DatePickerRange filterType={FILTER_TYPES.startDateTechnicalAssistance}  noBottomDivider={true} />
        </div>
        <div className={styles.collapsableComponent}>
          <SimpleSelector filterType={FILTER_TYPES.technicalAssistanceStatus} disableScroll={true} noBottomDivider={true} />
        </div>
        <div className={styles.collapsableComponent}>
          <DatePickerRange filterType={FILTER_TYPES.endDateCollaborationTechnicalAssistance}  noBottomDivider={true} />
        </div>
      </div>
    </CollapsableFilter>
  );
};

export default ClientsFilters;
