import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './datePicker.module.scss';
import dayjs, { Dayjs } from 'dayjs';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DatePickerProps {
  value: string;
  handleChange?: (s: string) => any;
  inputProps?: any;
  maxDate?: Date;
  minDate?: Date;
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const DatePicker: FC<DatePickerProps> = (props) => {
  const { value, handleChange, inputProps, maxDate, minDate } = props;
  const [valueState, setValueState] = useState(value);
  const [t] = useTranslation();

  const standarDate = (date: string) => {
    return date?.toString().split('/').reverse().join('/');
  };

  const getSanitizedDateFromString = (date: any) => {
    const day = date.date() < 10 ? `0${date.date()}` : date.date();
    const month = date.month() + 1 < 10 ? `0${date.month() + 1}` : date.month() + 1;
    const year = date.year();
    return `${day}/${month}/${year}`;
  };

  const handleChangeValue = (dateFieldValue?: any) => {
    let textFieldValue = '';
    if (dateFieldValue) {
      textFieldValue = getSanitizedDateFromString(dateFieldValue);
    }
    handleChange ? handleChange(textFieldValue) : null;
    setValueState(textFieldValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} >
      <div className={styles.datePickerContainer}>
        <MobileDatePicker
          openTo='year'
          format='DD/MM/YYYY'
          views={['year', 'month', 'day']}
          className='datePickerTitleValue'
          closeOnSelect={true}
          value={dayjs(standarDate(valueState))}
          onChange={(newValue) => handleChangeValue(newValue)}
          maxDate={dayjs(maxDate)}
          minDate={dayjs(minDate)}
          slotProps={{
            textField: {
              variant: 'standard', InputProps: {
                disableUnderline: true,
                ...inputProps
              },
            }
          }}
          slots={
            {
              actionBar: (props: any) => (<div />),

            }
          }

          sx={{
            '&.MuiPickersYear': {
              'backgroundColor': 'red'
            },
            '.MuiInputAdornment-root': {
              marginBottom: '10px',
              color: 'rgba(0,0,0,0)'
            },
            width: '100%'
          }}
        />
        {

          (valueState && valueState !== '--') ?

            <div className={styles.clearDatePickerContainer}>

              <IconButton
                className={styles.clearDatePicker}
                edge="end"
                size="small"
                onClick={() => handleChangeValue(null)}
              >
                <ClearIcon />
              </IconButton>
            </div>
            : null
        }
      </div>
    </LocalizationProvider >
  );
};

export default DatePicker;
