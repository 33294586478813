import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './taxonomy.module.scss';
import { ObraProperties } from '../../../../types/ObraDetails';
import TitleHelp from '../../components/titleHelp/titleHelp';
import InfoBoxBoolean from '../../components/InfoBoxBoolean/InfoBoxBoolean';
import InfoBox from '../../components/infoBox/infoBox';
import { ReactComponent as checkFalse } from '../../../assets/check_false_disable.svg';

interface TaxonomyProps {
  obra: ObraProperties,
}

const Taxonomy: FC<TaxonomyProps> = (props) => {
  const { obra } = props;
  const { t, i18n } = useTranslation();

  return (
    <div data-testid='esg-test' id='esg-nodata' className={styles.container}>
      <TitleHelp
        id='taxonomy'
        value={t('details.esg.taxonomy.helper')}
        help={t('help.esg.taxonomy')}
      />
      <div className={styles.sectionContainer}>
        <div className={styles.eligibility}>
          <InfoBoxBoolean
            identifier='eligibility'
            item={{ title: t('details.esg.taxonomy.eligibility'), value: obra.esg?.eligibilidad ? t('details.esg.taxonomy.yes') : t('details.esg.taxonomy.no') }}
            value={obra.esg?.eligibilidad}
            styles={
              { box: styles.eligibilityBox }
            }
          />
        </div>
        <div className={styles.code}>
          <InfoBox identifier='code' item={{ title:  t('details.esg.taxonomy.code'), value: !obra.esg.codNace1 || obra.esg.codNace1 === '' ? t('details.notInformed') :  obra.esg.codNace1}} customStyle={{ box: styles.codeBox, title: styles.codeTitle }} />
        </div>
      </div>
    </div>
  );
};


export default Taxonomy;
