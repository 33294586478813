import { Popper } from '@mui/material';
import React from 'react';
import './CustomPopper.scss';

export const CustomPopper = (
  {
    props,
    open,
  }: any,
) => {

  return (
    <Popper
      {...props}

      sx={{
        display: open ? 'block' : 'none'
      }}
    />
  );
};
