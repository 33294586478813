import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { InputLabel, MenuItem, Select } from '@mui/material';
import './simpleSelect.scss';
import TitleValue from '../titleValue/titleValue';
import { SelectOption } from '../../../../types/Option';
import styles from './simpleSelect.module.scss';
import { SaveContext } from '../../../../context/SaveProvider';

interface SimpleSelectComponentProps {
  id: string;
  options: SelectOption[];
  value?: SelectOption;
  label: string;
  handleChangeValue?: (o: SelectOption) => void;
  colorSchema?: { [id: string]: string };
  contrast?: boolean;
  editable: boolean;
  error?: boolean;
  customClassName?: string
}

const SimpleSelect: FC<SimpleSelectComponentProps> = (props) => {
  const { id, options, value, label, handleChangeValue, colorSchema, contrast = false, editable, error, customClassName } = props;
  const [isFocused, setIsFocused] = useState(false);
  const [errorClass, setErrorClass] = useState<boolean | undefined>(error);
  const [selectedOption, setSelectedOption] = useState<SelectOption | undefined>(value);
  const saveContext = useContext(SaveContext);

  useEffect(() => {
    setSelectedOption(value);
    setErrorClass(error);
  }, [value, error]);

  const handleChange = (id: number) => {
    setErrorClass(false);
    const selectedValue = options.find((o: SelectOption) => o.id === id);
    selectedValue && setSelectedOption(selectedValue);
    selectedValue && handleChangeValue && handleChangeValue(selectedValue);
    if (error) {
      saveContext.forceUpdate();
    }
  };
  const defaultValue = !editable ? '-' : '';

  const className = useMemo(() =>
    ((!contrast || isFocused) ? 'simple-select' : 'simple-select simple-select-contrast') + ' ' + (errorClass ? styles.error : ''), 
  [contrast, errorClass, isFocused]);

  return <div
    data-testid="simpleSelect-test"
    className={`${className} ${customClassName}`}>
    {editable ?
      <div data-testid="simpleSelect-edit-test">
        <InputLabel
          id={`${id}-standard-label`}
          sx={{
            fontSize: '12px',
            color: error ? 'red' : '#696969',
          }}
        >
          {label}
        </InputLabel>
        <Select
          labelId={`${id}-select`}
          id={`${id}-select`}
          value={selectedOption?.nombre || defaultValue}
          disableUnderline={true}
          onChange={(e) => handleChange(parseInt(e.target?.value))}
          onOpen={() => setIsFocused(true)} // Cuando el input obtiene el foco
          onClose={() => setIsFocused(false)} // Cuando el input pierde el foco
          sx={{
            width: '100%',
            border: '0',
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
            borderBottom: error ? '1px solid red' : '1px solid #c0c0c0',
          }}
          renderValue={(selected: any) => {
            // here you can add you logic for your selected value
            return <div
              style={
                {
                  display: 'flex',
                  color: colorSchema && selectedOption?.id && colorSchema[selectedOption.id] || 'default',
                }
              }>
              <span>{selected}</span>
            </div>;
          }}
        >
          {
            options.map((val: any, i: any) => {
              return (<MenuItem value={val.id} key={val.id}>
                <div className={'menu-item'}><span>{val.nombre}</span></div>
              </MenuItem>);
            })}
        </Select>
      </div> : <TitleValue
        identifier={id}
        title={label}
        value={selectedOption?.nombre || defaultValue}
        valueColor={colorSchema && selectedOption?.id && colorSchema[selectedOption.id] || 'default'}
      />
    }
  </div >;
};

export default SimpleSelect;
