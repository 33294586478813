/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, useContext, useEffect } from 'react';
import styles from './tabMenu.module.scss';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ObraProperties } from '../../types/ObraDetails';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';

import { AuthContext } from '../../services/authContextProvider';
import { UserModeContext } from '../../context/UserModeProvider';
import DropdownButton from './dropdownButton/dropdownButton';
import { ADMIN, VALIDATOR } from '../../userRoles/userRoles';
import { DetailsViewContext } from '../../context/DetailsViewProvider';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { SaveContext } from '../../context/SaveProvider';
import CheckSaved from '../checkSaved/checkSaved';
import { Button, SvgIcon, Tab, Tabs } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import ValidateProject from '../validateProject/validateProject';
import SaveDraft from '../saveDraft/saveDraft';
import { ReactComponent as tab_error } from '../../assets/tab_error.svg';
import ObrasAPI from '../../services/APIObras';
import LoadingSpinner from '../loadingSpinner/loadingSpinner';
import ReactGA from 'react-ga4';

interface TabMenuProps {
  obra: ObraProperties;
  options: any[];
  selectedTab: number;
  titles: any[];
  setUserMode: (userMode: string) => void;
  handleClickTab: (tabNumber: number) => void;
  t: TFunction<'translations'>;
  handleFinalErrors: (errors: any[]) => void;
}

function TabMenu(props: TabMenuProps) {
  const { obra, options, titles, selectedTab, handleClickTab, t, setUserMode, handleFinalErrors } = props;
  //const [t, i18n] = useTranslation();
  const authContext = useContext(AuthContext);
  const userModeContext = useContext(UserModeContext);
  const viewContext = useContext(DetailsViewContext);
  const saveContext = useContext(SaveContext);
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const [goBack, setGoBack] = React.useState<boolean>(false);
  const [changeMode, setChangeMode] = React.useState<boolean>(false);


  // const handleUnload = (event: any) => {
  //   if (saveContext.saveDraft !== undefined && !saveContext.saveDraft) {
  //     event.stopImmediatePropagation();
  //   } else if (saveContext.saveDraft) {
  //     event.returnValue = t('checkSaved.text');
  //     event.preventDefault();
  //   }
  // };

  // show or hide arrows of the tabs depends on window screen
  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const showScrollTabs = dimensions.width < 1500;
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize, false);
  }, []);

  // useUnload(handleUnload);

  const handleClick = (i: number) => {
    if (i === 2) {
      viewContext.handleVisualization(true);
    } else {
      viewContext.handleVisualization(false);
    }
    handleClickTab(i);
  };

  const renderOptions = (selected: number): ReactNode => {
    return options.map((opt, i: number) => (
      <div key={opt.tabLabel} className={i === selected ? styles.tabMenuOption_disabled : styles.tabMenuOption}>
        <Tab disableRipple label={titles[i]} onClick={() => handleClick(i)}
          icon={opt.error ? <SvgIcon component={tab_error} inheritViewBox /> : <></>} iconPosition="end"
          sx={{
            '&.MuiTab-root': {
              '.MuiSvgIcon-root': {
                marginLeft: 0,
              }
            }
          }} />
      </div >

    ));
  };

  const isAuthorized = authContext.hasEditorRole();

  const goback = () => {
    saveContext.saveDraft = false;
    saveContext.handleChangeSave(false);
    setGoBack(false);
    navigate('/');
  };

  const discardChanges = () => {
    saveContext.saveDraft = false;
    saveContext.handleChangeSave(false);
    setGoBack(false);
  };

  const isNoEditState = () => {
    return !authContext.hasRole(ADMIN) && !authContext.hasRole(VALIDATOR) && (obra.general.codEstadoPhia === 2 || obra.general.codEstadoPhia === 4 || obra.general.codEstadoPhia === 7);
  };

  if (isNoEditState() && userModeContext.userMode !== 'viewer' && userModeContext.userMode !== 'editorDisabled') {
    userModeContext.handleChangeSwitch('editorDisabled');
    setUserMode('editorDisabled');
  }

  const downloadPDF = async () => {
    const api = ObrasAPI.getInstance();
    ReactGA.event('download', {
      event_category: 'pdf',
      role: authContext.mainRole(false),
      code: obra.general?.codCentro
    });
    setLoading(true);
    
    await api.downloadPDF(obra.general?.codCentro);
    setLoading(false);
  };

  return (
    <div data-testid='tabmenu-test' className={styles.tabMenuContainer}>      
      {
        loading ?
          <LoadingSpinner />
          : null
      }
      <div className={styles.obraInfo} role='dialog'>
        <div className={isAuthorized && userModeContext.userMode === 'editor' ? styles.obraInfoDataAuth : styles.obraInfoData}>

          <div id='goback' className={styles.goBack}>
            <span onClick={() => {
              userModeContext.userMode === 'editor' && saveContext.saveDraft ? setGoBack(true) : navigate('/');
            }}><ArrowBackIosIcon /></span>
            {
              (goBack) ?
                <CheckSaved
                  handleFunction={() => {
                    goback();
                  }}
                  closeFunction={() => {
                    setGoBack(false);
                  }}
                  discardChanges={() => {
                    discardChanges();
                  }}
                />
                : null
            }
          </div>
          <div id='tabmenu-idandname' className={styles.idAndName}>
            <Tooltip
              enterDelay={1000}
              arrow
              title={
                <Typography>
                  {obra.general?.codCentro + ' · ' + (obra.general?.nomInternoCentro || t('details.notInformed'))}
                </Typography>
              }
              followCursor
            >
              <span>{obra.general?.codCentro + ' · ' + (obra.general?.nomInternoCentro || t('details.notInformed'))}</span>
            </Tooltip>
          </div>
          <div id='tabmenu-status' className={styles.status}>
            <span>{obra.general?.nomEstadoPhia || t('details.notInformed')}</span>
          </div>
          {isAuthorized &&
            <>
              <DropdownButton
                options={[
                  { name: t('details.switch.viewer'), value: 'viewer' },
                  { name: t('details.switch.editor'), value: 'editor' }
                ]}
                placeholder={t(`details.switch.${userModeContext.userMode}`)}
                handleClickOption={(option: string) => {
                  if (option !== userModeContext.userMode) {
                    if (option === 'viewer') {
                      if (saveContext.saveDraft) {
                        setChangeMode(true);
                      } else {
                        userModeContext.handleChangeSwitch(option);
                        setUserMode(option);
                      }
                      handleFinalErrors([]);
                    } else {
                      userModeContext.handleChangeSwitch(option);
                      setUserMode(option);
                    }
                  }
                }}
              />
              {
                (changeMode) ?
                  <CheckSaved
                    handleFunction={() => {
                      userModeContext.handleChangeSwitch('viewer');
                      setUserMode('viewer');
                      setChangeMode(false);
                      saveContext.handleChangeSave(false);
                    }}
                    closeFunction={() => {
                      setChangeMode(false);
                    }} />
                  : null
              }
            </>
          }
          <div className={styles.downloadPDF}>
            <Button id='buttonpdf' className={styles.buttonPDF} onClick={() => {
              downloadPDF();
            }} disableRipple={true} variant="contained" startIcon={<DownloadIcon />}>PDF</Button>
          </div>
        </div>
        <div className={styles.obraInfoSaveAndSend}>
          {isAuthorized && (userModeContext.userMode === 'editor' || userModeContext.userMode === 'editorDisabled') &&
            <div
              className={styles.saveAndSend}
            >
              <SaveDraft obra={obra} />
              <ValidateProject obra={obra} handleTabErrors={handleFinalErrors} />
            </div>
          }
        </div>
      </div>
      <div className={styles.tabMenu}>
        <Tabs
          className={styles.tabMenuWrapper}
          value={selectedTab}
          variant="scrollable"
          scrollButtons={showScrollTabs}
          TabIndicatorProps={{
            style: {
              backgroundColor: '#FF0000',
              height: 3,
              top: 45,
            }
          }}>
          {renderOptions(selectedTab)}
        </Tabs>
      </div>
    </div>
  );
}

export default withTranslation()(TabMenu);
