import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutocompleteMultipleChoice } from '../../../components/Filters/Components/AutocompleteMultichoice/AutocompleteMultichoice';
import styles from './basicFilters.module.scss';
import FILTER_TYPES, { FILTER_CONFIG } from '../../../components/Filters/filterTypes';
import CollapsableFilter, { CollapsableFilterChildrenProps } from '../components/collapsableFilter/collapsableFilter';
import { DatePickerRange } from '../../../components/Filters/Components/DatePickerRange/DatePickerRange';
import ContainSelect from '../../../components/Filters/Components/containSelect/containSelect';
import { NumberRange } from '../../../components/Filters/Components/numberRangeFilters/numberRange/numberRange';
import { CheckSelector } from '../../../components/Filters/Components/checkSelector/checkSelector';

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const BasicFilters: FC<CollapsableFilterChildrenProps> = (props) => {
  const { open, handleClickOpen } = props;
  const [t] = useTranslation();
  const [, setScrollDisabled] = useState(false);

  const disableScroll = (disable: boolean) => {
    setScrollDisabled(disable);
  };

  return (
    <CollapsableFilter
      id='basicFilters'
      value={t('advancedFilters.basic')}
      help={t('advancedFilters.basic')}
      handleClickOpen={handleClickOpen}
      open={open}
      advancedFilter={'basic'}
    >
      <div className={styles.basicFiltersContainer}>
        <div className={styles.collapsableComponent}>
          <AutocompleteMultipleChoice filterType={FILTER_TYPES.nameCode} disableScroll={disableScroll} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <AutocompleteMultipleChoice filterType={FILTER_TYPES.country} disableScroll={disableScroll} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <AutocompleteMultipleChoice filterType={FILTER_TYPES.supercenter} disableScroll={disableScroll} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <DatePickerRange filterType={FILTER_TYPES.adjudicationDate}  noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <ContainSelect filterType={FILTER_TYPES.workType} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <NumberRange filterType={FILTER_TYPES.adjudicationAmount} defaultUnit={FILTER_CONFIG.amountCurrency} noBottomDivider />
        </div>
        <div className={styles.collapsableComponent}>
          <CheckSelector filterType={FILTER_TYPES.projectStatus} noBottomDivider />
        </div>
      </div>
    </CollapsableFilter>
  );
};

export default BasicFilters;
