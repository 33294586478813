/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useState, useMemo, useContext, useEffect, useCallback, useRef } from 'react';
import styles from './grid.module.scss';
import { AgGridReact } from 'ag-grid-react';
import ReactGA from 'react-ga4';
import sortDown from '../../../assets/grid/sort-down.svg';
import sortUp from '../../../assets/grid/sort-up.svg';
import unsort from '../../../assets/grid/sort-none.svg';
import filterNotFound from '../../../assets/grid/filter-notFound.svg';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import './grid.scss';
import ObrasAPI from '../../../services/APIObras';
import { useTranslation } from 'react-i18next';
import Error from '../../../components/Error/Error';
import LoadingSpinner from '../../../components/loadingSpinner/loadingSpinner';
import { useNavigate } from 'react-router-dom';
import { GridReadyEvent, IDatasource, SortDirection } from 'ag-grid-community';
import { ViewContext } from '../../../context/ViewProvider';
import { FilterContext } from '../../../context/FilterProvider';
import { VALIDATOR } from '../../../userRoles/userRoles';
import { UserModeContext } from '../../../context/UserModeProvider';
import TagsOverlay from '../../../components/tagsOverlay/tagsOverlay';
import { AuthContext } from '../../../services/authContextProvider';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface gridProps {
  children?: JSX.Element;
  enableExport: any;
}

let order = {
  colId: '',
  sort: ''
};

const cache = {
  cached: false,
  filter: {},
  masterFilters: {},
};

const grid: FC<gridProps> = (props) => {
  const { enableExport } = props;
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const viewContext = useContext(ViewContext);
  const filterContext = useContext(FilterContext);
  const authContext = useContext(AuthContext);
  const [refreshData, setRefreshData] = useState(false);
  const gridRef = useRef<AgGridReact>(null);
  const [errorData, setErrorData] = useState(false);
  const [noRows, setNoRows] = useState(false);
  const userModeContext = useContext(UserModeContext);
  const [noTags, setNoTags] = useState(true);

  const [loading, setLoading] = useState(viewContext.mapIsSelected === false);

  if (filterContext.appliedFilters !== cache.filter && (Object.keys(filterContext.appliedFilters).length > 0 || Object.keys(cache.filter).length > 0)) {
    cache.filter = filterContext.appliedFilters;
    cache.masterFilters = filterContext.masterFilters;
    cache.cached = true;
    setLoading(viewContext.mapIsSelected === false);
    setRefreshData(true);
    setTimeout(() => { setRefreshData(false); }, 100);
  }

  useEffect(() => {
    setRefreshData(true);
    setTimeout(() => { setRefreshData(false); }, 100);
  }, [i18n.language]);

  const [numberList, setNumberList] = useState(0);

  const orderSort = order.sort as SortDirection || undefined;

  const defColumn = (
    headerName: string,
    field: string,
    cellClass: string,
    flex: number,
    headerAlign?: 'left' | 'center' | 'right',
  ) => {
    return {
      headerName,
      field,
      unSortIcon: true,
      cellClass,
      headerClass: headerAlign === 'right' ? 'right' : styles.cellHeader,
      initialSort: order.colId === field ? orderSort : undefined,
      flex
    };
  };

  const columnDefs = [
    defColumn(t('homeList.code'), 'codCentro', styles.cellBold, 1.1),
    defColumn(t('homeList.nomCenter'), 'nomInternoCentro', styles.cell, 2.3),
    defColumn(t('homeList.nomPais'), 'nomPais', styles.cell, 1.0),
    defColumn(t('homeList.year'), 'fecAdjudicacion', styles.cell, 0.7),
    defColumn(t('homeList.import'), 'importeAct', styles.cellRight, 1.0, 'right'),
    defColumn(t('homeList.type'), 'tipoObra', styles.cell, 2.0),
    defColumn(t('homeList.state'), 'estadoContrato', styles.cell, 1.1),
  ];

  const processRow = (data: any) => {
    data.map((row: any) => {
      const splitDate = row.fecAdjudicacion?.split('/');
      row.fecAdjudicacion = splitDate ? splitDate[splitDate.length - 1] : '-';
      row.importeAct = row.importeAct ? `${Number(row.importeAct).toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2, useGrouping: true })} EUR` : '-';
    });
  };

  // never changes, so we can use useMemo
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
  }), []);

  const icons = {
    sortAscending: '<img style="padding-top: 3px;" src=' + sortDown + ' />',
    sortDescending: '<img style="padding-top: 3px;" src=' + sortUp + ' />',
    sortUnSort: '<img style="padding-top: 3px;" src=' + unsort + ' />'
  };

  const onSortChanged = (a: any) => {
    const sortModel = a.columnApi.getColumnState().filter((col: any) => col.sort);
    order = sortModel[0] ? sortModel[0] : {
      colId: '',
      sort: ''
    };
  };

  const handleClick = (row: any) => {
    const api = ObrasAPI.getInstance();
    api.getRoles(row.data.codCentro)
      .then((roles) => {
        if (roles.includes(VALIDATOR)) userModeContext.handleChangeSwitch('editor');
        else userModeContext.handleChangeSwitch('viewer');
        ReactGA.event('click_details', {
          event_category: 'list',
          role: authContext.mainRole(false),
          code: row.data.codCentro
        });
        navigate(`/${row.data.codCentro}/detalles`);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop limit exceeded') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);


  const onGridReady = useCallback((params: GridReadyEvent) => {
    const dataSource: IDatasource = {
      rowCount: undefined,
      getRows: (params) => {
        if (params.startRow === 0) {
          setLoading(true);
        }
        const rowsPage = 100;
        const page = params.endRow / rowsPage;
        let query = `total=${rowsPage}&pagina=${page}`;
        if (order.colId != '' && order.sort != '') {
          query = `${query}&orden=${order.sort}&ordenar_por=${order.colId}`;
        }
        const api = ObrasAPI.getInstance();
        api.getObrasListAPI(
          query,
          filterContext.transformMasterFilters(
            cache.filter,
            cache.cached ? cache.masterFilters : undefined
          )).then((data: any) => {
          setNumberList(data.total);
          if (params.startRow === 0 && data.total === 0) {
            setNoRows(true);
            enableExport(false);
          } else {
            setNoRows(false);
            enableExport(true);
          }
          const { obras } = data;
          processRow(obras);
          let lastRow = -1;
          if (obras.length < rowsPage) {
            lastRow = data.total;
          }
          params.successCallback(obras, lastRow);
          setLoading(false);
        });

      },
    };
    // pass the dataSource object instead of the row data
    params.api.setDatasource(dataSource);
  }, []);

  const getRowStyle = (params: any) => {
    if (params.data === undefined) {
      return { opacity: 0 };
    }
    return { opacity: 1 };
  };

  useEffect(() => {
    const appliedTags = [];
    Object.keys(filterContext.appliedFilters).map((key) => {
      appliedTags.push(...filterContext.appliedFilters[key]);
    });
    setNoTags(appliedTags.length === 0);
  }, [filterContext.appliedFilters]);

  return (
    <div className={styles.grid} data-testid="grid-test">
      {
        (viewContext.mapIsSelected === false) ?
          <div className={styles.resultsNumber}>
            <div id="shownumber" className={styles.showNumber}>
              <div>{t('homeList.show')} <b>{numberList} {numberList !== 1 ? t('homeList.projects') : t('homeList.project')}</b></div>
            </div>
            {!noTags ? <div className={styles.tagsOverlay}>
              <TagsOverlay />
            </div> : null}
          </div> : false
      }
      {
        (loading) ?
          <LoadingSpinner /> : false
      }
      {
        (noRows && viewContext.mapIsSelected === false) ?
          <div className={styles.filterNotFound}>
            <img src={filterNotFound} />
            <div id="titleerror" className={styles.titleError}>{t('homeList.notFoundTitle')}</div>
            <div id="titleerror2" className={styles.titleError2}>{t('homeList.notFoundTitle2')}</div>
            <p className={styles.descriptionError}>{t('homeList.notFoundDescription')}</p>
          </div> : false
      }
      {
        (!errorData) ?
          (viewContext.mapIsSelected === false && !refreshData) ?
            <AgGridReact className="ag-theme-material"
              ref={gridRef}
              onRowClicked={handleClick}
              onSortChanged={onSortChanged}
              defaultColDef={defaultColDef}
              tooltipShowDelay={1000}
              columnDefs={columnDefs}
              getRowStyle={getRowStyle}
              icons={icons}
              rowModelType={'infinite'}
              onGridReady={onGridReady}
            ></AgGridReact>
            : false
          :
          <div className={styles.dataListError} >
            <Error />
          </div>
      }
    </div >
  );
};

export default grid;
