import { TFunction } from 'i18next';
import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import styles from './exportXlsx.module.scss';
import exportExcelIcon from '../../assets/exportExcel.svg';
import { Button, Dialog, Stack, SvgIcon } from '@mui/material';
import { FilterContext } from '../../context/FilterProvider';
import ObrasAPI from '../../services/APIObras';
import spinner from '../../assets/loader_export.svg';
import { ReactComponent as error } from '../../assets/error.svg';
import ReactGA from 'react-ga4';
import { AuthContext } from '../../services/authContextProvider';
import { DOWNLOAD } from '../../userRoles/userRoles';
interface ExportXlsxProps {
  t: TFunction<'translations'>;
  enableExport: boolean;
}
function ExportXlsx(props: ExportXlsxProps) {
  const { t, enableExport } = props;
  const filterContext = useContext(FilterContext);
  const authContext = useContext(AuthContext);
  const api = ObrasAPI.getInstance();
  const [hasPermission, setHasPermission] = React.useState(true);
  const [openError, setOpenError] = React.useState(false);
  const [exporting, setExporting] = React.useState(false);

  const downloadCSV = async () => {
    try {
      setExporting(true);
      if (!authContext.hasDownloadRole()) {
        ReactGA.event('download', {
          event_category: 'excel',
          role: authContext.mainRole(true),
          hasPermissions: false,
          numFilters: Object.keys(filterContext.appliedFilters).length,
        });
        setOpenError(true);
        setHasPermission(false);
        setExporting(false);
      } else if (filterContext.getAppliedFiltersCount() === 0 && filterContext.getAppliedAdvancedFiltersCount() === 0) {
        ReactGA.event('download', {
          event_category: 'excel',
          role: authContext.mainRole(true),
          hasPermissions: true,
          numFilters: 0,
        });
        setHasPermission(true);
        setOpenError(true);
        setExporting(false);
      } else {
        ReactGA.event('download', {
          event_category: 'excel',
          role: authContext.mainRole(true),
          hasPermissions: true,
          numFilters: Object.keys(filterContext.appliedFilters).length,
        });
        await api.getObrasXLSXAPI(filterContext.transformMasterFilters(filterContext.appliedFilters));
        setHasPermission(true);
        setExporting(false);
      }
    } catch (error) {
      console.log(error);
      if ((error as any).response && (error as any).response.status === 403) {
        setHasPermission(false);
      } else {
        setHasPermission(true);
      }
      setOpenError(true);
      setExporting(false);
    }
  };

  return (
    <div data-testid='exportXlsx-test' className={styles.exportContainer}>
      {exporting ?
        <Button
          variant="text"
          color="error"
          disabled={true}
          className={styles.buttonDisable}
          sx={{ textTransform: 'none' }}
          startIcon={<img src={spinner} />}
        >
          {t('exportExcel.textButton')}
        </Button>

        :
        <Button
          variant="text"
          onClick={() => downloadCSV()}
          className={ enableExport ? styles.buttonEnable : styles.buttonDisableExport}
          disabled={!enableExport}
          sx={{ textTransform: 'none' }}
          startIcon={<img src={exportExcelIcon} />}
        >
          {t('exportExcel.textButton')}
        </Button>
      }
      <Dialog
        classes={{
          paper: styles.dialogError
        }}
        BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.15)' } }}
        PaperProps={{ sx: { borderRadius: '20px', minWidth: '700px' } }}
        open={openError}
        onClose={() => setOpenError(false)}
      >
        <div className={styles.dialogContentError}>
          <div data-testid='error-test' className={styles.serverError}>
            {
              !hasPermission ?
                <div className={styles.defaultError}>
                  <div id='error-title' className={styles.title}>{t('exportExcel.needPermissions')}</div>
                  <div id='error-title2' className={styles.title2}>{t('exportExcel.serviceNow')}</div>
                </div>
                :
                filterContext.getAppliedFiltersCount() === 0 && filterContext.getAppliedAdvancedFiltersCount() === 0 ?
                  <div className={styles.defaultError}>
                    <div id='error-title' className={styles.title}>{t('exportExcel.errorFilterTitle')}</div>
                    <div id='error-subtitle' className={styles.subtitle}>{t('exportExcel.errorFilterDescription')}</div>
                  </div>
                  :
                  <div className={styles.serverErrorContent}>
                    <SvgIcon component={error} inheritViewBox />
                    <div id='error-title' className={styles.title}>{t('exportExcel.titleErrorExport')}</div>
                    <div id='error-subtitle' className={styles.subtitle}>{t('exportExcel.textErrorExport')}</div>
                  </div>
            }
          </div>
          <Stack spacing={2} direction="row" justifyContent="center">
            <Button className={styles.buttonConfirm} onClick={() => {
              setOpenError(false);
            }} disableRipple={true} variant="contained">{t('exportExcel.acceptButton')}</Button>
          </Stack>
        </div>
      </Dialog>
    </div >
  );
}

export default withTranslation()(ExportXlsx);