import { Option } from './Option';

export enum Mode {
    ADD = 'add',
    DELETE = 'delete',
    REPLACE = 'replace'
}

export enum ACCION {
    APPLY = 'apply',
    CLOSE = 'close',
    CLEAR = 'clear',
    DELETE = 'delete'
}

export interface FilterValue {
    code: string,
    name: string,
    elements?: Option[],
    codigos?: number[],
    tag?: string,
    workTypeCode?: string,
    unitCode?: string,
}