import React, { FC, useState } from 'react';
import { Button, Stack, TextField } from '@mui/material';
import parse from 'html-react-parser';

import styles from './comment.module.scss';
import { useTranslation } from 'react-i18next';
import { Comentario } from '../../../../types/ObraDetails';

interface CommentProps {
  comentario: Comentario,
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const Comment: FC<CommentProps> = (props) => {
  const { comentario } = props;

  const { t } = useTranslation();

  const inputStyle = {
    '&.MuiTextField-root': {
      '.MuiInputLabel-root': {
        color: 'rgba(122, 122, 122, 1)'
      },
      '.Mui-focused': {
        '&::after': {
          borderBottom: '2px solid rgba(122, 122, 122, 1)'
        }
      },
      '.MuiFilledInput-root': {
        '&::after': {
          borderBottom: '1px solid rgba(122, 122, 122, 1)',

        },
        backgroundColor: '#fff',

      }
    }
  };



  return (
    <div className={styles.dialogContent}>
      <div className={styles.dialogForm}>
        <div className={styles.title}>
          <TextField
            label={t('actions.rejectMotive')}
            variant='filled'
            sx={inputStyle}
            value={comentario.titulo}
            disabled
          />
        </div>
        <div className={styles.details}>
          <TextField
            variant='filled'
            sx={inputStyle}
            multiline
            rows={4}
            value={comentario.detalles}
            disabled
          />
        </div>
      </div>

    </div>


  );
};

export default Comment;
