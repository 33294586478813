/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useState,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import debounce from 'lodash.debounce';
import Collapse from '@mui/material/Collapse';
import Tags from './Tags/Tags';
import { Title } from './Title/Title';
import styles from './filterCollapsable.module.scss';
import { Divider } from '@mui/material';
import { FilterContext } from '../../../../context/FilterProvider';
import FILTER_TYPES from '../../filterTypes';

export const FilterCollapsableWrapper = (
  {
    children,
    filterType,
    handleClickButton,
    buttonText,
    tagsDisabled,
    noBottomDivider
  }: any,
) => {
  const filterContext = useContext(FilterContext);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const toggleIsOpenFilter = () => {
    setIsOpenFilter((prevState) => !prevState);
  };

  useEffect(() => {
    if (isOpenFilter) {
      filterContext.setOpenFilter(filterType);
      if (filterType === FILTER_TYPES.workTypeTechnicalAspects) {
        filterContext.handleBulkChange(FILTER_TYPES.workTypeTechnicalAspects, []);
      }
      if (filterType === FILTER_TYPES.classificationEstimatedAmount) {
        filterContext.handleBulkChange(FILTER_TYPES.classificationEstimatedAmount, []);
      }
      if (filterType === FILTER_TYPES.classificationFinalAmount) {
        filterContext.handleBulkChange(FILTER_TYPES.classificationFinalAmount, []);
      }
    }
  }, [isOpenFilter]);

  useEffect(() => {
    if (filterContext.openFilter !== filterType) {
      setIsOpenFilter(false);
    }
  }, [filterContext.openFilter]);

  const tags = filterContext.getTags(filterType);
  const tagsKeyword = filterContext.getTags(`${filterType}Keyword`);
  const tagsGroupType = filterContext.getTags(`${filterType}GroupType`);
  const changeUnselectedHandler = () => {
    console.log('update list');
  };

  const debounceUnSelectHandler = useCallback(
    debounce(changeUnselectedHandler, 100),
    [],
  );
  return (
    <>
      <Title
        filterType={filterType}
        tags={filterType !== 'workType' ? tags : [...tagsKeyword, ...tagsGroupType]}
        isOpenFilter={isOpenFilter}
        toggleIsOpenFilter={toggleIsOpenFilter}
        hoverable={!noBottomDivider}
      />
      <Collapse
        in={isOpenFilter}
        timeout="auto"
        unmountOnExit
      >
        {children}
        {Array.isArray(tags) &&
          <Tags
            tagsDisabled={tagsDisabled}
            tags={tags}
            debounceUnSelectHandler={debounceUnSelectHandler}
            filterType={filterType}
            handleClickButton={handleClickButton}
            buttonText={buttonText}
          />}
        {Array.isArray(tagsKeyword) &&
          <Tags
            tagsDisabled={tagsDisabled}
            tags={tagsKeyword}
            debounceUnSelectHandler={debounceUnSelectHandler}
            filterType={`${filterType}Keyword`}
            handleClickButton={handleClickButton}
            buttonText={buttonText}
          />}
        {Array.isArray(tagsGroupType) &&
          <Tags
            tagsDisabled={tagsDisabled}
            tags={tagsGroupType}
            debounceUnSelectHandler={debounceUnSelectHandler}
            filterType={`${filterType}GroupType`}
            handleClickButton={handleClickButton}
            buttonText={buttonText}
          />}
      </Collapse>
      {!noBottomDivider && <Divider className={styles.divider} />}
    </>);
};
