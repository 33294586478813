import React from 'react';
import { useTranslation } from 'react-i18next';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as error } from '../../../../assets/errorDetails.svg';
import './ErrorDetails.scss';

const ErrorDetails = () => {
  const { t } = useTranslation();
  return <div data-testid='errorDetails-test' className='server-error-details'>
    <div className='server-error-details-content'>
      <div><SvgIcon component={error} inheritViewBox /></div>
      <div>
        <div id='error-title' className='title'>{t('details.error.main')}</div>
        <div id='error-subtitle' className='subtitle'>{t('details.error.second')}</div>
      </div>
    </div>
  </div>;
};

export default ErrorDetails;

