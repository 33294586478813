/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../../../loadingSpinner/loadingSpinner';
import styles from './KMZLayer.module.scss';

interface KMZLayerProps {
  mapInstance: google.maps.Map;
  url: string;
  obra: any;
  callback?: (error?: string) => void;
}

function KMZLayer(props: KMZLayerProps) {
  const { mapInstance, url, callback } = props;
  const [layerReady, setLayerReady] = useState(false);
  const [, setDataError] = useState(false);

  useEffect(() => {
    if (window.google && mapInstance) {

      // eslint-disable-next-line no-async-promise-executor
      new Promise(async (res) => {
        if (url) {
          await addKMZToMap();
        } else {
          setLayerReady(true);
        }
        res(true);
      });
    }
  }, [mapInstance, url]);

  const addKMZToMap = async () => {
    try {
      const kmzLayer = new google.maps.KmlLayer({ url: url.replace(/\s/g, '%20') });
      kmzLayer.setMap(mapInstance);

      google.maps.event.addListener(kmzLayer, 'status_changed', () => {
        setLayerReady(true);
        const status = kmzLayer.getStatus();
        if (status === 'OK') {
          setDataError(false);
          callback && callback(url);
        } else {
          setDataError(true);
          callback && callback(status);
        }
      });


    } catch (e) {
      setDataError(true);
    }
  };

  const renderLayer = () => {
    return <div data-testid='kmz-layer' className={styles.kmzLayer} >
      <div id='kmzlayer-loading' className={styles.kmzLayerLoading} style={{
        display: layerReady ? 'none' : 'block',
      }}>
        <LoadingSpinner />
      </div>
    </div >;
  };
  return renderLayer();
}

export default (KMZLayer);
