/* eslint-disable @typescript-eslint/no-explicit-any */
import React,
{
  useState,
  useContext,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import DATE_PICKER_RANGE_TYPE from '../DatePickerRangeType';
import { Mode } from '../../../../../types/FiltersTypes';
import { FilterContext } from '../../../../../context/FilterProvider';
import { CustomTextField } from '../CustomTextField/CustomTextField';
import { CustomPopper } from '../CustomPopper/CustomPopper';
import FILTER_TYPES, { DATES_FILTERS } from '../../../filterTypes';

const FILTERS_NO_DATE_LIMIT = [
  ...DATES_FILTERS,
  FILTER_TYPES.clientCollaborationEndDate,
  FILTER_TYPES.endDateCollaborationTechnicalAssistance,
  FILTER_TYPES.startDateWorkManagement,
  FILTER_TYPES.endDateCollaborationSiteManagement,
  FILTER_TYPES.startDateTechnicalAssistance
];

export const CustomDatePicker = (
  {
    filterType,
    datePickerRangeType,
  }: any,
) => {
  const { t } = useTranslation();
  const filterContext = useContext(FilterContext);
  const [open, setOpen] = useState<boolean>(false);

  const dateFilterType = `${datePickerRangeType}${filterType}`;
  const value = useMemo(() => {
    setOpen(false);
    return filterContext.filters[dateFilterType] && filterContext.filters[dateFilterType][0]?.name;
  }, [filterContext.filters[dateFilterType]]);

  const handleOnAccept = (dateFieldValue?: any) => {
    if (!dateFieldValue) return;
    const textFieldValue = getSanitizedDateFromString(dateFieldValue);
    filterContext.handleChange(dateFilterType,
      {
        code: textFieldValue,
        name: dateFieldValue,
        tag: `${getLabel()}: ${textFieldValue}`,
      },
      Mode.ADD);
  };


  const getSanitizedDateFromString = (date: any) => {
    const day = date.date() < 10 ? `0${date.date()}` : date.date();
    const month = date.month() + 1 < 10 ? `0${date.month() + 1}` : date.month() + 1;
    const year = date.year();
    return `${day}/${month}/${year}`;
  };

  const getMinDate = (): any => {
    let minDate = null;
    if (
      datePickerRangeType === DATE_PICKER_RANGE_TYPE.endDate
      && filterContext.filters[`${DATE_PICKER_RANGE_TYPE.startDate}${filterType}`]
      && filterContext.filters[`${DATE_PICKER_RANGE_TYPE.startDate}${filterType}`][0]
    ) {
      minDate = filterContext.filters[`${DATE_PICKER_RANGE_TYPE.startDate}${filterType}`][0].name;
    }
    return minDate;
  };
  const minDate = getMinDate();

  const getMaxDate = (): any => {
    let maxDate = null;
    if (
      datePickerRangeType === DATE_PICKER_RANGE_TYPE.startDate
      && filterContext.filters[`${DATE_PICKER_RANGE_TYPE.endDate}${filterType}`]
      && filterContext.filters[`${DATE_PICKER_RANGE_TYPE.endDate}${filterType}`][0]
    ) {
      maxDate = filterContext.filters[`${DATE_PICKER_RANGE_TYPE.endDate}${filterType}`][0].name;
    }else if(!FILTERS_NO_DATE_LIMIT.includes(filterType)){
      maxDate = new Date();
    }
    return maxDate;
  };
  const maxDate = getMaxDate();

  const getLabel = () => {
    if (
      datePickerRangeType === DATE_PICKER_RANGE_TYPE.startDate
    ) {
      return t('datepicker.from');
    }
    if (
      datePickerRangeType === DATE_PICKER_RANGE_TYPE.endDate
    ) {
      return t('datepicker.to');
    }
    return;
  };


  const renderInput = (props: any) => {
    return <CustomTextField
      props={{...props, backgroundColor: '#FFFFFF'}}
      handleOpen={() => {
        setOpen(!open);
      }}
    />;
  };

  const renderPopper = (props: any) => {
    return <CustomPopper
      props={props}
      open={open}
    />;
  };
  return (<>
    <LocalizationProvider dateAdapter={AdapterDayjs} >
      <DatePicker
        data-testid="datePicker-test"
        closeOnSelect={true}
        open={open}
        openTo='year'
        format='DD/MM/YYYY'
        views={['year', 'month', 'day']}
        onAccept={handleOnAccept}
        value={(value
          ? value
          : null
        )}
        onClose={() => setOpen(false)}
        minDate={minDate}
        maxDate={dayjs(maxDate)}
        label={getLabel()}
        slots={
          {
            textField: renderInput,
            actionBar: () => (<div />),
            popper: renderPopper
          }
        }
        sx={{
          color: 'blue',
          '&.MuiPickersPopper-root': {
            display: 'none'
          }
        }}

      />

    </LocalizationProvider >
  </>);
};
