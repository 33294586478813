import React, { useContext } from 'react';
import styles from './tabMenuAdmin.module.scss';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import { AuthContext } from '../../services/authContextProvider';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { SaveContext } from '../../context/SaveProvider';
import CheckSaved from '../checkSaved/checkSaved';
import { AdminContext } from '../../context/AdminProvider';
import useUnload from '../../utils/useUnload';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TabMenuAdminProps {
  editRoles?: boolean;
}



function TabMenuAdmin(props: TabMenuAdminProps) {
  const { editRoles } = props;
  const authContext = useContext(AuthContext);
  const saveContext = useContext(SaveContext);
  const navigate = useNavigate();
  const adminContext = useContext(AdminContext);

  const [goBack, setGoBack] = React.useState<boolean>(false);

  // const handleUnload = (event: any) => {
  //   if (saveContext.saveDraft !== undefined && !saveContext.saveDraft) {
  //     event.stopImmediatePropagation();
  //   } else if (saveContext.saveDraft) {
  //     event.returnValue = t('checkSaved.text');
  //     event.preventDefault();
  //   }
  // };

  // useUnload(handleUnload);

  const isAuthorized = authContext.hasEditorRole();

  const handleNavigation = (route: number) => {
    if (adminContext.isEdition)  adminContext.handleSetIsEdition(false);
    else navigate(route);
  };

  return (
    <div data-testid='tabmenu-test' className={styles.tabMenuContainer}>
      <div className={styles.obraInfo} role='dialog'>
        <div className={isAuthorized ? styles.obraInfoDataAuth : styles.obraInfoData}>
          <div id='goback' className={styles.goBack}>
            <span onClick={() => handleNavigation(-1)
            }><ArrowBackIosIcon /></span>
          </div>
          <div id='tabmenu-idandname' className={styles.idAndName}>
            <Tooltip
              enterDelay={1000}
              arrow
              title={
                <Typography>
                  {t('header.adminPanel')}
                </Typography>
              }
              followCursor
            >
              <span>{editRoles ? adminContext.userToEdit?.name : t('header.adminPanel')}</span>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(TabMenuAdmin);
