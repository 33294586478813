/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useState } from 'react';
import styles from './switchInfoBox.module.scss';
import redSwitch from '../../../../assets/redSwitch.svg';
import blueSwitch from '../../../../assets/blueSwitch.svg';

interface Field {
  title: string;
  value: string;
}

interface SwitchInfoBoxProps {
  id: string;
  item: Field;
  switchItem: string;
  switchData: boolean;
}

const SwitchInfoBox: FC<SwitchInfoBoxProps> = (props) => {
  const { id, item, switchItem, switchData } = props;
  const [isEur, setIsEur] = useState(true);

  return (
    <div className={styles.box}>
      <div className={styles.fieldWrapper}>
        <div className={styles.field}>
          <div className={styles.title} id={`${id}-itemTitle`}>{item.title}</div>
          <div className={styles.value} id={`${id}-itemValue`}>{isEur ? item.value : switchItem}</div>
        </div>
      </div>
      {switchData &&
      <div className={styles.switchButtonContainer}>
        <img
          src={isEur ? redSwitch : blueSwitch} 
          className={styles.switchButton}
          onClick={()=>setIsEur(!isEur)}
          id='switchButton'/>
      </div>}
    </div>
  );
};

export default SwitchInfoBox;