import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import styles from './dropdownButton.module.scss';
import React, { LegacyRef, MouseEventHandler, useRef, useState } from 'react';

interface Option {
  name: string;
  value: string;
}

interface DropdownButtonProps {
  options: Option[];
  placeholder: string;
  handleClickOption: (option: string) => void;
}

const DropdownButton = (props: DropdownButtonProps) => {
  const { options, placeholder, handleClickOption } = props;
  const [dropdownIsActive, setDropdownIsActive] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>();
  const menuRef = useRef<HTMLUListElement>();

  window.addEventListener('click', (e) => {
    if (e.target !== buttonRef.current && e.target !== menuRef.current) {
      setDropdownIsActive(false);
    }
  });

  return (
    <div
      className={styles.userModeSwitch}
    >
      <div id='dropdownmenu' className={styles.dropdownMenu}>
        <button
          ref={buttonRef as LegacyRef<HTMLButtonElement>}
          onClick={() => {
            setDropdownIsActive(!dropdownIsActive);
          }}
        >
          {placeholder}
          {!dropdownIsActive
            ? <ArrowDropDown style={{ zIndex: '-1' }} />
            : <ArrowDropUp />}
        </button>
        {dropdownIsActive &&
          <ul className={styles.dropdownMenuOptions} ref={menuRef as LegacyRef<HTMLUListElement>}>
            {options.map(({ name, value }) => {
              return (
                <li key={value} onClick={() => handleClickOption(value) as unknown as MouseEventHandler<HTMLLIElement>}>
                  {name}
                </li>);
            })}
          </ul>
        }
      </div>
    </div>
  );
};

export default DropdownButton;