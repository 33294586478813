import { FC, useContext, useEffect, useState } from 'react';
import { t } from 'i18next';
import parse from 'html-react-parser';
import styles from './editProtectedSpaces.module.scss';
import { ObraProperties } from '../../../../../types/ObraDetails';
import { SaveContext } from '../../../../../context/SaveProvider';
import TitleHelp from '../../../components/titleHelp/titleHelp';
import ProtectedSpace from '../../components/protectedSpace/protectedSpace';
import protectionSpace from '../../../../../assets/protectionSpace.svg';
import { UserModeContext } from '../../../../../context/UserModeProvider';

interface EditProtectedSpacesProps {
  obra: ObraProperties;
  errors?: any[];
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const EditProtectedSpaces: FC<EditProtectedSpacesProps> = (props) => {
  const { obra, errors } = props;
  const userModeContext = useContext(UserModeContext);
  const isEditorMode = userModeContext.userMode === 'editor';
  const saveContext = useContext(SaveContext);
  const [protectedSpaces, setProtectedSpaces] = useState(obra.localizacion?.espacioProteccion || []);

  useEffect(() => {
    const newSpaces: any = [];
    obra.localizacion.espacioProteccion.map((mun) => newSpaces.push(mun));
    setProtectedSpaces(newSpaces);
  }, [obra.localizacion.espacioProteccion.length]);

  const handleAddProtectedSpace = () => {
    const newProtectedSpace = { id: undefined, areasProtegidas: [], nombre: '', superficieArea: null, ubicacion: { id: 0, nombre: '' }, superficieProyecto: null };
    setProtectedSpaces([...protectedSpaces, newProtectedSpace]);
    if (obra.localizacion?.espacioProteccion)
      obra.localizacion.espacioProteccion.push(newProtectedSpace);
    else
      obra.localizacion.espacioProteccion = [newProtectedSpace];

    saveContext.handleChangeSave(true);
  };

  const handleDelete = (i: number) => {
    obra.localizacion.espacioProteccion.splice(i, 1);
    setProtectedSpaces([...obra.localizacion.espacioProteccion]);
    saveContext.handleChangeSave(true);
  };

  return (
    <div className={styles.protectedpacesContainer}>
      <TitleHelp
        id='protectedspace'
        value={t('details.location.protectedSpace')}
        help={t('help.localization.protectedSpace')}
        addItemText={t('details.location.addProtectedSpace') as string}
        handleAddItem={handleAddProtectedSpace}
        error={errors && errors.length > 0 ? ' ' : null}
      />
      {
        obra.localizacion.espacioProteccion.length > 0 ?
          <ProtectedSpace obra={obra} handleDelete={handleDelete} errors={errors} editable={isEditorMode} />
          :
          <div id='protectionspace-nodata' className={styles.noDataPic}>
            <img src={protectionSpace} />
            <p>{parse(t('details.location.addProtectionSpace'))}</p>
          </div>
      }
    </div >
  );
};

export default EditProtectedSpaces;