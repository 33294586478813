/* eslint-disable linebreak-style */
import { useCallback } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import unePicture from '../../../../../../assets/une.svg';
import personPicture from '../../../../../../assets/person.svg';
import clientPicture from '../../../../../../assets/client.svg';
import accionaLogo from '../../../../../../assets/accionaLogo.svg';
import InfoBox from '../../../../components/infoBox/infoBox';

interface Props {
    obra: Record<string, any>;
}

const StartSection = ({obra}: Props) => {
  const { t } = useTranslation();

  const getBudget = useCallback((): string => {
    const budget = obra.presupuesto?.importeAdjudicacion?.monedaEur;
    if (!budget) return t('details.notInformed');
    const [wholeNumber, decimal] = budget.toString().split('.');
    return `${wholeNumber.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}${
      decimal ? ',' + decimal.substring(0, 2) : ',00'
    } EUR`;
  }, [obra]);

  return (
    <Grid item container spacing={2}>
      <Grid item xs={6}>
        <InfoBox
          identifier="projectName"
          picture={unePicture}
          item={{
            title: t('details.generalData.projectName'),
            value: obra.general.nomOficialCentro || t('details.notInformed'),
          }}
          overwriteStyle={{ marginBottom: 'auto', height: 'auto' }}
        />
      </Grid>
      <Grid item xs={6} display="flex" flexDirection="column">
        <InfoBox
          identifier="manager"
          picture={personPicture}
          item={{
            title: t('details.generalData.manager'),
            value: obra.general.nomResponsable || t('details.notInformed'),
          }}
          overwriteStyle={{ marginBottom: 'auto', height: 'auto', flex: 1 }}
        />
      </Grid>
      <Grid item xs={6} md={6} lg={3} display="flex" flexDirection="column">
        <InfoBox
          identifier="client"
          picture={clientPicture}
          item={{
            title: t('details.generalData.clientName'),
            value: obra.cliente.nomCliente || t('details.notInformed'),
          }}
          overwriteStyle={{ marginBottom: 'auto', height: 'auto', flex: 1 }}
        />
      </Grid>
      <Grid item xs={6} md={6} lg={3} display="flex" flexDirection="column">
        <InfoBox
          identifier="accionaparticipation"
          picture={accionaLogo}
          item={{
            title: t('details.generalData.accionaParticipation'),
            value:
              `${
                obra.general.porcParticipacionActualizada?.toString() || 0
              }%` || t('details.notInformed'),
          }}
          overwriteStyle={{ marginBottom: 'auto', height: 'auto', flex: 1 }}
        />
      </Grid>
      <Grid container item md={12} lg={6} spacing={2}>
        <Grid item xs={6}>
          <InfoBox
            identifier="budget"
            item={{
              title: t('details.generalData.budget'),
              value: getBudget(),
            }}
            overwriteStyle={{ marginBottom: 'auto', height: 'auto' }}
          />
        </Grid>
        <Grid item xs={6}>
          <InfoBox
            identifier="awarddate"
            item={{
              title: t('details.generalData.awardDate'),
              value:
                obra.presupuesto.fecAdjudicacion || t('details.notInformed'),
            }}
            overwriteStyle={{ marginBottom: 'auto', height: 'auto' }}
          />
        </Grid>
        <Grid item xs={6}>
          <InfoBox
            identifier="businessactivity"
            item={{
              title: t('details.generalData.businessActivity'),
              value: obra.general.actividadNegocio || t('details.notInformed'),
            }}
            overwriteStyle={{ marginBottom: 'auto', height: 'auto' }}
          />
        </Grid>
        <Grid item xs={6}>
          <InfoBox
            identifier="tendercode"
            item={{
              title: t('details.generalData.tenderCode'),
              value: (obra.general.codLicitacion && obra.general.codLicitacion != '0')
                ? obra.general.codLicitacion?.toString()
                : t('details.notInformed'),
            }}
            overwriteStyle={{ marginBottom: 'auto', height: 'auto' }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StartSection;
