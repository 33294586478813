/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/react-in-jsx-scope */
import Keycloak from 'keycloak-js';
import { createContext, useEffect, useState } from 'react';
import { UserMode } from '../context/UserModeProvider';
import { ADMIN, DOWNLOAD, EDITOR, VALIDATOR, VIEWER, VIEWER_VIP } from '../userRoles/userRoles';


/**
 * KeycloakInitOptions configures the Keycloak client.
 */
const keycloakInitOptions = {
  // Configure that Keycloak will check if a user is already authenticated (when opening the app or reloading the page). If not authenticated the user will be send to the login form. If already authenticated the webapp will open.
  // onLoad: 'login-required',
  redirectUri: process.env.REACT_APP_KEYCLOAK_REDIRECT_URI
};

// Create the Keycloak client instance
const keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM as string,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENTID as string,
});
let isAuthenticatedResponse = false;
let groups = [] as string[];
let generalGroups = [] as string[];
/**
 * AuthContextValues defines the structure for the default values of the {@link AuthContext}.
 */
interface AuthContextValues {
  /**
   * Whether or not a user is currently authenticated
   */
  isAuthenticated: boolean;
  /**
   * Whether or not the groups are loaded
   */
  hasGroupsLoaded: boolean;
  /**
   * The name of the authenticated user
   */
  username: string;
  /**
   * The email of the authenticated user
   */
  email: string;
  /**
   * Function to initiate the logout
   */
  logout: () => void;
  /**
   * Sets the default mode depending on the role
   */
  defaultMode: () => UserMode;
  /**
   * Check if the user has the given role
   */
  hasRole: (role: string) => boolean;
  /**
   * True if the user is editor, validator or admin
   */
  hasEditorRole: (general?: boolean) => boolean;
  /**
   * True if the user is download or admin
   */
  hasDownloadRole: (general?: boolean) => boolean;

  setRoles: (roles: string[]) => void;

  groups: string[];

  generalGroups: string[];

  mainRole: (general: boolean) => string;

  isViewer: () => boolean;

}

/**
 * Default values for the {@link AuthContext}
 */
const AuthContextData: AuthContextValues = {
  isAuthenticated: false,
  hasGroupsLoaded: false,
  username: '',
  email: '',
  logout: () => { },
  defaultMode: () => 'viewer',
  hasRole: () => false,
  hasEditorRole: (general?: boolean) => false,
  hasDownloadRole: (general?: boolean) => false,
  setRoles: (roles: string[]) => { },
  groups: [],
  generalGroups: [],
  mainRole: (general: boolean) => '',
  isViewer: () => false
};

/**
 * Create the AuthContext using the default values.
 */
export const AuthContext = createContext<AuthContextValues>(
  AuthContextData
);

/**
 * The props that must be passed to create the {@link AuthContextProvider}.
 */
interface AuthContextProviderProps {
  /**
   * The elements wrapped by the auth context.
   */
  children: JSX.Element;
}

/**
 * AuthContextProvider is responsible for managing the authentication state of the current user.
 *
 * @param props
 */
const AuthContextProvider = (props: AuthContextProviderProps) => {
  // Create the local state in which we will keep track if a user is authenticated
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);
  // Local state that will contain the users name once it is loaded
  const [username, setUsername] = useState<any>(localStorage.getItem('username'));
  // Local state that will contain the users email once it is loaded
  const [email, setEmail] = useState<any>(localStorage.getItem('email'));
  // Create the local state in which we will keep track if a user is authenticated
  const [hasGroupsLoaded, setHasGroupLoaded] = useState<boolean>(false);

  // Effect used to initialize the Keycloak client. It has no dependencies so it is only rendered when the app is (re-)loaded.
  useEffect(() => {
    const tokenPhia = localStorage.getItem('tokenPhia');
    if (!tokenPhia) {
      keycloak.login({
        scope: 'groups'
      });
    } else {
      setAuthenticated(true);
    }

  }, []);

  // This effect loads the users profile in order to extract the username
  useEffect(() => {
    /**
     * Load the profile for of the user from Keycloak
     */
    async function loadProfile() {
      try {
        const profile: any = await keycloak.loadUserProfile();
        let username = '';
        const email = profile.email || '';
        if (profile.firstName) {
          username = profile.lastName ? profile.firstName + ' ' + profile.lastName : profile.firstName;
        } else if (profile.username) {
          username = profile.username;
        }
        setUsername(username);
        setEmail(email);
        if (!localStorage.getItem('username')) {
          localStorage.setItem('username', username);
        }
        if (!localStorage.getItem('email')) {
          localStorage.setItem('email', email);
        }
        if (keycloak.tokenParsed) {
          generalGroups = keycloak.tokenParsed['groups'];
          if (!generalGroups || !generalGroups.some((g) => g.startsWith('/phia/'))) {
            if (!profile['attributes'] || Object.keys(profile['attributes']).length === 0) {
              return window.location.href = '/error-usuario';
            } else {
              return window.location.href = '/error-permisos';
            }
          }
          generalGroups && localStorage.setItem('general_groups', JSON.stringify(generalGroups));
          setHasGroupLoaded(true);
        } else {
          return window.location.href = '/error-permisos';
        }
      } catch {
        console.log('error trying to load the users profile');
      }
    }

    // Only load the profile if a user is authenticated
    if (isAuthenticated) {
      loadProfile();
    }
  }, [isAuthenticated]);

  /**
   * Initiate the logout
   */
  const logout = async () => {
    localStorage.removeItem('tokenPhia');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('username');
    localStorage.removeItem('email');
    keycloak.logout(); // {redirectUri: process.env.REACT_APP_KEYCLOAK_REDIRECT_URI});
    isAuthenticatedResponse = false;
  };

  /**
   * Sets "editor" as default mode if user is validator. Otherwise, it sets the default mode with "viewer"
   * @param role to be checked
   * @returns the default mode
   */
  const defaultMode = () => {
    return groups && groups.includes(VALIDATOR) ? 'editor' : 'viewer';
  };

  /**
   * Check if the user has the given role
   * @param role to be checked
   * @returns whether or not if the user has the role
   */
  const hasRole = (role: string) => {
    //TODO: Check general groups if groups doesn't exist because there is a bug if we have a token when the page is loaded
    const groupsToCheck = groups.length === 0 ? localStorage.getItem('general_groups') : groups;
    if (groupsToCheck && groupsToCheck.includes(ADMIN)) return true;
    return groupsToCheck && groupsToCheck.includes(role) ? true : false;
  };

  const setRoles = (roles: string[]) => {
    groups = roles;
    localStorage.setItem('particular_groups', JSON.stringify(roles));
  };

  /**
   * Check if the user has the given role
   * @param role to be checked
   * @returns whether or not if the user has the role
   */
  const hasEditorRole = (general?: boolean) => {
    if (general) {
      const storageGeneralGroups = localStorage.getItem('general_groups');
      generalGroups = storageGeneralGroups && JSON.parse(storageGeneralGroups);
      if (generalGroups && generalGroups.includes(ADMIN)) return true;
      return generalGroups && (generalGroups.includes(EDITOR) || generalGroups.includes(VALIDATOR));
    }
    if (groups && groups.includes(ADMIN)) return true;
    return groups && (groups.includes(EDITOR) || groups.includes(VALIDATOR));
  };

  /**
   * Check if the user has the download role
   * @param role to be checked
   * @returns whether or not if the user has the download role
   */
  const hasDownloadRole = () => {
    const roles = [ADMIN, DOWNLOAD, VALIDATOR, EDITOR, VIEWER_VIP];
    const storageGeneralGroups = localStorage.getItem('general_groups');
    if (storageGeneralGroups && (roles.some(role => storageGeneralGroups.includes(role))))
      return true;
    else if (groups && (roles.some(role => groups.includes(role))))
      return true;
    else
      return false;
  };

  /**
   * Get the main role of the user
   * @param general if the role is general or particular
   * @returns the main role of the user
   */
  const mainRole = (general: boolean) => {
    let roles;
    if (general) {
      const groups = localStorage.getItem('general_groups');
      roles = groups ? JSON.parse(groups) : null;
    } else {
      const groups = localStorage.getItem('particular_groups');
      roles = groups ? JSON.parse(groups) : null;

    }
    return roles && roles.includes(ADMIN) ? ADMIN : roles && roles.includes(VALIDATOR) ? VALIDATOR : roles && roles.includes(EDITOR) ? EDITOR : roles && roles.includes(VIEWER_VIP) ? VIEWER_VIP : VIEWER;
  };

  const isViewer = () => {
    return !(hasRole(ADMIN) || hasRole(EDITOR) || hasRole(VIEWER_VIP) || hasRole(VALIDATOR));
  };

  // Setup the context provider
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        username,
        email,
        logout,
        defaultMode,
        hasRole,
        hasDownloadRole,
        hasEditorRole,
        setRoles,
        groups,
        generalGroups,
        mainRole,
        isViewer,
        hasGroupsLoaded,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

const initAuthContextProvider = async () => {
  try {
    await keycloak.init(
      keycloakInitOptions
    );
    if (keycloak.token && keycloak.isTokenExpired()) {
      await refreshToken();
    } else if (keycloak.token) {
      localStorage.setItem('tokenPhia', (keycloak.token as string));
      localStorage.setItem('refreshToken', (keycloak.refreshToken as string));
    }
    isAuthenticatedResponse = true;
  } catch (err) {
    console.error(err);
    isAuthenticatedResponse = false;
  }
  return;
};

const refreshToken = async () => {
  //if the refresh token is not available because the page refreshed entirely
  if (!keycloak.refreshToken && localStorage.getItem('refreshToken')) {
    keycloak.refreshToken = localStorage.getItem('refreshToken') as string;
  }
  // update the token always with -1
  const refreshed = await keycloak.updateToken(-1);
  if (refreshed) {
    localStorage.setItem('tokenPhia', (keycloak.token as string));
    localStorage.setItem('refreshToken', (keycloak.refreshToken as string));
  }
  return keycloak.token;
};

export { AuthContextProvider, initAuthContextProvider, refreshToken };